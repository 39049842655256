import React from "react";
import { useForm } from "react-hook-form";
import { useForgotPasswordMutation } from "../../../store/features/auth/authApi";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function ForgetPasswordForm() {
	const { register, handleSubmit } = useForm();
	const [forgotPassword, { isLoading: forgotPasswordLoading }] = useForgotPasswordMutation();
	const navigate = useNavigate();

	const onSubmit = async (data) => {
		const { email } = data;
		try {
			await forgotPassword({ email }).unwrap();
			// toast.success("A new password has been sent to your email");
			navigate("/auth/sign-in", { replace: true });
		} catch (error) {
			// toast.error(error.data?.message || "Request failed");
		}
	};

	return (
		<div className="mt-10">
			<div>
				<form
					onSubmit={handleSubmit(onSubmit)}
					action="#"
					method="POST"
					className="space-y-6"
				>
					<div>
						<label
							htmlFor="email"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Email
						</label>
						<div className="mt-2">
							<input
								id="email"
								type="email"
								autoComplete="email"
								{...register("email", { required: true })}
								className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="flex justify-center">
						<button
							type="submit"
							value="submit"
							disabled={forgotPasswordLoading}
							className="flex justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
						>
							Done
						</button>
					</div>
				</form>
			</div>
		</div>
	);
}

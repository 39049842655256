import { useSelector } from "react-redux";
import {
	selectCurrentEmail,
	selectCurrentName,
	selectCurrentRole,
	selectCurrentToken,
	selectCurrentWarehouseName,
} from "../store/features/auth/authSlice";

export default function useAuthSelector() {
	const email = useSelector(selectCurrentEmail);
	const token = useSelector(selectCurrentToken);
	const role = useSelector(selectCurrentRole);
	const warehouseName = useSelector(selectCurrentWarehouseName);
	const name = useSelector(selectCurrentName);

	return { email, name, token, role, warehouseName };
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetSingleProductQuery } from '../../../store/features/product-management/productManagementApi';
import useQuery from '../../../hooks/useQuery';
import { RectangleStackIcon } from '@heroicons/react/20/solid';
export default function ViewProduct() {
  const productId = useQuery().get('productId');
  const navigate = useNavigate();

  const { currentData } = useGetSingleProductQuery({
    productId
  });

  return (
    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div className="px-4 py-6 sm:p-8">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Product Name (In Bangla)
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.nameBn ?? 'N/A'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Product Name (In English)
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.nameEn ?? 'N/A'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Product Short Name (In Bangla)
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.shortNameBn ?? 'N/A'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Product Short Name (In English)
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.shortNameEn ?? 'N/A'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Short Description (In Bangla)
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.shortDescriptionBn || 'N/A'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Short Description (In English)
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.shortDescriptionEn || 'N/A'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Product Refundable?
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.isReturnable ? 'YES' : 'NO'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Refund Days within (from sell)
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.returnInDays ?? 'N/A'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Brand/Company
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.brand?.name ?? 'N/A'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Vendor/Supplier Name
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.vendor ?? 'N/A'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Unit Quantity
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.unitQuantity ?? 'N/A'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Unit Type
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.unitType ?? 'N/A'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              SKU Number
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.sku ?? 'N/A'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Weight (in KG)
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.unitWeight ?? 'N/A'}</span>
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Product Image 1
            </label>
            <img
              src={currentData?.data?.photo1}
              alt=""
              width="40"
              height="40"
            />
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Product Image 2
            </label>
            {currentData?.data?.photo2 ? (
              <img
                src={currentData?.data?.photo2}
                alt=""
                width="40"
                height="40"
              />
            ) : (
              <RectangleStackIcon className="w-10 h-10" color="gray" />
            )}
            {/* <img src={currentData?.data?.photo2} alt="Photo 2" width="40" height="40" /> */}
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Categories
            </label>
            <div className="mt-2 flex gap-2">
              {currentData?.data?.categories?.map((c) => (
                <p className="bg-orange-200 rounded-md px-1.5 py-1">
                  {c.nameEn}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Back
        </button>
      </div>
    </div>
  );
}

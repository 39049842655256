import { flexRender } from '@tanstack/react-table';
import React, { Fragment } from 'react';
import Divider from '../../common/Divider';

export default function TableBody({ table, columns }) {
  const renderSubComponent = ({ row }) => {
    return (
      <div className="bg-gray-200">
        <Fragment>
          <tr className="flex justify-evenly">
            <td className="whitespace-nowrap py-4 text-sm text-black w-32 font-semibold">
              SKU
            </td>
            <td className="whitespace-nowrap py-4 text-sm text-black w-32 font-semibold">
              NAME
            </td>
            <td className="whitespace-nowrap py-4 text-sm text-black w-32 font-semibold">
              RETURNED QUANTITY
            </td>
          </tr>
        </Fragment>
        <Divider darkBorder />

        {React.Children.toArray(
          row?.original?.items?.map((item) => {
            return (
              <Fragment>
                <tr className="flex justify-evenly">
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500 w-32">
                    {item.product?.sku}
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500 w-32">
                    {item.product?.nameEn}
                  </td>
                  <td className="whitespace-nowrap py-4 text-sm text-gray-500 w-32">
                    {item.returnedQuantity}
                  </td>
                </tr>
              </Fragment>
            );
          })
        )}
      </div>
    );
  };
  return (
    <tbody className="divide-y divide-gray-200 bg-white">
      {React.Children.toArray(
        table.getRowModel().rows.map((row) => (
          <>
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 pl-6"
                  key={cell.id}
                  align={cell.column.columnDef.meta?.align}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
            {row.getIsExpanded() && (
              <tr>
                <td colSpan={row.getVisibleCells().length}>
                  {renderSubComponent({ row })}
                </td>
              </tr>
            )}
          </>
        ))
      )}
    </tbody>
  );
}

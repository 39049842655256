import { clsx } from 'clsx';
import { toast } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function appendParamsToUrl(parameters) {
  function filterUndefinedValues(obj) {
    return Object.keys(obj).reduce((filteredObj, key) => {
      if (obj[key] !== undefined) {
        filteredObj[key] = obj[key];
      }
      return filteredObj;
    }, {});
  }

  const filterParameters = filterUndefinedValues(parameters);

  const searchParams = new URLSearchParams();

  for (const key in filterParameters) {
    if (filterParameters.hasOwnProperty(key)) {
      searchParams.append(key, filterParameters[key]);
    }
  }

  const queryParameterChain = searchParams.toString();

  return queryParameterChain;
}

export function constructFormData(body, fromProduct = false, payload) {
  const formData = new FormData();
  formData.append('file', body[0]);
  if (fromProduct) {
    formData.append(Object.keys(payload)[0], payload.featureName);
    formData.append(Object.keys(payload)[1], payload.fileName);
  }
  return formData;
}

export function isValidURL(url) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );

  return pattern.test(url);
}

export function getAccountStatus() {
  return [
    {
      id: '',
      name: 'Select Status'
    },
    {
      id: 'ACTIVE',
      name: 'ACTIVE'
    },
    {
      id: 'INACTIVE',
      name: 'INACTIVE'
    }
  ];
}

export function getPaymentStatus() {
  return [
    {
      id: '',
      name: 'Select Payment Status'
    },
    {
      id: 'Due',
      name: 'Due'
    },
    {
      id: 'Paid',
      name: 'Paid'
    }
  ];
}

export function getOrderStatus() {
  return [
    {
      id: '',
      name: 'Select Order Status'
    },
    {
      id: 'Delivered',
      name: 'DELIVERED'
    },
    {
      id: 'Pending',
      name: 'PENDING'
    },
    {
      id: 'In_Progress',
      name: 'IN PROGRESS'
    },
    {
      id: 'Cancelled',
      name: 'CANCELLED'
    }
  ];
}

export function getRole() {
  return [
    {
      id: '',
      name: 'Select Role'
    },
    {
      id: 'SUPER_ADMIN',
      name: 'SUPER_ADMIN'
    },
    {
      id: 'WAREHOUSE_ADMIN',
      name: 'WAREHOUSE_ADMIN'
    },
    {
      id: 'SALES_ASSOCIATE',
      name: 'SALES_ASSOCIATE'
    },
    {
      id: 'WAREHOUSE_ASSOCIATE',
      name: 'WAREHOUSE_ASSOCIATE'
    },
    {
      id: 'DELIVERY_ASSOCIATE',
      name: 'DELIVERY_ASSOCIATE'
    },
    {
      id: 'DATA_USER',
      name: 'DATA_USER'
    },
    {
      id: 'CUSTOMER',
      name: 'CUSTOMER'
    }
  ];
}
// Function to get the next day's date in YYYY-MM-DD format
export function getNextDay() {
  const today = new Date();
  today.setDate(today.getDate() + 1);
  return today.toISOString().split('T')[0];
}

export function camelCaseToSpaces(str) {
  // Insert a space before each uppercase letter
  let result = str.replace(/([A-Z])/g, ' $1');
  // Capitalize the first letter of the result
  result = result.charAt(0).toUpperCase() + result.slice(1);
  return result;
}

export function findUpdatedValues(obj1, obj2) {
  function compareObjects(obj1, obj2, path = '') {
    let changes = {};

    if (obj1 === obj2) return changes;

    if (
      typeof obj1 !== 'object' ||
      obj1 === null ||
      typeof obj2 !== 'object' ||
      obj2 === null
    ) {
      if (obj1 !== obj2) {
        setNestedValue(changes, path, obj2);
      }
      return changes;
    }

    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    // let allKeys = new Set([...keys1, ...keys2]);
    // console.log('🚀 ~ compareObjects ~ allKeys:', allKeys);

    for (let key of keys1) {
      const newPath = path ? `${path}.${key}` : key;
      // if (!keys1.includes(key)) {
      //   setNestedValue(changes, newPath, obj2[key]);
      // } else if (!keys2.includes(key)) {
      //   setNestedValue(changes, newPath, undefined);
      // } else {
      const subChanges = compareObjects(obj1[key], obj2[key], newPath);
      Object.assign(changes, subChanges);
      // }
    }

    return changes;
  }

  function setNestedValue(obj, path, value) {
    const keys = path.split('.');
    let current = obj;
    while (keys.length > 1) {
      const key = keys.shift();
      if (!current[key]) current[key] = {};
      current = current[key];
    }
    current[keys[0]] = value;
  }

  return compareObjects(obj1, obj2);
}

export function mapErrorKeyToMessage(key) {
  switch (key) {
    case 'email.isEmail':
      return 'Please provide a valid email address.';
    case 'warehouseId.isUuid':
      return 'Please select a warehouse!';
    case 'role.isEnum':
      return 'Please select an appropriate role!';
    case 'routeId.isUuid':
      return 'Please select a route';
    default:
      return 'An error occurred. Please try again later.';
  }
}

export function handleErrorMessage(error) {
  const errorDescriptions = error.error.data?.error?.description;
  if (errorDescriptions && typeof errorDescriptions !== 'string') {
    const descriptionsArray = Object.keys(errorDescriptions);
    let customMessage;
    descriptionsArray.forEach((key) => {
      customMessage = mapErrorKeyToMessage(key);
    });
    toast.error(customMessage);
  } else {
    const errorMessage = error.error.data.message;
    toast.error(errorMessage);
  }
}

export function mapFilterToUrlParam(data) {
  let urlParam = '';
  Object.entries(data)?.forEach(([key, value], index) => {
    index === 0 ? (urlParam += '?') : (urlParam += '&');
    urlParam += `${key}=${value}`;
  });
  return urlParam;
}

export function getQueryParams(queryString) {
  const params = new URLSearchParams(queryString);
  const paramsObject = {};

  for (const [key, value] of params.entries()) {
    // If the key already exists, convert it to an array (if it's not already an array)
    if (paramsObject[key]) {
      if (Array.isArray(paramsObject[key])) {
        paramsObject[key].push(value);
      } else {
        paramsObject[key] = [paramsObject[key], value];
      }
    } else {
      paramsObject[key] = value;
    }
  }

  return paramsObject;
}

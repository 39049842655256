import { PlusCircleIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import React, { Fragment } from 'react';

export default function CustomerSummary({
  title,
  title2,
  customer,
  noAssignment = false,
  readOnly = false,
  orderId,
  removeAssignee,
  setOpen
}) {
  return (
    <Fragment>
      <div className="flex justify-between items-center bg-gray-200 rounded py-2 px-4">
        <div className="text-gray-700 text-lg font-bold">{title}</div>
      </div>
      <div className="py-4 border-r-2 bg-white rounded">
        <div className="mb-2 px-4 font-semibold">Name: {customer?.name}</div>
        <div className="mb-2 px-4 font-semibold">Phone: {customer?.phone}</div>
        <div className="mb-2 px-4 font-semibold">
          Chamak ID: {customer?.chamakId}
        </div>
        <div className="mb-2 px-4 font-semibold">
          Route Name: {customer?.routeName}
        </div>
        <div className="mb-4 px-4 font-semibold">
          Address: {customer?.address?.full}, {customer?.address?.village},{' '}
          {customer?.address?.thana}, {customer?.address?.district}
        </div>
        {!noAssignment && (
          <>
            <h4 className="text-gray-700 text-lg font-bold bg-gray-200 py-2 px-4">
              {title2 || 'Delivery Associate'}
            </h4>
            {customer?.deliveryAssociate?.name && (
              <Fragment>
                <div className="flex items-center justify-between pl-4 pr-8 pt-2">
                  <div className="my-2 flex items-center">
                    <UserCircleIcon className="h-12 w-12 text-gray-400" />
                    <div>
                      <div className="font-medium ml-2 text-gray-600">
                        {customer?.deliveryAssociate?.name}
                      </div>
                      <div className="font-medium ml-2 text-red-600">
                        {customer?.deliveryAssociate?.phone}
                      </div>
                    </div>
                  </div>
                  {!readOnly && (
                    <div>
                      <span
                        className="font-medium text-red-600 hover:text-red-500 cursor-pointer"
                        onClick={() => removeAssignee(orderId)}
                      >
                        Remove
                      </span>
                    </div>
                  )}
                </div>
              </Fragment>
            )}
            {!customer?.deliveryAssociate?.name && (
              <div className="flex items-center">
                <div
                  onClick={() => setOpen(true)}
                  className="mt-2 mx-4 flex items-center cursor-pointer py-2 pr-4 hover:bg-gray-100 rounded-xl transition-all hover:shadow-sm"
                >
                  <PlusCircleIcon className="h-12 w-12 text-gray-400" />
                  <span className="font-medium ml-2 text-red-600">Assign</span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Fragment>
  );
}

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import EditConfigForm from '../../../components/app/config-warehouse/EditConfigForm';

const pageMeta = {
  page_name: 'Edit Config'
};

export default function EditConfig() {
  const pages = [
    {
      name: 'Warehouse Configuration',
      location: '/app/config-warehouse/',
      current: false
    },
    {
      name: 'Edit',
      location: `/app/config-warehouse/edit`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-start">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            <Link to="/app/config-warehouse/view">
              <span className="flex items-center gap-4 hover:text-gray-500 transition-all">
                <ArrowLeftIcon className="h-7 w-7" />
                {pageMeta.page_name}
              </span>
            </Link>
          </h1>
          <div className="my-6">
            <BreadCrumbs pages={pages} />
          </div>
        </div>
      </div>
      <EditConfigForm />
    </div>
  );
}

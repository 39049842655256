import {
  PlusIcon,
  DocumentArrowDownIcon,
  DocumentIcon,
  RectangleStackIcon
} from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ApplyFilter from '../../../components/common/ApplyFilter';
import Table from '../../../components/common/DataTable/Table';
import TablePagination from '../../../components/common/TablePagination';
import {
  useCreateBulkProductMutation,
  useGetProductManagementQuery
} from '../../../store/features/product-management/productManagementApi';
import { isEmpty } from 'lodash';
import { useGetCategoryQuery } from '../../../store/features/category/categoryApi';
import { getQueryParams, mapFilterToUrlParam } from '../../../lib/utils';
import { toast } from 'react-hot-toast';
import useAuthSelector from '../../../hooks/useAuthSelector';

const rootMeta = {
  page_name: 'Product Management',
  breadcrumbs: null
};

export default function ProductManagementRoot() {
  const { token } = useAuthSelector();
  const location = useLocation();
  const queryParamsString = location.search;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});

  const handleSampleFile = async () => {
    const url = `${process.env.REACT_APP_API_BASE_URL}api/products/sample-file`;
    const headers = {
      Authorization: `Bearer ${token}`
    };
    try {
      const response = await fetch(url, {
        headers: headers
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlBlob;
      a.download = 'product-sample-upload.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      toast.error('Failed to download sample file');
    }
  };

  const { currentData, isLoading } = useGetProductManagementQuery(
    !isEmpty(selectedData)
      ? {
          page,
          ...selectedData
        }
      : {
          page
        }
  );

  const [callCreateBulkProductMutation, {}] = useCreateBulkProductMutation();

  const {} = useGetCategoryQuery();

  const searchModes = [
    {
      param_name: 'Name',
      param: 'nameEn',
      type: 'text'
    },
    {
      param_name: 'SKU',
      param: 'sku',
      type: 'text'
    },
    {
      param_name: 'Brand',
      param: 'brand',
      type: 'text'
    },
    {
      param_name: 'Supplier',
      param: 'supplier',
      type: 'text'
    },
    {
      param_name: 'Is Returnable',
      param: 'isReturnable',
      type: 'radio'
    }
  ];

  const truncateText = (text, length) => {
    if (!text) return '';
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU NO.',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div title={row?.nameEn}>{truncateText(row?.nameEn, 40)}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex gap-x-2">
            {row.photo1 ? (
              <img src={row.photo1} alt="" width="30" height="30" />
            ) : (
              <RectangleStackIcon className="w-10 h-10" color="gray" />
            )}
            {row.photo2 && (
              <img src={row.photo2} alt="" width="30" height="30" />
            )}
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'PHOTO',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{`${row.unitQuantity} ${row.unitType}`}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'UNIT',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div title={row?.shortNameEn}>
            {truncateText(row?.shortNameEn, 20)}
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SHORT NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor((row) => <Fragment>{row.unitWeight}</Fragment>, {
      cell: (info) => info.getValue(),
      header: 'WEIGHT (KG)',
      meta: { align: 'center', style: { textAlign: 'center' } }
    }),
    columnHelper.accessor((row) => <Fragment>{row.returnInDays}</Fragment>, {
      cell: (info) => info.getValue(),
      header: 'REFUNDABLE (DAYS)',
      meta: { align: 'center', style: { textAlign: 'center' } }
    }),
    columnHelper.accessor((row) => <Fragment>{row.brand?.name}</Fragment>, {
      cell: (info) => info.getValue(),
      header: 'BRAND/COMPANY',
      meta: { align: 'center', style: { textAlign: 'center' } }
    }),
    columnHelper.accessor((row) => <Fragment>{row.supplier}</Fragment>, {
      cell: (info) => info.getValue(),
      header: 'VENDOR/SUPPLIER',
      meta: { align: 'center', style: { textAlign: 'center' } }
    }),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <Link to={`/app/product-management/details?productId=${row.id}`}>
            <button className="text-red-600 ml-6 hover:underline mx-2">
              View
            </button>
          </Link>
          <Link to={`/app/product-management/edit?productId=${row.id}`}>
            <button className="text-red-600 hover:underline mx-2">Edit</button>
          </Link>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];

  const bulkUploadProducts = (file) => {
    callCreateBulkProductMutation(file);
  };

  const handleSelectedData = (data) => {
    navigate(`/app/product-management/view${mapFilterToUrlParam(data)}`);
    setSelectedData(data);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedData]);

  useEffect(() => {
    setSelectedData(getQueryParams(queryParamsString));
  }, [queryParamsString]);

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-0 sm:mt-0 sm:flex-none md:flex-none md:ml-16">
          <div className="flex items-center">
            <ApplyFilter
              data={searchModes}
              selectedData={selectedData}
              handleSelectedData={handleSelectedData}
            />
            <button
              type="button"
              onClick={handleSampleFile}
              className="mr-4 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
            >
              <DocumentIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Download Sample
            </button>
            <div className="relative">
              <label
                htmlFor="file-input"
                className="inline-flex items-center gap-x-1.5 mr-4 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all cursor-pointer"
              >
                <DocumentArrowDownIcon
                  className="-ml-0.5 h-5 w-5"
                  aria-hidden="true"
                />
                Bulk Upload
              </label>
              <input
                id="file-input"
                type="file"
                onChange={(e) => bulkUploadProducts(e.target.files)}
                className="hidden"
              />
            </div>
            <Link
              to="/app/product-management/create"
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Create Product
            </Link>
          </div>
        </div>
      </div>
      <Table data={currentData?.data?.items ?? []} columns={columns} />
      <TablePagination
        paginationData={currentData?.data?.meta}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

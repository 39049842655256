import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import toast from 'react-hot-toast';
import { useUpdateMfaMutation } from '../../../store/features/profile/mfaApi';
import { useGetProfileQuery } from '../../../store/features/profile/profileApi';

export default function MfaUpdate() {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const [mfaEnabled, setMfaEnabled] = useState(false);

  const { currentData } = useGetProfileQuery({});
  const [updateMfa, { isLoading, isSuccess, isError, error }] =
    useUpdateMfaMutation();

  useEffect(() => {
    if (currentData) {
      setMfaEnabled(currentData?.data?.isMfaEnabled);
      setValue('enable', currentData?.data?.isMfaEnabled);
      setValue('phone', currentData?.data?.mfaContact || '');
    }
  }, [currentData, setValue]);

  const onSubmit = async (data) => {
    if (data.enable && !data.phone) {
      toast.error('Phone number is required when MFA is enabled.');
    } else {
      await updateMfa(data);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/app/users/view');
    }

    if (isError) {
      toast.error(
        error?.data?.message || 'Failed to update MFA. Please try again later.'
      );
    }
  }, [isSuccess, isError, error, navigate]);

  const pageMeta = {
    page_name: 'Update MFA'
  };

  const pages = [
    {
      name: 'MFA',
      location: '/app/mfa-update',
      current: true
    }
  ];

  return (
    <>
      <div className="sm:px-4">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold leading-6 text-gray-900">
              <span className="flex items-center gap-4">
                <Link
                  to="/app/mfa-update/view"
                  className="hover:text-gray-500 transition-all"
                >
                  {pageMeta.page_name}
                </Link>
              </span>
            </h1>
            <div className="my-6">
              <BreadCrumbs pages={pages} />
            </div>
          </div>
        </div>
      </div>
      <Form>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid grid-cols-1 gap-y-8">
              {/* MFA Toggle */}
              <div className="flex items-center gap-4">
                <span className="block text-sm font-medium leading-6 text-gray-900">
                  Enable MFA
                </span>
                <Controller
                  name="enable"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="checkbox"
                      {...field}
                      checked={mfaEnabled}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        field.onChange(isChecked);
                        setMfaEnabled(isChecked);
                      }}
                      className="h-6 w-6 text-red-600 focus:ring-red-600 border-gray-300 rounded"
                    />
                  )}
                />
              </div>

              {/* Password */}
              <div>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <FormItem>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Password*
                      </label>
                      <FormControl>
                        <div className="mt-2">
                          <input
                            type="password"
                            {...field}
                            id="password"
                            placeholder="Enter Password"
                            className={clsx(
                              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                            )}
                          />
                        </div>
                      </FormControl>
                      {errors.password && <FormMessage />}
                    </FormItem>
                  )}
                />
              </div>

              {/* Phone Number */}
              {mfaEnabled && (
                <div>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <FormItem>
                        <label
                          htmlFor="phone-number"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Phone Number*
                        </label>
                        <FormControl>
                          <div className="mt-2">
                            <input
                              type="text"
                              {...field}
                              id="phone-number"
                              placeholder="Enter Phone Number"
                              className={clsx(
                                'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                              )}
                            />
                          </div>
                        </FormControl>
                        {/* {toast.error('Enter the phone number correctly')} */}
                        {errors.phone && <FormMessage />}
                      </FormItem>
                    )}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button
              type="button"
              onClick={() => navigate('/app/users/view')}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>

            <button
              type="submit"
              disabled={isLoading}
              className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
            >
              Update Mfa
            </button>
          </div>
        </form>
      </Form>
    </>
  );
}

const Form = ({ children }) => <div>{children}</div>;

const FormItem = ({ children }) => <div>{children}</div>;

const FormControl = ({ children }) => <div>{children}</div>;

const FormMessage = () => <p className="text-red-600">Error message</p>;

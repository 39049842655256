import { MinusIcon } from '@heroicons/react/20/solid';
import {
  CheckCircleIcon,
  CurrencyBangladeshiIcon,
  PlusCircleIcon
} from '@heroicons/react/24/outline';
import React, { Fragment } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { LoaderIcon } from 'react-hot-toast';
import ChangingProgressProvider from '../../common/ChangingProgressProvider';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

export default function StatsMarker({ stats }) {
  const navigate = useNavigate();

  return (
    <div>
      <div className="p-3 pb-5 flex space-x-12 justify-center my-7">
        <div className="w-32 h-32 2xl:w-48 2xl:h-48">
          <ChangingProgressProvider
            values={[
              0,
              parseFloat(
                (stats?.totalCreatedOrder !== 0
                  ? (stats?.totalDeliveredOrder / stats?.totalCreatedOrder) *
                    100
                  : 0
                ).toFixed(2)
              )
            ]}
          >
            {(percentage) => (
              <>
                <CircularProgressbar
                  value={percentage}
                  text={`${parseFloat(percentage).toFixed(2)}%`}
                  styles={buildStyles({
                    pathTransition:
                      percentage === 0
                        ? 'none'
                        : 'stroke-dashoffset 0.5s ease 0s'
                  })}
                />
                <div className="text-center mt-2">Delivered</div>
              </>
            )}
          </ChangingProgressProvider>
        </div>

        <div className="w-32 h-32 2xl:w-48 2xl:h-48">
          <ChangingProgressProvider
            values={[
              0,
              parseFloat(
                (stats?.totalInProgressOrder !== 0
                  ? (stats?.totalInProgressOrder / stats?.totalCreatedOrder) *
                    100
                  : 0
                ).toFixed(2)
              )
            ]}
          >
            {(percentage) => (
              <>
                <CircularProgressbar
                  value={percentage}
                  text={`${parseFloat(percentage).toFixed(2)}%`}
                  styles={buildStyles({
                    pathTransition:
                      percentage === 0
                        ? 'none'
                        : 'stroke-dashoffset 0.5s ease 0s'
                  })}
                />
                <div className="text-center mt-2">In Progress</div>
              </>
            )}
          </ChangingProgressProvider>
        </div>

        <div className="w-32 h-32 2xl:w-48 2xl:h-48">
          <ChangingProgressProvider
            values={[
              0,
              parseFloat(
                (stats?.totalCreatedOrder !== 0
                  ? (stats?.totalCancelledOrder / stats?.totalCreatedOrder) *
                    100
                  : 0
                ).toFixed(2)
              )
            ]}
          >
            {(percentage) => (
              <>
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage.toFixed(2)}%`}
                  styles={buildStyles({
                    pathTransition:
                      percentage === 0
                        ? 'none'
                        : 'stroke-dashoffset 0.5s ease 0s'
                  })}
                />
                <div className="text-center mt-2">Cancelled</div>
              </>
            )}
          </ChangingProgressProvider>
        </div>

        <div className="w-32 h-32 2xl:w-48 2xl:h-48">
          <ChangingProgressProvider
            values={[
              0,
              parseFloat(
                (stats?.totalDeliveredOrderAmount !== 0
                  ? (stats?.totalReceivedByAdmin /
                      stats?.totalDeliveredOrderAmount) *
                    100
                  : 0
                ).toFixed(2)
              )
            ]}
          >
            {(percentage) => (
              <>
                <CircularProgressbar
                  value={percentage}
                  text={`${percentage.toFixed(2)}%`}
                  styles={buildStyles({
                    pathTransition:
                      percentage === 0
                        ? 'none'
                        : 'stroke-dashoffset 0.5s ease 0s'
                  })}
                />
                <div className="text-center mt-2">Cash Received</div>
              </>
            )}
          </ChangingProgressProvider>
        </div>
      </div>

      <dl className="mt-20 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats && (
          <Fragment>
            {stats?.totalCreatedOrder !== null && (
              <Stat
                Icon={PlusCircleIcon}
                iconClassName="bg-blue-500"
                title="Created Order"
                data={stats?.totalCreatedOrder}
                onClick={() => navigate('/app/order-history/view')}
              />
            )}

            {stats?.totalCreatedOrderAmount !== null && (
              <Stat
                Icon={CurrencyBangladeshiIcon}
                iconClassName="bg-blue-500"
                title="Total Created Order Amount"
                data={stats?.totalCreatedOrderAmount}
                onClick={() => navigate('/app/order-history/view')}
              />
            )}

            {stats?.totalCompletedOrder !== null && (
              <Stat
                Icon={CheckCircleIcon}
                iconClassName="bg-green-500"
                title="Delivered Order"
                data={stats?.totalDeliveredOrder}
                onClick={() => navigate('/app/order-history/view?status=Delivered')}
              />
            )}

            {stats?.totalCollectedOrderAmount !== null && (
              <Stat
                Icon={CurrencyBangladeshiIcon}
                iconClassName="bg-green-500"
                title="Total Delivered Order Amount"
                data={stats?.totalDeliveredOrderAmount}
                onClick={() => navigate('/app/order-history/view?status=Delivered')}
                />
            )}

            {stats?.totalInProgressOrder !== null && (
              <Stat
                Icon={CurrencyBangladeshiIcon}
                iconClassName="bg-yellow-500"
                title="Total In Progress Order"
                data={stats?.totalInProgressOrder}
                onClick={() => navigate('/app/order-history/view?status=In_Progress')}
              />
            )}

            {stats?.totalInProgressOrderAmount !== null && (
              <Stat
                Icon={CurrencyBangladeshiIcon}
                iconClassName="bg-yellow-500"
                title="Total In Progress Order Amount"
                data={stats?.totalInProgressOrderAmount}
                onClick={() => navigate('/app/order-history/view?status=In_Progress')}
              />
            )}

            {stats?.totalCancelledOrder !== null && (
              <Stat
                Icon={MinusIcon}
                iconClassName="bg-red-500"
                title="Cancelled Order"
                data={stats?.totalCancelledOrder}
                onClick={() => navigate('/app/order-history/view?status=Cancelled')}
              />
            )}

            {stats?.totalReceivedByAdmin !== null && (
              <Stat
                Icon={CurrencyBangladeshiIcon}
                iconClassName="bg-gray-500"
                title="Received By Admin"
                data={stats?.totalReceivedByAdmin}
              />
            )}
          </Fragment>
        )}
      </dl>
    </div>
  );
}

const Stat = ({ Icon, iconClassName, title, data, onClick }) => {

  return (
    <div
      className="relative overflow-hidden rounded-lg bg-white px-6 py-7 shadow sm:px-8 sm:pt-8 hover:bg-gray-200"
      onClick={onClick}
    >
      <div className="flex items-center">
        <div className={clsx(`rounded-md p-3 ${iconClassName}`)}>
          <Icon className="h-8 w-7 text-white" aria-hidden="true" />
        </div>
        <div className="ml-6">
          <p className="text-sm font-medium text-gray-500">{title}</p>
          <p className="text-xl font-semibold text-gray-900">
            {data ?? <LoaderIcon className="h-7 w-7 mt-1" />}
          </p>
        </div>
      </div>
    </div>
  );
};

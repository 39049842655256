import clsx from 'clsx';
import React, { Fragment } from 'react';
import { getNextDay } from '../../lib/utils';

export default function DeliveryInstruction({ title, handleDeliveryData }) {
  return (
    <Fragment>
      <div className="flex justify-between items-center bg-gray-200 rounded py-2 px-4">
        <div className="text-gray-700 text-lg font-bold">{title}</div>
      </div>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 bg-white rounded p-4">
        <div className="sm:col-span-3">
          <label
            htmlFor="deliveryInstruction"
            className="mb-2 block font-medium leading-6 text-gray-900"
          >
            Delivery Date
          </label>
          <input
            type="date"
            id="deliveryInstruction"
            defaultValue={getNextDay()}
            onChange={(e) => handleDeliveryData('date', e.target.value)}
            placeholder={'Enter Delivery Instruction'}
            className={clsx(
              'w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
            )}
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="deliveryInstruction"
            className="mb-2 block font-medium leading-6 text-gray-900"
          >
            Delivery Instruction
          </label>
          <textarea
            id="deliveryInstruction"
            onBlur={(e) => handleDeliveryData('instruction', e.target.value)}
            placeholder={'Enter Delivery Instruction'}
            className={clsx(
              'w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
            )}
          />
        </div>
      </div>
    </Fragment>
  );
}

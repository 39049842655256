import React, { useState, useEffect } from 'react';

const ChangingProgressProvider = ({ values, children }) => {
  const [valuesIndex, setValuesIndex] = useState(0);

  useEffect(() => {
    setValuesIndex((prevIndex) => (prevIndex + 1) % values.length);
  }, []);

  return <>{children(values[valuesIndex])}</>;
};

export default ChangingProgressProvider;

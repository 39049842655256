import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import useQuery from '../../../hooks/useQuery';
import EditSalesAssociateForm from '../../../components/app/manage-sales-associate/EditSalesAssociateForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Edit Sales Associate'
};

export default function EditSalesAssociate() {
  const query = useQuery();

  const pages = [
    {
      name: 'Sales Associate',
      location: '/app/manage-sales-associate/',
      current: false
    },
    {
      name: 'Edit',
      location: `/app/manage-sales-associate/edit?${query.get(
        'salesAssociateId'
      )}`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <EditSalesAssociateForm />
    </div>
  );
}

import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import FilterTray from '../../../components/app/reports/FilterTray';
import ReportDownloader from '../../../components/app/reports/ReportDownloader';
import { useGetReportsDataUserQuery } from '../../../store/features/reports/reportsApi';
import StatsMarkerDataUser from '../../../components/app/reports-data-user/StatsMarkerDataUser';
import FilterTrayDataUser from '../../../components/app/reports-data-user/FilterTrayDataUser';

const rootMeta = {
  page_name: 'Data User Dashboard'
};

export default function ReportsDataUserRoot() {
  const [customDate, setCustomDate] = useState({
    from: null,
    to: null
  });

  const [applyFilter, setApplyFilter] = useState(false);

  const [currentFilter, setCurrentFilter] = useState('TODAY');
  const [selectedIdType, setSelectedIdType] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const { currentData } = useGetReportsDataUserQuery(
    applyFilter
      ? {
          // rangeType: currentFilter,
          idType: selectedIdType?.key,
          id: selectedId?.id,
          startDate:
            currentFilter === 'CUSTOM' && customDate?.from
              ? dayjs(customDate?.from).toISOString()
              : undefined,

          endDate:
            currentFilter === 'CUSTOM'
              ? customDate?.to !== ''
                ? dayjs(customDate?.to).toISOString()
                : customDate?.from !== ''
                ? dayjs(customDate?.from).toISOString('')
                : undefined
              : undefined
        }
      : {
          // rangeType: currentFilter,
          startDate:
            currentFilter === 'CUSTOM' && customDate?.from
              ? dayjs(customDate?.from).toISOString()
              : undefined,

          endDate:
            currentFilter === 'CUSTOM'
              ? customDate?.to !== ''
                ? dayjs(customDate?.to).toISOString()
                : customDate?.from !== ''
                ? dayjs(customDate?.from).toISOString('')
                : undefined
              : undefined
        }
  );

  useEffect(() => {
    currentFilter !== 'CUSTOM' &&
      setCustomDate({
        from: null,
        to: null
      });
  }, [currentFilter]);

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
      </div>
      {/* <div className="flex items-end justify-end">
        <FilterTrayDataUser
          enableSidebarFilter={true}
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
          setDate={setCustomDate}
          date={customDate}
          selectedIdType={selectedIdType}
          setSelectedIdType={setSelectedIdType}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setApplyFilter={setApplyFilter}
        />
      </div> */}
      {/* <ReportDownloader rawData={currentData?.data} /> */}

      <StatsMarkerDataUser stats={currentData?.data ?? []} />
    </div>
  );
}


// import dayjs from 'dayjs';
// import React, { useEffect, useState } from 'react';
// import { DayPicker, SelectRangeProvider } from 'react-day-picker';
// import 'react-day-picker/dist/style.css';
// import { useGetReportsDataUserQuery } from '../../../store/features/reports/reportsApi';
// import StatsMarkerDataUser from '../../../components/app/reports-data-user/StatsMarkerDataUser';

// const rootMeta = {
//   page_name: 'Data User Dashboard'
// };

// export default function ReportsDataUserRoot() {
//   const [customDate, setCustomDate] = useState({
//     from: dayjs().toDate(),
//     to: dayjs().toDate()
//   });

//   const [applyFilter, setApplyFilter] = useState(false);
//   const [currentFilter, setCurrentFilter] = useState('TODAY');
//   const [selectedIdType, setSelectedIdType] = useState(null);
//   const [selectedId, setSelectedId] = useState(null);
//   const [isCalendarOpen, setIsCalendarOpen] = useState(false);

//   const { currentData } = useGetReportsDataUserQuery(
//     applyFilter
//       ? {
//           idType: selectedIdType?.key,
//           id: selectedId?.id,
//           startDate:
//             currentFilter === 'CUSTOM' && customDate?.from
//               ? dayjs(customDate?.from).toISOString()
//               : undefined,
//           endDate:
//             currentFilter === 'CUSTOM'
//               ? customDate?.to
//                 ? dayjs(customDate?.to).toISOString()
//                 : customDate?.from
//                 ? dayjs(customDate?.from).toISOString()
//                 : undefined
//               : undefined
//         }
//       : {
//           startDate:
//             currentFilter === 'CUSTOM' && customDate?.from
//               ? dayjs(customDate?.from).toISOString()
//               : undefined,
//           endDate:
//             currentFilter === 'CUSTOM'
//               ? customDate?.to
//                 ? dayjs(customDate?.to).toISOString()
//                 : customDate?.from
//                 ? dayjs(customDate?.from).toISOString()
//                 : undefined
//               : undefined
//         }
//   );

//   useEffect(() => {
//     if (currentFilter !== 'CUSTOM') {
//       setCustomDate({
//         from: dayjs().toDate(),
//         to: dayjs().toDate()
//       });
//     }
//   }, [currentFilter]);

//   useEffect(() => {
//     if (applyFilter) {
//       setApplyFilter(false); // Reset applyFilter after applying the filter
//     }
//   }, [applyFilter]);

// const handleDateChange = (range) => {
//   if (!range || !range.from || !range.to) {
//     setCustomDate({
//       fromDate: dayjs().format('YYYY-MM-DD'),
//       toDate: dayjs().format('YYYY-MM-DD')
//     });
//   } else if (dayjs(range.from).isSame(dayjs(range.to), 'day')) {
//     setCustomDate({
//       fromDate: dayjs(range.from).format('YYYY-MM-DD'),
//       toDate: dayjs(range.to).format('YYYY-MM-DD')
//     });
//   } else {
//     const { from, to } = range;
//     setCustomDate({
//       fromDate: from
//         ? dayjs(from).format('YYYY-MM-DD')
//         : dayjs().format('YYYY-MM-DD'),
//       toDate: to
//         ? dayjs(to).format('YYYY-MM-DD')
//         : dayjs().format('YYYY-MM-DD')
//     });
//   }
// };

//   return (
//     <SelectRangeProvider
//       initialProps={{
//         selected: { from: customDate.from, to: customDate.to }
//       }}
//     >
//       <div className="sm:px-4">
//         <div className="sm:flex sm:items-center justify-between">
//           <div className="sm:flex-auto">
//             <h1 className="text-3xl font-semibold leading-6 text-gray-900">
//               {rootMeta.page_name}
//             </h1>
//           </div>
//           <div className="relative">
//             <button
//               onClick={() => setIsCalendarOpen(!isCalendarOpen)}
//               className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
//             >
//               Filter Date
//             </button>
//             {isCalendarOpen && (
//               <div className="absolute right-0 mt-2 bg-white border border-gray-300 rounded shadow-lg z-10">
//                 <DayPicker
//                   mode="range"
//                   selected={{
//                     from: customDate.from,
//                     to: customDate.to
//                   }}
//                   onSelect={handleDateChange}
//                 />
//               </div>
//             )}
//           </div>
//         </div>
//         <div className="flex items-end justify-end mt-4">
//           {/* Uncomment this if you want to use FilterTrayDataUser
//           <FilterTrayDataUser
//             enableSidebarFilter={true}
//             currentFilter={currentFilter}
//             setCurrentFilter={setCurrentFilter}
//             setDate={setCustomDate}
//             date={customDate}
//             selectedIdType={selectedIdType}
//             setSelectedIdType={setSelectedIdType}
//             selectedId={selectedId}
//             setSelectedId={setSelectedId}
//             setApplyFilter={setApplyFilter}
//           /> */}
//         </div>
//         {/* Uncomment this if you want to use ReportDownloader
//         <ReportDownloader rawData={currentData?.data} /> */}
//         <StatsMarkerDataUser stats={currentData?.data ?? []} />
//       </div>
//     </SelectRangeProvider>
//   );
// }

import { Navigate, useLocation } from "react-router-dom";
import useAuthSelector from "../hooks/useAuthSelector";

export default function AuthRoutes({ children }) {
	const { token } = useAuthSelector();
	const location = useLocation();
	if (!token) {
		return children;
	} else {
		return <Navigate to="/" state={{ from: location }} replace />;
	}
}

import toast from 'react-hot-toast';
import { chamakApi } from '../../app/api';
import { appendParamsToUrl } from '../../../lib/utils';

export const brandApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getBrands: builder.query({
      query: ({ ...query }) => ({
        url: `/api/brands?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getBrands']
    }),
    createBrand: builder.mutation({
      query: ({ ...query }) => ({
        url: '/api/brands/',
        method: 'POST',
        body: {
          ...query
        }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Brand created successfully!');
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
      invalidatesTags: ['getBrands']
    }),
    getOneBrand: builder.query({
      query: ({ brandId }) => ({
        url: `/api/brands/${brandId}`,
        method: 'GET'
      }),
      providesTags: ['getOneBrand']
    }),
    updateBrand: builder.mutation({
      query: ({ brandId, ...body }) => ({
        url: `/api/brands/${brandId}`,
        method: 'PUT',
        body
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          toast.success('Brand updated successfully');
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
      invalidatesTags: ['getBrands', 'getOneBrand']
    })
  })
});

export const {
  useGetBrandsQuery,
  useGetOneBrandQuery,
  useCreateBrandMutation,
  useUpdateBrandMutation
} = brandApi;

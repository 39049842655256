import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import Table from '../../common/DataTable/Table';

export default function CustomizeLinkAddStocks({
  draftProductsStock,
  removeProduct,
  handleDraftProducts
}) {
  const columnHelper = createColumnHelper();

  const updateDraftProducts = (id, field, value) => {
    handleDraftProducts(id, field, value);
  };

  const truncateText = (text, length) => {
    if (!text) return '';
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div title={row?.nameEn}>{truncateText(row?.nameEn, 40)}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <input
              className={clsx(
                'block w-20 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
              )}
              defaultValue={row.value}
              onBlur={(event) =>
                updateDraftProducts(row.id, 'value', event.target.value)
              }
              type="number"
            />
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'STOCK REQUESTED'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <button
            className="text-red-600 hover:underline"
            onClick={() => removeProduct(row)}
          >
            Remove
          </button>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION'
      }
    )
  ];
  return (
    <div className="sm:px-4 mt-5">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            Draft Stocks in Warehouse
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
      </div>
      <Table data={draftProductsStock} columns={columns} />
    </div>
  );
}

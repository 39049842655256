import { PlusIcon } from '@heroicons/react/20/solid';
import { PhoneIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { Fragment, useEffect, useState } from 'react';
import AssignSingleModal from '../../../components/app/shipment/AssignSingleModal';
import ConfirmationModal from '../../../components/common/DataTable/ConfirmationModal';
import Table from '../../../components/common/DataTable/Table';
import DebouncedSearchTab from '../../../components/common/DebouncedSearchTab';
import TablePagination from '../../../components/common/TablePagination';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import useQuery from '../../../hooks/useQuery';
import {
  useAssignUserToWarehouseMutation,
  useGetUserQuery,
  useRemoveUserFromWarehouseMutation
} from '../../../store/features/users/usersApi';

const rootMeta = {
  page_name: 'Warehouse Users',
  breadcrumbs: null
};

const delivery_statuses = {
  ACTIVE: (
    <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
      Active
    </span>
  ),
  PENDING: (
    <span className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
      Pending
    </span>
  ),
  INACTIVE: (
    <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
      Inactive
    </span>
  )
};

const searchModes = [
  {
    param_name: 'Name',
    param: 'name'
  },
  {
    param_name: 'Email',
    param: 'email'
  },
  {
    param_name: 'Phone',
    param: 'phone'
  }
];

const pageMeta = {
  page_name: 'Warehouse Users'
};

export default function ViewSingleWarehouseUsers() {
  const warehouseId = useQuery().get('warehouseId');

  const pages = [
    {
      name: 'Warehouse',
      location: `/app/warehouse`,
      current: false
    },
    {
      name: 'Warehouse users',
      location: `/app/warehouse/users?warehouseId=${warehouseId}`,
      current: true
    }
  ];

  const [page, setPage] = useState(1);
  const [searchMode, setSearchMode] = useState('name');
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [userId, setUserId] = useState();

  const [
    assignUserToWarehouseMutation,
    {
      isLoading: assignUserToWarehouseMutationLoading,
      isSuccess: assignUserToWarehouseMutationSuccess
    }
  ] = useAssignUserToWarehouseMutation();

  const [
    removeUserFromWarehouseMutation,
    {
      isLoading: removeUserFromWarehouseMutationLoading,
      isSuccess: removeUserFromWarehouseMutationSuccess
    }
  ] = useRemoveUserFromWarehouseMutation();
  const { currentData: getAllUser, isLoading: getAllUserLoading } =
    useGetUserQuery(
      query
        ? {
            page,
            name: query ?? undefined
          }
        : {
            page
          }
    );

  const { currentData, isLoading } = useGetUserQuery(
    searchValue
      ? {
          page,
          warehouseId,
          [searchMode]: searchValue ?? undefined
        }
      : {
          page,
          warehouseId
        }
  );

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const confirmAction = (id) => {
    removeUserFromWarehouseMutation(id);
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.name}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.email}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'EMAIL'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          {row.phone ? (
            <div className="flex items-center gap-1">
              <span>{row.phone}</span>
              <a className="flex items-center gap-1" href={`tel:${row.phone}`}>
                <span className="text-red-600">
                  <PhoneIcon width={16} />
                </span>
                <span className="text-red-600 font-medium">Call</span>
              </a>
            </div>
          ) : (
            <div>N/A</div>
          )}
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'PHONE'
      }
    ),
    columnHelper.accessor(
      (row) => <Fragment>{delivery_statuses[row.accountStatus]}</Fragment>,
      {
        cell: (info) => info.getValue(),
        header: 'ACCOUNT STATUS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex items-center gap-1">
            <span>{row.role}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ROLE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <button
            className="text-red-600 hover:underline"
            onClick={() => {
              setUserId(row.id);
              setIsConfirmationOpen(true);
            }}
          >
            Remove
          </button>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION'
      }
    )
  ];

  const handleAssignUserToWarehouse = async ({ associate }) => {
    await assignUserToWarehouseMutation({
      userId: associate.id,
      warehouseId,
      role: associate.role
    });
  };

  const cleanup = () => {
    setQuery('');
  };

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  useEffect(() => {
    if (removeUserFromWarehouseMutationSuccess) {
      setUserId();
      setIsConfirmationOpen(false);
    }
  }, [removeUserFromWarehouseMutationSuccess]);

  useEffect(() => {
    assignUserToWarehouseMutationSuccess && setOpen(false);
  }, [assignUserToWarehouseMutationSuccess]);
  return (
    <div className="sm:px-4">
      <div className="flex justify-between">
        <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
        <div className="sm:flex sm:items-center justify-end">
          <div className="sm:ml-16 sm:mt-0 sm:flex-none">
            <div className="flex items-center">
              <DebouncedSearchTab
                modes={searchModes}
                handleSearch={handleSearch}
                setValue={setSearchValue}
                setMode={setSearchMode}
                value={searchValue}
                isLoading={isLoading}
                data={currentData ?? []}
              />
              <button
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
                onClick={() => setOpen(true)}
              >
                <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Add User
              </button>
            </div>
          </div>
        </div>
      </div>
      <Table data={currentData?.data?.users ?? []} columns={columns} />
      <TablePagination
        paginationData={currentData?.data?.meta ?? undefined}
        page={page}
        setPage={setPage}
      />
      <AssignSingleModal
        title="User"
        open={open}
        setOpen={setOpen}
        query={query}
        setQuery={setQuery}
        dataList={getAllUser?.data?.users ?? []}
        assignDeliveryPerson={handleAssignUserToWarehouse}
        cleanup={cleanup}
      />
      <ConfirmationModal
        id={userId}
        open={isConfirmationOpen}
        setOpen={setIsConfirmationOpen}
        title={'Confirm Removal'}
        message={`Are you sure you want to remove this customer registration request?`}
        confirmAction={confirmAction}
        cleanup={() => {}}
        disabled={removeUserFromWarehouseMutationLoading}
      />
    </div>
  );
}

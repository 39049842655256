import { flexRender } from "@tanstack/react-table";
import clsx from "clsx";
import React from "react";

export default function TableHead({ table }) {
	return (
		<thead className="bg-gray-100">
			{table.getHeaderGroups().map((headerGroup, idx) => (
				<tr key={headerGroup.id}>
					{headerGroup.headers.map((header, idx) => (
						<th
							className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900"
							scope="col"
							key={header.id}
							style={header.column.columnDef.meta?.style}
						>
							{header.isPlaceholder
								? null
								: flexRender(
										header.column.columnDef.header,
										header.getContext()
								  )}
						</th>
					))}
				</tr>
			))}
		</thead>
	);
}

// py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6

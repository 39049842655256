import QRCode from 'qrcode';
import { useEffect, useState } from 'react';

export default function useQrDataUrl({ text }) {
  const [QRbase64, setQRbase64] = useState('');

  useEffect(() => {
    (async () => {
      try {
        const qr = await QRCode.toDataURL(text);
        setQRbase64(qr);
      } catch (err) {}
    })();
  }, [text]);

  return { QRbase64 };
}

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import useQuery from '../../../hooks/useQuery';
import { useGetOneSalesAssociateQuery } from '../../../store/features/sales-associate/salesAssociateApi';
import DetailsSalesAssociate from '../../../components/app/manage-sales-associate/DetailsSalesAssociate';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Details'
};

export default function SalesAssociateDetails() {
  const query = useQuery();

  const { currentData } = useGetOneSalesAssociateQuery({
    salesAssociateId: query.get('salesAssociateId')
  });

  const pages = [
    {
      name: 'Sales Associate',
      location: '/app/manage-sales-associate',
      current: false
    },
    {
      name: 'Details',
      location: `/app/manage-sales-associate/details?=${query.get(
        'salesAssociateId'
      )}`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <DetailsSalesAssociate currentData={currentData} />
    </div>
  );
}

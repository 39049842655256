import { createColumnHelper } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ApplyFilter from '../../../components/common/ApplyFilter';
import Table from '../../../components/common/DataTable/Table';
import TablePagination from '../../../components/common/TablePagination';
import { getQueryParams, mapFilterToUrlParam } from '../../../lib/utils';
import { useGetActivityLogQuery } from '../../../store/features/activity-log/activityLogApi';
import dayjs from 'dayjs';

const rootMeta = {
  page_name: 'Activity Log',
  breadcrumbs: null
};

export default function ActivityLogRoot() {
  const location = useLocation();
  const queryParamsString = location.search;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});

  const { currentData } = useGetActivityLogQuery(
    !isEmpty(selectedData)
      ? {
          page,
          ...selectedData
        }
      : {
          page
        }
  );

  const searchModes = [
    {
      param_name: 'DETAILS',
      param: 'name',
      type: 'text'
    },
    {
      param_name: 'ACTION',
      param: 'name',
      type: 'text'
    },
    {
      param_name: 'TIME',
      param: 'time',
      type: 'text'
    },
    {
      param_name: 'DATE',
      param: 'date',
      type: 'text'
    }
  ];

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.user?.name}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'USER NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.user?.role}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'USER ROLE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.title}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'DETAILS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.action}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.entityName}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ENTITY NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex items-center gap-1">
            <div>{dayjs(row.timestamp).format('hh:mm A')}</div>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'TIME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex items-center gap-1">
            <div>{dayjs(row.timestamp).format('DD MMM YYYY')}</div>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'DATE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];
  const handleSelectedData = (data) => {
    navigate(`/app/manage-sales-associate/view${mapFilterToUrlParam(data)}`);
    setSelectedData(data);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedData]);

  useEffect(() => {
    setSelectedData(getQueryParams(queryParamsString));
  }, [queryParamsString]);

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <ApplyFilter
              data={searchModes}
              selectedData={selectedData}
              handleSelectedData={handleSelectedData}
            />
          </div>
        </div>
      </div>
      <Table data={currentData?.data?.items ?? []} columns={columns} />
      <TablePagination
        paginationData={currentData?.data?.meta ?? undefined}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

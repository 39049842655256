import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import useQuery from '../../../hooks/useQuery';
import EditDeliverAssociateForm from '../../../components/app/delivery-associate/EditDeliveryAssociateForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Edit Delivery Associate'
};

export default function EditDeliveryAssociate() {
  const query = useQuery();

  const pages = [
    {
      name: 'Delivery Associate',
      location: '/app/delivery-associate/',
      current: false
    },
    {
      name: 'Edit',
      location: `/app/delivery-associate/edit?${query.get(
        'deliveryAssociateId'
      )}`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <EditDeliverAssociateForm />
    </div>
  );
}

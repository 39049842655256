import { useState, useEffect } from 'react';
import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useCreateSmsMutation,
  useGetUserQuery
} from '../../../store/features/sms/smsApi';
import Input from '../../../components/common/AssignModal/Input';

const options = [
  { id: 1, name: 'Super Admins', role: 'SUPER_ADMIN' },
  { id: 2, name: 'Warehouse Admins', role: 'WAREHOUSE_ADMIN' },
  { id: 3, name: 'Warehouse Associates', role: 'WAREHOUSE_ASSOCIATE' },
  { id: 4, name: 'Sales Associates', role: 'SALES_ASSOCIATE' },
  { id: 5, name: 'Delivery Associates', role: 'DELIVERY_ASSOCIATE' },
  { id: 6, name: 'Customers', role: 'CUSTOMER' },
  { id: 7, name: 'Data Users', role: 'DATA_USER' }
];

const SmsRoot = () => {
  const [sms, setSms] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [showIndividual, setShowIndividual] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  // const [selectedRecipientIds, setSelectedRecipientIds] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [query, setQuery] = useState('');
  const maxCharacters = 160;

  const navigate = useNavigate();
  const [createSms, { isLoading }] = useCreateSmsMutation();
  const { data: usersResponse, refetch } = useGetUserQuery(
    { page: 1, name: query },
    { skip: !showIndividual }
  );

  useEffect(() => {
    if (showIndividual) {
      refetch();
    }
  }, [showIndividual, refetch]);

  const handleSmsChange = (event) => {
    setSms(event.target.value);
  };

  const handleSelectInGeneralClick = () => {
    setShowOptions(!showOptions);
    setShowIndividual(false);
    setSelectedRoles([]);
    setSelectedUsers([]);
    // setSelectedRecipientIds([]);
  };

  const handleSelectIndividualClick = () => {
    setShowIndividual(!showIndividual);
    setShowOptions(false);
    // setSelectedRecipientIds([]);
  };

  const handleRoleCheckboxChange = (roleId) => {
    setSelectedRoles((prevSelectedRoles) =>
      prevSelectedRoles.includes(roleId)
        ? prevSelectedRoles.filter((id) => id !== roleId)
        : [...prevSelectedRoles, roleId]
    );
  };

  const handleSubmit = () => {
    const selectedRoleNames = selectedRoles.map(
      (roleId) => options.find((option) => option.id === roleId).role
    );

    const payload = {
      body: sms,
      ...(showOptions && selectedRoles.length > 0
        ? { roles: selectedRoleNames }
        : {}),
      ...(showIndividual && selectedUsers.length > 0
        ? { recipientIds: selectedUsers?.map((item) => item.id) }
        : {})
    };

    createSms(payload);
    setSelectedUsers([]);
    setSms('');
    setSelectedRoles([]);
    setQuery('');
  };

  const isSendButtonDisabled =
    sms.trim() === '' ||
    (!showOptions && !showIndividual) ||
    (showOptions && selectedRoles.length === 0);

  const users = usersResponse?.data?.users || [];

  const handleUserClick = (userId, userName, userPhone) => {
    if (!selectedUsers.find((item) => item.id === userId)) {
      setSelectedUsers((prevSelectedUsers) => [
        ...prevSelectedUsers,
        { id: userId, name: userName, phone: userPhone }
      ]);
      // setSelectedRecipientIds((prevSelectedRecipientIds) => [
      //   ...prevSelectedRecipientIds,
      //   userId
      // ]);
    }
  };

  return (
    <div className="sm:px-4 mt-5">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900 py-2">
            Write SMS
          </h1>
        </div>
      </div>
      <div className="mt-4">
        <textarea
          className={clsx(
            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
          )}
          onChange={handleSmsChange}
          value={sms}
          rows="4"
          maxLength={maxCharacters}
          placeholder="Type your SMS here..."
        />
        <div className="text-right text-gray-600 mt-2">
          {sms.length}/{maxCharacters} characters
        </div>
      </div>
      <div className="mt-4 flex gap-2">
        <button
          className={clsx(
            'inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold shadow-sm transition-all',
            showOptions
              ? 'bg-red-700 text-white hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700'
              : 'bg-gray-500 text-white hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
          )}
          onClick={handleSelectInGeneralClick}
        >
          Select in Group
        </button>
        <button
          className={clsx(
            'inline-flex items-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold shadow-sm transition-all',
            showIndividual
              ? 'bg-red-700 text-white hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700'
              : 'bg-gray-500 text-white hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
          )}
          onClick={handleSelectIndividualClick}
        >
          Select Individual(s)
        </button>
      </div>
      {showOptions && (
        <div className="mt-4 p-4 border rounded shadow-sm">
          {options.map((option) => (
            <div key={option.id} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={`option-${option.id}`}
                className="mr-2"
                checked={selectedRoles.includes(option.id)}
                onChange={() => handleRoleCheckboxChange(option.id)}
              />
              <label htmlFor={`option-${option.id}`}>{option.name}</label>
            </div>
          ))}
        </div>
      )}
      {showIndividual && (
        <div className="mt-4 p-4 border rounded shadow-sm">
          <Input query={query} setQuery={setQuery} title="Users" />
          <div className="mt-5"> </div>
          {users.map((user) => (
            <div
              key={user.id}
              className="flex items-center mb-2 cursor-pointer hover:bg-gray-300"
              onClick={() => handleUserClick(user.id, user.name, user.phone)}
            >
              {user.name} - {user.phone}
            </div>
          ))}
        </div>
      )}

      {/* Chip list to display selected users */}
      {showIndividual && (
        <div className="flex flex-wrap mt-4">
          {selectedUsers.map((item, index) => (
            <span
              key={index}
              className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${
                index % 2 === 0
                  ? 'inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700 mr-1'
                  : 'inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800 ml-1'
              }`}
            >
              {item.name} - {item.phone}
              <button
                className="ml-1 text-xs font-medium"
                onClick={() => {
                  setSelectedUsers((prevSelectedUsers) =>
                    prevSelectedUsers.filter((row) => row.id !== item.id)
                  );
                  // setSelectedRecipientIds((prevSelectedRecipientIds) =>
                  //   prevSelectedRecipientIds.filter(
                  //     (row) => row.id !== selectedRecipientIds[index]
                  //   )
                  // );
                }}
              >
                &#10005;
              </button>
            </span>
          ))}
        </div>
      )}

      <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8 mt-6">
        <button
          type="button"
          onClick={handleSubmit}
          disabled={isSendButtonDisabled}
          className={clsx(
            'rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all',
            isSendButtonDisabled
              ? 'bg-gray-500'
              : 'bg-red-600 hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
          )}
        >
          {isLoading ? 'Sending...' : 'Send SMS'}
        </button>
      </div>
    </div>
  );
};

export default SmsRoot;

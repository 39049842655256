import React, { useEffect, useState } from 'react';
import CustomizeLinkAddStocks from '../../../components/app/add-stocks/CustomizeLinkAddStocks';
import WarehouseProducts from '../../../components/portal/common/WarehouseProducts';
import { useCreateInventoryStockRequestMutation } from '../../../store/features/inventory/inventoryApi';

export default function AddStocksRoot() {
  const [draftProductsStock, setDraftProductsStock] = useState([]);
  const [removedDraftProductId, setRemovedDraftProductId] = useState();

  const [
    callCreateInventoryStockRequestMutation,
    {
      isSuccess: isCreateInventoryStockRequestMutationSuccess,
      isLoading: createInventoryStockRequestMutationLoading
    }
  ] = useCreateInventoryStockRequestMutation();

  const handleDraftProducts = (id, field, value) => {
    setDraftProductsStock((data) =>
      data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value
          };
        }
        return item;
      })
    );
  };

  const removeProduct = (row) => {
    setDraftProductsStock((data) =>
      data.filter((rowItem) => rowItem.id !== row.id)
    );
    setRemovedDraftProductId(row.id);
  };

  const addToWareHouseDraft = (row) => {
    setDraftProductsStock((data) => [
      ...data,
      {
        productId: row?.product?.id,
        photo1: row?.product?.photo1,
        photo2: row?.product?.photo2,
        nameEn: row?.product?.nameEn,
        sku: row?.product?.sku,
        id: row.id,
        value: 0
      }
    ]);
  };

  const handleBtnSubmit = () => {
    const payload = {
      items: draftProductsStock.map((item) => ({
        productId: item.productId,
        type: 'INC',
        value: parseInt(item.value, 10)
      }))
    };
    callCreateInventoryStockRequestMutation(payload);
  };

  useEffect(() => {
    isCreateInventoryStockRequestMutationSuccess && setDraftProductsStock([]);
  }, [isCreateInventoryStockRequestMutationSuccess]);

  return (
    <>
      <div className="sm:px-4">
        <WarehouseProducts
          title={'Add Stocks'}
          addToStockDraft={addToWareHouseDraft}
          draftProducts={draftProductsStock}
          removedProductId={removedDraftProductId}
        />
        <CustomizeLinkAddStocks
          draftProductsStock={draftProductsStock}
          removeProduct={removeProduct}
          handleDraftProducts={handleDraftProducts}
        />
      </div>

      {draftProductsStock.length > 0 && (
        <div className="mt-10 flex justify-end mr-8">
          <button
            type="submit"
            onClick={handleBtnSubmit}
            className="flex items-center rounded-md bg-red-600 px-3 py-2 text-white shadow-sm hover:bg-red-500"
          >
            Submit
          </button>
        </div>
      )}
    </>
  );
}

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import useQuery from '../../../hooks/useQuery';
import { useGetSingleCustomerRegistraionRequestQuery } from '../../../store/features/customer/customerApi';
import ViewCustomersRegistration from '../../../components/app/customers-reg/ViewCustomersRegistration';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Details'
};

export default function CustomerRegistrationDetails() {
  const customerId = useQuery().get('customerId');

  const { currentData } =
    useGetSingleCustomerRegistraionRequestQuery(customerId);

  const pages = [
    {
      name: 'Customers Registration Requests',
      location: '/app/customers-reg-requests',
      current: false
    },
    {
      name: 'View',
      location: `/app/customers-reg-requests/details`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <ViewCustomersRegistration currentData={currentData} />
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';
import { LoaderIcon } from 'react-hot-toast';
import { useGetWarehouseQuery } from '../../../store/features/warehouse/warehouseApi';
import { accountStatus } from '../../../data/accountStatus';

export default function DetailsProfile({ currentData }) {
  const navigate = useNavigate();
  const query = useQuery();
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [routeNames, setRouteNames] = useState([]);
  const [page, setPage] = useState(1);

  const { data: warehouseData, isLoading: getWarehouseQueryLoading } =
    useGetWarehouseQuery({});

  useEffect(() => {
    if (warehouseData?.data?.warehouses) {
      const names = {};
      warehouseData.data.warehouses.forEach((warehouse) => {
        names[warehouse.id] = warehouse.name;
      });
      setWarehouseNames(names);
    }
  }, [warehouseData]);

  return (
    <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div className="px-4 py-6 sm:p-8">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              User Name
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.name ?? <LoaderIcon />}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Email
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.email ?? 'N/A'}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              User Role
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.role ?? 'N/A'}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Warehouse Name
            </label>
            <div className="mt-2">
              <div>{currentData?.data?.warehouse?.name || 'N/A'}</div>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Two Factor Authentication
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.isMfaEnabled ? 'Enabled' : 'Disabled'}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
            Two Factor Authentication Number
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.mfaContact ?? 'N/A'}</span>
            </div>
          </div>

        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
        
      </div>
    </form>
  );
}

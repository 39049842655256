
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useState } from 'react';

export default function TablePagination({
  currentPage,
  paginationData = { from: 0, to: 0, totalItems: 0, maxPages: 0 },
  setPage,
  page
}) {
  const { from, to, totalItems, maxPages } = paginationData;
  const [visibleRange, setVisibleRange] = useState({ start: 1, end: 5 });

  const back = () => {
    if (page > 1) {
      if (page === visibleRange.start) {
        shiftRangeBack();
      } else {
        setPage(page - 1);
      }
    }
  };

  const next = () => {
    if (page < maxPages) {
      if (page === visibleRange.end) {
        shiftRangeForward();
      } else {
        setPage(page + 1);
      }
    }
  };

  const jumpToPage = (newPage) => {
    setPage(newPage);
    const newStart = Math.floor((newPage - 1) / 5) * 5 + 1;
    setVisibleRange({ start: newStart, end: newStart + 4 });
  };

  const shiftRangeBack = () => {
    if (visibleRange.start > 1) {
      const newStart = Math.max(visibleRange.start - 5, 1);
      const newEnd = newStart + 4;
      setVisibleRange({ start: newStart, end: newEnd });
      setPage(newStart);
    }
  };

  const shiftRangeForward = () => {
    if (visibleRange.end < maxPages) {
      const newStart = visibleRange.start + 5;
      const newEnd = Math.min(visibleRange.end + 5, maxPages);
      setVisibleRange({ start: newStart, end: newEnd });
      setPage(newStart);
    }
  };

  return (
    <div className="flex items-center justify-between mt-4 border-gray-200 py-3">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={back}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </button>
        <button
          onClick={next}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{from}</span> to{' '}
            <span className="font-medium">{to}</span> of{' '}
            <span className="font-medium">{totalItems}</span> results
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm bg-white"
            aria-label="Pagination"
          >
            <button
              onClick={shiftRangeBack}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous Range</span>
              <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              onClick={back}
              className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-4 w-4" aria-hidden="true" />
            </button>
            <div className="flex">
              {maxPages ? renderPageButtons(maxPages, jumpToPage, page, visibleRange) : null}
            </div>
            <button
              onClick={next}
              className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
            </button>
            <button
              onClick={shiftRangeForward}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Next Range</span>
              <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

TablePagination.propTypes = {
  currentPage: PropTypes.number,
  paginationData: PropTypes.shape({
    from: PropTypes.number,
    to: PropTypes.number,
    totalItems: PropTypes.number,
    maxPages: PropTypes.number
  }),
  setPage: PropTypes.func,
  page: PropTypes.number
};

function renderPageButtons(maxPageCount, setPage, currentPage, visibleRange) {
  const pageButtons = [];
  const { start, end } = visibleRange;

  for (let i = start; i <= Math.min(end, maxPageCount); i++) {
    pageButtons.push(
      <button
        key={i}
        onClick={() => setPage(i)}
        aria-current="page"
        className={clsx(
          'relative z-10 inline-flex items-center border-2 px-4 py-2 text-sm font-semibold focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600',
          currentPage === i && 'bg-red-100 border-red-600 text-red-600'
        )}
      >
        {i}
      </button>
    );
  }

  return pageButtons;
}

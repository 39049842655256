import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import React from "react";
import { Link } from "react-router-dom";

export default function BreadCrumbs({ pages }) {
	return (
		<nav className="flex" aria-label="Breadcrumb">
			<ol role="list" className="flex items-center space-x-4">
				<li>
					<div>
						<Link to="/" className="text-gray-400 hover:text-gray-500">
							<HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
							<span className="sr-only">Home</span>
						</Link>
					</div>
				</li>
				{pages.map((page) => (
					<li key={page.name}>
						<div className="flex items-center">
							<ChevronRightIcon
								className="h-5 w-5 flex-shrink-0 text-gray-400"
								aria-hidden="true"
							/>
							{page.current ? (
								<span
									className="ml-4 text-sm font-medium text-gray-700"
									aria-current="page"
								>
									{page.name}
								</span>
							) : (
							<Link
								to={page.location}
								className={clsx(
									"ml-4 text-sm font-medium hover:text-gray-700",
									page.current ? "text-gray-700" : "text-gray-500"
								)}
								aria-current={page.current ? "page" : undefined}
							>
								{page.name}
							</Link>
							)}
						</div>
					</li>
				))}
			</ol>
		</nav>
	);
}

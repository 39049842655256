import { format } from 'date-fns';

import { CalendarIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';
import { cn } from '../../../../lib/utils';
import { Button } from '../../../common/Button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '../../../common/Popover';
import { Calendar } from './Calendar';

export function ShipmentDatePicker({ date, setDate }) {
  const [openPopover, setOpenPopover] = useState(false);
  return (
    <Popover open={openPopover}>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'w-full justify-start text-left font-normal',
            !date && 'text-muted-foreground'
          )}
          onClick={() => setOpenPopover(!openPopover)}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, 'PPP') : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(value) => {
            value !== undefined ? setDate(value) : setDate('');
            setOpenPopover(false);
          }}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}

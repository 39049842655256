import React from 'react';
import TableHead from './TableHead';
import TableBody from './TableBody';
import {
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from '@tanstack/react-table';

export default function Table({
  data,
  columns,
  rowSelection = false,
  setRowSelection = false,
  getRowCanExpand
}) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: rowSelection
    },
    getRowCanExpand,
    getExpandedRowModel: getExpandedRowModel(),
    onRowSelectionChange: setRowSelection,
    enableRowSelection: true
  });

  return (
    <div className="mt-4 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <TableHead table={table} />
              <TableBody table={table} columns={columns} />
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

import { z } from 'zod';

const categorynSchema = z.object({
  nameEn: z
    .string()
    .min(1, 'Name (English) is required')
    .max(50, 'Too Long'),
  photo: z.string().optional(),
  nameBn: z.string().optional(),
  shortDescription: z.string().optional()
});

export default categorynSchema;

import toast from 'react-hot-toast';

const longDurationSuccessToast = (message, duration) => {
  toast.success(message, {
    duration: duration,
    // Add any additional custom options here if needed
  });
};

export default longDurationSuccessToast;

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useGetDeliveryManagerQuery } from '../../store/features/delivery-manager/deliveryManagerApi';
import { useGetDeliveryPersonsQuery } from '../../store/features/delivery-person/deliveryPersonApi';
import { useGetPickupLocationQuery } from '../../store/features/pickup-location/pickupLocationApi';
import FilterOptions from '../app/reports/FilterOptions';
import SearchFilter from '../app/reports/SearchFilter';
import { Button } from './Button';

export default function SlideOver({
  open,
  setOpen,
  selectedId,
  setSelectedId,
  selectedIdType,
  setSelectedIdType,
  setApplyFilter
}) {
  const [query, setQuery] = useState('');

  const filterTypes = [
    {
      name: 'Delivery Manager',
      key: 'WAREHOUSE_ADMIN'
    },
    {
      name: 'Delivery Person',
      key: 'DELIVERY_PERSON'
    },
    {
      name: 'Warehouse',
      key: 'PICK_UP_LOCATION'
    }
  ];

  const { currentData: deliveryManagerData } = useGetDeliveryManagerQuery(
    query
      ? {
          page: 1,
          name: query
        }
      : {
          page: 1
        },
    {
      skip: selectedIdType?.key !== 'WAREHOUSE_ADMIN'
    }
  );

  const { currentData: deliveryPersonData } = useGetDeliveryPersonsQuery(
    query
      ? {
          page: 1,
          name: query
        }
      : {
          page: 1
        },
    {
      skip: selectedIdType?.key !== 'DELIVERY_PERSON'
    }
  );

  const { currentData: pickupLocationData } = useGetPickupLocationQuery(
    query
      ? { page: 1, name: query }
      : {
          page: 1
        },
    {
      skip: selectedIdType?.key !== 'PICK_UP_LOCATION'
    }
  );

  const memoizedDatas = useMemo(() => {
    return {
      WAREHOUSE_ADMIN: deliveryManagerData?.data?.deliveryManagers ?? [],
      DELIVERY_PERSON: deliveryPersonData?.data?.deliveryPersons ?? [],
      PICK_UP_LOCATION: pickupLocationData?.data?.pickUpLocations ?? []
    };
  }, [deliveryManagerData, deliveryPersonData, pickupLocationData]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[99999]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-sm">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                      <button
                        type="button"
                        className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                        Filter
                      </Dialog.Title>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                      {/* Your content */}
                      <FilterOptions
                        options={filterTypes ?? []}
                        selectedOption={selectedIdType}
                        setSelectedOption={setSelectedIdType}
                      />
                      {/* <ComboboxLeftCheck /> */}
                      <SearchFilter
                        selectedId={selectedId}
                        setSelectedId={setSelectedId}
                        data={memoizedDatas[selectedIdType?.key] ?? []}
                        setSearchQuery={setQuery}
                        searchQuery={query}
                      />
                    </div>
                    <div className="self-center">
                      <Button
                        variant="outline"
                        className="mr-2"
                        onClick={() => setOpen(false)}
                      >
                        Close
                      </Button>
                      <Button
                        className="bg-red-600 text-white"
                        onClick={() => {
                          if (selectedIdType?.key && selectedId.id) {
                            setApplyFilter(true);
                            setOpen(false);
                          } else {
                            toast.error('Please select a filter');
                          }
                        }}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

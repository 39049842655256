import React from 'react';
import useQuery from '../../../hooks/useQuery';
import EditCustomerForm from '../../../components/app/customers/EditCustomerForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Edit Customer'
};

export default function EditCustomer() {
  const query = useQuery();

  const pages = [
    {
      name: 'Customer',
      location: '/app/customers/',
      current: false
    },
    {
      name: 'Edit',
      location: `/app/customers/edit?${query.get('customerId')}`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <EditCustomerForm />
    </div>
  );
}

import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useCreateRouteMutation } from '../../../store/features/manage-routes/manageRoutesApi';

export default function CreateRouteForm({ dirtyData }) {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [
    createRoute,
    { isSuccess: createRouteSuccess, isLoading: createRouteMutationLoading }
  ] = useCreateRouteMutation();
 
  const onSubmit = (data) => {
    const payload = {
      ...data
    };
    createRoute(payload);
  };

  useEffect(() => {
    if (createRouteSuccess) {
      navigate('/app/manage-routes/view');
    }
  }, [createRouteSuccess]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
    >
      <div className="px-4 py-6 sm:p-8">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Route Name*
            </label>
            <div className="mt-2">
              <input
                {...register('name', { required: 'Required' })}
                placeholder={'Enter Route Name'}
                className={clsx(
                  'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                )}
              />
            </div>
            <div className="text-red-500">
              {errors.name && errors.name.message}
            </div>
          </div>
          <div className="sm:col-span-6">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Route Description
            </label>
            <div className="mt-2">
              <input
                {...register('description')}
                placeholder={'Enter Route Description'}
                className={clsx(
                  'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                )}
              />
            </div>
            <div className="text-red-500">
              {errors.name && errors.name.message}
            </div>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
        <button
          type="button"
          onClick={() => navigate('/app/manage-routes/view')}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>

        {dirtyData ? (
          <button
            type="submit"
            disabled={createRouteMutationLoading}
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
          >
            Save
          </button>
        ) : (
          <button
            type="submit"
            disabled={createRouteMutationLoading}
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:hover:cursor-not-allowed disabled:bg-gray-500"
          >
            Create Route
          </button>
        )}
      </div>
    </form>
  );
}

import React from 'react';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import useQuery from '../../../hooks/useQuery';
import { useGetOneBrandQuery } from '../../../store/features/brand/brandApi';
import BrandDetail from '../../../components/app/brand/BrandDetail';

const pageMeta = {
  page_name: 'View Brand'
};

export default function ViewBrand() {
  const query = useQuery();
  const brandId = query.get('brandId');

  const pages = [
    {
      name: 'Brands',
      location: '/app/manage-brands/',
      current: false
    },
    {
      name: 'Detail',
      location: `/app/manage-brands/detail?${brandId}`,
      current: true
    }
  ];

  const { currentData } = useGetOneBrandQuery({ brandId });

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <BrandDetail data={currentData?.data} />
    </div>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '../../common/Form';
import {
  useGetConfigQuery,
  useUpdateConfigMutation
} from '../../../store/features/config-warehouse/configApi';
import DropdownSelect from '../../common/DropdownSelect';

export const userSchema = z.object({
  deliveryCharge: z.string(),
  opsStatus: z.enum(['ACTIVE', 'INACTIVE'])
});
const opsStatuses = [
  { id: 0, name:'ACTIVE' },
  { id: 1, name: 'INACTIVE' }
]
export default function EditConfigForm() {
  const { currentData } = useGetConfigQuery({});

  const methods = useForm({
    resolver: zodResolver(userSchema),
    mode: 'onChange',
    values: {
      deliveryCharge: String(currentData?.data?.deliveryCharge),
      opsStatus: 'INACTIVE'
    }
  });

  const [selectedStatus, setSelectedStatus] = useState({
    id: 0,
    name: 'ACTIVE'
  });

  useEffect(()=>{
    setSelectedStatus(
    {
      id: opsStatuses.find((status) => status?.name === currentData?.data?.opsStatus)?.id ?? '',
      name: currentData?.data?.opsStatus ?? ''
    })
  }, [currentData])

  const [
    callUpdateConfigMutation,
    {
      isSuccess: isUpdateConfigMutationSuccess,
      isLoading: updateConfigMutationLoading
    }
  ] = useUpdateConfigMutation();

  const navigate = useNavigate();

  const onSubmit = (data) => {
    const { name, ...body } = data;
    callUpdateConfigMutation({
      ...body,
      deliveryCharge: parseInt(data?.deliveryCharge, 10),
      opsStatus: selectedStatus?.name
    });
  };

  useEffect(() => {
    if (isUpdateConfigMutationSuccess) {
      navigate('/app/config-warehouse/view');
    }
  }, [isUpdateConfigMutationSuccess]);

  return (
    <Form {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
      >
        <div className="px-4 py-6 sm:p-8">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <FormField
                name="name"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="user-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Config Name*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="user-name"
                          placeholder={'Delivery Charge'}
                          disabled
                          readOnly
                          defaultValue={'Delivery Charge'}
                          className={clsx(
                            'disabled:bg-gray-200 disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="deliveryCharge"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="deliveryCharge"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Delivery Charge*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="number"
                          {...field}
                          id="deliveryCharge"
                          placeholder={'Enter Charge'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="name"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="OperationStatus"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Config Name*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="OperationStatus"
                          placeholder={'Operation Status'}
                          disabled
                          readOnly
                          defaultValue={'Operation Status'}
                          className={clsx(
                            'disabled:bg-gray-200 disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            
            <div className="sm:col-span-3">
              <FormField
                name="opsStatus"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="opsStatus"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Operation Status*
                    </label>
                     <DropdownSelect
                      data={opsStatuses}
                      selected={selectedStatus}
                      setSelected={setSelectedStatus}
                      placeholder="Select Status"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button
            type="button"
            onClick={() => navigate('/app/config-warehouse/view')}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={updateConfigMutationLoading}
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
          >
            Save
          </button>
        </div>
      </form>
    </Form>
  );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import BrowserRouterObject from './routers/BrowserRouter';
import { persistor, store } from './store/persistStore';

const router = createBrowserRouter(BrowserRouterObject);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={<>Loading...</>} persistor={persistor}>
        <Toaster
          containerStyle={{
            zIndex: 99999999999 // For the container
          }}
          toastOptions={{
            style: {
              zIndex: 99999999999 // For toasts
            }
          }}
        />
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.Fragment>
);

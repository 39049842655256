import { createColumnHelper } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useGetInventoryQuery } from '../../../store/features/inventory/inventoryApi';
import ApplyFilter from '../../common/ApplyFilter';
import Table from '../../common/DataTable/Table';
import TablePagination from '../../common/TablePagination';
import { RectangleStackIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

const searchModes = [
  {
    param_name: 'Name',
    param: 'nameEn',
    type: 'text'
  },
  {
    param_name: 'SKU',
    param: 'sku',
    type: 'text'
  },
  {
    param_name: 'Brand',
    param: 'brand',
    type: 'text'
  },
  {
    param_name: 'Supplier',
    param: 'supplier',
    type: 'text'
  }
];
export default function WarehouseProducts({
  title,
  addToStockDraft,
  draftProducts,
  removedProductId
}) {
  const rootMeta = {
    page_name: title,
    breadcrumbs: null
  };
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [productsData, setProductsData] = useState([]);

  const { currentData, isLoading } = useGetInventoryQuery(
    !isEmpty(selectedData)
      ? {
          page,
          ...selectedData
        }
      : {
          page
        }
  );

  const handleAddToWarehouseDraft = (row) => {
    setProductsData((data) =>
      data?.map((item) => {
        if (item.id === row.id) {
          return {
            ...item,
            isAddDisabled: true
          };
        }
        return item;
      })
    );
    addToStockDraft(row);
  };

  const truncateText = (text, length) => {
    if (!text) return '';
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.product?.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU NO.'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div title={row?.product?.nameEn}>{truncateText(row?.product?.nameEn, 40)}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex gap-x-2">
            {row?.product?.photo1 ? (
              <img src={row?.product?.photo1} alt="" width="30" height="30" />
            ) : (
              <RectangleStackIcon className="w-10 h-10" color="gray" />
            )}
            {row?.product?.photo2 && (
              <img src={row?.product?.photo2} alt="" width="30" height="30" />
            )}
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'PHOTO'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row.unitPrice}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'UNIT PRICE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row.memberUnitPrice}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'MEMBER PRICE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row.stock}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'STOCK'
      }
    ),
    columnHelper.accessor(
      (row) => {
        return (
          <Fragment>
            <button
              className="text-red-600 hover:underline disabled:text-gray-500 mx-2"
              onClick={() => handleAddToWarehouseDraft(row)}
              disabled={row.isAddDisabled}
            >
              Add
            </button>
            <Link
              className="text-red-600 hover:underline disabled:text-gray-500 mx-2"
              to={`/app/product-management/details?productId=${row?.product?.id}`}
            >
              View
            </Link>
          </Fragment>
        );
      },
      {
        cell: (info) => info.getValue(),
        header: 'ACTION'
      }
    )
  ];

  useEffect(() => {
    setPage(1);
  }, [selectedData]);

  useEffect(() => {
    if (currentData?.data?.items) {
      draftProducts?.length === 0 &&
        setProductsData(
          currentData?.data?.items?.map((item) => {
            return {
              ...item,
              isAddDisabled: false
            };
          })
        );
      draftProducts?.length > 0 &&
        setProductsData(
          currentData?.data?.items?.map((item) => {
            if (draftProducts?.find((draft) => draft.id === item.id)) {
              return {
                ...item,
                isAddDisabled: true
              };
            }
            return item;
          })
        );
    }
  }, [currentData, draftProducts]);
  useEffect(() => {
    setProductsData((data) =>
      data?.map((item) => {
        if (item.id === removedProductId) {
          return {
            ...item,
            isAddDisabled: false
          };
        }
        return item;
      })
    );
  }, [removedProductId]);

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <ApplyFilter
              data={searchModes}
              selectedData={selectedData}
              handleSelectedData={setSelectedData}
            />
          </div>
        </div>
      </div>

      <Table data={productsData ?? []} columns={columns} />
      <TablePagination
        paginationData={currentData?.data?.meta ?? undefined}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

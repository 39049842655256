import clsx from 'clsx';
import React from 'react';

export default function Divider({ label = '', darkBorder }) {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div
          className={
            darkBorder
              ? clsx('w-full border-t border-gray-300')
              : clsx('w-full border-t border-gray-200')
          }
        />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-sm text-gray-300">{label}</span>
      </div>
    </div>
  );
}

import { PlusIcon } from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ApplyFilter from '../../../components/common/ApplyFilter';
import Table from '../../../components/common/DataTable/Table';
import TablePagination from '../../../components/common/TablePagination';
import { accountStatus } from '../../../data/accountStatus';
import {
  getAccountStatus,
  getQueryParams,
  mapFilterToUrlParam
} from '../../../lib/utils';
import { useGetDataUserQuery } from '../../../store/features/data-user/dataUserApi';
import { useSelector } from 'react-redux';
import { selectCurrentRole } from '../../../store/features/auth/authSlice';

const rootMeta = {
  page_name: 'Data User',
  breadcrumbs: null
};

export default function DataUserRoot() {
  const location = useLocation();
  const queryParamsString = location.search;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});

  const { currentData } = useGetDataUserQuery(
    isEmpty(selectedData)
      ? { page, role: 'DATA_USER' }
      : { page, role: 'DATA_USER', ...selectedData }
  );

  const role = useSelector(selectCurrentRole);


  const searchModes = [
    {
      param_name: 'Name',
      param: 'name',
      type: 'text'
    },
    {
      param_name: 'Email',
      param: 'email',
      type: 'text'
    },
    {
      param_name: 'Account Status',
      param: 'accountStatus',
      type: 'select',
      options: getAccountStatus()
    }
  ];

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.name}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'DATA USER'
      }
    ),

    columnHelper.accessor(
      (row) => <Fragment>{accountStatus[row.accountStatus]}</Fragment>,
      {
        cell: (info) => info.getValue(),
        header: 'STATUS'
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex items-center gap-1">
            <span>{row.email}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'Email'
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <Link to={`/app/data-user/edit?dataUserId=${row.id}`}>
            <button className="text-red-600 hover:underline ml-3">Edit</button>
          </Link>

          <Link to={`/app/data-user/details?dataUserId=${row.id}`}>
            <button className="text-red-600 hover:underline ml-3">
              Details
            </button>
          </Link>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];

  const handleSelectedData = (data) => {
    navigate(`/app/data-user/view${mapFilterToUrlParam(data)}`);
    setSelectedData(data);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedData]);

  useEffect(() => {
    setSelectedData(getQueryParams(queryParamsString));
  }, [queryParamsString]);

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <ApplyFilter
              data={searchModes}
              selectedData={selectedData}
              handleSelectedData={handleSelectedData}
            />
            {role === 'SUPER_ADMIN' && (
            <Link
              to="/app/data-user/create"
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Create
            </Link>
            )}
          </div>
        </div>
      </div>
      <Table data={currentData?.data?.users ?? []} columns={columns} />
      <TablePagination
        paginationData={currentData?.data?.meta ?? undefined}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

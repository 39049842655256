import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import WarehouseProducts from '../../../components/portal/common/WarehouseProducts';
import useQuery from '../../../hooks/useQuery';
import LinkProductsGoals from '../../../components/app/manage-sales-associate/LinkProductsGoals';
import { useUpdateOneSalesAssociateGoalsMutation } from '../../../store/features/sales-associate/salesAssociateApi';
const pageMeta = {
  page_name: 'Sales Associate Target Goals'
};

export default function SetTargetGoals() {
  const query = useQuery();
  const salesAssociateId = query.get('salesAssociateId');

  const [draftProductsGoals, setDraftProductsGoals] = useState([]);
  const [removedDraftProductId, setRemovedDraftProductId] = useState();

  const [
    callUpdateOneSalesAssociateGoalsMutation,
    {
      isSuccess: isUpdateOneSalesAssociateGoalsMutationSuccess,
      isLoading: updateOneSalesAssociateGoalsMutationLoading
    }
  ] = useUpdateOneSalesAssociateGoalsMutation();

  const navigate = useNavigate();

  const pages = [
    {
      name: 'Sales Associate',
      location: '/app/manage-sales-associate/view',
      current: false
    },
    {
      name: 'SA Goals',
      location: `/app/manage-sales-associate/goals-list?salesAssociateId=${salesAssociateId}`,
      current: false
    },
    {
      name: 'SA Target Goals',
      location: `/app/manage-sales-associate/goals?salesAssociateId=${salesAssociateId}`,
      current: true
    }
  ];

  const handleDraftProducts = (id, field, value) => {
    setDraftProductsGoals((data) =>
      data.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value
          };
        }
        return item;
      })
    );
  };

  const addToWareHouseDraft = (row) => {
    setDraftProductsGoals((data) => [
      ...data,
      {
        name: row.product?.nameEn,
        sku: row.product?.sku,
        id: row.id,
        value: 0
      }
    ]);
  };

  const removeProduct = (row) => {
    setDraftProductsGoals((data) =>
      data.filter((rowItem) => rowItem.id !== row.id)
    );
    setRemovedDraftProductId(row.id);
  };
  const addProductsToWarehouse = () => {
    const payload = {
      salesAssociateId,
      goals: draftProductsGoals?.map((item) => {
        return {
          inventoryId: item.id,
          quantity: parseInt(item.value, 10)
        };
      })
    };
    callUpdateOneSalesAssociateGoalsMutation(payload);
  };

  useEffect(() => {
    isUpdateOneSalesAssociateGoalsMutationSuccess &&
      navigate(
        `/app/manage-sales-associate/goals-list?salesAssociateId=${salesAssociateId}`,
        { replace: true }
      );
  }, [isUpdateOneSalesAssociateGoalsMutationSuccess]);
  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-start">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            <Link
              to={`/app/manage-sales-associate/goals-list?salesAssociateId=${salesAssociateId}`}
            >
              <span className="flex items-center gap-4 hover:text-gray-500 transition-all">
                <ArrowLeftIcon className="h-7 w-7" />
                {pageMeta.page_name}
              </span>
            </Link>
          </h1>
          <div className="my-6">
            <BreadCrumbs pages={pages} />
          </div>
        </div>
      </div>
      <WarehouseProducts
        title={'Warehouse Products'}
        addToStockDraft={addToWareHouseDraft}
        draftProducts={draftProductsGoals}
        removedProductId={removedDraftProductId}
      />
      <LinkProductsGoals
        draftProductsGoals={draftProductsGoals}
        removeProduct={removeProduct}
        handleDraftProducts={handleDraftProducts}
      />
      {draftProductsGoals?.length > 0 && (
        <div className="mt-10 flex justify-end">
          <button
            className="flex items-center rounded-md bg-red-600 px-3 py-2 text-white shadow-sm hover:bg-red-500"
            onClick={addProductsToWarehouse}
          >
            <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Set Product(s) Goals
          </button>
        </div>
      )}
    </div>
  );
}

import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import useQuery from '../../../hooks/useQuery';
import DetailsProfile from '../../../components/app/profile/DetailsProfile';
import { useGetProfileQuery } from '../../../store/features/profile/profileApi';

const pageMeta = {
  page_name: 'Profile Details'
};

export default function ProfileDetails() {
  const query = useQuery();

  const { currentData } = useGetProfileQuery({ 
  });

  const pages = [
    {
      name: 'Profile',
      location: '/app/profile',
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-start">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            <Link to="/app/profile/view">
              <span className="flex items-center gap-4 hover:text-gray-500 transition-all">
                
                {pageMeta.page_name}
              </span>
            </Link>
          </h1>
          <div className="my-6">
            <BreadCrumbs pages={pages} />
          </div>
        </div>
      </div>
      <DetailsProfile currentData={currentData} />
    </div>
  );
}

import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import ApplyFilter from '../../../components/common/ApplyFilter';
import Table from '../../../components/common/DataTable/Table';
import AssignSingleModal from '../../../components/app/shipment/AssignSingleModal';
import { useGetDeliveryAssociateQuery } from '../../../store/features/delivery-associate/deliveryAssociateApi';
import {
  useAssignDeliveryAssociateMutation,
  useCreateOrderSummaryInvoiceMutation,
  useGetOrderInvoiceQuery,
  useUpdateOrderPrintStatusMutation
} from '../../../store/features/order/orderApi';
import { useGetSalesAssociateQuery } from '../../../store/features/sales-associate/salesAssociateApi';
import PrintModal from '../../../components/app/shipment/PrintModal';
import { useGetAllRoutesQuery } from '../../../store/features/manage-routes/manageRoutesApi';
import TableCheckbox from '../../../components/common/TableCheckbox';
import { Link } from 'react-router-dom';

const rootMeta = {
  page_name: 'Invoice History',
  breadcrumbs: null
};
const getSA = (data) => {
  return [
    {
      id: '',
      name: 'Select Sales Associate'
    },
    ...data?.map((item) => ({
      id: item.id,
      name: item.name
    }))
  ];
};
const getDA = (data) => {
  return [
    {
      id: '',
      name: 'Select Delivery Associate'
    },
    ...data?.map((item) => ({
      id: item.id,
      name: item.name
    }))
  ];
};

const getRoutes = (data) => {
  return [
    {
      id: '',
      name: 'Select Route'
    },
    ...data?.map((item) => ({
      id: item.id,
      name: item.name
    }))
  ];
};

const delivery_statuses = {
  COMPLETED: (
    <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
      Delivered
    </span>
  ),
  PENDING: (
    <span className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
      Pending
    </span>
  ),
  IN_PROGRESS: (
    <span className="inline-flex items-center rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-800">
      In progress
    </span>
  ),
  CANCELLED: (
    <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
      Cancelled
    </span>
  )
};

const invoice_statuses = {
  true: (
    <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
      Printed
    </span>
  ),
  false: (
    <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-yellow-800">
      Not Printed
    </span>
  )
};

const columnHelper = createColumnHelper();

export default function InvoiceHistoryRoot() {
  const [orderId, setOrderId] = useState(null);
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [isOpenPrint, setIsOpenPrint] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [summary, setSummary] = useState('');

  const { currentData, isLoading } = useGetOrderInvoiceQuery(
    !isEmpty(selectedData)
      ? {
          ...selectedData
        }
      : {}
  );
  const [
    callCreateOrderSummaryInvoiceMutation,
    {
      isSuccess: isCreateOrderSummaryInvoiceMutationSuccess,
      isLoading: createOrderSummaryInvoiceMutationLoading
    }
  ] = useCreateOrderSummaryInvoiceMutation();

  const [
    callUpdateOrderPrintStatusMutation,
    {
      isSuccess: isUpdateOrderPrintStatusMutationSuccess,
      isLoading: updateOrderPrintStatusMutationLoading
    }
  ] = useUpdateOrderPrintStatusMutation();

  const { currentData: salesAssociateData } = useGetSalesAssociateQuery({
    page
  });
  const { currentData: deliveryAssociateData } = useGetDeliveryAssociateQuery({
    page
  });
  const { currentData: routesData } = useGetAllRoutesQuery({
    page
  });
  const searchModes = [
    {
      param_name: 'Sales Associate Name',
      param: 'salesAssociateId',
      type: 'select',
      options: getSA(salesAssociateData?.data?.users ?? [])
    },
    {
      param_name: 'Delivery Associate Name',
      param: 'deliveryAssociateId',
      type: 'select',
      options: getDA(deliveryAssociateData?.data?.users ?? [])
    },
    {
      param_name: 'Route Name',
      param: 'routeId',
      type: 'select',
      options: getRoutes(routesData?.data?.routes ?? [])
    },
    {
      param_name: 'Delivery Date',
      param: 'deliveryDate',
      type: 'date'
    }
  ];

  const columns = [
    {
      id: 'select',
      header: ({ table }) => (
        <TableCheckbox
          {...{
            checked:
              selectedOrders?.length === currentData?.data?.invoices?.length,
            indeterminate: selectedOrders?.length > 0,
            onChange: handleOnChangeAllCheckbox
          }}
        />
      ),
      cell: ({ row }) => (
        <TableCheckbox
          {...{
            checked: selectedOrders?.find(
              (order) => order.id === row.original?.id
            ),
            onChange: () => handleOnChangeCheckbox(row)
          }}
        />
      )
    },
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <Link to={`/app/order-history/detail?orderId=${row.id}`} target='_blank'>
            <div className='border-b-2'>{row.id}</div>
          </Link>
      </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ORDER ID',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.customer?.name}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'CUSTOMER NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.route?.name}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ROUTE NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.salesAssociate?.name}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SR NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>BDT {row.invoice?.totalPayable}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'AMOUNT',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => row?.address?.full,
      {
        cell: (info) => info.getValue(),
        header: 'ADDRESS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{dayjs(row.createdAt).format('DD MMM YYYY h:mm A')}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'CREATED AT',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
  
    columnHelper.accessor(
      (row) => <Fragment>{invoice_statuses[row.isInvoicePrinted]}</Fragment>,
      {
        cell: (info) => info.getValue(),
        header: 'PRINT STATUS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>{delivery_statuses[row.orderStatus?.toUpperCase()]}</Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'STATUS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),    
    columnHelper.accessor(
      (row) => {
        return (
          <Fragment>
            {row?.deliveryAssociate?.name ? (
              <span>{row?.deliveryAssociate?.name}</span>
            ) : (
              <span>N/A</span>
            )}
          </Fragment>
        );
      },
      {
        cell: (info) => info.getValue(),
        header: 'DELIVERY ASSOCIATE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <Link to={`/app/order-history/detail?orderId=${row.id}`}>
            <button className="text-red-600 hover:underline">View</button>
          </Link>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'DETAILS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
  ];

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');

  const { currentData: deliveryAssociatessCurrentData } =
    useGetDeliveryAssociateQuery(
      query
        ? {
            page: 1,
            name: query
          }
        : {
            page: 1
          }
    );

  const [asssignDeliveryAssociate, { isSuccess: isAssignSuccess }] =
    useAssignDeliveryAssociateMutation();

  const handleAssignDeliveryAssociate = async ({ associate }) => {
    await asssignDeliveryAssociate({
      orderId,
      deliveryAssociateId: associate?.id
    });
  };

  const handlePrintSummary = async () => {
    const res = await callCreateOrderSummaryInvoiceMutation({
      orderIds: selectedOrders?.map((order) => order.id)
    });
    setSummary(res?.data?.data);
    setIsOpenPrint(true);
  };

  const updatePrintStatus = () => {
    callUpdateOrderPrintStatusMutation({
      isInvoicePrinted: true,
      orderIds: selectedOrders?.map((order) => order.id)
    });
  };

  const handlePrint = () => {
    setSummary('');
    setIsOpenPrint(true);
  };

  const handleOnChangeAllCheckbox = () => {
    if (selectedOrders?.length === currentData?.data?.invoices?.length) {
      setSelectedOrders([]);
    } else {
      setSelectedOrders(currentData?.data?.invoices);
    }
  };

  const handleOnChangeCheckbox = (row) => {
    if (selectedOrders?.find((order) => order.id === row.original?.id)) {
      setSelectedOrders((orders) =>
        orders.filter((order) => order.id !== row.original?.id)
      );
    } else {
      setSelectedOrders((orders) => [...orders, row.original]);
    }
  };

  useEffect(() => {
    if (isAssignSuccess) {
      setOpen(false);
      setOrderId(null);
    }
  }, [isAssignSuccess]);

  useEffect(() => {
    isUpdateOrderPrintStatusMutationSuccess && setIsOpenPrint(false);
  }, [isUpdateOrderPrintStatusMutationSuccess]);

  const cleanup = () => {
    setQuery('');
  };

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <button
              type="button"
              onClick={handlePrintSummary}
              className="mr-4 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:cursor-not-allowed disabled:bg-gray-500"
              disabled={
                selectedOrders?.length === 0 ||
                createOrderSummaryInvoiceMutationLoading
              }
            >
              Print Summary
            </button>
            <button
              type="button"
              onClick={handlePrint}
              className="mr-4 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:cursor-not-allowed disabled:bg-gray-500"
              disabled={selectedOrders?.length === 0}
            >
              Print Invoice
            </button>
            <ApplyFilter
              data={searchModes}
              selectedData={selectedData}
              handleSelectedData={setSelectedData}
            />
          </div>
        </div>
      </div>
      <Table data={currentData?.data?.invoices ?? []} columns={columns} />
      <AssignSingleModal
        open={open}
        setOpen={setOpen}
        setQuery={setQuery}
        query={query}
        dataList={deliveryAssociatessCurrentData?.data?.users ?? []}
        assignDeliveryPerson={handleAssignDeliveryAssociate}
        cleanup={cleanup}
      />
      <PrintModal
        data={summary || selectedOrders}
        open={isOpenPrint}
        setOpen={setIsOpenPrint}
        isIFrameEnabled={summary}
        handleFunc={updatePrintStatus}
        disabled={updateOrderPrintStatusMutationLoading}
      />
    </div>
  );
}

import toast from 'react-hot-toast';
import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const profileApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: ({ ...query }) => ({
        url: `/api/profile?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getProfile']
    }),
    // createSalesAssociate: builder.mutation({
    //   query: ({ name, phone, warehouseId, routeIds }) => ({
    //     url: '/api/users/sales-associates',
    //     method: 'POST',
    //     body: { name, phone, warehouseId, routeIds}
    //   }),
    //   onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
    //     try {
    //       await queryFulfilled;

    //       toast.success('Sales Associate has been created!');
    //     } catch (error) {
    //       toast.error(error.error.data.message);
    //     }
    //   },
    //   invalidatesTags: [
    //     'getSalesAssociate','getUser'
    //     // 'getOneUser, getUnAssignedUsers'
    //   ]
    // }),
    // getOneSalesAssociate: builder.query({
    //   query: ({ salesAssociateId }) => ({
    //     url: `/api/users/sales-associates/${salesAssociateId}`,
    //     method: 'GET'
    //   }),
    //   providesTags: ['getOneSalesAssociateWithId']
    // }),
    // updateSalesAssociate: builder.mutation({
    //   query: ({ salesAssociateId, ...query }) => ({
    //     url: `/api/users/sales-associates/${salesAssociateId}`,
    //     method: 'PUT',
    //     body: { ...query}
    //   }),
    //   onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
    //     try {
    //       await queryFulfilled;
    //       toast.success('Sales Associate has been updated!');
    //     } catch (error) {
    //       toast.error(error.error.data.message);
    //     }
    //   },
    //   invalidatesTags: [
    //     'getUser', 'getSalesAssociate',
    //     'getOneSalesAssociateWithId'
    //   ]
    // })
  })
});

export const {
  useGetProfileQuery,
  useCreateSalesAssociateMutation,
  useGetOneSalesAssociateQuery,
  useUpdateSalesAssociateMutation,
} = profileApi;

import { Switch } from '@headlessui/react';
import { PlusIcon, RectangleStackIcon } from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ConfirmationModal from '../../../components/common/DataTable/ConfirmationModal';
import Table from '../../../components/common/DataTable/Table';
import ModifyAmount from '../../../components/common/ModifyAmount';
import TablePagination from '../../../components/common/TablePagination';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import useQuery from '../../../hooks/useQuery';
import {
  useDeleteOneSalesAssociateGoalMutation,
  useGetOneSalesAssociateGoalsQuery,
  useUpdateOneSalesAssociateGoalTypeMutation,
  useUpdateOneSalesAssociateSingleGoalMutation
} from '../../../store/features/sales-associate/salesAssociateApi';
import { useSelector } from 'react-redux';
import { selectCurrentRole } from '../../../store/features/auth/authSlice';

const rootMeta = {
  page_name: 'Sales Associate Goals List',
  breadcrumbs: null
};

export default function SalesAssociateGoals() {
  const salesAssociateId = useQuery().get('salesAssociateId');
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [inventoryId, setInventoryId] = useState();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const pages = [
    {
      name: 'Sales Associate',
      location: '/app/manage-sales-associate',
      current: false
    },
    {
      name: 'Goals List',
      location: `/app/manage-sales-associate/goals-list?salesAssociateId=${salesAssociateId}`,
      current: false
    }
  ];

  const { currentData } = useGetOneSalesAssociateGoalsQuery(
    !isEmpty(selectedData)
      ? {
          page,
          ...selectedData
        }
      : {
          page,
          salesAssociateId
        }
  );

  const [
    callUpdateOneSalesAssociateSingleGoalMutation,
    {
      isLoading: isLoadingUpdateOneSalesAssociateSingleGoalMutation,
      isSuccess: isSuccessUpdateOneSalesAssociateSingleGoalMutation
    }
  ] = useUpdateOneSalesAssociateSingleGoalMutation();

  const [
    callDeleteSingleInventoryMutation,
    {
      isLoading: isLoadingDeleteSingleInventoryMutation,
      isSuccess: isSuccessDeleteSingleInventoryMutation
    }
  ] = useDeleteOneSalesAssociateGoalMutation();

  const [
    callUpdateOneSalesAssociateGoalTypeMutation,
    {
      isLoading: isLoadingUpdateOneSalesAssociateGoalTypeMutation,
      isSuccess: isSuccessUpdateOneSalesAssociateGoalTypeMutation
    }
  ] = useUpdateOneSalesAssociateGoalTypeMutation();

  const role = useSelector(selectCurrentRole);

  const columnHelper = createColumnHelper();

  const handleGoalRemove = (id) => {
    setInventoryId(id);
    setIsConfirmationOpen(true);
  };

  const handleApplyGoals = (row) => {
    setOpen(true);
    setSelected(row);
  };

  const updateGoal = (data) => {
    const payload = {
      salesAssociateId,
      inventoryId: data?.id,
      quantity: parseInt(data?.quantity, 10)
    };

    callUpdateOneSalesAssociateSingleGoalMutation(payload);
  };

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex gap-x-2">
            {row?.inventory?.product?.photo1 ? (
              <img
                src={row?.inventory?.product?.photo1}
                alt=""
                width="30"
                height="30"
              />
            ) : (
              <RectangleStackIcon className="w-10 h-10" color="gray" />
            )}
            {row?.inventory?.product?.photo2 && (
              <img
                src={row?.inventory?.product?.photo2}
                alt=""
                width="30"
                height="30"
              />
            )}
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'PHOTO'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.inventory?.product?.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.inventory?.product?.nameEn}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME(ENGLISH)'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.inventory?.product?.nameBn}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME(BANGLA)'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.targetQuantity}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'TARGET QUANTITY'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.inventory?.commissionType}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'COMMISSION TYPE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.inventory?.commissionValue}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'COMMISSION VALUE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <button
            onClick={() => handleApplyGoals(row)}
            className="text-red-600 hover:underline ml-3"
          >
            Apply Goals
          </button>
          <button
            className="text-red-600 hover:underline ml-3"
            onClick={() => handleGoalRemove(row.inventory?.id)}
          >
            Remove
          </button>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];

  const confirmRemoveProduct = (id) => {
    callDeleteSingleInventoryMutation({
      salesAssociateId,
      inventoryId: id
    });
  };

  const handleCleanUp = () => {
    setInventoryId();
  };

  useEffect(() => {
    isSuccessDeleteSingleInventoryMutation && setIsConfirmationOpen(false);
  }, [isSuccessDeleteSingleInventoryMutation]);

  useEffect(() => {
    isSuccessUpdateOneSalesAssociateSingleGoalMutation && setOpen(false);
  }, [isSuccessUpdateOneSalesAssociateSingleGoalMutation]);

  useEffect(() => {
    setPage(1);
  }, [selectedData]);

  return (
    <div className="sm:px-4">
      <div className="flex justify-between">
        <TopNavigationBar pageName={rootMeta.page_name} pages={pages} />
        {role === 'WAREHOUSE_ADMIN' && (
          <div className="sm:flex sm:items-center">
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex items-center gap-x-5">
              <Switch
                checked={currentData?.data?.goalType === 'FOCUS'}
                onChange={() => {
                  const goalType =
                    currentData?.data?.goalType === 'FOCUS'
                      ? 'RESTRICT'
                      : 'FOCUS';
                  callUpdateOneSalesAssociateGoalTypeMutation({
                    salesAssociateId,
                    goalType
                  });
                }}
                className={clsx(
                  currentData?.data?.goalType === 'FOCUS'
                    ? 'bg-green-700'
                    : 'bg-red-600',
                  'flex items-center relative h-8 w-28 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2'
                )}
              >
                {currentData?.data?.goalType === 'RESTRICT' && (
                  <span className="text-white font-semibold pl-2 text-sm">
                    RESTRICT
                  </span>
                )}
                <span
                  className={clsx(
                    currentData?.data?.goalType === 'FOCUS'
                      ? 'translate-x-1'
                      : 'translate-x-2',
                    'pointer-events-none relative inline-block h-6 w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  )}
                >
                  <span
                    className={clsx(
                      currentData?.data?.goalType === 'FOCUS'
                        ? 'opacity-0 duration-100 ease-out'
                        : 'opacity-100 duration-200 ease-in',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                  >
                    <svg
                      className="h-3 w-3 text-red-600"
                      fill="none"
                      viewBox="0 0 12 12"
                    >
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  {currentData?.data?.goalType === 'FOCUS' && (
                    <span className="pl-8 text-white text-sm font-semibold">
                      FOCUS
                    </span>
                  )}
                  <span
                    className={clsx(
                      currentData?.data?.goalType === 'FOCUS'
                        ? 'opacity-100 duration-200 ease-in'
                        : 'opacity-0 duration-100 ease-out',
                      'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    )}
                    aria-hidden="true"
                  >
                    <svg
                      className="h-3 w-3 text-green-700"
                      fill="currentColor"
                      viewBox="0 0 12 12"
                    >
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
              <div className="flex items-center">
                <Link
                  to={`/app/manage-sales-associate/goals?salesAssociateId=${salesAssociateId}`}
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
                >
                  <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Set Target Goals
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <Table data={currentData?.data?.items ?? []} columns={columns} />
      <ModifyAmount
        open={open}
        setOpen={setOpen}
        id={selected?.inventory?.id}
        quantity={selected?.targetQuantity}
        updateProduct={updateGoal}
        productName={selected?.inventory?.product?.nameEn}
        sku={selected?.inventory?.product?.sku}
        fromOrder
      />
      <ConfirmationModal
        id={inventoryId}
        open={isConfirmationOpen}
        setOpen={setIsConfirmationOpen}
        title="Confirm Removal"
        message="Are you sure you want to remove this product goal for this sales associate?"
        confirmAction={confirmRemoveProduct}
        cleanup={() => handleCleanUp()}
        disabled={isLoadingDeleteSingleInventoryMutation}
      />
    </div>
  );
}

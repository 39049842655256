import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import Input from './Input';
import { CheckIcon } from '@heroicons/react/20/solid';

export default function AssignSingleModal({
  open,
  setOpen,
  query,
  setQuery,
  title = '',
  dataList = [],
  setSelected,
  selected
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[99999]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full relative items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Title>Assign {title}</Title>
                    <div className="mt-2 relative">
                      <Input query={query} setQuery={setQuery} title={title} />
                      <div className="absolute inset-x-0 mt-3 rounded-lg p-6 z-[99999999999] bg-white  border shadow-sm">
                        <ul className="divide-y divide-gray-200 max-h-48 overflow-y-scroll scroll- overflow-hidden scrollbar-none">
                          {dataList?.length > 0 ? (
                            React.Children.toArray(
                              dataList.map((item) => (
                                <div
                                  className={`flex justify-between px-4 py-4 hover:bg-slate-200 rounded-xl cursor-pointer ${
                                    selected?.id === item?.id
                                      ? 'bg-gray-100'
                                      : ''
                                  }`}
                                  onClick={() => {
                                    setOpen(false);
                                    setSelected(item);
                                  }}
                                >
                                  <li key={item?.id}>{item?.name}</li>
                                  {selected?.id === item?.id && (
                                    <CheckIcon className="h-4 w-4 text-green-500" />
                                  )}
                                </div>
                              ))
                            )
                          ) : (
                            <LoaderIcon />
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const Title = ({ children }) => (
  <Dialog.Title
    as="h3"
    className="text-xl font-bold leading-6 text-gray-900 border-b pb-8 border-black/10"
  >
    {children}
  </Dialog.Title>
);

AssignSingleModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  query: PropTypes.string,
  setSelected: PropTypes.func,
  selected: PropTypes.object,
  title: PropTypes.string,
  setQuery: PropTypes.func,
  dataList: PropTypes.array
};

import toast from 'react-hot-toast';
import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const pickupLocationApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getPickupLocation: builder.query({
      query: ({ ...query }) => ({
        url: `/api/pick-up-locations?${appendParamsToUrl({ ...query })}`,
        method: 'GET'
      }),
      providesTags: ['getPickupLocation']
    }),
    createPickupLocation: builder.mutation({
      query: (body) => ({
        url: '/api/pick-up-locations',
        method: 'POST',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Warehouse created successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getOnePickupLocation',
        'getPickupLocation',
        'getUnAssignedManagers'
      ]
    }),
    getOnePickupLocation: builder.query({
      query: ({ locationId }) => ({
        url: `/api/pick-up-locations/${locationId}`,
        method: 'GET'
      }),
      providesTags: ['getOnePickupLocation']
    }),
    updatePickupLocation: builder.mutation({
      query: ({ locationId, ...body }) => ({
        url: `/api/pick-up-locations/${locationId}`,
        method: 'PUT',
        body: body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Warehouse updated successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getOnePickupLocation',
        'getPickupLocation',
        'getUnAssignedManagers'
      ]
    }),
    changeOpsStatus: builder.mutation({
      query: ({ pickUpLocationId, opsStatus }) => ({
        url: `/api/pick-up-locations/${pickUpLocationId}`,
        method: 'PUT',
        body: { opsStatus }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Warehouse status changed successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getOnePickupLocation', 'getPickupLocation']
    }),
    unassignedPickupLocations: builder.query({
      query: ({ query }) => ({
        url: `/api/pick-up-locations?page=1${query && '&name=' + query}`,
        method: 'GET'
      }),
      providesTags: ['getPickupLocation']
    })
  })
});

export const {
  useGetPickupLocationQuery,
  useCreatePickupLocationMutation,
  useGetOnePickupLocationQuery,
  useUpdatePickupLocationMutation,
  useChangeOpsStatusMutation,
  useUnassignedPickupLocationsQuery
} = pickupLocationApi;

import toast from 'react-hot-toast';
import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';
export const orderApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrder: builder.query({
      query: ({ ...query }) => ({
        url: `/api/orders/admin?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getOrder']
    }),
    getOrderInvoice: builder.query({
      query: ({ ...query }) => ({
        url: `/api/orders/admin/invoices?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getOrderInvoice']
    }),
    createOrderSummaryInvoice: builder.mutation({
      query: (body) => ({
        url: `/api/orders/admin/invoices/summary-for-delivery`,
        method: 'POST',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          const res = await queryFulfilled;
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getOrderInvoice']
    }),
    updateOrderPrintStatus: builder.mutation({
      query: (body) => ({
        url: `/api/orders/admin/print-status`,
        method: 'PATCH',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Invoice print status updated successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getOrderInvoice']
    }),
    placeOrder: builder.mutation({
      query: (body) => ({
        url: `/api/orders`,
        method: 'POST',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Order placed successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getCart']
    }),
    getSingleOrder: builder.query({
      query: ({ orderId }) => ({
        url: `/api/orders/admin/${orderId}`,
        method: 'GET'
      }),
      providesTags: ['getSingleOrder']
    }),
    updateSingleOrder: builder.mutation({
      query: ({ orderId, ...body }) => ({
        url: `/api/orders/admin/${orderId}/quantity`,
        method: 'PATCH',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          const res = await queryFulfilled;
          toast.success(res?.data?.data || 'Order updated successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getSingleOrder']
    }),
    updateSingleProductDiscount: builder.mutation({
      query: ({ orderId, ...body }) => ({
        url: `/api/orders/admin/${orderId}/discount`,
        method: 'PATCH',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          const res = await queryFulfilled;
          toast.success(res?.data?.data || 'Discount applied successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getSingleOrder']
    }),
    assignDeliveryAssociate: builder.mutation({
      query: ({ orderId, ...body }) => ({
        url: `/api/orders/admin/${orderId}/delivery-associate`,
        method: 'PATCH',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          const res = await queryFulfilled;
          toast.success(
            res?.data?.data || 'Delivery Associate assigned successfully'
          );
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getOrder', 'getSingleOrder']
    }),
    getOrderSettlement: builder.query({
      query: (deliveryAssociateId) => ({
        url: `/api/orders/for-settlements/${deliveryAssociateId}`,
        method: 'GET'
      }),
      providesTags: ['getOrderSettlement']
    }),
    createOrderSettlementSummary: builder.mutation({
      query: ({ deliveryAssociateId, ...body }) => ({
        url: `/api/orders/for-settlements/${deliveryAssociateId}/summary`,
        method: 'POST',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: []
    }),
    createOrderSettlement: builder.mutation({
      query: ({ deliveryAssociateId, ...body }) => ({
        url: `/api/orders/for-settlements/${deliveryAssociateId}/settle`,
        method: 'POST',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success(
            'Cash collection for the selected orders is successful'
          );
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getOrderSettlement', 'getOrder']
    }),
    addPaymentOrderSettlement: builder.mutation({
      query: ({ orderId, ...body }) => ({
        url: `/api/orders/for-settlements/add-payment/${orderId}`,
        method: 'PATCH',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Add Payment is successful');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getOrderSettlement', 'getOrder']
    }),
    applySalesDiscount: builder.mutation({
      query: ({ orderId, ...body }) => ({
        url: `/api/orders/admin/${orderId}/sales-discount`,
        method: 'PATCH',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Sales discount is applied successful');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getSingleOrder']
    })
  })
});
export const {
  useGetOrderQuery,
  useGetOrderInvoiceQuery,
  useCreateOrderSummaryInvoiceMutation,
  useUpdateOrderPrintStatusMutation,
  usePlaceOrderMutation,
  useGetSingleOrderQuery,
  useUpdateSingleOrderMutation,
  useUpdateSingleProductDiscountMutation,
  useAssignDeliveryAssociateMutation,
  useGetOrderSettlementQuery,
  useCreateOrderSettlementSummaryMutation,
  useCreateOrderSettlementMutation,
  useAddPaymentOrderSettlementMutation,
  useApplySalesDiscountMutation
} = orderApi;

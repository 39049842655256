import toast from 'react-hot-toast';
import { appendParamsToUrl, handleErrorMessage } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const deliveryAssociateApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getDeliveryAssociate: builder.query({
      query: ({ ...query }) => ({
        url: `/api/users/delivery-associates?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getDeliveryAssociate']
    }),
    createDeliveryAssociate: builder.mutation({
      query: ({ name, phone, warehouseId, routeIds }) => ({
        url: '/api/users/delivery-associates',
        method: 'POST',
        body: { name, phone, warehouseId, routeIds}
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Delivery Associate has been created!');
        } catch (error) {
          handleErrorMessage(error);
        }
      },
      invalidatesTags: [
        'getDeliveryAssociate','getUser'
      ]
    }),
    getOneDeliveryAssociate: builder.query({
      query: ({ deliveryAssociateId }) => ({
        url: `/api/users/delivery-associates/${deliveryAssociateId}`,
        method: 'GET'
      }),
      providesTags: ['getOneDeliveryAssociateWithId']
    }),
    updateDeliveryAssociate: builder.mutation({
      query: ({ deliveryAssociateId, ...query }) => ({
        url: `/api/users/delivery-associates/${deliveryAssociateId}`,
        method: 'PUT',
        body: { ...query}
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Delivery Associate has been updated!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getUser', 'getDeliveryAssociate',
        'getOneDeliveryAssociateWithId'
      ]
    })
  })
});

export const {
  useGetDeliveryAssociateQuery,
  useCreateDeliveryAssociateMutation,
  useGetOneDeliveryAssociateQuery,
  useUpdateDeliveryAssociateMutation,
} = deliveryAssociateApi;

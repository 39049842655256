import React, { Fragment } from 'react';
import { LoaderIcon } from 'react-hot-toast';

export default function StatsMarkerDataUser({ stats }) {
  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {Array.isArray(stats) && stats.length > 0 ? (
          stats.map((item, index) => (
            <Stat
              key={index}
              photo={item?.product?.photo || ''}
              title={item?.product?.nameEn || 'Product XXX'}
              data={`${item?.totalQuantity || 0} piece(s)`}
            />
          ))
        ) : (
          <p>No data available</p>
        )}
      </dl>
    </div>
  );
}

const Stat = ({ photo, title, data }) => (
  <div className="relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:px-6 sm:pt-6">
    <div className="flex items-center">
    <div className="rounded-md p-2">
        <img src={photo} alt={title} className="h-12 w-12 object-cover rounded-full" />
      </div>
      <div className="ml-5">
        <p className="truncate text-sm font-medium text-gray-500">{title}</p>
        <p className="text-xl font-semibold text-gray-900">
          {data ?? <LoaderIcon className="h-7 w-7 mt-1" />}
        </p>
      </div>
    </div>
  </div>
);

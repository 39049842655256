import { PlusCircleIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useState } from 'react';
import { useGetAllRoutesQuery } from '../../store/features/manage-routes/manageRoutesApi';
import Divider from '../common/Divider';
import AssignSingleModal from './AssignModal/AssignSingleModal';
import { isEmpty } from 'lodash';

export default function RoutesPickup({ setSelected, selected }) {
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');

  const { currentData } = useGetAllRoutesQuery({
    // page: 1,
    name: query || undefined
  });

  return (
    <div>
      <h4 className="font-bold mb-2 block leading-6 text-gray-700">
        Assign Route
      </h4>
      <Divider className="my-2" />
      {!isEmpty(selected) && (
        <Fragment>
          <div className="flex items-center justify-between">
            <div className="my-1 flex items-center">
              <UserCircleIcon className="h-12 w-12 text-gray-400" />
              <span className="font-medium ml-2 text-gray-600">
                {selected?.name}
              </span>
            </div>
            <div>
              <span
                className="font-medium text-red-600 hover:text-red-500 cursor-pointer"
                onClick={() => {
                  setSelected({});
                }}
              >
                Remove
              </span>
            </div>
          </div>

          <Divider />
        </Fragment>
      )}

      <div className="flex items-center">
        <div
          onClick={() => setOpen(true)}
          className="mt-2 flex items-center cursor-pointer py-2 pr-4 hover:bg-gray-100 rounded-xl transition-all hover:shadow-sm"
        >
          <PlusCircleIcon className="h-12 w-12 text-gray-400" />
          <span className="font-medium ml-2 text-red-600">Assign</span>
        </div>
      </div>

      <AssignSingleModal
        open={open}
        setOpen={setOpen}
        query={query}
        setQuery={setQuery}
        title="Route"
        setSelected={setSelected}
        selected={selected}
        dataList={currentData?.data?.routes ?? []}
      />
    </div>
  );
}

import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import ApplyFilter from '../../../components/common/ApplyFilter';
import Table from '../../../components/common/DataTable/Table';
import TablePagination from '../../../components/common/TablePagination';
import {
  useGetInventoryStockRequestQuery,
  useUpdateInventorySingleStockRequestMutation
} from '../../../store/features/inventory/inventoryApi';

const rootMeta = {
  page_name: 'Pending Stocks',
  breadcrumbs: null
};

const columnHelper = createColumnHelper();

export default function PendingStocksRoot() {
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});

  const { currentData, isLoading } = useGetInventoryStockRequestQuery(
    !isEmpty(selectedData)
      ? {
          page,
          ...selectedData
        }
      : {
          page
        }
  );

  const [
    callUpdateInventorySingleStockRequestMutation,
    {
      isSuccess: approveStockRequestSuccess,
      isLoading: approveStockRequestLoading
    }
  ] = useUpdateInventorySingleStockRequestMutation();

  const singleProductApproveStock = (id) => {
    const payload = {
      id,
      action: 'accept'
    };

    callUpdateInventorySingleStockRequestMutation(payload);
    // setProductsState((data) => data.filter((row) => row.id !== id));
  };

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.product?.nameEn}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row.inventory?.stock}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'CURRENT STOCK',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <input
              type="number"
              placeholder={''}
              defaultValue={row.value}
              className={clsx(
                'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
              )}
            />
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'REQUESTED STOCK',
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <button
            className="text-red-600 hover:underline"
            onClick={() => singleProductApproveStock(row.id)}
          >
            Approve
          </button>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION'
      }
    )
  ];

  useEffect(() => {
    setPage(1);
  }, [selectedData]);

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
      </div>
      <Table data={currentData?.data ?? []} columns={columns} />
    </div>
  );
}

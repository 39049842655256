import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import EditCustomerRegistrationForm from '../../../components/app/customers-reg/EditCustomerRegistrationForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Update Customer Registration'
};

const pages = [
  {
    name: 'Customers Registration Request',
    location: '/app/customers-reg-requests',
    current: false
  },
  {
    name: 'Update',
    location: '/app/customers-reg-requests/edit',
    current: true
  }
];

export default function EditCustomerRegistration() {
  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <EditCustomerRegistrationForm />
    </div>
  );
}

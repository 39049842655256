import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import Table from '../../common/DataTable/Table';
import CheckboxWithLabel from '../../common/CheckboxWithLabel';

const discountTypeData = [
  {
    id: 1,
    name: 'PERCENTAGE'
  },
  {
    id: 2,
    name: 'FIXED_AMOUNT'
  }
];

export default function CustomizeLinkProducts({
  draftProducts,
  removeProduct,
  handleDraftProducts
}) {
  const columnHelper = createColumnHelper();

  const updateDraftProducts = (id, field, value) => {
    handleDraftProducts(id, field, value);
  };

  const truncateText = (text, length) => {
    if (!text) return '';
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div title={row.nameEn}>{truncateText(row.nameEn, 40)}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <input
              className={clsx(
                'block w-20 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
              )}
              onBlur={(event) =>
                updateDraftProducts(
                  row.productId,
                  'unitPrice',
                  event.target.value
                )
              }
              defaultValue={row.unitPrice}
              type="number"
            />
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'UNIT PRICE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <input
              className={clsx(
                'block w-20 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
              )}
              onBlur={(event) =>
                updateDraftProducts(
                  row.productId,
                  'memberUnitPrice',
                  event.target.value
                )
              }
              defaultValue={row.memberUnitPrice}
              type="number"
            />
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'MEMBER PRICE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <CheckboxWithLabel
            onPress={() =>
              updateDraftProducts(
                row.productId,
                'isFreeDelivery',
                !row.isFreeDelivery
              )
            }
            noLabel
            isChecked={row.isFreeDelivery}
            id={row.productId}
          />
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'FREE DELIVERY'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <input
              className={clsx(
                'block w-20 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
              )}
              onBlur={(event) =>
                updateDraftProducts(row.productId, 'stock', event.target.value)
              }
              defaultValue={row.stock}
              type="number"
            />
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'STOCK'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <select
            id="discountType"
            name="discountType"
            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm"
            onChange={(e) => {
              updateDraftProducts(
                row.productId,
                'discountType',
                e.target.value
              );
            }}
            value={row.discountType}
          >
            {discountTypeData.map((item) => (
              <option value={item.name} key={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'INCENTIVE TYPE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <input
              className={clsx(
                'block w-20 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
              )}
              onBlur={(event) =>
                updateDraftProducts(
                  row.productId,
                  'discountValue',
                  event.target.value
                )
              }
              type="number"
              defaultValue={row.discountValue}
            />
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'INCENTIVE VALUE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <select
            id="commissionType"
            name="commissionType"
            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm"
            onChange={(e) => {
              const newCommissionType = e.target.value;
              let newCommissionValue;
              if (newCommissionType === 'PERCENTAGE') {
                newCommissionValue = '1';
              } else if (newCommissionType === 'FIXED_AMOUNT') {
                newCommissionValue = '5';
              }
              updateDraftProducts(
                row.productId,
                'commissionType',
                newCommissionType
              );
              updateDraftProducts(
                row.productId,
                'commissionValue',
                newCommissionValue
              );
            }}
            value={row.commissionType}
          >
            {discountTypeData.map((item) => (
              <option value={item.name} key={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'COMMISSION TYPE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <input
              className={clsx(
                'block w-20 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
              )}
              onBlur={(event) =>
                updateDraftProducts(
                  row.productId,
                  'commissionValue',
                  event.target.value
                )
              }
              defaultValue={row?.commissionValue}
              type="text"
            />
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'COMMISSION VALUE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <button
            className="text-red-600 hover:underline"
            onClick={() => removeProduct(row)}
          >
            Remove
          </button>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION'
      }
    )
  ];
  return (
    <div className="sm:px-4 mt-5">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            Draft Products in Warehouse
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
      </div>
      <Table data={draftProducts} columns={columns} />
    </div>
  );
}

import { createColumnHelper } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import Table from '../../common/DataTable/Table';
import TablePagination from '../../common/TablePagination';
import { useGetProductManagementQuery } from '../../../store/features/product-management/productManagementApi';
import ApplyFilter from '../../common/ApplyFilter';
import { RectangleStackIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

const rootMeta = {
  page_name: 'Central Products',
  breadcrumbs: null
};

const searchModes = [
  {
    param_name: 'Name',
    param: 'nameEn',
    type: 'text'
  },
  {
    param_name: 'SKU',
    param: 'sku',
    type: 'text'
  },
  {
    param_name: 'Brand',
    param: 'brand',
    type: 'text'
  },
  {
    param_name: 'Supplier',
    param: 'supplier',
    type: 'text'
  },
  {
    param_name: 'Is Published',
    param: 'isPublished',
    type: 'text'
  },
  {
    param_name: 'Category Ids',
    param: 'categoryIds',
    type: 'text'
  },
  {
    param_name: 'Is Returnable',
    param: 'isReturnable',
    type: 'text'
  }
];

export default function CentralProducts({
  addToWareHouseDraft,
  draftProducts,
  removedProductId
}) {
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [productsData, setProductsData] = useState([]);

  const { currentData, isLoading } = useGetProductManagementQuery(
    !isEmpty(selectedData)
      ? {
          page,
          ...selectedData
        }
      : {
          page
        }
  );

  const handleAddToWarehouseDraft = (row) => {
    setProductsData((data) =>
      data?.map((item) => {
        if (item.id === row.id) {
          return {
            ...item,
            isAddDisabled: true
          };
        }
        return item;
      })
    );
    addToWareHouseDraft(row);
  };

  const truncateText = (text, length) => {
    if (!text) return '';
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex gap-x-2">
            {row?.photo1 ? (
              <img src={row?.photo1} alt="" width="30" height="30" />
            ) : (
              <RectangleStackIcon className="w-10 h-10" color="gray" />
            )}
            {row?.photo2 && (
              <img src={row?.photo2} alt="" width="30" height="30" />
            )}
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'PHOTO'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU NO.'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div title={row.nameEn}>{truncateText(row.nameEn, 40)}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME'
      }
    ),
    columnHelper.accessor(
      (row) => {
        return (
          <Fragment>
              <button
                className="text-red-600 hover:underline disabled:text-gray-500 w-fit inline-flex mx-2"
                onClick={() => handleAddToWarehouseDraft(row)}
                disabled={row.isAddDisabled}
              >
                Add
              </button>
              <Link
               to={`/app/product-management/details?productId=${row?.id}`}
               className="text-red-600 hover:underline disabled:text-gray-500 w-fit inline-flex mx-2"
               >
                  View
              </Link>
          </Fragment>
        );
      },
      {
        cell: (info) => info.getValue(),
        header: 'ACTION'
      }
    )
  ];

  useEffect(() => {
    setPage(1);
  }, [selectedData]);

  useEffect(() => {
    if (currentData?.data?.items) {
      draftProducts?.length === 0 &&
        setProductsData(
          currentData?.data?.items?.map((item) => {
            return {
              ...item,
              isAddDisabled: false
            };
          })
        );
      draftProducts?.length > 0 &&
        setProductsData(
          currentData?.data?.items?.map((item) => {
            if (draftProducts?.find((draft) => draft.productId === item.id)) {
              return {
                ...item,
                isAddDisabled: true
              };
            }
            return item;
          })
        );
    }
  }, [currentData, draftProducts]);

  useEffect(() => {
    setProductsData((data) =>
      data?.map((item) => {
        if (item.id === removedProductId) {
          return {
            ...item,
            isAddDisabled: false
          };
        }
        return item;
      })
    );
  }, [removedProductId]);

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <ApplyFilter
              data={searchModes}
              selectedData={selectedData}
              handleSelectedData={setSelectedData}
            />
          </div>
        </div>
      </div>
      <Table data={productsData ?? []} columns={columns} />
      <TablePagination
        paginationData={currentData?.data?.meta ?? undefined}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

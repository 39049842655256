import toast from 'react-hot-toast';
import { appendParamsToUrl, handleErrorMessage } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const manageRoutesApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllRoutes: builder.query({
      query: ({ ...query }) => ({
        // TO DO: Remove page=1
        url: `/api/routes?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getAllRoutes']
    }),
    getOneRoute: builder.query({
      query: ({ routeId }) => ({
        url: `/api/routes/${routeId}`,
        method: 'GET'
      }),
      providesTags: ['getOneRoute']
    }),
    createRoute: builder.mutation({
      query: ({ ...query }) => ({
        url: `/api/routes`,
        method: 'POST',
        body: {
          ...query
        }
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          toast.success('Route created successfully');
        } catch (error) {
          handleErrorMessage(error);
        }
      }
    }),
    manageRouteAssignDeliveryPerson: builder.mutation({
      query: ({ routeId, deliveryPersonId }) => ({
        url: `/api/routes/${routeId}`,
        method: 'PUT',
        body: {
          deliveryPersonId
        }
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          toast.success('Delivery person assigned successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getAllRoutes']
    }),
    updateRoute: builder.mutation({
      query: ({ routeId, ...body }) => ({
        url: `/api/routes/${routeId}`,
        method: 'PUT',
        body
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          toast.success('Route updated successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getAllRoutes', 'getOneRoute']
    })
  })
});

export const {
  useGetAllRoutesQuery,
  useCreateRouteMutation,
  useManageRouteAssignDeliveryPersonMutation,
  useGetOneRouteQuery,
  useUpdateRouteMutation
} = manageRoutesApi;

import { PlusIcon } from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import { Fragment, useEffect, useState } from 'react';
import Table from '../../../components/common/DataTable/Table';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import useQuery from '../../../hooks/useQuery';
import ModifyAmount from '../../../components/common/ModifyAmount';
import AddProductsModal from '../../../components/common/AddProductsModal/AddProductsModal';
import InvoiceSummary from '../../../components/common/InvoiceSummary';
import {
  useAssignDeliveryAssociateMutation,
  useGetSingleOrderQuery,
  useUpdateSingleOrderMutation,
  useUpdateSingleProductDiscountMutation
} from '../../../store/features/order/orderApi';
import CustomerSummary from '../../../components/common/CustomerSummary';
import ApplyDiscount from '../../../components/common/ApplyDiscount';
import AssignSingleModal from '../../../components/app/shipment/AssignSingleModal';
import { useGetDeliveryAssociateQuery } from '../../../store/features/delivery-associate/deliveryAssociateApi';
import ConfirmationModal from '../../../components/common/DataTable/ConfirmationModal';

const pageMeta = {
  page_name: 'Order Details',
  breadcrumbs: null
};

export default function ShipmentDetails() {
  const orderId = useQuery().get('orderId');

  const pages = [
    {
      name: 'Orders',
      location: `/app/shipment`,
      current: false
    },
    {
      name: 'View Order',
      location: `/app/shipment/detail?orderId=${orderId}`,
      current: true
    }
  ];

  const [isAddProductsOpen, setIsAddProductsOpen] = useState(false);
  const [isModifyOpen, setIsModifyOpen] = useState(false);
  const [isDiscountOpen, setIsDiscountOpen] = useState(false);
  const [cartProducts, setCartProducts] = useState([]);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [productToRemove, setProductToRemove] = useState(null);

  const { currentData } = useGetSingleOrderQuery({
    orderId
  });

  const [
    callUpdateSingleOrderMutation,
    {
      isSuccess: updateSingleOrderMutationSuccess,
      isLoading: isUpdateSingleOrderMutationLoading
    }
  ] = useUpdateSingleOrderMutation();

  const [
    callUpdateSingleProductDiscountMutation,
    { isSuccess: updateSingleProductDiscountSuccess }
  ] = useUpdateSingleProductDiscountMutation();

  const { currentData: deliveryAssociatessCurrentData } =
    useGetDeliveryAssociateQuery(
      query
        ? {
            name: query
          }
        : {}
    );

  const [asssignDeliveryAssociate, { isSuccess: isAssignSuccess }] =
    useAssignDeliveryAssociateMutation();

  const handleAssignDeliveryAssociate = async ({ associate }) => {
    await asssignDeliveryAssociate({
      orderId,
      deliveryAssociateId: associate?.id
    });
  };

  const handleRemoveAssociate = (orderId) => {
    asssignDeliveryAssociate({
      orderId,
      deliveryAssociateId: null
    });
  };

  useEffect(() => {
    if (isAssignSuccess) {
      setOpen(false);
    }
  }, [isAssignSuccess]);

  useEffect(() => {
    currentData?.data && setCartProducts(currentData?.data?.items);
  }, [currentData]);

  const toggleModal = () => {
    setIsAddProductsOpen((e) => !e);
  };

  const updateOrderProduct = (data) => {
    const payload = {
      orderId,
      inventoryId: data?.id,
      quantity: parseInt(data?.quantity, 10)
    };
    callUpdateSingleOrderMutation(payload);
  };

  const updateSingleProductDiscount = (data) => {
    const payload = {
      orderId,
      inventoryId: data?.id,
      unitDiscount: parseFloat(data?.discount)
    };
    callUpdateSingleProductDiscountMutation(payload);
  };

  const handleModifyAmount = (data) => {
    setSelected({
      id: data?.inventoryId,
      quantity: data?.quantity,
      productName: data?.product?.nameEn,
      sku: data?.product?.sku,
      stock: data?.stock
    });
    setIsModifyOpen(true);
  };

  const handleDiscount = (data) => {
    setSelected({
      id: data?.inventoryId,
      quantity: data?.quantity,
      productName: data?.product?.nameEn,
      sku: data?.product?.sku,
      discount: data?.itemDiscount,
      price: data?.unitPrice
    });
    setIsDiscountOpen(true);
  };

  useEffect(() => {
    if (updateSingleOrderMutationSuccess) {
      setIsModifyOpen(false);
      setSelected(null);
    }
  }, [updateSingleOrderMutationSuccess]);

  useEffect(() => {
    if (updateSingleProductDiscountSuccess) {
      setIsDiscountOpen(false);
      setSelected(null);
    }
  }, [updateSingleProductDiscountSuccess]);

  useEffect(() => {
    updateSingleOrderMutationSuccess && setIsConfirmationOpen(false);
  }, [updateSingleOrderMutationSuccess]);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.product?.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.product?.nameEn}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex justify-center items-center">
            <div>{row?.quantity}</div>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'QUANTITY',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex justify-center items-center">
            <div>{row.subTotal}</div>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SUB-TOTAL',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.totalDiscount}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'TOTAL DISCOUNT',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex justify-center items-center">
            <div>{row.totalPrice}</div>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'FINAL PRICE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <button
            className="text-red-600 hover:underline"
            onClick={() => handleDiscount(row)}
          >
            Apply Discount
          </button>
          <button
            className="text-red-600 hover:underline ml-4"
            onClick={() => handleModifyAmount(row)}
          >
            Modify
          </button>
          <button
            className="ml-4 text-red-600 hover:underline"
            onClick={() => {
              setProductToRemove(row);
              setIsConfirmationOpen(true);
            }}
          >
            Remove
          </button>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];

  const getSkuIds = () => {
    return cartProducts?.map((cart) => ({
      sku: cart?.product?.sku,
      quantity: cart?.quantity
    }));
  };

  const cleanup = () => {
    setQuery('');
  };

  const confirmRemoveProduct = () => {
    updateOrderProduct({
      id: productToRemove.inventoryId,
      quantity: 0
    });
    setProductToRemove(null);
  };

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <div className="mt-4 sm:ml-0 sm:mt-0 sm:flex-none md:flex-none">
        <CustomerSummary
          title={'Customer Summary'}
          fromOrder
          customer={{
            ...currentData?.data?.customer,
            address: currentData?.data?.address,
            deliveryAssociate: currentData?.data?.deliveryAssociate,
            routeName: currentData?.data?.route?.name
          }}
          orderId={orderId}
          removeAssignee={handleRemoveAssociate}
          setOpen={setOpen}
        />
      </div>
      <div className="bg-gray-200">
        <div className="flex justify-between items-center pt-2">
          <div className="text-gray-700 text-lg font-bold px-4">
            Product Summary
          </div>
          <div>
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
              onClick={toggleModal}
            >
              <PlusIcon className="h-5 w-5" aria-hidden="true" />
              Add Product
            </button>
          </div>
        </div>
        <Table data={cartProducts ?? []} columns={columns} />
      </div>
      <InvoiceSummary
        title={'Invoice Summary'}
        invoice={currentData?.data?.invoice}
      />
      <ApplyDiscount
        open={isDiscountOpen}
        setOpen={setIsDiscountOpen}
        id={selected?.id}
        quantity={selected?.quantity}
        updateSingleProductDiscount={updateSingleProductDiscount}
        productName={selected?.productName}
        sku={selected?.sku}
        price={selected?.price}
        discount={selected?.discount}
      />
      <ModifyAmount
        open={isModifyOpen}
        setOpen={setIsModifyOpen}
        id={selected?.id}
        quantity={selected?.quantity}
        updateProduct={updateOrderProduct}
        productName={selected?.productName}
        sku={selected?.sku}
        stock={selected?.stock}
        fromOrder
      />
      <AddProductsModal
        open={isAddProductsOpen}
        setOpen={toggleModal}
        title="Products"
        orderId={orderId}
        skuIds={getSkuIds()}
        fromOrder
      />
      <AssignSingleModal
        open={open}
        setOpen={setOpen}
        setQuery={setQuery}
        query={query}
        dataList={ deliveryAssociatessCurrentData?.data?.users ?? []}
        assignDeliveryPerson={handleAssignDeliveryAssociate}
        cleanup={cleanup}
      />
      <ConfirmationModal
        open={isConfirmationOpen}
        setOpen={setIsConfirmationOpen}
        title="Confirm Removal"
        message="Are you sure you want to remove this product from the order?"
        confirmAction={confirmRemoveProduct}
        cleanup={() => setProductToRemove(null)}
        disabled={isUpdateSingleOrderMutationLoading}
      />
    </div>
  );
}

import { useEffect, useState } from 'react';
import SideBanner from '../../../components/auth/sign-in/SideBanner';
import OTPInput from 'react-otp-input';
import InputTopBar from '../../../components/common/InputTopBar';
import {
  useResendOtpMutation,
  useVerifyOtpMutation
} from '../../../store/features/auth/authApi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectOTPSessionEmail,
  selectOTPSessionId
} from '../../../store/features/auth/otpSlice';

export default function VerifyWithOtpRoot() {
  const [otp, setOTP] = useState('');
  const navigate = useNavigate();
  const email = useSelector(selectOTPSessionEmail);
  const sessionId = useSelector(selectOTPSessionId);

  const [
    callSuperAdminVerifyOtpMutation,
    { data: superAdminVerifyOtpMutationData }
  ] = useVerifyOtpMutation();

  const [resendOtp] = useResendOtpMutation();

  const onResendOtp = () => {
    resendOtp({ email, sessionId });
  };

  useEffect(() => {
    if (otp.length === 4) {
      callSuperAdminVerifyOtpMutation({
        // email: email,
        sessionId: sessionId,
        otp: otp
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  useEffect(() => {
    if (superAdminVerifyOtpMutationData) {
      navigate('/', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superAdminVerifyOtpMutationData]);
  return (
    <>
      <div className="flex min-h-screen flex-1 bg-white">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <InputTopBar
              title={'OTP Verification'}
              subtitle={
                'Enter your 4-digit code we sent to your email and phone'
              }
              withBrandLogo={true}
            />

            <OTPInput
              value={otp}
              onChange={setOTP}
              numInputs={4}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: '3rem',
                height: '3rem',
                margin: '20px 0.5rem',
                fontSize: '1rem',
                borderRadius: 6,
                border: '1px solid rgba(0,0,0,0.15)'
              }}
              inputType="number"
            />
            <div className="flex flex-col gap-2">
              <p className="text-semibold leading-6 text-gray-500">
                Did not receive the code?
              </p>

              <p
                onClick={onResendOtp}
                className="font-semibold text-red-600 hover:text-red-500 cursor-pointer"
              >
                Resend Code
              </p>
            </div>
          </div>
        </div>
        <SideBanner bannerSource="/login-banner.jpg" />
      </div>
    </>
  );
}

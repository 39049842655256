import toast from 'react-hot-toast';
import { chamakApi } from '../../app/api';
export const cartApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getCart: builder.query({
      query: ({ customerId }) => ({
        url: `/api/cart/${customerId}`,
        method: 'GET'
      }),
      providesTags: ['getCart']
    }),
    updateCart: builder.mutation({
      query: ({ customerId, ...body }) => ({
        url: `/api/cart/${customerId}`,
        method: 'PATCH',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Cart updated successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getCart']
    })
  })
});
export const { useGetCartQuery, useUpdateCartMutation } = cartApi;

import {
  CheckIcon,
  PlusIcon,
  RectangleStackIcon
} from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ApplyFilter from '../../../components/common/ApplyFilter';
import Table from '../../../components/common/DataTable/Table';
import TablePagination from '../../../components/common/TablePagination';
import ConfirmationModal from '../../../components/common/DataTable/ConfirmationModal';
import {
  useDeleteSingleOfferProductMutation,
  useGetOfferProductQuery
} from '../../../store/features/offer-product/offerProductApi';
import { getQueryParams, mapFilterToUrlParam } from '../../../lib/utils';

const rootMeta = {
  page_name: 'Offer Products',
  breadcrumbs: null
};

const searchModes = [
  {
    param_name: 'Name',
    param: 'nameEn',
    type: 'text'
  },
  {
    param_name: 'SKU',
    param: 'sku',
    type: 'text'
  },
  {
    param_name: 'Brand',
    param: 'brand',
    type: 'text'
  },
  {
    param_name: 'Supplier',
    param: 'supplier',
    type: 'text'
  },
  {
    param_name: 'Is Published',
    param: 'isPublished',
    type: 'text'
  },
  {
    param_name: 'Category Ids',
    param: 'categoryIds',
    type: 'text'
  },
  {
    param_name: 'Is Returnable',
    param: 'isReturnable',
    type: 'text'
  }
];

export default function ProductsOfferRoot() {
  const location = useLocation();
  const queryParamsString = location.search;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [productToRemove, setProductToRemove] = useState(null);

  const { currentData, isLoading } = useGetOfferProductQuery(
    !isEmpty(selectedData)
      ? {
          page,
          ...selectedData
        }
      : {
          page
        }
  );

  const [
    callDeleteSingleOfferProductMutation,
    {
      isSuccess: isDeleteSingleOfferProductSuccess,
      isLoading: isDeleteSingleOfferProductLoading
    }
  ] = useDeleteSingleOfferProductMutation();

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex gap-x-2">
            {row?.product?.photo1 ? (
              <img src={row?.product?.photo1} alt="" width="30" height="30" />
            ) : (
              <RectangleStackIcon className="w-10 h-10" color="gray" />
            )}
            {row?.product?.photo2 && (
              <img src={row?.product?.photo2} alt="" width="30" height="30" />
            )}
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'PHOTO',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.product?.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU NO.',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.product?.nameEn}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row?.unitPrice}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'UNIT PRICE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row?.memberUnitPrice}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'MEMBER PRICE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          {row?.isFreeDelivery ? (
            <CheckIcon className="w-6 h-6" color="green" />
          ) : (
            <span>N/A</span>
          )}
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'FREE DELIVERY',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row?.stock}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'STOCK',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <button
            className="text-red-600 hover:underline"
            onClick={() => {
              setProductToRemove(row.id);
              setIsConfirmationOpen(true);
            }}
          >
            Remove
          </button>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];

  const confirmRemoveProduct = (id) => {
    callDeleteSingleOfferProductMutation({ inventoryId: id });
    setProductToRemove(null);
  };
  const handleSelectedData = (data) => {
    navigate(`/app/offer-products/view${mapFilterToUrlParam(data)}`);
    setSelectedData(data);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedData]);

  useEffect(() => {
    setSelectedData(getQueryParams(queryParamsString));
  }, [queryParamsString]);

  useEffect(() => {
    isDeleteSingleOfferProductSuccess && setIsConfirmationOpen(false);
  }, [isDeleteSingleOfferProductSuccess]);

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <ApplyFilter
              data={searchModes}
              selectedData={selectedData}
              handleSelectedData={handleSelectedData}
            />
            <Link
              to="/app/offer-products/add"
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Add
            </Link>
          </div>
        </div>
      </div>
      <Table data={currentData?.data ?? []} columns={columns} />
      {/* <TablePagination
        paginationData={currentData?.data?.meta}
        page={page}
        setPage={setPage}
      /> */}
      <ConfirmationModal
        id={productToRemove}
        open={isConfirmationOpen}
        setOpen={setIsConfirmationOpen}
        title="Confirm Removal"
        message="Are you sure you want to remove this offer product?"
        confirmAction={confirmRemoveProduct}
        cleanup={() => setProductToRemove(null)}
        disabled={isDeleteSingleOfferProductLoading}
      />
    </div>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '../../common/Form';
import {
  useGetWarehouseQuery
} from '../../../store/features/warehouse/warehouseApi';
import DropdownSelect from '../../common/DropdownSelect';
import { useCreateSalesAssociateMutation } from '../../../store/features/sales-associate/salesAssociateApi';
import { z } from 'zod';
import MultipleRoutePickup from '../../common/MultipleRoutePickup';

export const salesAssociateSchema = z.object({
  name: z.string().min(1, 'Name is required').max(50, 'Too Long'),
  phone: z.string().regex(/^\+?(88)?0?1[3456789][0-9]{8}\b/, 'Invalid phone'),
});

export default function CreateSalesAssociateForm() {

  const [page, setPage] = useState(1);
  const [selectedWarehouse, setSelectedWarehouse] = useState({
    id: '',
    name: 'Select Warehouse'
  });

  const [selectedRoutes,setSelectedRoutes] = useState([]);
  
  const [warehouseName, setWarehouseName] = useState([]);

  const methods = useForm({
    resolver: zodResolver(salesAssociateSchema),
    mode: 'onChange'
  });

  const [
    callCreateSalesAssociateMutation,
    {
      isSuccess: isCreateSalesAssociateMutationSuccess,
      isLoading: createSalesAssociateMutationLoading
    }
  ] = useCreateSalesAssociateMutation();

    const { data: warehouseData, isLoading: getWarehouseQueryLoading } =
    useGetWarehouseQuery({});

  useEffect(() => {
    if (warehouseData?.data?.warehouses) {
      setWarehouseName(
        warehouseData.data.warehouses.map((warehouse) => ({
          id: warehouse.id,
          name: warehouse.name
        }))
      );
    }
  }, [warehouseData]);

  const navigate = useNavigate();

  const handleRoutePickUpIds = (selectedRoutes) => {
    setSelectedRoutes(selectedRoutes);
  };

  const onSubmit = (data) => {

    const payload = {
      ...data,
      warehouseId: selectedWarehouse?.id,
      routeIds: selectedRoutes.map((route) => route.id)
    };

    callCreateSalesAssociateMutation(payload);
  };

  useEffect(() => {
    if (isCreateSalesAssociateMutationSuccess) {
      navigate('/app/manage-sales-associate/view');
    }
  }, [isCreateSalesAssociateMutationSuccess]);

  return (
    <Form {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
      >
        <div className="px-4 py-6 sm:p-8">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <FormField
                name="name"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="user-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Name*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="user-name"
                          placeholder={'Enter name'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="phone"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone Number*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="tel"
                          {...field}
                          id="phone"
                          placeholder={'Enter Phone Number'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="warehouse"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Select Warehouse*
              </label>
              <div className="mt-2">
                <DropdownSelect
                  data={warehouseName}
                  isLoading={getWarehouseQueryLoading}
                  selected={selectedWarehouse}
                  setSelected={setSelectedWarehouse}
                />
              </div>
              
            </div>

            <div className="col-span-6 mt-10">
              <MultipleRoutePickup handleRoutePickUpIds={handleRoutePickUpIds} />
            </div>

            </div>

        </div>

        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button
            type="button"
            onClick={() => navigate('/app/manage-sales-associate/view')}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={
              createSalesAssociateMutationLoading || !methods.formState.isValid
            }
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
          >
            Add Sales Associate
          </button>
        </div>
      </form>
    </Form>
  );
}



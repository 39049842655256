import { createSlice } from "@reduxjs/toolkit";

const otpSlice = createSlice({
	name: "otp",
	initialState: {
		email: null,
		sessionId: null,
	},
	reducers: {
		setOTPSession: (state, action) => {
			const { sessionId, email } = action.payload;
			state.email = email;
			state.sessionId = sessionId;
		},
		clearOtpSession: (state) => {
			state.email = null;
			state.sessionId = null;
		},
	},
});

export const { setOTPSession, clearOtpSession } = otpSlice.actions;

export default otpSlice.reducer;

export const selectOTPSessionEmail = (state) => state.otp.email;
export const selectOTPSessionId = (state) => state.otp.sessionId;

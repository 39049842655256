import React from "react";
import InputTopBar from "../../../components/common/InputTopBar";
import SideBanner from "../../../components/auth/sign-in/SideBanner";
import SetPasswordForm from "../../../components/auth/set-password/SetPasswordForm";

export default function SetPasswordRoot() {
	return (
		<>
			<div className="flex min-h-screen flex-1 bg-white">
				<div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
					<div className="mx-auto w-full max-w-sm lg:w-96">
						<InputTopBar
							title={"Create password"}
							subtitle={"Set your password to access your account"}
							withBrandLogo={true}
						/>

						<SetPasswordForm />
					</div>
				</div>
				<SideBanner bannerSource="/login-banner.jpg" />
			</div>
		</>
	);
}

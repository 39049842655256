import toast from 'react-hot-toast';
import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';
export const inventoryApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getInventory: builder.query({
      query: ({ ...query }) => ({
        url: `/api/inventory?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getInventory']
    }),
    createInventory: builder.mutation({
      query: (body) => ({
        url: '/api/inventory/multiple',
        method: 'POST',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Inventory created successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getSingleInventory', 'getInventory']
    }),
    getSingleInventory: builder.query({
      query: ({ inventoryId }) => ({
        url: `/api/inventory/${inventoryId}`,
        method: 'GET'
      }),
      providesTags: ['getSingleInventory']
    }),
    updateSingleInventory: builder.mutation({
      query: ({ inventoryId, ...body }) => ({
        url: `/api/inventory/${inventoryId}`,
        method: 'PATCH',
        body: body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Inventory updated successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getSingleInventory', 'getInventory']
    }),
    deleteSingleInventory: builder.mutation({
      query: (inventoryId) => ({
        url: `/api/inventory/${inventoryId}`,
        method: 'DELETE'
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Inventory updated successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getInventory']
    }),
    getInventoryStockRequest: builder.query({
      query: () => ({
        url: `/api/inventory/stock-requests`,
        method: 'GET'
      }),
      providesTags: ['getInventoryStockRequest', 'getInventory']
    }),
    createInventoryStockRequest: builder.mutation({
      query: (body) => ({
        url: '/api/inventory/bulk-stock-requests',
        method: 'POST',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success(
            'Stocks are in pending state. Waiting for admin approval',
            {
              duration: 2000
            }
          );
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: []
    }),
    updateInventorySingleStockRequest: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/api/inventory/stock-requests/${id}`,
        method: 'PATCH',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Stock request updated successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getInventoryStockRequest', 'getInventory']
    }),
    addInventorySingleStockRequest: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/api/inventory/${id}/stock`,
        method: 'PATCH',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Stock request updated successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getInventoryStockRequest', 'getInventory']
    })
  })
});
export const {
  useGetInventoryQuery,
  useCreateInventoryMutation,
  useGetSingleInventoryQuery,
  useUpdateSingleInventoryMutation,
  useDeleteSingleInventoryMutation,
  useGetInventoryStockRequestQuery,
  useCreateInventoryStockRequestMutation,
  useUpdateInventorySingleStockRequestMutation,
  useAddInventorySingleStockRequestMutation
} = inventoryApi;

import { ArrowLeftIcon, PlusIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import { useCreateOfferProductMutation } from '../../../store/features/offer-product/offerProductApi';
import CustomizeLinkProductsOffer from '../../../components/app/offer-products/CustomizeLinkProductsOffer';
import WarehouseProducts from '../../../components/portal/common/WarehouseProducts';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Add Offer Product'
};

const pages = [
  {
    name: 'Offer Products',
    location: '/app/offer-products',
    current: false
  },
  { name: 'Add', location: '/app/offer-products/add', current: true }
];

export default function AddProductOffer() {
  const navigate = useNavigate();
  const [draftProducts, setDraftProducts] = useState([]);
  const [removedDraftProductId, setRemovedDraftProductId] = useState();

  const [
    callCreateOfferProductMutation,
    {
      isSuccess: isCreateOfferProductMutationSuccess,
      isLoading: createOfferProductMutationLoading
    }
  ] = useCreateOfferProductMutation();

  const addToWareHouseDraft = (row) => {
    setDraftProducts((data) => [
      ...data,
      {
        photo1: row?.product?.photo1,
        photo2: row?.product?.photo2,
        nameEn: row?.product?.nameEn,
        sku: row?.product?.sku,
        id: row.id
      }
    ]);
  };
  const handleDraftProducts = (id, field, value) => {
    setDraftProducts((data) =>
      data.map((item) => {
        if (item.productId === id) {
          return {
            ...item,
            [field]: value
          };
        }
        return item;
      })
    );
  };

  const removeProduct = (row) => {
    setDraftProducts((data) => data.filter((rowItem) => rowItem.id !== row.id));
    setRemovedDraftProductId(row.id);
  };

  const addProductsToWarehouse = () => {
    const payload = {
      inventoryIds: draftProducts?.map((item) => item.id)
    };
    callCreateOfferProductMutation(payload);
  };

  useEffect(() => {
    isCreateOfferProductMutationSuccess && navigate('/app/offer-products/view');
  }, [isCreateOfferProductMutationSuccess]);

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <WarehouseProducts
        addToStockDraft={addToWareHouseDraft}
        draftProducts={draftProducts}
        removedProductId={removedDraftProductId}
      />
      <CustomizeLinkProductsOffer
        draftProductsOffer={draftProducts}
        removeProduct={removeProduct}
        handleDraftProducts={handleDraftProducts}
      />
      {draftProducts?.length > 0 && (
        <div className="mt-10 flex justify-end">
          <button
            className="flex items-center rounded-md bg-red-600 px-3 py-2 text-white shadow-sm hover:bg-red-500 disabled:bg-gray-500"
            onClick={addProductsToWarehouse}
            disabled={createOfferProductMutationLoading}
          >
            <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Add Product(s) to Offer Products
          </button>
        </div>
      )}
    </div>
  );
}

import React from 'react';
import { LoaderIcon } from 'react-hot-toast';
import { Link } from 'react-router-dom';

export default function BrandDetail({ data }) {
  return (
    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div className="px-4 py-6 sm:p-8">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="location-name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Brand Name
            </label>
            <div className="mt-2">
              <span>{data?.name ?? <LoaderIcon />}</span>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <label
            htmlFor="routes"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Routes
          </label>

          <div className="mt-2 flex gap-2">
            {data?.routes?.map((route)=> (<p className='border-2 p-1 rounded-md'>{route.name}</p>))}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
        <Link
          to="/app/manage-brands/view"
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Back
        </Link>
      </div>
    </div>
  );
}

import React from 'react';
import useQuery from '../../../hooks/useQuery';
import { useGetOneCustomerQuery } from '../../../store/features/customer/customerApi';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import ViewInventory from '../../../components/app/products/ViewInventory';

const pageMeta = {
  page_name: 'Details'
};

export default function ProductDetails() {
  const query = useQuery();

  // const { currentData } = useGetOneCustomerQuery({
  //   customerId: query.get('inventoryId')
  // });

  const pages = [
    {
      name: 'Products',
      location: '/app/products',
      current: false
    },
    { name: 'View', 
      location: `/app/products/details?${query.get('inventoryId')}`,
      current: true }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <ViewInventory />
    </div>
  );
}

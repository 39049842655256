import React from 'react';
import useQuery from '../../../hooks/useQuery';
import { useGetOneDeliveryAssociateQuery } from '../../../store/features/delivery-associate/deliveryAssociateApi';
import DetailsDeliveryAssociate from '../../../components/app/delivery-associate/DetailsDeliveryAssociate';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Details'
};

export default function DeliveryAssociateDetails() {
  const query = useQuery();

  const { currentData } = useGetOneDeliveryAssociateQuery({
    deliveryAssociateId: query.get('deliveryAssociateId')
  });

  const pages = [
    {
      name: 'Delivery Associate',
      location: '/app/delivery-associate',
      current: false
    },
    {
      name: 'Details',
      location: `/app/delivery-associate/details?=${query.get(
        'deliveryAssociateId'
      )}`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <DetailsDeliveryAssociate currentData={currentData} />
    </div>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import useQuery from '../../../hooks/useQuery';
import DropdownSelect from '../../common/DropdownSelect';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '../../common/Form';

import {
  useGetSingleInventoryQuery,
  useUpdateSingleInventoryMutation
} from '../../../store/features/inventory/inventoryApi';

const discountTypeData = [
  {
    id: 1,
    name: 'PERCENTAGE'
  },
  {
    id: 2,
    name: 'FIXED_AMOUNT'
  }
];

const deliveryTypeData = [
  {
    id: 1,
    name: 'Enabled'
  },
  {
    id: 2,
    name: 'Free'
  }
];

export const productSchema = z.object({
  nameEn: z.string().optional(),
  sku: z.string().optional(),
  discountValue: z.string().optional(),
  commissionValue: z.string().optional(),
  unitPrice: z.string().optional(),
  memberUnitPrice: z.string().optional()
});

export default function EditProductForm() {
  const inventoryId = useQuery().get('inventoryId');
  const { currentData } = useGetSingleInventoryQuery({
    inventoryId
  });

  const [selected, setSelected] = useState(
    discountTypeData[0] ?? { id: '', name: '' }
  );

  const [selectedCommission, setSelectedCommission] = useState(
    discountTypeData[0] ?? { id: '', name: '' }
  );

  const [deliveryType, setDeliveryType] = useState(
    currentData?.data?.isFreeDelivery
      ? deliveryTypeData[1]
      : deliveryTypeData[0]
  );

  const [page, setPage] = useState(1);

  const methods = useForm({
    resolver: zodResolver(productSchema),
    mode: 'onChange',
    values: {
      ...currentData?.data,
      nameEn: currentData?.data?.product?.nameEn,
      sku: currentData?.data?.product?.sku,
      unitPrice: currentData?.data?.unitPrice
        ? String(currentData?.data?.unitPrice)
        : '0',
      discountValue: currentData?.data?.discountValue
        ? String(currentData?.data?.discountValue)
        : '0',
      commissionValue: currentData?.data?.commissionValue
        ? String(currentData?.data?.commissionValue)
        : '0',
      memberUnitPrice: currentData?.data?.memberUnitPrice
        ? String(currentData?.data?.memberUnitPrice)
        : '0'
    }
  });

  const [
    callEditSingleInventoryMutation,
    {
      isSuccess: isUpdateInventoryMutationSuccess,
      isLoading: updateInventoryMutationLoading
    }
  ] = useUpdateSingleInventoryMutation();

  useEffect(() => {
    setSelected({
      id:
        discountTypeData.find(
          (status) => status?.name === currentData?.data?.discountType
        )?.id ?? '',
      name: currentData?.data?.discountType ?? ''
    });
  }, [currentData]);

  useEffect(() => {
    setSelectedCommission({
      id:
        discountTypeData.find(
          (status) => status?.name === currentData?.data?.commissionType
        )?.id ?? '',
      name: currentData?.data?.commissionType ?? ''
    });
  }, [currentData]);

  useEffect(() => {
    setDeliveryType(
      currentData?.data?.isFreeDelivery
        ? deliveryTypeData[1]
        : deliveryTypeData[0]
    );
  }, [currentData]);

  const navigate = useNavigate();

  const onSubmit = (data) => {
    const {
      nameEn,
      sku,
      unitPrice,
      memberUnitPrice,
      discountValue,
      commissionValue,
      ...body
    } = data;

    callEditSingleInventoryMutation({
      ...body,
      unitPrice: parseInt(data.unitPrice),
      memberUnitPrice: parseInt(data.memberUnitPrice),
      discountValue: parseFloat(data.discountValue),
      commissionValue: parseFloat(data.commissionValue),
      discountType: selected?.name,
      commissionType: selectedCommission?.name,
      isFreeDelivery: deliveryType?.id === 2,
      inventoryId
    });
  };

  useEffect(() => {
    if (isUpdateInventoryMutationSuccess) {
      navigate('/app/products/view');
    }
  }, [isUpdateInventoryMutationSuccess]);

  return (
    <Form {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
      >
        <div className="px-4 py-6 sm:p-8">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <FormField
                name="nameEn"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="nameEn"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Product Name*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="nameEn"
                          disabled
                          readOnly
                          placeholder={'Enter Product name'}
                          className={clsx(
                            'disabled:bg-gray-200 disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="sku"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="sku"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      SKU NO*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="sku"
                          disabled
                          readOnly
                          placeholder={'Enter SKU Number'}
                          className={clsx(
                            'disabled:bg-gray-200 disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="discountType"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Discount Type*
              </label>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <DropdownSelect
                    data={discountTypeData}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="discountValue"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="discountValue"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Discount Value*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="number"
                          {...field}
                          id="discountValue"
                          placeholder={'Enter Discount Value'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="commissionType"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Commission Type*
              </label>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <DropdownSelect
                    data={discountTypeData}
                    selected={selectedCommission}
                    setSelected={setSelectedCommission}
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="commissionValue"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="commissionValue"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Commission Value*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="number"
                          {...field}
                          id="commissionValue"
                          placeholder={'Enter Commission Value'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="unitPrice"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="unitPrice"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Unit Price*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="number"
                          {...field}
                          id="unitPrice"
                          placeholder={'Enter Unit Price'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="memberUnitPrice"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="memberUnitPrice"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Member Unit Price*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="number"
                          {...field}
                          id="memberUnitPrice"
                          placeholder={'Enter Member Unit Price'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="deliveryType"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Delivery Charge*
              </label>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <DropdownSelect
                    data={deliveryTypeData}
                    selected={deliveryType}
                    setSelected={setDeliveryType}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button
            type="button"
            onClick={() => navigate('/app/products/view')}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={updateInventoryMutationLoading}
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
          >
            Save
          </button>
        </div>
      </form>
    </Form>
  );
}

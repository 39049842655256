import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import districts from '../../../data/districts';
import thana from '../../../data/thana';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '../../common/Form';
import { useGetWarehouseQuery } from '../../../store/features/warehouse/warehouseApi';
import DropdownSelect from '../../common/DropdownSelect';
import { z } from 'zod';
import { useCreateCustomerMutation } from '../../../store/features/customer/customerApi';
import RoutesPickup from '../../common/RoutesPickup';

export const customerSchema = z.object({
  name: z.string().min(1, 'Name is required').max(50, 'Too Long'),
  phone: z.string().regex(/^\+?(88)?0?1[3456789][0-9]{8}\b/, 'Invalid phone'),
  address: z.object({
    full: z
      .string()
      .min(1, 'Full Address is required')
      .max(100, 'Too Long'),
    village: z
      .string()
      .min(1, 'Village name is required')
      .max(50, 'Too Long')
  }),
  shopName: z
    .string()
    .min(1, 'Shop Name is required')
    .max(50, 'Too Long'),
  chamakId: z
    .string()
    .max(10, 'Too Long')
    .optional()
});

export default function CreateCustomerForm() {
  const [selectedWarehouse, setSelectedWarehouse] = useState({
    id: '',
    name: 'Select Warehouse'
  });

  const [selectedDistrict, setSelectedDistrict] = useState({
    id: '',
    name: 'Select District'
  });

  const [selectedThana, setSelectedThana] = useState({
    id: '',
    name: 'Select Thana'
  });

  const [filteredThana, setFilteredThana] = useState([
    { id: '', name: 'Select district first' }
  ]);

  const [selectedRoutes, setSelectedRoutes] = useState([]);

  const [warehouseName, setWarehouseName] = useState([]);

  const methods = useForm({
    resolver: zodResolver(customerSchema),
    mode: 'onChange'
  });

  const [
    callCreateCustomerMutation,
    {
      isSuccess: isCreateCustomerMutationSuccess,
      isLoading: createCustomerMutationLoading
    }
  ] = useCreateCustomerMutation();

  const { data: warehouseData, isLoading: getWarehouseQueryLoading } =
    useGetWarehouseQuery({});

  useEffect(() => {
    if (warehouseData?.data?.warehouses) {
      setWarehouseName(
        warehouseData.data.warehouses.map((warehouse) => ({
          id: warehouse.id,
          name: warehouse.name
        }))
      );
    }
  }, [warehouseData]);

  useEffect(() => {
    setFilteredThana(() =>
      thana.filter((eachThana) => eachThana.district_id === selectedDistrict.id)
    );
    setSelectedThana({
      id: '',
      name: 'Select Thana'
    });
  }, [selectedDistrict]);

  const navigate = useNavigate();

  const handleRoutePickUpIds = (selectedRoutes) => {
    setSelectedRoutes(selectedRoutes);
  };

  const onSubmit = (data) => {
    const payload = {
      ...data,
      address: {
        ...data?.address,
        thana: selectedThana?.name,
        district: selectedDistrict?.name
      },
      warehouseId: selectedWarehouse?.id,
      routeId: selectedRoutes?.id
    };

    callCreateCustomerMutation(payload);
  };

  useEffect(() => {
    if (isCreateCustomerMutationSuccess) {
      navigate('/app/customers/view');
    }
  }, [isCreateCustomerMutationSuccess]);

  return (
    <Form {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
      >
        <div className="px-4 py-6 sm:p-8">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <FormField
                name="name"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="user-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Name*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="user-name"
                          placeholder={'Enter name'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="phone"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone Number*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="tel"
                          {...field}
                          id="phone"
                          placeholder={'Enter Phone Number'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="warehouse"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Select Warehouse*
              </label>
              <div className="mt-2">
                <DropdownSelect
                  data={warehouseName}
                  isLoading={getWarehouseQueryLoading}
                  selected={selectedWarehouse}
                  setSelected={setSelectedWarehouse}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="address.village"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="village"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Village*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="village"
                          placeholder={'Enter Village Name'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Select District*
              </label>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <DropdownSelect
                    data={districts.sort((a, b) => (a.name > b.name ? 1 : -1))}
                    selected={selectedDistrict}
                    setSelected={setSelectedDistrict}
                  />
                </div>
              </div>
              <div className="text-red-600"></div>
            </div>

            <div className="sm:col-span-3">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Select the police station nearest*
              </label>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <DropdownSelect
                    data={filteredThana.sort((a, b) =>
                      a.name > b.name ? 1 : -1
                    )}
                    selected={selectedThana}
                    setSelected={setSelectedThana}
                  />
                </div>
              </div>
              <div className="text-red-600"></div>
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="address.full"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="addressFull"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Full Address*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="full"
                          placeholder={'Enter Full Address'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="shopName"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="shopName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Shop Name*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="shopName"
                          placeholder={'Enter Shop Name'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="chamakId"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="chamakId"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Chamak Id
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="chamakId"
                          placeholder={'Enter Chamak Id'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="col-span-6 mt-10">
              {/* <MultipleRoutePickup
                handleRoutePickUpIds={handleRoutePickUpIds}
              /> */}
              <RoutesPickup
                selected={selectedRoutes}
                setSelected={handleRoutePickUpIds}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button
            type="button"
            onClick={() => navigate('/app/customers/view')}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={
              createCustomerMutationLoading || !methods.formState.isValid
            }
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
          >
            Add Customer
          </button>
        </div>
      </form>
    </Form>
  );
}

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import useQuery from '../../../hooks/useQuery';
import { useGetOneCustomerQuery } from '../../../store/features/customer/customerApi';
import ViewCustomers from '../../../components/app/customers/ViewCustomers';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Details'
};

export default function CustomerDetails() {
  const query = useQuery();

  const { currentData } = useGetOneCustomerQuery({
    customerId: query.get('customerId')
  });

  const pages = [
    {
      name: 'Customers',
      location: '/app/customers',
      current: false
    },
    {
      name: 'View',
      location: `/app/customers/details`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <ViewCustomers currentData={currentData} />
    </div>
  );
}

import toast from 'react-hot-toast';
import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';
export const warehouseApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getWarehouse: builder.query({
      query: ({ ...query }) => ({
        url: `/api/warehouses?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getWarehouse']
    }),
    createWarehouse: builder.mutation({
      query: (body) => ({
        url: '/api/warehouses',
        method: 'POST',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Warehouse created successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getOneWarehouse',
        'getWarehouse',
        'getUnAssignedManagers'
      ]
    }),
    getOneWarehouse: builder.query({
      query: ({ warehouseId }) => ({
        url: `/api/warehouses/${warehouseId}`,
        method: 'GET'
      }),
      providesTags: ['getOneWarehouse']
    }),
    updateWarehouse: builder.mutation({
      query: ({ warehouseId, ...body }) => ({
        url: `/api/warehouses/${warehouseId}`,
        method: 'PUT',
        body: body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Warehouse updated successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getOneWarehouse',
        'getWarehouse',
        'getUnAssignedManagers'
      ]
    }),
    warehouseChangeOpsStatus: builder.mutation({
      query: ({ warehouseId, opsStatus }) => ({
        url: `/api/warehouses/${warehouseId}`,
        method: 'PUT',
        body: { opsStatus }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Warehouse status changed successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getOneWarehouse', 'getWarehouse']
    }),
    unassignedWarehouse: builder.query({
      query: ({ query }) => ({
        url: `/api/warehouses?page=1${query && '&name=' + query}`,
        method: 'GET'
      }),
      providesTags: ['getWarehouse']
    })
  })
});
export const {
  useGetWarehouseQuery,
  useCreateWarehouseMutation,
  useGetOneWarehouseQuery,
  useUpdateWarehouseMutation,
  useWarehouseChangeOpsStatusMutation,
  useUnassignedWarehouseQuery
} = warehouseApi;

import React from 'react';

export default function AccountDeletionGuide() {
  return (
    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div className="px-4 py-6 sm:p-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          Account Deletion Request Guide
        </h1>
        <p className="mt-2 text-sm text-gray-600">
          Welcome to the Account Deletion Request page. If you wish to delete
          your account, please read the following instructions carefully.
          Requesting to delete your account will notify Chamak’s admin team to
          take action and block your access to the app. After the admin approves
          your account deletion, all your account-related data will be removed
          except for your order history.
        </p>

        <h2 className="mt-6 text-xl font-semibold text-gray-900">
          Steps to Request Account Deletion:
        </h2>
        <ul className="mt-2 list-disc pl-5 text-sm text-gray-600">
          <li>
            <strong>Go to Profile Screen:</strong> Open the app and navigate to
            your profile screen.
          </li>
          <li>
            <strong>Press the “Request Delete Account” Button:</strong> Scroll
            down to find the “Request Delete Account” button and press it.
          </li>
          <li>
            <strong>Confirm Deletion Request Consequences:</strong> A pop-up
            will appear detailing the consequences of account deletion. Please
            read it carefully.
          </li>
          <li>
            <strong>Confirm the Request:</strong> After understanding the
            consequences, confirm your request. You will be logged out
            immediately, and your account will be under review for deletion.
          </li>
        </ul>

        <div className="mt-6 bg-yellow-50 border-l-4 border-yellow-400 p-4">
          <h2 className="text-lg font-semibold text-gray-900">
            Important Information:
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            <strong>Review Process:</strong> Once you submit your request, our
            admin team will review it. During this period, your access to the
            app will be blocked.
          </p>
          <p className="mt-2 text-sm text-gray-600">
            <strong>Data Removal:</strong> Upon approval of your account
            deletion request, all your account-related data will be permanently
            deleted, except for your order history which will be retained for
            record-keeping purposes.
          </p>
        </div>

        <p className="mt-6 text-sm text-gray-600">
          We hope this guide helps you through the account deletion process. If
          you have any questions or face any issues, please contact our support
          team for assistance.
        </p>
        <p className="mt-2 text-sm text-gray-600">
          Thank you for using Chamak.
        </p>
      </div>
    </div>
  );
}

import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormMessage } from './Form';
import { forOwn } from 'lodash';

export default function ApplyFilter({
  data,
  selectedData,
  setSelectedData,
  handleSelectedData
}) {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: selectedData
  });
  let [isOpen, setIsOpen] = useState(false);

  function closeModal(isNoReset) {
    !isNoReset && methods.reset();
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const onSubmit = (data) => {
    closeModal(true);
    let formattedData = {};
    forOwn(data, (value, key) => {
      value &&
        (formattedData = {
          ...formattedData,
          [key]: value
        });
    });
    // setSelectedData(formattedData);
    handleSelectedData(formattedData);
  };

  const clearFilter = () => {
    forOwn(selectedData, (value, key) => {
      methods.resetField(key, { defaultValue: '' });
    });
    closeModal(true);
    // setSelectedData({});
    handleSelectedData({});
  };

  return (
    <>
      <Form {...methods}>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={openModal}
            className="mr-4 inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
          >
            Apply Filter
          </button>
        </div>

        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="mt-10 fixed inset-0 overflow-y-scroll">
              <div className="flex min-h-full justify-end p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 w-1/4"
                  >
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl p-6 text-left align-middle transition-all">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900 mb-10"
                      >
                        Apply Filter
                      </Dialog.Title>
                      {React.Children.toArray(
                        data?.map((item) => {
                          return (
                            <FormField
                              name={item.param}
                              control={methods.control}
                              render={({ field }) => (
                                <FormItem>
                                  <label
                                    htmlFor={item.param_name}
                                    className={
                                      'block text-sm font-medium leading-6 text-gray-900 my-2'
                                    }
                                  >
                                    {item.param_name}
                                  </label>
                                  <FormControl>
                                    <div className="mt-2">
                                      {item?.type === 'select' && (
                                        <select
                                          {...field}
                                          id={item.param}
                                          placeholder={item.param_name}
                                          className={clsx(
                                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                                          )}
                                        >
                                          {item?.options?.map((option) => (
                                            <option value={option.id}>
                                              {option.name}
                                            </option>
                                          ))}
                                        </select>
                                      )}
                                      {item?.type === 'radio' && (
                                        <div className="flex">
                                          <div>
                                            <input
                                              {...field}
                                              type={item?.type}
                                              id={item.param}
                                              value={true}
                                              defaultChecked={
                                                selectedData?.[item.param] ===
                                                'true'
                                              }
                                              placeholder={item.param_name}
                                            />
                                            <span className="ml-2 mr-4">
                                              YES
                                            </span>
                                          </div>
                                          <div>
                                            <input
                                              {...field}
                                              type={item?.type}
                                              id={item.param}
                                              value={false}
                                              defaultChecked={
                                                selectedData?.[item.param] ===
                                                'false'
                                              }
                                              placeholder={item.param_name}
                                            />
                                            <span className="ml-2">NO</span>
                                          </div>
                                        </div>
                                      )}
                                      {item?.type === 'text' && (
                                        <input
                                          {...field}
                                          type={item?.type}
                                          id={item.param}
                                          placeholder={item.param_name}
                                          className={clsx(
                                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                                          )}
                                        />
                                      )}
                                      {item?.type === 'date' && (
                                        <input
                                          {...field}
                                          type={item?.type}
                                          id={item.param}
                                          placeholder={item.param_name}
                                          className={clsx(
                                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                                          )}
                                        />
                                      )}
                                    </div>
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          );
                        })
                      )}

                      <div className="mt-10 flex justify-center items-center">
                        <button
                          type="submit"
                          className="mr-4 mt-4 w-3/4 items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
                        >
                          Apply Changes
                        </button>
                      </div>
                      <div className="mt-4 flex justify-center">
                        <button
                          type="button"
                          className="text-sm font-semibold leading-6 text-red-600"
                          onClick={clearFilter}
                        >
                          Clear Filters
                        </button>
                      </div>
                      <div className="mt-4 flex justify-center">
                        <button
                          type="button"
                          className="text-sm font-semibold leading-6 text-gray-900"
                          onClick={closeModal}
                        >
                          Close Filters
                        </button>
                      </div>
                    </Dialog.Panel>
                  </form>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </Form>
    </>
  );
}

import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  BuildingStorefrontIcon,
  CurrencyBangladeshiIcon,
  HomeIcon,
  MapPinIcon,
  TruckIcon,
  UserGroupIcon,
  UsersIcon,
  XMarkIcon,
  KeyIcon,
  ChartPieIcon,
  FolderIcon,
  ArchiveBoxIcon,
  GiftIcon
} from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';

import { UserCircleIcon } from '@heroicons/react/20/solid';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Outlet } from 'react-router-dom';
import {
  logOut,
  selectCurrentRole,
  selectCurrentName,
  selectCurrentWarehouseName
} from '../../store/features/auth/authSlice';
import { Link } from 'react-router-dom';

const navigation = [
  {
    name: 'Dashboard',
    to: '/app/dashboard',
    icon: HomeIcon,
    permission: ['SUPER_ADMIN']
  },
  {
    name: 'Dashboard',
    to: '/app/reports-warehouse',
    icon: HomeIcon,
    permission: ['WAREHOUSE_ADMIN', 'WAREHOUSE_ASSOCIATE']
  },
  {
    name: 'Dashboard',
    to: '/app/dashboard-data-user',
    icon: HomeIcon,
    permission: ['DATA_USER']
  },
  {
    name: 'Warehouse',
    to: '/app/warehouse/view',
    icon: BuildingStorefrontIcon,
    permission: ['SUPER_ADMIN']
  },
  {
    name: 'Manage Routes',
    to: '/app/manage-routes',
    icon: MapPinIcon,
    permission: ['SUPER_ADMIN']
  },
  {
    name: 'Manage Brands',
    to: '/app/manage-brands',
    icon: GiftIcon,
    permission: ['SUPER_ADMIN']
  },
  {
    name: 'Product Category',
    to: '/app/category/view',
    icon: KeyIcon,
    permission: ['SUPER_ADMIN']
  },
  {
    name: 'Product Management',
    to: '/app/product-management/view',
    icon: ChartPieIcon,
    permission: ['SUPER_ADMIN']
  },
  {
    name: 'Shipment',
    to: '/app/shipment',
    icon: TruckIcon,
    permission: ['WAREHOUSE_ADMIN']
  },
  {
    name: 'Order History',
    to: '/app/order-history',
    icon: ArchiveBoxIcon,
    permission: ['WAREHOUSE_ADMIN', 'SUPER_ADMIN']
  },
  {
    name: 'Cash Collection',
    to: '/app/cash-collection',
    icon: CurrencyBangladeshiIcon,
    permission: ['WAREHOUSE_ADMIN', 'WAREHOUSE_ASSOCIATE']
  },
  {
    name: 'Products',
    to: '/app/products',
    icon: FolderIcon,
    permission: ['WAREHOUSE_ADMIN']
  },
  {
    name: 'Offer Products',
    to: '/app/offer-products',
    icon: FolderIcon,
    permission: ['WAREHOUSE_ADMIN']
  },
  {
    name: 'Add Stocks',
    to: '/app/add-stocks',
    icon: CurrencyBangladeshiIcon,
    permission: ['WAREHOUSE_ASSOCIATE']
  },
  {
    name: 'Pending Stocks',
    to: '/app/pending-stocks',
    icon: FolderIcon,
    permission: ['WAREHOUSE_ADMIN']
  },
  {
    name: 'Invoice History',
    to: '/app/invoice-history',
    icon: TruckIcon,
    permission: ['WAREHOUSE_ASSOCIATE', 'WAREHOUSE_ADMIN']
  },
  {
    name: 'All Users',
    to: '/app/users/view',
    icon: UsersIcon,
    permission: ['SUPER_ADMIN', 'WAREHOUSE_ADMIN']
  },
  {
    name: 'Sales Associate',
    to: '/app/manage-sales-associate',
    icon: UserGroupIcon,
    permission: ['WAREHOUSE_ADMIN', 'SUPER_ADMIN']
  },
  {
    name: 'Delivery Associate',
    to: '/app/delivery-associate',
    icon: TruckIcon,
    permission: ['WAREHOUSE_ADMIN', 'SUPER_ADMIN']
  },
  {
    name: 'Data User',
    to: '/app/data-user',
    icon: UserGroupIcon,
    permission: ['WAREHOUSE_ADMIN', 'SUPER_ADMIN']
  },
  {
    name: 'Customers',
    to: '/app/customers',
    icon: UserGroupIcon,
    permission: ['SUPER_ADMIN', 'WAREHOUSE_ADMIN']
  },
  {
    name: 'Customers Reg Request',
    to: '/app/customers-reg-requests',
    icon: UserGroupIcon,
    permission: ['SUPER_ADMIN', 'WAREHOUSE_ADMIN', 'WAREHOUSE_ASSOCIATE']
  },
  {
    name: 'Send SMS',
    to: '/app/sms',
    icon: FolderIcon,
    permission: ['SUPER_ADMIN', 'WAREHOUSE_ADMIN']
  },
  {
    name: 'Config',
    to: '/app/config-warehouse',
    icon: KeyIcon,
    permission: ['WAREHOUSE_ADMIN']
  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SideNavbar() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const role = useSelector(selectCurrentRole);
  const name = useSelector(selectCurrentName);
  const warehouseName = useSelector(selectCurrentWarehouseName);
  const dispatch = useDispatch();

  const signOutHandler = () => {
    dispatch(logOut());
  };

  const filteredNavigation = navigation.filter((each) =>
    each.permission.includes(role)
  );

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="/chamak.png"
                        alt="Chamak"
                      />
                    </div>

                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {filteredNavigation.map((item) => (
                              <li key={item.name}>
                                <NavLink
                                  to={item.to}
                                  className={({ isActive }) =>
                                    classNames(
                                      isActive
                                        ? 'bg-gray-200'
                                        : 'text-gray-700 hover:bg-gray-200',
                                      'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                    )
                                  }
                                >
                                  <item.icon
                                    className="h-6 w-6 shrink-0"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <img className="h-8 w-auto pl-5" src="/chamak.png" alt="Chamak" />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {filteredNavigation.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.to}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? 'bg-gray-200'
                                : 'text-gray-700 hover:bg-gray-200',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold transition-all'
                            )
                          }
                        >
                          <item.icon
                            className="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>

            <span className="text-center text-gray-300">
              {/* {`${process.env.REACT_APP_VERSION ?? 'No version specified'}`} */}
              {/* <NavLink
                className={({ isActive }) =>
                  classNames(isActive && 'text-red-500 underline')
                }
                to="/app/change-log"
              >
                v0.95
              </NavLink> */}
            </span>
            <div className="h-16 w-full bg-gray-100 rounded-md hover:bg-gray-200 transition-all">
              <ul className="py-3 px-3">
                <li className="text-sm text-gray-600">
                  <span className="font-bold">{name}</span>
                </li>
                <li className="text-sm  text-gray-400">{role}</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-200 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="flex items-center justify-end gap-x-4 lg:gap-x-6 w-full">
                {/* <span className="text-md border border-gray-500 p-2 text-black-400">{warehouseName}</span> */}
                {warehouseName ? (
                  <span className="text-md border border-gray-500 p-2 text-black-500">
                    {warehouseName}
                  </span>
                ) : (
                  <span className="text-md text-black-500">
                    {warehouseName}
                  </span>
                )}

                {/* <button
									type="button"
									className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
								>
									<BellIcon className="h-6 w-6" aria-hidden="true" />
								</button> */}

                {/* Separator */}
                {/* <div
									className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
									aria-hidden="true"
								/> */}

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5 gap-2">
                    <UserCircleIcon className="h-8 w-8 text-gray-400" />
                    {/* <span className="text-gray-600">Fayaze H. Pantho</span>
                    <ChevronDownIcon
                      className="h-4 w-4 text-gray-600"
                      aria-hidden="true"
                    /> */}
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-36 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        <Link to={`/app/profile`}>
                          <button
                            type="button"
                            // className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
                            className={classNames(
                              'block px-3 py-1 text-sm leading-6 w-full text-left hover:bg-gray-100 hover:text-neutral-800 p-1 rounded-sm text-neutral-700 transition-all'
                            )}
                          >
                            Profile
                          </button>
                        </Link>
                      </Menu.Item>
                      {/* <Menu.Item>
                        <Link to={`/request-account-delete`}>
                          <button
                            type="button"
                            // className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
                            className={classNames(
                              'block px-3 py-1 text-sm leading-6 w-full text-left hover:bg-gray-100 hover:text-neutral-800 p-1 rounded-sm text-neutral-700 transition-all'
                            )}
                          >
                            Account Deletion Guide
                          </button>
                        </Link>
                      </Menu.Item> */}
                      {role !== 'DATA_USER' && (
                        <Menu.Item>
                          <Link to={`/app/activity-log`}>
                            <button
                              type="button"
                              className={classNames(
                                'block px-3 py-1 text-sm leading-6 w-full text-left hover:bg-gray-100 hover:text-neutral-800 p-1 rounded-sm text-neutral-700 transition-all'
                              )}
                            >
                              Activity Log
                            </button>
                          </Link>
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        <Link to={`/app/password-update`}>
                          <button
                            type="button"
                            className={classNames(
                              'block px-3 py-1 text-sm leading-6 w-full text-left hover:bg-gray-100 hover:text-neutral-800 p-1 rounded-sm text-neutral-700 transition-all'
                            )}
                          >
                            Update Password
                          </button>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link to={`/app/mfa-update`}>
                          <button
                            type="button"
                            className={classNames(
                              'block px-3 py-1 text-sm leading-6 w-full text-left hover:bg-gray-100 hover:text-neutral-800 p-1 rounded-sm text-neutral-700 transition-all'
                            )}
                          >
                            Update Mfa
                          </button>
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <button
                          onClick={signOutHandler}
                          className={classNames(
                            'block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left'
                          )}
                        >
                          <div className="hover:bg-gray-100 hover:text-neutral-800 rounded-sm text-neutral-700 transition-all">
                            Sign out
                          </div>
                        </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="py-10 h-[calc(100vh-4rem)]">
            <div className="px-4 sm:px-6 lg:px-8 pb-16">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

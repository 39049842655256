import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BreadCrumbs from './BreadCrumbs';

const TopNavigationBar = ({ pageName, pages }) => {
  const navigate = useNavigate();
  return (
    <div className="sm:flex sm:items-start">
      <div className="sm:flex-auto">
        <h1 className="text-3xl font-semibold leading-6 text-gray-900">
          <button onClick={() => navigate(-1)}>
            <span className="flex items-center gap-4 hover:text-gray-500 transition-all">
              <ArrowLeftIcon className="h-7 w-7" />
              {pageName}
            </span>
          </button>
        </h1>
        <div className="my-6">
          <BreadCrumbs pages={pages} />
        </div>
      </div>
    </div>
  );
};

export default TopNavigationBar;

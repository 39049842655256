import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef } from 'react';
import { LoaderIcon } from 'react-hot-toast';

export default function DebouncedSearchTab({
  modes,
  setMode,
  setValue,
  handleSearch,
  isLoading,
  data
}) {
  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 300);
  }, [handleSearch]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  const inputRef = useRef(null);

  return (
    <div>
      <div className="relative rounded-md shadow-sm mx-4">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          {isLoading || !data ? (
            <LoaderIcon />
          ) : (
            <MagnifyingGlassIcon className="h-4 w-4 text-gray-500" />
          )}
        </div>

        <input
          type="text"
          onChange={debouncedResults}
          className="block w-full rounded-md border-0 py-1.5 pl-8 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
          placeholder="Search"
          ref={inputRef}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          <label htmlFor="Mode" className="sr-only">
            Mode
          </label>
          <select
            id="Mode"
            name="params"
            className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm"
            onChange={(e) => {
              setMode(e.target.value);
              setValue('');
              inputRef.current.value = '';
            }}
          >
            {modes.map((mode, index) => (
              <option value={mode.param} key={index}>
                {mode.param_name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

DebouncedSearchTab.propTypes = {
  modes: PropTypes.array,
  setMode: PropTypes.func,
  setValue: PropTypes.func,
  value: PropTypes.string,
  handleSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  data: PropTypes.any
};

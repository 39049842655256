import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';
import { LoaderIcon } from 'react-hot-toast';
import { useGetWarehouseQuery } from '../../../store/features/warehouse/warehouseApi';
import { accountStatus } from '../../../data/accountStatus';

export default function DetailsSalesAssociate({ currentData }) {
  const navigate = useNavigate();
  const query = useQuery();
  const [warehouseNames, setWarehouseNames] = useState([]);
  const [routeNames, setRouteNames] = useState([]);
  const [page, setPage] = useState(1);

  const { data: warehouseData, isLoading: getWarehouseQueryLoading } =
    useGetWarehouseQuery({});

  useEffect(() => {
    if (warehouseData?.data?.warehouses) {
      const names = {};
      warehouseData.data.warehouses.forEach((warehouse) => {
        names[warehouse.id] = warehouse.name;
      });
      setWarehouseNames(names);
    }
  }, [warehouseData]);

  return (
    <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div className="px-4 py-6 sm:p-8">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Sales Associate Name
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.name ?? <LoaderIcon />}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Mobile Number
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.phone ?? <LoaderIcon />}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Account Status
            </label>
            <div className="mt-2">
              <span>{accountStatus[currentData?.data?.accountStatus ?? <LoaderIcon />]}</span>
            </div>
            <div className="text-red-600"></div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Warehouse Name
            </label>
            <div className="mt-2">
              <div>{warehouseNames[currentData?.data?.warehouseId] || 'N/A'}</div>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Routes Name
            </label>
            <div className="mt-2">
              <div>

                {currentData?.data?.routes?.length > 0 ? currentData.data.routes.map((route, index) => (
                <span
                  key={index}
                  className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${
                    index % 2 === 0
                      ? 'inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700 mr-1'
                      : 'inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800 ml-1'
                  }`}
                >
                  {route.name}
                </span>
              )) : 'N/A'}

              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
        <button
          type="button"
          onClick={() => navigate('/app/manage-sales-associate/view')}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Back
        </button>
      </div>
    </form>
  );
}

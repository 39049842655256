import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import useQuery from '../../../hooks/useQuery';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '../../common/Form';
import MultipleProductPickup from '../../common/MultipleProductPickup';
import { useGetOneDataUserQuery, useUpdateDataUserMutation } from '../../../store/features/data-user/dataUserApi';

const emailRegex = /^$|^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const dataUserSchema = z.object({
  name: z.string().min(1, 'Name is required').max(50, 'Too Long'),
  email: z
    .string()
    .max(50)
    .refine((value) => value === '' || emailRegex.test(value), {
      message: 'Invalid email'
    })
});

export default function EditDataUserForm() {
  const dataUserId = useQuery().get('dataUserId');
  const { currentData } = useGetOneDataUserQuery({
    dataUserId
  });

  const [selectedRoutes, setSelectedRoutes] = useState([]);

  const handleRoutePickUpIds = (selectedRoutes) => {
    setSelectedRoutes(selectedRoutes);
  };

  useEffect(() => {
    currentData?.data?.products?.length > 0 &&
      setSelectedRoutes(currentData?.data?.products);
  }, [currentData]);

  const [page, setPage] = useState(1);

  const methods = useForm({
    resolver: zodResolver(dataUserSchema),
    mode: 'onChange',
    values: {
      ...currentData?.data
    }
  });

  const [
    callUpdateDataUserMutation,
    {
      isSuccess: isUpdateDataUserMutationSuccess,
      isLoading: updateDataUserMutationLoading
    }
  ] = useUpdateDataUserMutation();

  const navigate = useNavigate();

  const onSubmit = (data) => {
    const {email, ...body} = data;
    callUpdateDataUserMutation({
      ...body,
      productIds: selectedRoutes.map(route => route.id),
      dataUserId
    });
  };

  useEffect(() => {
    if (isUpdateDataUserMutationSuccess) {
      navigate('/app/data-user/view');
    }
  }, [isUpdateDataUserMutationSuccess]);

  return (
    <Form {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
      >
        <div className="px-4 py-6 sm:p-8">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <FormField
                name="name"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="user-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Name*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="user-name"
                          placeholder={'Enter User name'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="email"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email Address*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="email"
                          {...field}
                          id="email"
                          disabled
                          readOnly
                          placeholder={'Enter Email'}
                          className={clsx(
                            'disabled:bg-gray-200 disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="col-span-6 mt-10">
              <MultipleProductPickup
                selected={selectedRoutes}
                handleRoutePickUpIds={handleRoutePickUpIds}
              />
            </div>

          </div>
        </div>

        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button
            type="button"
            onClick={() => navigate('/app/data-user/view')}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={updateDataUserMutationLoading}
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
          >
            Save
          </button>
        </div>
      </form>
    </Form>
  );
}

import toast from 'react-hot-toast';
import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const categoryApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getCategory: builder.query({
      query: () => ({
        url: '/api/categories',
        method: 'GET'
      }),
      providesTags: ['getCategory']
    }),
    createCategory: builder.mutation({
      query: ({ ...query }) => ({
        url: '/api/categories/multiple',
        method: 'POST',
        body: {
          ...query
        }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Category/SubCategory created successfully!');
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
      invalidatesTags: ['getCategory']
    }),
    getSingleCategory: builder.query({
      query: ({ categoryId }) => ({
        url: `/api/categories/${categoryId}`,
        method: 'GET'
      }),
      providesTags: ['getCategory']
    }),
    updateSingleCategory: builder.mutation({
      query: ({ categoryId, ...query }) => ({
        url: `/api/categories/${categoryId}`,
        method: 'PATCH',
        body: {
          ...query
        }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Category/SubCategory created successfully!');
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
      invalidatesTags: ['getSingleCategory', 'getCategory']
    })
  })
});

export const {
  useGetCategoryQuery,
  useCreateCategoryMutation,
  useGetSingleCategoryQuery,
  useUpdateSingleCategoryMutation
} = categoryApi;

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import useQuery from '../../../hooks/useQuery';
import { useGetOneUserQuery } from '../../../store/features/users/usersApi';
import ViewUsers from '../../../components/app/users/ViewUsers';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Details'
};

export default function UsersDetails() {
  const query = useQuery();

  const { currentData } = useGetOneUserQuery({
    userId: query.get('userId')
  });

  const pages = [
    {
      name: 'Users',
      location: '/app/users',
      current: false
    },
    {
      name: 'Details',
      location: `/app/users/details?${query.get('userId')}`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <ViewUsers currentData={currentData} />
    </div>
  );
}

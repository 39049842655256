import toast from 'react-hot-toast';
import { appendParamsToUrl, handleErrorMessage } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const salesAssociateApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getSalesAssociate: builder.query({
      query: ({ ...query }) => ({
        url: `/api/users/sales-associates?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getSalesAssociate']
    }),
    createSalesAssociate: builder.mutation({
      query: ({ name, phone, warehouseId, routeIds }) => ({
        url: '/api/users/sales-associates',
        method: 'POST',
        body: { name, phone, warehouseId, routeIds }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Sales Associate has been created!');
        } catch (error) {
          handleErrorMessage(error);
        }
      },
      invalidatesTags: ['getSalesAssociate', 'getUser']
    }),
    getOneSalesAssociate: builder.query({
      query: ({ salesAssociateId }) => ({
        url: `/api/users/sales-associates/${salesAssociateId}`,
        method: 'GET'
      }),
      providesTags: ['getOneSalesAssociateWithId']
    }),
    updateSalesAssociate: builder.mutation({
      query: ({ salesAssociateId, ...query }) => ({
        url: `/api/users/sales-associates/${salesAssociateId}`,
        method: 'PUT',
        body: { ...query }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Sales Associate has been updated!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getUser',
        'getSalesAssociate',
        'getOneSalesAssociateWithId'
      ]
    }),
    getOneSalesAssociateGoals: builder.query({
      query: ({ salesAssociateId }) => ({
        url: `/api/users/sales-associates/${salesAssociateId}/goals`,
        method: 'GET'
      }),
      providesTags: ['getOneSalesAssociateGoals']
    }),
    updateOneSalesAssociateGoals: builder.mutation({
      query: ({ salesAssociateId, ...query }) => ({
        url: `/api/users/sales-associates/${salesAssociateId}/add-goals`,
        method: 'PUT',
        body: { ...query }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Sales Associate goals has been updated!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getSalesAssociate', 'getOneSalesAssociateWithId']
    }),
    updateOneSalesAssociateSingleGoal: builder.mutation({
      query: ({ salesAssociateId, ...query }) => ({
        url: `/api/users/sales-associates/${salesAssociateId}/goal-target`,
        method: 'PUT',
        body: { ...query }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Sales Associate goal has been updated!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getSalesAssociate', 'getOneSalesAssociateGoals']
    }),
    deleteOneSalesAssociateGoal: builder.mutation({
      query: ({ salesAssociateId, inventoryId }) => ({
        url: `/api/users/sales-associates/${salesAssociateId}/goal`,
        method: 'DELETE',
        body: {
          inventoryId
        }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Sales Associate goal has been deleted!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getSalesAssociate', 'getOneSalesAssociateGoals']
    }),
    updateOneSalesAssociateGoalType: builder.mutation({
      query: ({ salesAssociateId, ...query }) => ({
        url: `/api/users/sales-associates/${salesAssociateId}/goal-type`,
        method: 'PATCH',
        body: { ...query }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Sales Associate goal type has been updated!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getSalesAssociate', 'getOneSalesAssociateGoals']
    }),
    getOneSalesAssociateReport: builder.query({
      query: ({ salesAssociateId, ...query }) => ({
        url:
          `/api/reports/admin/sales-associates/${salesAssociateId}?` +
          appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getOneSalesAssociateReport']
    })
  })
});

export const {
  useGetSalesAssociateQuery,
  useCreateSalesAssociateMutation,
  useGetOneSalesAssociateQuery,
  useUpdateSalesAssociateMutation,
  useGetOneSalesAssociateGoalsQuery,
  useUpdateOneSalesAssociateGoalsMutation,
  useUpdateOneSalesAssociateSingleGoalMutation,
  useDeleteOneSalesAssociateGoalMutation,
  useUpdateOneSalesAssociateGoalTypeMutation,
  useGetOneSalesAssociateReportQuery
} = salesAssociateApi;

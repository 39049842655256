import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '../../common/Form';
import { userSchema } from './EditUsersForm';
import { useCreateUserMutation } from '../../../store/features/users/usersApi';
import { useGetWarehouseQuery } from '../../../store/features/warehouse/warehouseApi';
import { UserRole } from '../../../data/UserRole';
import DropdownSelect from '../../common/DropdownSelect';
import { useSelector } from 'react-redux';
import { selectCurrentRole } from '../../../store/features/auth/authSlice';

export default function CreateUsersForm() {
  const role = useSelector(selectCurrentRole);

  const [page, setPage] = useState(1);
  const [selectedWarehouse, setSelectedWarehouse] = useState({
    id: '',
    name: 'Select Warehouse'
  });

  const [warehouseName, setWarehouseName] = useState([]);

  const [selectedRole, setSelectedRole] = useState({
    id: 0,
    name: 'Select Role'
  });

  const methods = useForm({
    resolver: zodResolver(userSchema),
    mode: 'onChange'
  });

  const [
    callCreateUserMutation,
    {
      isSuccess: isCreateUserMutationSuccess,
      isLoading: createUserMutationLoading
    }
  ] = useCreateUserMutation();

  const { data: warehouseData, isLoading: getWarehouseQueryLoading } =
    useGetWarehouseQuery({});

  useEffect(() => {
    if (warehouseData?.data?.warehouses) {
      setWarehouseName(
        warehouseData.data.warehouses.map((warehouse) => ({
          id: warehouse.id,
          name: warehouse.name
        }))
      );
    }
  }, [warehouseData]);

  const navigate = useNavigate();

  const onSubmit = (data) => {
    let payload = {
      ...data,
      role: selectedRole?.name,
      ...(selectedWarehouse?.id && { warehouseId: selectedWarehouse?.id })
    };
    if (selectedRole?.name !== 'SUPER_ADMIN') {
      payload = {
        ...payload,
        warehouseId: selectedWarehouse?.id
      };
    }

    callCreateUserMutation(payload);
  };

  useEffect(() => {
    if (isCreateUserMutationSuccess) {
      navigate('/app/users/view');
    }
  }, [isCreateUserMutationSuccess]);

  useEffect(() => {
    role === 'WAREHOUSE_ADMIN' && setSelectedRole(UserRole?.[2]);
  }, [role]);

  return (
    <Form {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
      >
        <div className="px-4 py-6 sm:p-8">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <FormField
                name="name"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="user-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Name*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="user-name"
                          placeholder={'Enter User name'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="phone"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone Number*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="tel"
                          {...field}
                          id="phone"
                          placeholder={'Enter Phone Number'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="role"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Select Role*
              </label>
              <div className="mt-2">
                {role === 'WAREHOUSE_ADMIN' ? (
                  <input
                    type="text"
                    disabled
                    className={clsx(
                      'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 disabled:bg-gray-200'
                    )}
                    value={selectedRole?.name}
                  />
                ) : (
                  <DropdownSelect
                    data={UserRole}
                    selected={selectedRole}
                    setSelected={setSelectedRole}
                  />
                )}
              </div>
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="email"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email Address*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="email"
                          {...field}
                          id="email"
                          placeholder={'Enter Email'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {!['Select Role', 'SUPER_ADMIN', 'CUSTOMER'].includes(
              selectedRole?.name
            ) && (
              <div className="sm:col-span-3">
                <label
                  htmlFor="warehouse"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Select Warehouse*
                </label>
                <div className="mt-2">
                  <DropdownSelect
                    data={warehouseName}
                    isLoading={getWarehouseQueryLoading}
                    selected={selectedWarehouse}
                    setSelected={setSelectedWarehouse}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button
            type="button"
            onClick={() => navigate('/app/users/view')}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={createUserMutationLoading || !methods.formState.isValid}
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
          >
            Add User
          </button>
        </div>
      </form>
    </Form>
  );
}

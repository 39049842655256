import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EditProductManagementForm from '../../../components/app/product-management/EditProductManagementForm';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import useQuery from '../../../hooks/useQuery';

const pageMeta = {
  page_name: 'Edit Product'
};

export default function EditProductManagement() {
  const navigate = useNavigate();
  const productId = useQuery().get('productId');
  const pages = [
    {
      name: 'Product Management',
      location: '/app/product-management/',
      current: false
    },
    {
      name: 'Edit',
      location: `/app/product-management/edit?productId=${productId}`,
      current: true
    }
  ];
  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <EditProductManagementForm />
    </div>
  );
}

export const UserRole = [
    {
        id: '1',
        name: 'SUPER_ADMIN'
    },
    {
        id: '2',
        name: 'WAREHOUSE_ADMIN'
    },
    {
        id: '3',
        name: 'WAREHOUSE_ASSOCIATE'
    },
    {
        id: '4',
        name: 'SALES_ASSOCIATE'
    },
    {
        id: '5',
        name: 'DELIVERY_ASSOCIATE'
    },
    {
        id: '6',
        name: 'DATA_USER'
    },
    {
        id: '7',
        name: 'CUSTOMER'
    }
];
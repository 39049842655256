import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import districts from '../../../data/districts';
import thana from '../../../data/thana';
import pickupLocationSchema from '../../../schemas/PickupLocationSchema';
import { useCreateWarehouseMutation } from '../../../store/features/warehouse/warehouseApi';
import DropdownSelect from '../../common/DropdownSelect';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '../../common/Form';
import MultipleRoutePickup from '../../common/MultipleRoutePickup';

export default function CreateWarehouseForm() {
  const navigate = useNavigate();
  const [createWarehouse, { isLoading, isSuccess }] =
    useCreateWarehouseMutation();

  const methods = useForm({
    resolver: zodResolver(pickupLocationSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      contactPhone: '',
      contactEmail: '',
      address: {
        full: '',
        village: '',
        thana: '',
        district: ''
      },
      mapLink: ''
    }
  });

  const [selectedDistrict, setSelectedDistrict] = useState({
    id: '',
    name: 'Select District'
  });

  const [selectedThana, setSelectedThana] = useState({
    id: '',
    name: 'Select Thana'
  });

  const [filteredThana, setFilteredThana] = useState([
    { id: '', name: 'Select district first' }
  ]);

  useEffect(() => {
    setFilteredThana(() =>
      thana.filter((eachThana) => eachThana.district_id === selectedDistrict.id)
    );
    setSelectedThana({
      id: '',
      name: 'Select Thana'
    });
  }, [selectedDistrict]);

  useEffect(() => {
    isSuccess && navigate('/app/warehouse/view');
  }, [isSuccess]);

  const [selectedRoutes,setSelectedRoutes] = useState([]);

  const handleRoutePickUpIds = (selectedRoutes) => {
    setSelectedRoutes(selectedRoutes);
  };

  const onSubmit = async (data) => {
    if (selectedDistrict.id === '') {
      toast.error('You must need to select a district');
      return;
    }

    if (selectedThana.id === '') {
      toast.error('You must need to select a thana');
      return;
    }
    if (selectedRoutes.length < 1) {
      toast.error('Routes required');
      return;
    }

    if (selectedDistrict.id !== '' && selectedThana.id !== '') {
      const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;

      const mapLink = data.mapLink;
      const lat_long = regex.exec(mapLink);

      const payload = {
        ...data,
        location: {
          coordinates: [+lat_long[1], +lat_long[2]]
        },
        address: {
          ...data.address,
          district: selectedDistrict.name,
          thana: selectedThana.name
        },
        routeIds: selectedRoutes?.map((route)=> route?.id)
      };

      createWarehouse(payload);
    }
  };

  return (
    <Form {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
      >
        <div className="px-4 py-6 sm:p-8">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <FormField
                name="name"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="location-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Location Name*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="location-name"
                          placeholder={'Enter location name'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="sm:col-span-3">
              <FormField
                name="contactPhone"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone number*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="contactPhone"
                          placeholder={'Enter phone number'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="sm:col-span-3">
              <FormField
                name="contactEmail"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email address*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="contactEmail"
                          placeholder={'Enter email address'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="sm:col-span-3">
              <FormField
                name="address.village"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="village-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Village Name or Bazar*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="village-name"
                          placeholder={'Enter village name or bazar'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="district"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Select District*
              </label>
              <div className="mt-2">
                <DropdownSelect
                  data={districts.sort((a, b) => (a.name > b.name ? 1 : -1))}
                  selected={selectedDistrict}
                  setSelected={setSelectedDistrict}
                  // setSelected={setSelectedDistrictForm}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="thana"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Select the police station nearest*
              </label>
              <div className="mt-2">
                <DropdownSelect
                  data={filteredThana.sort((a, b) =>
                    a.name > b.name ? 1 : -1
                  )}
                  selected={selectedThana}
                  setSelected={setSelectedThana}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="address.full"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Full address*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="address"
                          placeholder={'Enter full address'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="mapLink"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="mapLink"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Map Link*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="mapLink"
                          placeholder={'Enter map link'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="col-span-6 mt-10">
            <MultipleRoutePickup handleRoutePickUpIds={handleRoutePickUpIds} />
          </div>
        </div>

        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => navigate('/app/warehouse/view')}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
            disabled={isLoading || !methods.formState.isValid}
          >
            Submit
          </button>
        </div>
      </form>
    </Form>
  );
}

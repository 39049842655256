import React from 'react';
import CreateProductManagementForm from '../../../components/app/product-management/CreateProductManagementForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Create Product'
};

const pages = [
  {
    name: 'Product Management',
    location: '/app/product-management/',
    current: false
  },
  { name: 'Create', location: '/app/product-management/create', current: true }
];

export default function CreateProductManagement() {
  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />

      <CreateProductManagementForm />
    </div>
  );
}

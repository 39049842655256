import useAuthSelector from "../hooks/useAuthSelector";
import { Navigate } from "react-router-dom";
import access from "../routers/access";

export default function FirstPage() {
	const { role } = useAuthSelector();
	if (role === "SUPER_ADMIN") {
		return <Navigate to={access.SUPER_ADMIN} replace />;
	} else if (role === "WAREHOUSE_ADMIN" || role=== "WAREHOUSE_ASSOCIATE"){
		return <Navigate to={access.WAREHOUSE_ADMIN} replace />;
	}
	else{
		return <Navigate to={access.DATA_USER} replace />;
	}
}

import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ApplyFilter from '../../../components/common/ApplyFilter';
import Table from '../../../components/common/DataTable/Table';
import TablePagination from '../../../components/common/TablePagination';
import { useGetDeliveryAssociateQuery } from '../../../store/features/delivery-associate/deliveryAssociateApi';
import { useGetOrderQuery } from '../../../store/features/order/orderApi';
import { useGetCustomerQuery } from '../../../store/features/customer/customerApi';
import {
  getOrderStatus,
  getPaymentStatus,
  getQueryParams,
  mapFilterToUrlParam
} from '../../../lib/utils';
import { useGetAllRoutesQuery } from '../../../store/features/manage-routes/manageRoutesApi';

const rootMeta = {
  page_name: 'Order History',
  breadcrumbs: null
};
const getCustomers = (data) => {
  return [
    {
      id: '',
      name: 'Select Customer'
    },
    ...data?.map((item) => ({
      id: item.id,
      name: item.name
    }))
  ];
};
const getSA = (data) => {
  return [
    {
      id: '',
      name: 'Select Sales Associate'
    },
    ...data?.map((item) => ({
      id: item.id,
      name: item.name
    }))
  ];
};
const getDA = (data) => {
  return [
    {
      id: '',
      name: 'Select Delivery Associate'
    },
    ...data?.map((item) => ({
      id: item.id,
      name: item.name
    }))
  ];
};
const getRoutes = (data) => {
  return [
    {
      id: '',
      name: 'Select Route'
    },
    ...data?.map((item) => ({
      id: item.id,
      name: item.name
    }))
  ];
};
const delivery_statuses = {
  DELIVERED: (
    <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
      Delivered
    </span>
  ),
  PENDING: (
    <span className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
      Pending
    </span>
  ),
  IN_PROGRESS: (
    <span className="inline-flex items-center rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-800">
      In progress
    </span>
  ),
  CANCELLED: (
    <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
      Cancelled
    </span>
  )
};

const columnHelper = createColumnHelper();

export default function OrderHistoryRoot() {
  const location = useLocation();
  const queryParamsString = location.search;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});

  const { currentData } = useGetOrderQuery(
    !isEmpty(selectedData)
      ? {
          page,
          ...selectedData
        }
      : {
          page
        }
  );
  const { currentData: customersData } = useGetCustomerQuery({});
  const { currentData: routesData } = useGetAllRoutesQuery({});
  const { currentData: deliveryAssociateData } = useGetDeliveryAssociateQuery(
    {}
  );
  const searchModes = [
    {
      param_name: 'Customer Name',
      param: 'customerId',
      type: 'select',
      options: getCustomers(customersData?.data?.users ?? [])
    },
    {
      param_name: 'Delivery Associate Name',
      param: 'deliveryAssociateId',
      type: 'select',
      options: getDA(deliveryAssociateData?.data?.users ?? [])
    },
    {
      param_name: 'Route Name',
      param: 'routeId',
      type: 'select',
      options: getRoutes(routesData?.data?.routes ?? [])
    },
    {
      param_name: 'Order Status',
      param: 'status',
      type: 'select',
      options: getOrderStatus()
    },
    {
      param_name: 'Payment Status',
      param: 'cashCollectionStatus',
      type: 'select',
      options: getPaymentStatus()
    },
    {
      param_name: 'From Date',
      param: 'fromDate',
      type: 'date'
    },
    {
      param_name: 'To Date',
      param: 'toDate',
      type: 'date'
    },
    {
      param_name: 'Chamak Id',
      param: 'chamakId',
      type: 'text'
    },
    {
      param_name: 'Order Id',
      param: 'orderId',
      type: 'text'
    }
  ];

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Link to={`/app/order-history/detail?orderId=${row.id}`} target='_blank'>
            <div className='border-b-2'>{row.id}</div>
        </Link>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ORDER ID',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.customer?.name}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'CUSTOMER NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.route?.name}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ROUTE NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.salesAssociate?.name}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SR NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>BDT {row.invoice?.totalPayable}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'AMOUNT',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => row?.address?.full,
      {
        cell: (info) => info.getValue(),
        header: 'ADDRESS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{dayjs(row.createdAt).format('DD MMM YYYY h:mm A')}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'CREATED AT',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>{delivery_statuses[row.orderStatus?.toUpperCase()]}</Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'STATUS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    
    columnHelper.accessor(
      (row) => {
        return (
          <Fragment>
            {row?.deliveryAssociate?.name ? (
              <span>{row?.deliveryAssociate?.name}</span>
            ) : (
              <span>N/A</span>
            )}
          </Fragment>
        );
      },
      {
        cell: (info) => info.getValue(),
        header: 'DELIVERY ASSOCIATE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <Link to={`/app/order-history/detail?orderId=${row.id}`}>
            <button className="text-red-600 hover:underline">View</button>
          </Link>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'DETAILS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];

  const handleSelectedData = (data) => {
    navigate(`/app/order-history/view${mapFilterToUrlParam(data)}`);
    setSelectedData(data);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedData]);

  useEffect(() => {
    setSelectedData(getQueryParams(queryParamsString));
  }, [queryParamsString]);

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <ApplyFilter
              data={searchModes}
              selectedData={selectedData}
              handleSelectedData={handleSelectedData}
            />
          </div>
        </div>
      </div>
      <Table data={currentData?.data?.orders ?? []} columns={columns} />
      <TablePagination
        paginationData={currentData?.data?.meta ?? undefined}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

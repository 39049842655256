import useAuthSelector from "../hooks/useAuthSelector";
import NotAllowed from "../components/common/NotAllowed";

export default function RoleProtectedRoutes({ children, allowedRoles }) {
	const { role } = useAuthSelector();
	if (allowedRoles.includes(role)) {
		return children;
	} else {
		return <NotAllowed />;
	}
}

import { PlusIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CentralProducts from '../../../components/app/products/CentralProducts';
import CustomizeLinkProducts from '../../../components/app/products/CustomizeLinkProducts';
import { useCreateInventoryMutation } from '../../../store/features/inventory/inventoryApi';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Add Product'
};

const pages = [
  {
    name: 'Products',
    location: '/app/products',
    current: false
  },
  { name: 'Add', location: '/app/products/add', current: true }
];

export default function AddProduct() {
  const navigate = useNavigate();
  const [draftProducts, setDraftProducts] = useState([]);
  const [removedDraftProductId, setRemovedDraftProductId] = useState();

  const [
    callCreateInventoryMutation,
    {
      isSuccess: isCreateInventoryMutationSuccess,
      isLoading: createInventoryMutationLoading
    }
  ] = useCreateInventoryMutation();

  const addToWareHouseDraft = (row) => {
    setDraftProducts((data) => [
      ...data,
      {
        nameEn: row.nameEn,
        sku: row.sku,
        productId: row.id,
        stock: '0',
        unitPrice: '0',
        memberUnitPrice: '0',
        isFreeDelivery: true,
        discountType: 'PERCENTAGE',
        discountValue: '0',
        commissionType: 'PERCENTAGE',
        commissionValue: '0'
      }
    ]);
  };
  const handleDraftProducts = (id, field, value) => {
    setDraftProducts((data) =>
      data.map((item) => {
        if (item.productId === id) {
          return {
            ...item,
            [field]: value
          };
        }
        return item;
      })
    );
  };

  const removeProduct = (row) => {
    setDraftProducts((data) =>
      data.filter((rowItem) => rowItem.productId !== row.productId)
    );
    setRemovedDraftProductId(row.productId);
  };

  const addProductsToWarehouse = () => {
    const payload = {
      list: draftProducts?.map((item) => {
        const { nameEn, sku, ...body } = item;
        return body;
      })
    };
    callCreateInventoryMutation(payload);
  };

  useEffect(() => {
    isCreateInventoryMutationSuccess && navigate('/app/products/view');
  }, [isCreateInventoryMutationSuccess]);

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <CentralProducts
        addToWareHouseDraft={addToWareHouseDraft}
        draftProducts={draftProducts}
        removedProductId={removedDraftProductId}
      />
      <CustomizeLinkProducts
        draftProducts={draftProducts}
        removeProduct={removeProduct}
        handleDraftProducts={handleDraftProducts}
      />
      {draftProducts?.length > 0 && (
        <div className="mt-10 flex justify-end">
          <button
            className="flex items-center rounded-md bg-red-600 px-3 py-2 text-white shadow-sm hover:bg-red-500 disabled:bg-gray-500"
            onClick={addProductsToWarehouse}
            disabled={createInventoryMutationLoading}
          >
            <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Add Product(s) to Warehouse
          </button>
        </div>
      )}
    </div>
  );
}

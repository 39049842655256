import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import useQuery from '../../../hooks/useQuery';
import EditUsersForm from '../../../components/app/users/EditUsersForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Edit User'
};

export default function EditUsers() {
  const query = useQuery();

  const pages = [
    {
      name: 'Users',
      location: '/app/users/',
      current: false
    },
    {
      name: 'Edit',
      location: `/app/users/edit?${query.get('userId')}`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <EditUsersForm />
    </div>
  );
}

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import Input from './Input';
import toast from 'react-hot-toast';

export default function AssignMultipleModals({
  open,
  setOpen,
  query,
  setQuery,
  title = '',
  dataList = [],
  setSelected,
  selected
}) {
  const [selectedItems, setSelectedItems] = useState([]);

  const toggleSelection = (item) => {
    const selectedIndex = selectedItems?.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );
    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, item]);
    } else {
      const updatedSelection = [...selectedItems];
      updatedSelection.splice(selectedIndex, 1);
      setSelectedItems(updatedSelection);
    }
  };

  const confirmSelection = () => {
    setSelected(selectedItems);
    setOpen(false);
    toast.success('Selection confirmed!');
  };

  useEffect(() => {
    setSelectedItems(selected);
  }, [open, selected]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[99999]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full relative items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="absolute right-5 top-2.5 transform -translate-x-1/2">
                  <button
                    type="button"
                    className={`inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all ${
                      !selectedItems?.length
                        ? 'bg-gray-400 text-gray-600 cursor-not-allowed'
                        : 'bg-red-600 text-white hover:bg-red-500'
                    }`}
                    onClick={confirmSelection}
                    disabled={!selectedItems?.length}
                  >
                    Done
                  </button>
                </div>
                <div>
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Title>Assign {title}</Title>
                    <div className="mt-2 relative">
                      <Input query={query} setQuery={setQuery} title={title} />
                      <div className="absolute inset-x-0 mt-3 rounded-lg p-6 z-[99999999999] bg-white  border shadow-sm">
                        <ul className="divide-y divide-gray-200 max-h-48 overflow-y-scroll scroll- overflow-hidden scrollbar-none">
                          {dataList?.length > 0 ? (
                            dataList.map((item) => (
                              <li
                                key={item.id}
                                className={`px-4 py-4 hover:bg-slate-200 rounded-xl cursor-pointer ${
                                  selectedItems?.find(
                                    (selectedItem) =>
                                      selectedItem.id === item.id
                                  )
                                    ? 'bg-gray-100'
                                    : ''
                                }`}
                                onClick={() => toggleSelection(item)}
                              >
                                <div className="flex items-center">
                                  <span className="mr-2">{item.name || item?.nameEn}</span>
                                  {selectedItems?.find(
                                    (selectedItem) =>
                                      selectedItem.id === item.id
                                  ) && (
                                    <CheckIcon className="h-4 w-4 text-green-500" />
                                  )}
                                </div>
                              </li>
                            ))
                          ) : (
                            <LoaderIcon />
                          )}
                        </ul>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="mt-4 inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      onClick={confirmSelection}
                    >
                      Confirm Selection
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const Title = ({ children }) => (
  <Dialog.Title
    as="h3"
    className="text-xl font-bold leading-6 text-gray-900 border-b pb-8 border-black/10"
  >
    {children}
  </Dialog.Title>
);

AssignMultipleModals.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  query: PropTypes.string,
  setSelected: PropTypes.func,
  selected: PropTypes.array,
  title: PropTypes.string,
  setQuery: PropTypes.func,
  dataList: PropTypes.array
};

import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { debounce } from 'lodash';
import { useEffect, useMemo } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SearchFilter({
  data,
  selectedId,
  setSelectedId,
  searchQuery,
  setSearchQuery
}) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData =
    searchQuery === ''
      ? data
      : data.filter((option) => {
          return option.name.toLowerCase().includes(searchQuery.toLowerCase());
        });

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 300);
  }, [handleSearch]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  // const inputRef = useRef(null);

  return (
    <Combobox as="div" value={selectedId} onChange={setSelectedId}>
      {/*  <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">
        Assigned to
      </Combobox.Label> */}
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
          onChange={debouncedResults}
          displayValue={(person) => person?.name ?? ''}
          placeholder="Select Filter"
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredData.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredData.map((item) => (
              <Combobox.Option
                key={item?.key}
                value={item}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-8 pr-4',
                    active ? 'bg-red-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        'block truncate',
                        selected && 'font-semibold'
                      )}
                    >
                      {item.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 left-0 flex items-center pl-1.5',
                          active ? 'text-white' : 'text-red-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}

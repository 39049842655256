import clsx from 'clsx';
import React, { Fragment } from 'react';

export default function InvoiceSummary({
  title,
  invoice,
  isKeyValuePair,
  productName
}) {
  if (isKeyValuePair && invoice) {
    return (
      <Fragment>
        <h4 className="text-gray-700 text-lg font-bold px-4 py-2 rounded bg-gray-200">
          {title}
        </h4>
        <div className="py-4 px-6 border-2 bg-white rounded">
          {Object.entries(invoice)?.map(([key, value], index) => (
            <div
              className={clsx(
                `flex justify-between ${
                  Object.keys(productName)?.[0] === key ||
                  Object.keys(productName)?.[
                    Object.keys(productName).length - 1
                  ] === key
                    ? 'font-semibold'
                    : ''
                }`
              )}
            >
              <div>{productName[key]}</div>
              <div className="mb-2">{value}</div>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <h4 className="text-gray-700 text-lg font-bold px-4 py-2 rounded bg-gray-200">
        {title}
      </h4>
      <div className="py-4 px-6 border-r-2 bg-white rounded">
        <div className="flex justify-between pr-48">
          <div className="flex-grow">Sub Total</div>
          <div className="mb-2">{invoice?.itemSubTotal}</div>
        </div>
        <div className="flex justify-between pr-48">
          <div>Delivery Charge</div>
          <div className="mb-2">{invoice?.deliveryCharge}</div>
        </div>
        <div className="flex justify-between pr-48">
          <div>Item Discount</div>
          <div className="mb-2">
            {invoice?.itemDiscount ? `-${invoice?.itemDiscount}` : 0}
          </div>
        </div>
        <div className="flex justify-between pr-48">
          <div>Sales Discount</div>
          <div className="mb-2">
            {invoice?.salesDiscount ? `-${invoice?.salesDiscount}` : 0}
          </div>
        </div>
        <div className="flex justify-between pr-48 text-lg border-t-2 font-bold">
          <div>Total Payable</div>
          <div className="mb-2 flex items-center">
            BDT {invoice?.totalPayable}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

import React from "react";

export default function AutoCompleteChamakIds({
  data = [],
  setChamakId,
  isSelected,
  setIsSelected,
}) {
  return (
    <>
      {data && !isSelected && (
        <div className="absolute inset-x-0 mt-3 rounded-lg p-3 z-[99999999999] bg-white border shadow-sm">
          <ul className="divide-y divide-gray-200 max-h-48 overflow-y-scroll scroll- overflow-hidden scrollbar-none">
            {data?.map((item) => (
              <li
                key={item?.id}
                className="px-2 py-1 hover:bg-slate-200 rounded-sm cursor-pointer"
                onClick={() => {
                  setChamakId(item?.chamakId);
                  setIsSelected(true);
                }}
              >
                <p>
                  {item?.name}{" "}
                  <span className="text-gray-500">#{item?.chamakId}</span>
                </p>
                <p className="text-xs text-gray-500">
                  {item?.ownerName}, {item?.phone}
                </p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

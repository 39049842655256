import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const reportsApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    // getReportsSuperAdmin: builder.query({
    //   query: ({ rangeType, startDate, endDate, id, idType }) => ({
    //     url: `/api/reports/super-admin?${appendParamsToUrl({
    //       rangeType,
    //       startDate,
    //       endDate,
    //       idType,
    //       id
    //     })}`,
    //     method: 'GET'
    //   })
    // }),
    // getReportsSuperAdmin: builder.query({
    //   query: ({ ...query }) => ({
    //     url: `/api/reports/admin/my-dashboard?${appendParamsToUrl({ ...query })}`,
    //     method: 'GET'
    //   })
    // }),
    getReportsSuperAdmin: builder.query({
      query: ({ fromDate, toDate, warehouseId }) => ({
        url: `/api/reports/admin/my-dashboard?${appendParamsToUrl({
          fromDate,
          toDate,
          warehouseId
        })}`,
        method: 'GET'
      }),
      // providesTags: ['getReportsSuperAdmin']
    }),

    getReportsDataUser: builder.query({
      query: ({ ...query }) => ({
        url: `/api/reports/data-users/my-reports?${appendParamsToUrl({
          ...query
        })}`,
        method: 'GET'
      })
    }),
    getReportsDeliveryManager: builder.query({
      query: ({ rangeType, startDate, endDate }) => ({
        url: `/api/reports/delivery-manager?${appendParamsToUrl({
          rangeType,
          startDate,
          endDate
        })}`,
        method: 'GET'
      })
    })
  })
});

export const {
  useGetReportsSuperAdminQuery,
  useGetReportsDataUserQuery,
  useGetReportsDeliveryManagerQuery
} = reportsApi;

import toast from "react-hot-toast";
import { chamakApi } from "../../app/api";

export const shipmentApi = chamakApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllShipments: builder.query({
			query: ({ page, status }) => ({
				url: `/api/shipments?page=${page}&status=${status}`,
				method: "GET",
			}),

			providesTags: ["Shipments"],
		}),
		createShipment: builder.mutation({
			query: ({ body }) => ({
				url: "/api/shipments",
				method: "POST",
				body,
			}),
			onQueryStarted: async (_, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success("Shipment created");
				} catch (error) {
					toast.error(error.error.data.message);
				}
			},
			invalidatesTags: ["Shipments"],
		}),
		getShipmentById: builder.query({
			query: ({ shipmentId }) => ({
				url: `/api/shipments/${shipmentId}`,
				method: "GET",
			}),
			providesTags: ["Shipment"],
		}),
		shipmentAssignDeliveryPerson: builder.mutation({
			query: ({ shipmentId, deliveryPersonId }) => ({
				url: `/api/shipments/assign-delivery-person`,
				method: "PUT",
				body: {
					shipmentId,
					deliveryPersonId,
				},
			}),
			invalidatesTags: ["Shipments", "Shipment"],
			onQueryStarted: async (_, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success("Delivery person assigned");
				} catch (error) {
					toast.error(error.error.data.message);
				}
			},
		}),
		shipmentRemoveDeliveryPerson: builder.mutation({
			query: ({ shipmentId }) => ({
				url: `/api/shipments/remove-delivery-person`,
				method: "PUT",
				body: {
					shipmentId,
				},
			}),
			invalidatesTags: ["Shipments", "Shipment"],
			onQueryStarted: async (_, { queryFulfilled }) => {
				try {
					await queryFulfilled;
					toast.success("Delivery person removed");
				} catch (error) {
					toast.error(error.error.data.message);
				}
			},
		}),
	}),
});

export const {
	useGetAllShipmentsQuery,
	useCreateShipmentMutation,
	useGetShipmentByIdQuery,
	useShipmentAssignDeliveryPersonMutation,
	useShipmentRemoveDeliveryPersonMutation,
} = shipmentApi;

import {
  DocumentArrowDownIcon,
  DocumentDuplicateIcon,
  RectangleStackIcon
} from '@heroicons/react/20/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoaderIcon, toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';
import categorynSchema from '../../../schemas/categorySchema';
import {
  useGetCategoryQuery,
  useGetSingleCategoryQuery,
  useUpdateSingleCategoryMutation
} from '../../../store/features/category/categoryApi';
import { useFileUploadMutation } from '../../../store/features/media/mediaApi';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '../../common/Form';

const size = 1024 * 100;

export default function EditCategoryForm() {
  const categoryId = useQuery().get('categoryId');
  const { currentData: singleCategoryData } = useGetSingleCategoryQuery({
    categoryId
  });

  const methods = useForm({
    resolver: zodResolver(categorynSchema),
    mode: 'onChange',
    defaultValues: {
      nameEn: '',
      nameBn: '',
      photo: '',
      shortDescription: ''
    },
    values: {
      nameEn: singleCategoryData?.data?.nameEn,
      nameBn: singleCategoryData?.data?.nameBn,
      photo: singleCategoryData?.data?.photo,
      shortDescription: singleCategoryData?.data?.shortDescription
    }
  });
  const {
    currentData,
    isLoading: isCategoryLoading,
    error: categoryError
  } = useGetCategoryQuery();

  const [
    callUpdateSingleCategoryMutation,
    {
      isSuccess: isUpdateSingleCategoryMutationSuccess,
      isLoading: isUpdateSingleCategoryMutationLoading
    }
  ] = useUpdateSingleCategoryMutation();

  const [
    callFileUploadMutation,
    {
      isSuccess: isFileUploadMutationSuccess,
      isLoading: fileUploadMutationLoading
    }
  ] = useFileUploadMutation();

  const navigate = useNavigate();

  useEffect(() => {
    categoryError && toast.error(categoryError?.data?.message);
  }, [categoryError]);

  const handleFileUpload = async (event, onChange) => {
    const files = event.target.files;
    if (Object.values(files)[0]?.size <= size) {
      const res = await callFileUploadMutation({
        featureName: 'products',
        fileName: '',
        ...files
      });
      res?.data?.data?.url && onChange(res?.data?.data?.url);
    } else {
      toast.error('Max size limit can be ' + size / 1024 + ' kb');
    }
  };

  const onSubmit = async (data) => {
    const payload = {
      categoryId,
      ...data
    };
    callUpdateSingleCategoryMutation(payload);
  };

  useEffect(() => {
    isUpdateSingleCategoryMutationSuccess && navigate('/app/category');
  }, [isUpdateSingleCategoryMutationSuccess]);

  return (
    <Form {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
      >
        <div className="px-4 py-6 sm:p-8">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="parentCategory"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Parent Category
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  disabled
                  readOnly
                  value={singleCategoryData?.data?.parent?.nameEn}
                  placeholder={'N/A'}
                  className={clsx(
                    'block w-full rounded-md border-0 py-1.5 bg-gray-200 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                  )}
                />
              </div>
            </div>
            <div className="sm:col-span-3">
              <FormField
                name="nameEn"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="nameEn"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Category/SubCategory*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="nameEn"
                          placeholder={'Enter category/subcategory'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="sm:col-span-3">
              <FormField
                name="nameBn"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="categoryBn"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Category/SubCategory (Bangla)
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="nameBn"
                          placeholder={'Enter category/subcategory'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="sm:col-span-3">
              <FormField
                name="photo"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="photo"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Category Photo
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          {...field}
                          id={'photo'}
                          type="file"
                          accept="image/*"
                          placeholder="Choose photo"
                          value={field?.value?.fileName}
                          disabled={fileUploadMutationLoading}
                          onChange={(event) =>
                            handleFileUpload(event, field?.onChange)
                          }
                          className="hidden"
                        />
                        {field?.value ? (
                          <div className="flex h-10">
                            <img
                              {...field}
                              id={'photo'}
                              src={field.value}
                              alt=""
                              width="40"
                              height="40"
                            />
                            <label
                              htmlFor={'photo'}
                              className="inline-flex h-10 items-center ml-10 gap-x-1.5 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all cursor-pointer"
                            >
                              {fileUploadMutationLoading && <LoaderIcon />}
                              <DocumentArrowDownIcon
                                className="-ml-0.5 h-5 w-5"
                                aria-hidden="true"
                              />
                              Choose photo
                            </label>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <RectangleStackIcon
                              className="w-10 h-10"
                              color="gray"
                            />
                            <label
                              htmlFor={'photo'}
                              className="ml-10 inline-flex h-10 items-center gap-x-1.5 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all cursor-pointer"
                            >
                              <DocumentDuplicateIcon
                                className="-ml-0.5 h-5 w-5"
                                aria-hidden="true"
                              />
                              Choose photo
                            </label>
                          </div>
                        )}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="sm:col-span-3">
              <FormField
                name="shortDescription"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="shortDescription"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Short Description
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="shortDescription"
                          placeholder={'Enter short description'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            onClick={() => navigate('/app/category/view')}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
            disabled={
              isUpdateSingleCategoryMutationLoading ||
              fileUploadMutationLoading ||
              !methods.formState.isValid
            }
          >
            Update
          </button>
        </div>
      </form>
    </Form>
  );
}

import React from 'react';
import EditRouteForm from '../../../components/app/manage-routes/EditRouteForm';
import useQuery from '../../../hooks/useQuery';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Edit Route'
};

export default function EditRoutes() {
  const routeId = useQuery().get('routeId');
  const pages = [
    {
      name: 'Manage Routes',
      location: '/app/manage-routes',
      current: false
    },
    {
      name: 'Edit',
      location: '/app/manage-routes/edit?routeId=' + routeId,
      current: true
    }
  ];
  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <EditRouteForm />
    </div>
  );
}

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import CreateSalesAssociateForm from '../../../components/app/manage-sales-associate/CreateSalesAssociateForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Create Sales Associate'
};

const pages = [
  {
    name: 'Sales Associate',
    location: '/app/manage-sales-associate',
    current: false
  },
  {
    name: 'Create',
    location: '/app/manage-sales-associate/create',
    current: true
  }
];

export default function CreateSalesAssociate() {
  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <CreateSalesAssociateForm />
    </div>
  );
}

import { PlusIcon } from '@heroicons/react/20/solid';
import { PhoneIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ApplyFilter from '../../../components/common/ApplyFilter';
import Table from '../../../components/common/DataTable/Table';
import TablePagination from '../../../components/common/TablePagination';
import { useGetUserQuery } from '../../../store/features/users/usersApi';
import { accountStatus } from '../../../data/accountStatus';
import { useGetAllRoutesQuery } from '../../../store/features/manage-routes/manageRoutesApi';
import { useGetWarehouseQuery } from '../../../store/features/warehouse/warehouseApi';
import {
  getAccountStatus,
  getQueryParams,
  getRole,
  mapFilterToUrlParam
} from '../../../lib/utils';

const rootMeta = {
  page_name: 'Users',
  breadcrumbs: null
};

const getWarehouses = (data) => {
  return [
    {
      id: '',
      name: 'Select Warehouse'
    },
    ...data?.map((item) => ({
      id: item.id,
      name: item.name
    }))
  ];
};

const getRoutes = (data) => {
  return [
    {
      id: '',
      name: 'Select Route'
    },
    ...data?.map((item) => ({
      id: item.id,
      name: item.name
    }))
  ];
};

export default function UsersRoot() {
  const location = useLocation();
  const queryParamsString = location.search;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});

  const { currentData } = useGetUserQuery(
    !isEmpty(selectedData)
      ? {
          page,
          ...selectedData
        }
      : {
          page
        }
  );

  const { currentData: warehouseData } = useGetWarehouseQuery({});

  const { currentData: routesData } = useGetAllRoutesQuery({});

  const searchModes = [
    {
      param_name: 'Name',
      param: 'name',
      type: 'text'
    },
    {
      param_name: 'Phone',
      param: 'phone',
      type: 'text'
    },
    {
      param_name: 'Email',
      param: 'email',
      type: 'text'
    },
    {
      param_name: 'Warehouse Name',
      param: 'warehouseId',
      type: 'select',
      options: getWarehouses(warehouseData?.data?.warehouses ?? [])
    },
    {
      param_name: 'Route Name',
      param: 'routeId',
      type: 'select',
      options: getRoutes(routesData?.data?.routes ?? [])
    },
    {
      param_name: 'Role',
      param: 'role',
      type: 'select',
      options: getRole()
    },
    {
      param_name: 'Account Status',
      param: 'accountStatus',
      type: 'select',
      options: getAccountStatus()
    }
  ];

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.name}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.email}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'EMAIL'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex items-center gap-1">
            <span>{row.phone}</span>
            {row.phone && (
              <a className="flex items-center gap-1" href={`tel:${row.phone}`}>
                <span className="text-red-600">
                  <PhoneIcon width={16} />
                </span>
                <span className="text-red-600 font-medium">Call</span>
              </a>
            )}
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'PHONE'
      }
    ),
    columnHelper.accessor(
      (row) => <Fragment>{accountStatus[row.accountStatus]}</Fragment>,
      {
        cell: (info) => info.getValue(),
        header: 'ACCOUNT STATUS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex items-center gap-1">
            <span>{row.role}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ROLE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <Link to={`/app/users/edit?userId=${row.id}`}>
            <button className="text-red-600 hover:underline ml-3">Edit</button>
          </Link>
          <Link to={`/app/users/details?userId=${row.id}`}>
            <button className="text-red-600 hover:underline ml-3">
              Details
            </button>
          </Link>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];
  const handleSelectedData = (data) => {
    navigate(`/app/users/view${mapFilterToUrlParam(data)}`);
    setSelectedData(data);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedData]);

  useEffect(() => {
    setSelectedData(getQueryParams(queryParamsString));
  }, [queryParamsString]);
  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <ApplyFilter
              data={searchModes}
              selectedData={selectedData}
              handleSelectedData={handleSelectedData}
            />
            <Link
              to="/app/users/create"
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Create User
            </Link>
          </div>
        </div>
      </div>
      <Table data={currentData?.data?.users ?? []} columns={columns} />
      <TablePagination
        paginationData={currentData?.data?.meta ?? undefined}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

import toast from 'react-hot-toast';
import { constructFormData } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const mediaApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    fileUpload: builder.mutation({
      query: ({ featureName, fileName, ...body }) => ({
        url: `/api/media/upload`,
        method: 'POST',
        headers: {},
        body: constructFormData(body, true, { featureName, fileName })
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Image uploaded successfully!');
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      }
      // invalidatesTags: ['']
    })
  })
});

export const { useFileUploadMutation } = mediaApi;

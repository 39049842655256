import { createColumnHelper } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { Fragment, useState } from 'react';
import ApplyFilter from '../../../components/common/ApplyFilter';
import Table from '../../../components/common/DataTable/Table';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import useQuery from '../../../hooks/useQuery';
import { useGetOneSalesAssociateReportQuery } from '../../../store/features/sales-associate/salesAssociateApi';

const rootMeta = {
  page_name: 'Sales Associate Achievements',
  breadcrumbs: null
};

const searchModes = [
  {
    param_name: 'Start Date',
    param: 'fromDate',
    type: 'date'
  },
  {
    param_name: 'End Date',
    param: 'toDate',
    type: 'date'
  }
];

export default function SalesAssociatesAchievements() {
  const salesAssociateId = useQuery().get('salesAssociateId');
  const [selectedData, setSelectedData] = useState({});
  console.log('🚀 ~ SalesAssociatesAchievements ~ selectedData:', selectedData);

  const { currentData } = useGetOneSalesAssociateReportQuery(
    !isEmpty(selectedData)
      ? {
          salesAssociateId,
          ...selectedData
        }
      : {
          salesAssociateId
        }
  );

  const pages = [
    {
      name: 'Sales Associate',
      location: '/app/manage-sales-associate/view',
      current: false
    },
    {
      name: 'SA Achievements',
      location: `/app/manage-sales-associate/achievements?salesAssociateId=${salesAssociateId}`,
      current: true
    }
  ];

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.nameEn}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.nameBn}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME (BANGLA)'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.soldQuantity}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SOLD QTY',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.soldAmount}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SOLD AMOUNT',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <div>{row?.targetQuantity}</div>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'TARGET QTY',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];
  const handleSelectedData = (data) => {
    // navigate(
    //   `/app/manage-sales-associate/achievements?salesAssociateId=${salesAssociateId}${mapFilterToUrlParam(
    //     data
    //   )}`
    // );
    setSelectedData(data);
  };

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={rootMeta.page_name} pages={pages} />
      <ApplyFilter
        data={searchModes}
        selectedData={selectedData}
        handleSelectedData={handleSelectedData}
      />
      <Table data={currentData?.data ?? []} columns={columns} />
    </div>
  );
}

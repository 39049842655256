import toast from 'react-hot-toast';
import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const deliveryManagerApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getDeliveryManager: builder.query({
      query: ({ ...query }) => ({
        url: `/api/delivery-managers?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getDeliveryManager']
    }),
    createDeliveryManager: builder.mutation({
      query: ({ name, email, pickUpLocationId, phone }) => ({
        url: '/api/delivery-managers',
        method: 'POST',
        body: { name, email, pickUpLocationId, phone }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Delivery manager has been invited!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getDeliveryManager',
        'getOneDeliveryManager, getUnAssignedManagers'
      ]
    }),
    getOneDeliveryManager: builder.query({
      query: ({ managerId }) => ({
        url: `/api/delivery-managers/${managerId}`,
        method: 'GET'
      }),
      providesTags: ['getOneDeliveryManagerWithId']
    }),
    updateDeliveryManager: builder.mutation({
      query: ({ managerId, name, phone, accountStatus, pickUpLocationId }) => ({
        url: `/api/delivery-managers/${managerId}`,
        method: 'PUT',
        body: { name, phone, accountStatus, pickUpLocationId }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Delivery manager has been updated!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getDeliveryManager',
        'getOneDeliveryManagerWithId, getUnAssignedManagers'
      ]
    }),
    getUnAssignedManagers: builder.query({
      query: (query) => ({
        url: `/api/delivery-managers?unAssignedOnly=true&includeInActiveManagers=false&page=1${
          query && '&name=' + query
        }`,
        method: 'GET'
      }),
      providesTags: ['getUnAssignedManagers']
    }),
    reInviteManager: builder.mutation({
      query: ({ id }) => ({
        url: `/api/delivery-managers/${id}/re-invite`,
        method: 'PUT'
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          toast.success('Manager invited again');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      }
    })
  })
});

export const {
  useGetDeliveryManagerQuery,
  useCreateDeliveryManagerMutation,
  useGetOneDeliveryManagerQuery,
  useUpdateDeliveryManagerMutation,
  useGetUnAssignedManagersQuery,
  useReInviteManagerMutation
} = deliveryManagerApi;

import {
  ArrowLeftIcon,
  PlusIcon,
  ChevronUpIcon,
  ChevronDownIcon
} from '@heroicons/react/20/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { createColumnHelper } from '@tanstack/react-table';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import AddPaymentModal from '../../../components/common/AddPaymentModal';
import Table from '../../../components/common/DataTable/Table';
import Divider from '../../../components/common/Divider';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '../../../components/common/Form';
import ChamakModal from '../../../components/common/Modal';
import TableCheckbox from '../../../components/common/TableCheckbox';
import useQuery from '../../../hooks/useQuery';
import {
  useCreateOrderSettlementMutation,
  useCreateOrderSettlementSummaryMutation,
  useGetOrderSettlementQuery
} from '../../../store/features/order/orderApi';

const delivery_statuses = {
  Delivered: (
    <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
      Delivered
    </span>
  ),
  Pending: (
    <span className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
      Pending
    </span>
  ),
  In_Progress: (
    <span className="inline-flex items-center rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-800">
      In progress
    </span>
  ),
  Cancelled: (
    <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
      Cancelled
    </span>
  )
};

const getTotalReturnedQty = (data) => {
  return `x ${data?.reduce((curr, acc) => curr + acc?.quantity, 0)}`;
};

const columnHelper = createColumnHelper();

const cashCollectionSchema = z.object({
  remarks: z
    .string()
    .min(1, {
      message: 'Remarks is required'
    })
    .max(500)
});

export default function CollectCash() {
  const deliveryAssociateId = useQuery().get('deliveryAssociateId');
  const deliveryAssociateName = useQuery().get('deliveryAssociateName');
  const [rowSelection, setRowSelection] = useState({});
  const [open, setOpen] = useState(false);
  const [isAddPaymentOpen, setIsAddPaymentOpen] = useState(false);
  const [selectedTotal, setSelectedTotal] = useState(0);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [summary, setSummary] = useState(null);
  const [productsData, setProductsData] = useState([]);
  const [orderId, setOrderId] = useState();
  const [settlementType, setSettlementType] = useState('');
  const cancelButtonRef = useRef(null);

  const [
    callCreateOrderSettlementMutation,
    {
      isLoading: createOrderSettlementMutationLoading,
      isSuccess: createOrderSettlementMutationSuccess
    }
  ] = useCreateOrderSettlementMutation();

  const handleSettlementTypeChange = (e) => {
    setSettlementType(e.target.value);
    console.log(
      '🚀 ~ handleSettlementTypeChange ~ e.target.value:',
      e.target.value
    );
  };

  const [
    callCreateOrderSettlementSummaryMutation,
    {
      isLoading: createOrderSettlementSummaryMutationLoading,
      isSuccess: createOrderSettlementSummaryMutationSuccess
    }
  ] = useCreateOrderSettlementSummaryMutation();

  const { currentData } = useGetOrderSettlementQuery(deliveryAssociateId);
  const columns = [
    {
      id: 'select',
      header: ({ table }) => (
        <TableCheckbox
          {...{
            checked:
              selectedOrderIds?.length === currentData?.data?.orders?.length,
            indeterminate: selectedOrderIds?.length > 0,
            onChange: handleOnChangeAllCheckbox
          }}
        />
      ),
      cell: ({ row }) => (
        <TableCheckbox
          {...{
            checked: selectedOrderIds?.includes(row.original?.id),
            onChange: () => handleOnChangeCheckbox(row)
          }}
        />
      )
    },
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.id}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ORDER ID'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{delivery_statuses[row.orderStatus]}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ORDER STATUS'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.invoice?.totalPayable} BDT</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'TOTAL'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.cashCollection?.receivedByAdmin} BDT</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ADMIN RECEIVED'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.cashCollection?.due} BDT</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'DUE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.deliveryRemarks}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'REMARKS'
      }
    ),
    columnHelper.accessor((row) => <></>, {
      cell: ({ row }) => (
        <Fragment>
          <div className="flex items-center">
            <button
              onClick={() => {
                setOrderId(row.original?.id);
                setIsAddPaymentOpen(true);
              }}
            >
              <span className="pr-2 text-red-600">Add Payment</span>
            </button>
            <Link
              to={`/app/order-history/detail?orderId=${row.original?.id}`}
              target="_blank"
            >
              <span className="pr-2 text-red-600">Details</span>
            </Link>
            <button
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: { cursor: 'pointer' },
                className: 'flex items-center'
              }}
            >
              <span className="pr-2 text-red-600">
                {row.getIsExpanded()
                  ? 'Returned Products'
                  : 'Returned Products'}
              </span>
              {row.getIsExpanded() ? (
                <ChevronDownIcon className="w-6 h-6" />
              ) : (
                <ChevronUpIcon className="w-6 h-6" />
              )}
            </button>
          </div>
        </Fragment>
      ),
      header: 'ACTION'
    })
  ];

  const columnsInModal = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.id}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ORDER ID'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.invoice?.totalPayable} BDT</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'TOTAL'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.cashCollection?.receivedByAdmin} BDT</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ADMIN RECEIVED'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.cashCollection?.due} BDT</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'DUE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.deliveryRemarks}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'REMARKS'
      }
    )
  ];

  const handleOnChangeAllCheckbox = () => {
    if (selectedOrderIds?.length === currentData?.data?.orders?.length) {
      setSelectedOrderIds([]);
      setSelectedTotal(0);
    } else {
      setSelectedOrderIds(currentData?.data?.orders?.map((item) => item.id));
      setSelectedTotal(
        currentData?.data?.orders?.reduce(
          (curr, acc) => curr + acc?.cashCollection?.due,
          0
        )
      );
    }
  };
  const handleOnChangeCheckbox = (row) => {
    if (selectedOrderIds?.includes(row.original?.id)) {
      setSelectedOrderIds((ids) => ids.filter((id) => id !== row.original?.id));
      setSelectedTotal((total) => total - row.original?.cashCollection?.due);
    } else {
      setSelectedOrderIds((ids) => [...ids, row.original?.id]);
      setSelectedTotal((total) => total + row.original?.cashCollection?.due);
    }
  };

  const handleCashCollectModal = async () => {
    setOpen(true);
    const res = await callCreateOrderSettlementSummaryMutation({
      deliveryAssociateId,
      orderIds: selectedOrderIds
    });
    setSummary(res?.data?.data);
    setProductsData(
      res?.data?.data?.items?.map((item) => {
        return {
          id: item.inventoryId,
          name: item?.product?.nameEn,
          sku: item?.product?.sku,
          quantity: item.returnedQuantity,
          originalQty: item.returnedQuantity
        };
      })
    );
  };

  const formMethods = useForm({
    resolver: zodResolver(cashCollectionSchema),
    mode: 'onChange',
    defaultValues: {
      remarks: ''
    }
  });

  const collectPayment = async (data) => {
    try {
      const res = await callCreateOrderSettlementMutation({
        deliveryAssociateId,
        orderIds: selectedOrderIds,
        ...data,
        type: settlementType
      }).unwrap();
      setRowSelection({});
    } catch (error) {
      // if(type === 'CASH'){
      //   formMethods.setValue('collectedAmount', 0);
      // }
    }
  };

  useEffect(() => {
    if (createOrderSettlementMutationSuccess) {
      setSelectedOrderIds([]);
      setSummary(null);
      setProductsData([]);
      setSelectedTotal(0);
      setOpen(false);
      formMethods.reset();
    }
  }, [createOrderSettlementMutationSuccess]);

  const cleanUp = () => {
    setSummary(null);
  };

  const pageMeta = {
    page_name: deliveryAssociateName ?? '',
    breadcrumbs: null
  };

  return (
    <Fragment>
      <div className="sm:px-4">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold leading-6 text-gray-900">
              <Link to="/app/cash-collection/view">
                <span className="flex items-center gap-4 hover:text-gray-500 transition-all">
                  <ArrowLeftIcon className="h-7 w-7" />
                  {pageMeta.page_name}
                </span>
              </Link>
            </h1>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <div className="flex items-center">
              <span className="font-semibold leading-6 text-gray-900 mr-4">
                Total: {selectedTotal} BDT
              </span>
              <button
                onClick={handleCashCollectModal}
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:cursor-not-allowed disabled:opacity-30"
                disabled={selectedOrderIds?.length === 0}
              >
                <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Collect
              </button>
            </div>
          </div>
        </div>
        <Table
          data={currentData?.data?.orders ?? []}
          columns={columns}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          getRowCanExpand={() => true}
        />
      </div>
      <ChamakModal
        open={open}
        setOpen={setOpen}
        title={'Cash Collect'}
        cleanUp={cleanUp}
      >
        <div className="w-[650px] max-h-[80vh] overflow-scroll scrollbar-none">
          <div className="bg-gray-200">
            <h4 className="text-gray-700 text-lg font-bold px-4 pt-2 rounded bg-gray-200">
              Order Summary
            </h4>
            <Table data={summary?.orders ?? []} columns={columnsInModal} />
          </div>
          <Fragment>
            <h4 className="text-gray-700 text-lg font-bold px-4 py-2 rounded bg-gray-200">
              Returned Product Summary
            </h4>
            <div className="py-4 px-6 border-2 bg-white rounded">
              <div className="flex justify-between">
                <div className="flex-grow">
                  <span className="font-semibold">Product</span>
                </div>
                <div className="font-semibold mb-2 pr-10">
                  Returned Quantity
                </div>
              </div>

              {React.Children.toArray(
                productsData?.map((item) => (
                  <>
                    <Divider />
                    <div className="flex items-center justify-between my-2">
                      <div>
                        {item?.name}
                        <div className="font-semibold">{item?.sku}</div>
                      </div>
                      <div className="pr-28">x {item?.quantity}</div>
                    </div>
                  </>
                ))
              )}
              <Divider />
              <div className="flex justify-between mt-2">
                <div className="font-semibold">Total</div>
                <div className="font-semibold mb-2 pr-28">
                  {getTotalReturnedQty(productsData)}
                </div>
              </div>
            </div>
          </Fragment>
          <Form {...formMethods}>
            <form
              onSubmit={formMethods.handleSubmit(collectPayment)}
              className="mt-3"
            >
              <FormField
                name="remarks"
                control={formMethods.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-semibold">Remarks*</FormLabel>
                    <FormControl>
                      <textarea
                        rows={3}
                        placeholder="Enter Bank Slip No, Cheques or Cash"
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <select
                className="mt-4 w-fit rounded-md text-sm font-semibold m-2"
                value={settlementType}
                onChange={handleSettlementTypeChange}
              >
                <option value="">Choose option </option>
                {summary?.cashCollectionSummary?.totalDue &&
                  productsData.length > 0 && (
                    <option value="CASH_AND_PRODUCT">
                      Collect | ৳ {summary?.cashCollectionSummary?.totalDue} And{' '}
                      {getTotalReturnedQty(productsData)} Products
                    </option>
                  )}
                {summary?.cashCollectionSummary?.totalDue && (
                  <option value="CASH">
                    Collect | ৳ {summary?.cashCollectionSummary?.totalDue}
                  </option>
                )}
                {productsData.length > 0 && (
                  <option value="PRODUCT">
                    Collect | {getTotalReturnedQty(productsData)} Products
                  </option>
                )}
              </select>

              <ActionButtonsWrapper>
                <button
                  type="submit"
                  disabled={
                    !formMethods.formState.isDirty ||
                    !formMethods.formState.isValid ||
                    createOrderSettlementMutationLoading
                  }
                  className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto disabled:cursor-not-allowed disabled:opacity-50"
                >
                  Collect
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(false);
                  }}
                  ref={cancelButtonRef}
                >
                  Close
                </button>
              </ActionButtonsWrapper>
            </form>
          </Form>
        </div>
      </ChamakModal>
      <AddPaymentModal
        open={isAddPaymentOpen}
        setOpen={setIsAddPaymentOpen}
        orderId={orderId}
      />
    </Fragment>
  );
}

const ActionButtonsWrapper = ({ children }) => (
  <div className="py-3 sm:flex sm:flex-row-reverse">{children}</div>
);

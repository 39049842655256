import React from 'react';
import useQuery from '../../../hooks/useQuery';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import DetailsStockUpdateHistory from '../../../components/app/products/DetailsStockUpdateHistory';

const pageMeta = {
  page_name: 'Stock Update History'
};

export default function EditProduct() {
  const query = useQuery();

  const pages = [
    {
      name: 'Product',
      location: '/app/products/',
      current: false
    },
    {
      name: 'Details',
      location: `/app/products/details?${query.get('inventoryId')}`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <DetailsStockUpdateHistory />
    </div>
  );
}

import { createColumnHelper } from '@tanstack/react-table';
import { Fragment, useEffect, useState } from 'react';
import Table from '../../../components/common/DataTable/Table';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import useQuery from '../../../hooks/useQuery';
import { useGetCartQuery } from '../../../store/features/cart/cartApi';
import InvoiceSummary from '../../../components/common/InvoiceSummary';
import CustomerSummary from '../../../components/common/CustomerSummary';
import { usePlaceOrderMutation } from '../../../store/features/order/orderApi';
import { useNavigate } from 'react-router-dom';
import AssignSingleModal from '../../../components/app/shipment/AssignSingleModal';
import { useGetSalesAssociateQuery } from '../../../store/features/sales-associate/salesAssociateApi';
import DeliveryInstruction from '../../../components/common/DeliveryInstruction';
import { getNextDay } from '../../../lib/utils';

const pageMeta = {
  page_name: 'Checkout Summary',
  breadcrumbs: null
};

export default function CheckoutDetails() {
  const navigate = useNavigate();
  const customerId = useQuery().get('customerId');
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [salesAssociate, setSalesAssociate] = useState(null);
  const [deliveryData, setDeliveryData] = useState({
    deliveryDate: getNextDay(),
    deliveryInstruction: ''
  });
  const { currentData, isLoading } = useGetCartQuery({
    customerId
  });

  const { currentData: salesAssociateData } = useGetSalesAssociateQuery(
    query
      ? {
          page: 1,
          name: query
        }
      : {
          page: 1
        }
  );

  const [
    callPlaceOrderMutation,
    {
      isSuccess: placeOrderMutationSuccess,
      isLoading: placeOrderMutationLoading
    }
  ] = usePlaceOrderMutation();

  const pages = [
    {
      name: 'Customers',
      location: `/app/customers`,
      current: false
    },
    {
      name: 'View Cart',
      location: `/app/cart/view?customerId=${customerId}`,
      current: false
    },
    {
      name: 'Checkout',
      location: `/app/cart/checkout?customerId=${customerId}`,
      current: true
    }
  ];

  useEffect(() => {
    if (placeOrderMutationSuccess) {
      navigate('/app/customers', { replace: true });
    }
  }, [placeOrderMutationSuccess]);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.product?.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.product?.nameEn}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.quantity}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'QUANTITY',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.stock}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'STOCK',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.unitPrice}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'UNIT PRICE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.subTotal}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SUBTOTAL',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];

  const cleanup = () => {
    setQuery('');
  };

  const handlePlaceOrder = () => {
    callPlaceOrderMutation({
      customerId,
      ...deliveryData,
      ...(salesAssociate?.id && { salesAssociateId: salesAssociate?.id })
    });
  };

  const handleAssignSalesAssociate = async ({ associate }) => {
    setSalesAssociate({
      id: associate.id,
      name: associate.name,
      phone: associate.phone
    });
    setOpen(false);
  };

  const handleRemoveAssociate = (orderId) => {
    setSalesAssociate(null);
  };

  const handleDeliveryData = (flag, value) => {
    if (flag === 'date') {
      setDeliveryData({
        ...deliveryData,
        deliveryDate: value
      });
    } else {
      setDeliveryData({
        ...deliveryData,
        deliveryInstruction: value
      });
    }
  };

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <div className="mt-4 sm:ml-0 sm:mt-0 sm:flex-none md:flex-none">
        <CustomerSummary
          title={'Customer Summary'}
          customer={{
            ...currentData?.data?.customer
          }}
          removeAssignee={handleRemoveAssociate}
          setOpen={setOpen}
          noAssignment
        />
      </div>
      <DeliveryInstruction
        title={'Delivery Summary'}
        handleDeliveryData={handleDeliveryData}
      />
      <div className="text-gray-700 bg-gray-200 mt-8 px-4 py-2 rounded text-lg font-bold">
        Product Summary
      </div>
      <Table data={currentData?.data?.items ?? []} columns={columns} />
      <InvoiceSummary
        title={'Invoice Summary'}
        invoice={currentData?.data?.invoice}
      />
      <div className="flex justify-end">
        <button
          type="button"
          className="mt-8 px-10 rounded-md bg-red-600 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
          onClick={handlePlaceOrder}
          disabled={placeOrderMutationLoading}
        >
          Confirm & Place Order
        </button>
      </div>
      <AssignSingleModal
        title="Sales Associate"
        open={open}
        setOpen={setOpen}
        setQuery={setQuery}
        query={query}
        dataList={salesAssociateData?.data?.users ?? []}
        assignDeliveryPerson={handleAssignSalesAssociate}
        cleanup={cleanup}
      />
    </div>
  );
}

import SideBanner from "../../../components/auth/sign-in/SideBanner";
import SignInForm from "../../../components/auth/sign-in/SignInForm";
import InputTopBar from "../../../components/common/InputTopBar";

export default function SignInRoot() {
	return (
		<>
			<div className="flex min-h-screen flex-1 bg-white">
				<div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
					<div className="mx-auto w-full max-w-sm lg:w-96">
						<InputTopBar
							title={"Welcome Back 👋"}
							subtitle={"Enter your credentials to access your account"}
							withBrandLogo={true}
						/>

						<SignInForm />
					</div>
				</div>
				<SideBanner bannerSource="/login-banner.jpg" />
			</div>
		</>
	);
}

import { XCircleIcon } from "@heroicons/react/20/solid";

export default function NotAllowed() {
	return (
		<div className="rounded-md bg-red-50 p-4">
			<div className="flex">
				<div className="flex-shrink-0">
					<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
				</div>
				<div className="ml-3">
					<h3 className="text-sm font-medium text-red-800">
						There could be 3 potential reasons why you are seeing this
					</h3>
					<div className="mt-2 text-sm text-red-700">
						<ul role="list" className="list-disc space-y-1 pl-5">
							<li>You don't have permission to view this page</li>
							<li>
								Someone might have sent you a link to which you have no access
							</li>
							<li>Your permissions might have been updated recently</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}

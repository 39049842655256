import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
	name: "auth",
	initialState: {
		user: null,
		role: null,
		warehouseName: null,
		token: null,
	},
	reducers: {
		setCredentials: (state, action) => {
			const { email, accessToken, role, warehouseName, name } = action.payload;
			state.email = email;
			state.role = role;
			state.warehouseName = warehouseName;
			state.token = accessToken;
			state.name = name;
		},
		logOut: (state, _) => {
			state.email = null;
			state.role = null;
			state.warehouseName = null;
			state.token = null;
			state.name = null;
		},
	},
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentEmail = (state) => state.auth.email;
export const selectCurrentName = (state) => state.auth.name;
export const selectCurrentRole = (state) => state.auth.role;
export const selectCurrentWarehouseName = (state) => state.auth.warehouseName;
export const selectCurrentToken = (state) => state.auth.token;

import React from 'react';
import CreateUsersForm from '../../../components/app/users/CreateUsersForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Create User'
};

const pages = [
  {
    name: 'Users',
    location: '/app/users/',
    current: false
  },
  { name: 'Create', location: '/app/users/create', current: true }
];

export default function CreateUsers() {
  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <CreateUsersForm />
    </div>
  );
}

import { createColumnHelper } from '@tanstack/react-table';
import { Fragment, useEffect, useState } from 'react';
import Table from '../../../components/common/DataTable/Table';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import useQuery from '../../../hooks/useQuery';
import InvoiceSummary from '../../../components/common/InvoiceSummary';
import {
  useApplySalesDiscountMutation,
  useGetSingleOrderQuery,
  useUpdateSingleProductDiscountMutation
} from '../../../store/features/order/orderApi';
import CustomerSummary from '../../../components/common/CustomerSummary';
import ApplyDiscount from '../../../components/common/ApplyDiscount';
import SalesDiscount from '../../../components/common/SalesDiscount';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { selectCurrentRole } from '../../../store/features/auth/authSlice';

const pageMeta = {
  page_name: 'Order Details',
  breadcrumbs: null
};

export default function OrderDetails() {
  const orderId = useQuery().get('orderId');
  const [
    callUpdateSingleProductDiscountMutation,
    { isSuccess: updateSingleProductDiscountSuccess }
  ] = useUpdateSingleProductDiscountMutation();

  const [
    callApplySalesDiscountMutation,
    { isSuccess: applySalesDiscountMutationSuccess }
  ] = useApplySalesDiscountMutation();

  const [selected, setSelected] = useState(null);
  const [isDiscountOpen, setIsDiscountOpen] = useState(false);
  const [isSalesDiscountOpen, setIsSalesDiscountOpen] = useState(false);
  const role = useSelector(selectCurrentRole);

  const pages = [
    {
      name: 'Orders',
      location: `/app/order-history`,
      current: false
    },
    {
      name: 'View Order',
      location: `/app/order-history/detail?orderId=${orderId}`,
      current: true
    }
  ];

  const { currentData } = useGetSingleOrderQuery({
    orderId
  });

  const delivery_statuses = {
    DELIVERED: (
      <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
        Delivered
      </span>
    ),
    PENDING: (
      <span className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
        Pending
      </span>
    ),
    IN_PROGRESS: (
      <span className="inline-flex items-center rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-800">
        In progress
      </span>
    ),
    CANCELLED: (
      <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-800">
        Cancelled
      </span>
    )
  };

  const handleDiscount = (data) => {
    setSelected({
      id: data?.inventoryId,
      quantity: data?.quantity,
      productName: data?.product?.nameEn,
      sku: data?.product?.sku,
      discount: data?.discount,
      price: data?.price
    });
    setIsDiscountOpen(true);
  };

  const updateSingleProductDiscount = (data) => {
    const payload = {
      orderId,
      inventoryId: data?.id,
      unitDiscount: parseFloat(data?.discount)
    };
    callUpdateSingleProductDiscountMutation(payload);
  };

  const applySalesDiscount = (amount) => {
    callApplySalesDiscountMutation({
      orderId,
      amount: parseFloat(amount)
    });
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.product?.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.product?.nameEn}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex justify-center items-center">
            <div>{row?.quantity}</div>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'QUANTITY',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex justify-center items-center">
            <div>{row.subTotal}</div>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SUB-TOTAL',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.totalDiscount}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'TOTAL DISCOUNT',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex justify-center items-center">
            <div>{row.totalPrice}</div>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'FINAL PRICE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
  ];

  const paymentColumns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{dayjs(row.paymentDate).format('DD MMM YY')}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'CREATE DATE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{dayjs(row.paymentDate).format('hh:mm A')}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'CREATE TIME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.paymentMethod?.toUpperCase()}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'PAYMENT METHOD',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.amount}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'AMOUNT',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.receivedBy?.name}</div>
          <div>{row.receivedBy?.email}</div>
          <div>{row.receivedBy?.phone}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'RECEIVED BY',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];

  const orderDetailsCard = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.id}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ORDER ID',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{delivery_statuses[row?.orderStatus.toUpperCase() ?? 'N/A']}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ORDER STATUS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.deliveryRemarks ?? 'N/A'}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'DELIVERY REMARKS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.deliveryCancelReason ?? 'N/A'}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'DELIVERY CANCEL REASON',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];

  useEffect(() => {
    if (updateSingleProductDiscountSuccess) {
      setIsDiscountOpen(false);
      setSelected(null);
    }
  }, [updateSingleProductDiscountSuccess]);

  useEffect(() => {
    applySalesDiscountMutationSuccess && setIsSalesDiscountOpen(false);
  }, [applySalesDiscountMutationSuccess]);

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <div className="mt-4 sm:ml-0 sm:mt-0 sm:flex-none md:flex-none mb-5">
      <Table
          data={[currentData?.data]}
          columns={orderDetailsCard}
        />
      </div>
      <div className="mt-4 sm:ml-0 sm:mt-0 sm:flex-none md:flex-none">
        <CustomerSummary
          title={'Customer Summary'}
          customer={{
            ...currentData?.data?.customer,
            address: currentData?.data?.address,
            deliveryAssociate: currentData?.data?.deliveryAssociate,
            routeName: currentData?.data?.route?.name
          }}
          readOnly
        />
      </div>
      <div className="bg-gray-200">
        <div className="flex justify-between items-center pt-2">
          <div className="text-gray-700 text-lg font-bold px-4">
            Product Summary
          </div>
         {
          role !== 'WAREHOUSE_ASSOCIATE' && 
            <div>
              <button
                type="button"
                className="mr-4 inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
                onClick={() => setIsSalesDiscountOpen(true)}
              >
                Apply Sales Discount
              </button>
            </div>
          }
        </div>
        <Table data={currentData?.data?.items ?? []} columns={columns} />
      </div>
      <InvoiceSummary
        title={'Invoice Summary'}
        invoice={currentData?.data?.invoice}
      />
      <div className="bg-gray-200">
        <div className="flex justify-between items-center pt-2">
          <div className="text-gray-700 text-lg font-bold px-4">
            Transaction History
          </div>
        </div>
        <Table
          data={currentData?.data?.payments ?? []}
          columns={paymentColumns}
        />
      </div>
      <ApplyDiscount
        open={isDiscountOpen}
        setOpen={setIsDiscountOpen}
        id={selected?.id}
        quantity={selected?.quantity}
        updateSingleProductDiscount={updateSingleProductDiscount}
        productName={selected?.productName}
        sku={selected?.sku}
        price={selected?.price}
        discount={selected?.discount}
      />
      <SalesDiscount
        open={isSalesDiscountOpen}
        setOpen={setIsSalesDiscountOpen}
        discount={currentData?.data?.invoice?.salesDiscount}
        applySalesDiscount={applySalesDiscount}
      />
    </div>
  );
}

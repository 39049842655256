import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { Fragment, useEffect, useState } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import { useUpdateCartMutation } from '../../../store/features/cart/cartApi';
import { useGetInventoryQuery } from '../../../store/features/inventory/inventoryApi';
import { useUpdateSingleOrderMutation } from '../../../store/features/order/orderApi';
import Table from '../DataTable/Table';
import DebouncedSearchTab from '../DebouncedSearchTab';
import InputFieldWithPlusMinus from '../InputFieldWithPlusMinus';
import TablePagination from '../TablePagination';

const searchModes = [
  {
    param_name: 'Product',
    param: 'nameEn'
  },
  {
    param_name: 'SKU',
    param: 'sku'
  }
];

export default function AddProductsModal({
  open,
  setOpen,
  title = '',
  customerId,
  orderId,
  skuIds = [],
  fromOrder = false
}) {
  const [page, setPage] = useState(1);
  const [searchMode, setSearchMode] = useState('nameEn');
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState([]);

  const { currentData, isLoading } = useGetInventoryQuery(
    searchValue
      ? {
          page,
          [searchMode]: searchValue ?? undefined
        }
      : {
          page
        }
  );

  const [callUpdateCartMutation, { isLoading: isUpdateCartLoading }] =
    useUpdateCartMutation();

  const [
    callUpdateSingleOrderMutation,
    { isLoading: isUpdateSingleOrderLoading }
  ] = useUpdateSingleOrderMutation();

  useEffect(() => {
    currentData?.data?.items &&
      setData(
        currentData?.data?.items?.map((item) => {
          let skuArr = [];
          skuIds.map((item) => skuArr.push(item.sku));
          return {
            ...item,
            isExist: skuArr.includes(item?.product?.sku),
            quantity:
              skuIds?.find((child) => child.sku === item?.product?.sku)
                ?.quantity ?? 1
          };
        })
      );
  }, [currentData, skuIds]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const updateOrderProduct = (inventoryId) => {
    const dataItem = data?.find((item) => item.id === inventoryId);
    const payload = {
      orderId,
      inventoryId: dataItem?.id,
      quantity: parseInt(dataItem?.quantity, 10) || 1
    };
    callUpdateSingleOrderMutation(payload);
  };

  const updateCartProduct = (id) => {
    const dataItem = data?.find((item) => item.id === id);
    const payload = {
      customerId,
      inventoryId: dataItem?.id,
      quantity: parseInt(dataItem?.quantity, 10) || 1
    };
    callUpdateCartMutation(payload);
  };

  const handleProductAdd = (id) => {
    if (fromOrder) {
      updateOrderProduct(id);
    } else {
      updateCartProduct(id);
    }
  };

  const onChangeInputField = (id, qty) => {
    setData((data) =>
      data?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity: qty
          };
        }
        return item;
      })
    );
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.product?.nameEn}</div>
          <div className="font-bold">{row.product?.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME & SKU'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>Price: {row?.unitPrice}</div>
          <div>Stock: {row?.stock}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'PRICE & STOCK'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <InputFieldWithPlusMinus
              id={row.id}
              type={'number'}
              onChange={onChangeInputField}
              value={row.quantity || 1}
            />
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'QUANTITY'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <button
            className="text-red-600 hover:underline disabled:text-gray-500"
            onClick={() => handleProductAdd(row.id ?? row.inventoryId)}
            disabled={isUpdateCartLoading || isUpdateSingleOrderLoading}
          >
            {fromOrder
              ? `${row?.isExist ? 'Update Order' : 'Add to Order'}`
              : `${row?.isExist ? 'Update Cart' : 'Add to Cart'}`}
          </button>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION'
      }
    )
  ];
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[99999]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex relative justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="mt-3 w-[60vw] text-center sm:mt-0 sm:text-left">
              <div className="mt-2 relative">
                <div className="absolute inset-x-0 rounded-lg my-10 p-6 z-[99999999999] bg-white justify-center border shadow-sm">
                  <div className="pr-4 pt-4 sm:block justify-between mb-2">
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <span className="font-bold left-8 absolute text-gray-700">
                        Add {title}
                      </span>
                      <div className="flex justify-end">
                        <DebouncedSearchTab
                          modes={searchModes}
                          handleSearch={handleSearch}
                          setValue={setSearchValue}
                          setMode={setSearchMode}
                          value={searchValue}
                          isLoading={isLoading}
                          data={currentData}
                        />
                        <button
                          className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          <XMarkIcon className="h-8 w-8" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <ul className="divide-y divide-gray-200 max-h-full overflow-y-scroll scroll- overflow-hidden scrollbar-none">
                    {data?.length > 0 ? (
                      <>
                        <Table data={data ?? []} columns={columns} />
                        <TablePagination
                          paginationData={currentData?.data?.meta}
                          page={page}
                          setPage={setPage}
                        />
                      </>
                    ) : (
                      <LoaderIcon />
                    )}
                  </ul>
                  {/* <div className="flex justify-end">
                    <button
                      type="button"
                      className="inline-flex items-center mt-4 px-6 rounded-md bg-red-600 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
                      onClick={setOpen}
                      disabled={
                        isUpdateCartLoading || isUpdateSingleOrderLoading
                      }
                    >
                      {(isUpdateCartLoading || isUpdateSingleOrderLoading) && (
                        <div className="mr-4">
                          <LoaderIcon />
                        </div>
                      )}
                      Done & Close
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import toast from 'react-hot-toast';
import {
  appendParamsToUrl,
  handleErrorMessage,
} from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const customerApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomer: builder.query({
      query: ({ ...query }) => ({
        url: `/api/users/customers?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getCustomer']
    }),
    createCustomer: builder.mutation({
      query: (body) => ({
        url: '/api/users/customers',
        method: 'POST',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Customer has been created!');
        } catch (error) {
          handleErrorMessage(error);
        }
      },
      invalidatesTags: ['getCustomer', 'getUser']
    }),
    getOneCustomer: builder.query({
      query: ({ customerId }) => ({
        url: `/api/users/customers/${customerId}`,
        method: 'GET'
      }),
      providesTags: ['getOneCustomerWithId']
    }),
    updateCustomer: builder.mutation({
      query: ({ customerId, ...query }) => ({
        url: `/api/users/customers/${customerId}`,
        method: 'PUT',
        body: { ...query }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Customer has been updated!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getUser',
        'getCustomer',
        'getOneUserWithId, getOneCustomerWithId'
      ]
    }),
    getCustomerRegistraionRequest: builder.query({
      query: ({ ...query }) => ({
        url:
          `/api/users/customer-reg-requests?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getCustomerRegRequest']
    }),
    createCustomerRegistraionRequest: builder.mutation({
      query: (body) => ({
        url: '/api/users/customer-reg-requests',
        method: 'POST',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Customer registration request created successfully!');
        } catch (error) {
          toast.error(
            error?.error?.data?.message ||
              'Failed to process customer registration requests'
          );
        }
      },
      invalidatesTags: ['getCustomerRegRequest']
    }),
    getSingleCustomerRegistraionRequest: builder.query({
      query: (id) => ({
        url: `/api/users/customer-reg-requests/${id}`,
        method: 'GET'
      }),
      providesTags: ['getSingleCustomerRegRequest']
    }),
    updateCustomerRegistraionRequest: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/api/users/customer-reg-requests/${id}`,
        method: 'PATCH',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Customer registration request updated successfully!');
        } catch (error) {
          toast.error(
            error?.error?.data?.message ||
              'Failed to update customer registration request'
          );
        }
      },
      invalidatesTags: ['getSingleCustomerRegRequest', 'getCustomerRegRequest']
    }),
    deleteSingleCustomerRegistraionRequest: builder.mutation({
      query: (id) => ({
        url: `/api/users/customer-reg-requests/${id}`,
        method: 'DELETE'
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Customer registration request deleted successfully!');
        } catch (error) {
          handleErrorMessage(error);
        }
      },
      invalidatesTags: ['getCustomerRegRequest']
    }),
    approveSingleCustomerRegistrationApprove: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/api/users/customer-reg-requests/${id}/approve`,
        method: 'POST',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Customer registration request approved successfully!');
        } catch (error) {
          toast.error(error?.error?.data?.message ?? 'Failed to approve!');
        }
      },
      invalidatesTags: ['getCustomerRegRequest']
    })
  })
});

export const {
  useGetCustomerQuery,
  useCreateCustomerMutation,
  useGetOneCustomerQuery,
  useUpdateCustomerMutation,
  useGetCustomerRegistraionRequestQuery,
  useCreateCustomerRegistraionRequestMutation,
  useGetSingleCustomerRegistraionRequestQuery,
  useUpdateCustomerRegistraionRequestMutation,
  useDeleteSingleCustomerRegistraionRequestMutation,
  useApproveSingleCustomerRegistrationApproveMutation
} = customerApi;

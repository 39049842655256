import { PhoneIcon } from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Table from '../../../components/common/DataTable/Table';
import DebouncedSearchTab from '../../../components/common/DebouncedSearchTab';
import TablePagination from '../../../components/common/TablePagination';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import useQuery from '../../../hooks/useQuery';
import { useGetUserQuery } from '../../../store/features/users/usersApi';

const pageMeta = {
  page_name: 'Add Warehouse User'
};

const delivery_statuses = {
  ACTIVE: (
    <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
      Active
    </span>
  ),
  PENDING: (
    <span className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
      Pending
    </span>
  ),
  INACTIVE: (
    <span className="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
      Inactive
    </span>
  )
};

const searchModes = [
  {
    param_name: 'Name',
    param: 'name'
  },
  {
    param_name: 'Email',
    param: 'email'
  },
  {
    param_name: 'Phone',
    param: 'phone'
  }
];

export default function AddSingleWarehouseUser() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchMode, setSearchMode] = useState('name');
  const [searchValue, setSearchValue] = useState('');

  const { currentData } = useGetUserQuery(
    searchValue
      ? {
          page,
          [searchMode]: searchValue ?? undefined,
          includeInActiveManagers: true
        }
      : {
          page,
          includeInActiveManagers: true
        }
  );

  const warehouseId = useQuery().get('warehouseId');
  const pages = [
    {
      name: 'Warehouse',
      location: '/app/warehouse',
      current: false
    },
    {
      name: 'Warehouse users',
      location: `/app/warehouse/users?warehouseId=${warehouseId}`,
      current: false
    },
    {
      name: 'Add',
      location: `/app/warehouse/users/add?warehouseId=${warehouseId}`,
      current: true
    }
  ];

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.name}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.email}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'EMAIL'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex items-center gap-1">
            <span>{row.phone}</span>
            {row.phone && (
              <a className="flex items-center gap-1" href={`tel:${row.phone}`}>
                <span className="text-red-600">
                  <PhoneIcon width={16} />
                </span>
                <span className="text-red-600 font-medium">Call</span>
              </a>
            )}
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'PHONE'
      }
    ),
    columnHelper.accessor(
      (row) => <Fragment>{delivery_statuses[row.accountStatus]}</Fragment>,
      {
        cell: (info) => info.getValue(),
        header: 'ACCOUNT STATUS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex items-center gap-1">
            <span>{row.role}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ROLE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <button className="text-red-600 hover:underline">Add</button>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION'
      }
    )
  ];

  return (
    <div className="sm:px-4">
      <div className="flex justify-between">
        <TopNavigationBar
          backLocation={`/app/warehouse/users?warehouseId=${warehouseId}`}
          pageName={pageMeta.page_name}
          pages={pages}
        />
        <DebouncedSearchTab
          modes={searchModes}
          handleSearch={handleSearch}
          setValue={setSearchValue}
          setMode={setSearchMode}
          value={searchValue}
          isLoading={false}
          data={currentData}
        />
      </div>
      <Table data={currentData?.data?.users ?? []} columns={columns} />
      <TablePagination
        paginationData={currentData?.data?.meta ?? undefined}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

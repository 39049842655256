import React from 'react';
import { useForm } from 'react-hook-form';
import useQuery from '../../../hooks/useQuery';
import { useDeliveryManagerSetPasswordMutation } from '../../../store/features/auth/authApi';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export default function SetPasswordForm() {
  const { register, handleSubmit } = useForm();
  const [setPassword, { isLoading: setPasswordLoading }] =
    useDeliveryManagerSetPasswordMutation();
  const query = useQuery();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    const { password, confirmPassword } = data;
    if (password === confirmPassword) {
      const token = query.get('token');

      if (token) {
        setPassword({ token, newPassword: password });
        navigate('/auth/sign-in', { replace: true });
      } else {
        toast.error('Token is missing');
      }
    } else {
      toast.error('Both passwords do not match');
    }
  };

  return (
    <div className="mt-10">
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          action="#"
          method="POST"
          className="space-y-6"
        >
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Password
            </label>
            <div className="mt-2">
              <input
                id="password"
                type="password"
                autoComplete="password"
                {...register('password', { required: true })}
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="confirm-password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Confirm Password
            </label>
            <div className="mt-2">
              <input
                id="confirm-password"
                type="password"
                autoComplete="confirm-password"
                {...register('confirmPassword', { required: true })}
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              value="submit"
              disabled={setPasswordLoading}
              className="flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            >
              Set password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

import toast from 'react-hot-toast';
import { chamakApi } from '../../app/api';

export const mfaApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    updateMfa: builder.mutation({
      query: ({ ...query }) => ({
        url: '/api/profile/mfa',
        method: 'PATCH',
        body: { ...query }
      }),
      onQueryStarted: async (args, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          toast.success('MFA has been updated successfully!');
        } catch (error) {
          // toast.error(error.error.data.message || 'Failed to update MFA. Please try again later.');
        }
      },
      invalidatesTags: ['getProfile']
    })
  })
});

export const { useUpdateMfaMutation } = mfaApi;

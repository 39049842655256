import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '../../common/Form';
import { productManagementSchema, productSchemaLabel } from './ProductManagementSchema';
import { useCreateProductManagementMutation } from '../../../store/features/product-management/productManagementApi';
import { useGetCategoryQuery } from '../../../store/features/category/categoryApi';
import DropdownSelect from '../../common/DropdownSelect';
import CheckboxWithLabel from '../../common/CheckboxWithLabel';
import Divider from '../../common/Divider';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
  DocumentArrowDownIcon,
  DocumentDuplicateIcon,
  RectangleStackIcon,
  TrashIcon
} from '@heroicons/react/20/solid';
import { useFileUploadMutation } from '../../../store/features/media/mediaApi';
import { useGetBrandsQuery } from '../../../store/features/brand/brandApi';

const size = 1024 * 100;

const returnableData = [
  {
    id: 1,
    name: 'NO'
  },
  {
    id: 2,
    name: 'YES'
  }
];

const unitTypeData = [
  {
    id: 1,
    name: 'Kg'
  },
  {
    id: 2,
    name: 'Gm'
  },
  {
    id: 3,
    name: 'Liter'
  },
  {
    id: 4,
    name: 'Pcs'
  },
  {
    id: 5,
    name: 'Box'
  },
  {
    id: 6,
    name: 'Bosta'
  },
  {
    id: 7,
    name: 'Bottle'
  },
  {
    id: 8,
    name: 'Bag'
  },
  {
    id: 9,
    name: 'Ml'
  },
  {
    id: 10,
    name: 'Packet'
  }
];

export default function CreateProductManagementForm() {
  const methods = useForm({
    resolver: zodResolver(productManagementSchema),
    mode: 'onChange',
    defaultValues: {
      returnInDays: '0'
    }
  });

  const { currentData: categoryDataFromApi } = useGetCategoryQuery();
  const { currentData: brandDataFromApi } = useGetBrandsQuery({});

  const [
    callCreateProductManagementMutation,
    {
      isSuccess: isCreateProductManagementMutationSuccess,
      isLoading: createProductManagementMutationLoading
    }
  ] = useCreateProductManagementMutation();

  const [callFileUploadMutation, { isLoading: fileUploadMutationLoading }] =
    useFileUploadMutation();

  const [tabIndex, setTabIndex] = useState(0);

  const [selectedReturnable, setSelectedReturnable] = useState({
    id: 1,
    name: 'NO'
  });
  const [selectedUnitType, setSelectedUnitType] = useState({
    id: 1,
    name: 'KG'
  });
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({ id: 0, name: 'Select One*' });

  const navigate = useNavigate();

  useEffect(() => {
    isCreateProductManagementMutationSuccess &&
      navigate('/app/product-management');
  }, [isCreateProductManagementMutationSuccess]);

  const onSubmit = (data) => {
    const payload = {
      ...data,
      isReturnable: selectedReturnable?.name === 'YES',
      returnInDays: data?.returnInDays ? parseInt(data?.returnInDays, 10) : 0,
      categoryIds: selectedCategoryIds,
      brandId: selectedBrand?.id
    };
    callCreateProductManagementMutation(payload);
  };

  const handleFileUpload = async (event, onChange) => {
    const files = event.target.files;
    if (Object.values(files)[0]?.size <= size) {
      const res = await callFileUploadMutation({
        featureName: 'products',
        fileName: '',
        ...files
      });
      res?.data?.data?.url && onChange(res?.data?.data?.url);
    } else {
      toast.error('Max size limit can be ' + size / 1024 + ' kb');
    }
  };

  const handleCategorySelect = (id) => {
    selectedCategoryIds.includes(id)
      ? setSelectedCategoryIds(
          selectedCategoryIds?.filter((item) => item !== id)
        )
      : setSelectedCategoryIds((data) => [...data, id]);
  };

  useEffect(() => {
    if (selectedReturnable?.name === 'YES') {
      methods.setValue('isReturnable', true, { shouldValidate: true });
      methods.setValue('returnInDays', '3', { shouldValidate: true });
    } else {
      methods.setValue('isReturnable', false, { shouldValidate: true });
      methods.setValue('returnInDays', '0', { shouldValidate: true });
    }
  }, [selectedReturnable]);

  useEffect(() => {
    methods.setValue('unitType', selectedUnitType?.name, {
      shouldValidate: true
    });
  }, [selectedUnitType]);

  const renderFormField = (
    control,
    name,
    type,
    placeholder,
    label,
    isSelect = false,
    options,
    selected,
    setSelected,
    disabled = false
  ) => {
    return (
      <div className="sm:col-span-3">
        <FormField
          name={name}
          control={control}
          render={({ field }) => (
            <FormItem>
              <label
                htmlFor={name}
                className={'block text-sm font-medium leading-6 text-gray-900'}
              >
                {label}
              </label>
              <FormControl>
                <div className="mt-2">
                  {type === 'file' ? (
                    <>
                      <input
                        {...field}
                        id={name}
                        type="file"
                        accept="image/*"
                        value={field?.value?.fileName}
                        disabled={fileUploadMutationLoading}
                        onChange={(event) =>
                          handleFileUpload(event, field?.onChange)
                        }
                        className="hidden"
                      />
                      {field?.value ? (
                        <div className="flex h-16">
                          <img
                            {...field}
                            id={name}
                            src={field.value}
                            alt=""
                            width="60"
                            height="60"
                          />
                          {field.value && (
                            <button
                              htmlFor={name}
                              className="inline-flex items-center ml-10 gap-x-1.5 mr-4 rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all cursor-pointer"
                              onClick={() => {
                                methods.setValue(name, '', {
                                  shouldValidate: true
                                });
                              }}
                            >
                              <TrashIcon className="w-6 h-6" />
                              Delete Image
                            </button>
                          )}
                        </div>
                      ) : (
                        <div className="flex items-center h-16">
                          <RectangleStackIcon
                            className="w-16 h-16"
                            color="gray"
                          />
                          <div className="h-16">
                            <label
                              htmlFor={name}
                              className="ml-10 inline-flex h-full items-center gap-x-1.5 mr-4 rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all cursor-pointer"
                            >
                              <DocumentDuplicateIcon
                                className="-ml-0.5 h-5 w-5"
                                aria-hidden="true"
                              />
                              {placeholder}
                            </label>
                          </div>
                        </div>
                      )}
                    </>
                  ) : isSelect ? (
                    <DropdownSelect
                      data={options}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  ) : (
                    <input
                      {...field}
                      type={type}
                      id={name}
                      placeholder={placeholder}
                      disabled={disabled}
                      className={clsx(
                        `block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 ${
                          disabled && 'bg-gray-200'
                        } shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6`
                      )}
                    />
                  )}
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    );
  };

  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={(index) => {
        if (
          createProductManagementMutationLoading ||
          fileUploadMutationLoading
        ) {
        } else {
          setTabIndex(index);
        }
      }}
    >
      <Form {...methods}>
        <form
          action='#'
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        >
          <TabList className="flex">
            <Tab
              className={clsx(
                'p-3 rounded-sm cursor-pointer mb-2 block',
                tabIndex === 0 ? 'text-red-600' : 'bg-gray-200 text-gray-900'
              )}
            >
              Details
            </Tab>
            <Tab
              className={clsx(
                'p-3 rounded-sm cursor-pointer mb-2 block',
                tabIndex === 1 ? 'text-red-600' : 'bg-gray-200 text-gray-900'
              )}
            >
              Image
            </Tab>
            <Tab
              className={clsx(
                'p-3 rounded-sm cursor-pointer mb-2 block',
                tabIndex === 2 ? 'text-red-600' : 'bg-gray-200 text-gray-900'
              )}
            >
              Categories
            </Tab>
          </TabList>

          <TabPanel>
            <div className="px-4 py-6 sm:p-8">
              <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {/* Product Name*(In Bangla) */}
                {renderFormField(
                  methods.control,
                  'nameBn',
                  'text',
                  'Enter Product name(Bangla)',
                  'Product Name*(In Bangla)'
                )}
                {/* Product Name*(In English) */}
                {renderFormField(
                  methods.control,
                  'nameEn',
                  'text',
                  'Enter Product name(English)',
                  'Product Name*(In English)'
                )}
                {/* Product Short Name (In Bangla* */}
                {renderFormField(
                  methods.control,
                  'shortNameBn',
                  'text',
                  'Enter short name',
                  'Product Short Name*(In Bangla)'
                )}
                {/* Product Short Name (In English* */}
                {renderFormField(
                  methods.control,
                  'shortNameEn',
                  'text',
                  'Enter short name',
                  'Product Short Name*(In English)'
                )}
                {/* Short Description (In Bangla) */}
                {renderFormField(
                  methods.control,
                  'shortDescriptionBn',
                  'text',
                  'Enter Description',
                  'Short Description (In Bangla)'
                )}
                {/* Short Description (In English) */}
                {renderFormField(
                  methods.control,
                  'shortDescriptionEn',
                  'text',
                  'Enter Description',
                  'Short Description (In English)'
                )}
                {/* Product Refundable?* */}
                {renderFormField(
                  methods.control,
                  'isReturnable',
                  'select',
                  '',
                  'Product Refundable?*',
                  true,
                  returnableData,
                  selectedReturnable,
                  setSelectedReturnable
                )}
                {/* Refund Days within (from sell)* */}
                {renderFormField(
                  methods.control,
                  'returnInDays',
                  'number',
                  'Enter days',
                  'Refund Days within (from sell)*',
                  false,
                  [],
                  false,
                  false,
                  selectedReturnable?.name === 'NO'
                )}
                {/* Brand/Company */}
                {renderFormField(
                  methods.control,
                  'brand',
                  'text',
                  '',
                  'Brand/Company*',
                  true,
                  brandDataFromApi?.data?.items??[],
                  selectedBrand,
                  setSelectedBrand
                )}
                {/* Vendor/Supplier Name */}
                {renderFormField(
                  methods.control,
                  'supplier',
                  'text',
                  'Enter Vendor/Supplier',
                  'Vendor/Supplier Name*'
                )}
                {/* Unit Quantity */}
                {renderFormField(
                  methods.control,
                  'unitQuantity',
                  'number',
                  'Enter Unit Quantity',
                  'Unit Quantity*'
                )}
                {/* Unit Type */}
                {renderFormField(
                  methods.control,
                  'unitType',
                  'number',
                  '',
                  'Unit Type*',
                  true,
                  unitTypeData,
                  selectedUnitType,
                  setSelectedUnitType
                )}
                {/* SKU Number* */}
                {renderFormField(
                  methods.control,
                  'sku',
                  'text',
                  'Enter SKU Number',
                  'SKU Number*'
                )}
                {/* Weight */}
                {renderFormField(
                  methods.control,
                  'unitWeight',
                  'number',
                  'Enter Weight',
                  'Weight (in KG)*'
                )}
              </div>
            </div>

            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                type="button"
                onClick={() => navigate('/app/product-management/view')}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Cancel
              </button>

              <button
                type="button"
                className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
                onClick={() => setTabIndex(1)}
              >
                Next
              </button>
            </div>
          </TabPanel>
          <TabPanel className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 m-4 p-4">
              {/*  Product Image(max size: 100kb) */}
              {renderFormField(
                methods.control,
                'photo1',
                'file',
                'Choose Image',
                'Product Image(max size: 100kb)'
              )}
              {/*  Product Image 2(max size: 100kb) */}
              {renderFormField(
                methods.control,
                'photo2',
                'file',
                'Choose Image',
                'Product Image 2(max size: 100kb) (Optional)'
              )}
            </div>
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                type="button"
                onClick={() => setTabIndex(0)}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Back
              </button>
              <button
                type="button"
                className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
                onClick={() => setTabIndex(2)}
                disabled={fileUploadMutationLoading}
              >
                Next
              </button>
            </div>
          </TabPanel>
          <TabPanel className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
            <CheckboxWithLabel
              label="Category/SubCategory"
              data={categoryDataFromApi?.data ?? []}
              selectedIds={selectedCategoryIds}
              onPress={handleCategorySelect}
            />
            <Divider className="my-2" />
            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
              <button
                type="button"
                onClick={() => setTabIndex(1)}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Back
              </button>
              <button
                type="submit"
                onClick={async (ev)=>{
                  ev.preventDefault();
                  const errors = []

                  const result = await methods.trigger();
                  if(!result){
                    const errorKeys = Object.keys(methods.formState.errors)
                    for(const eKey of errorKeys){
                      errors.push(`${productSchemaLabel[eKey] ?? eKey} - ${methods.formState.errors[eKey].message}`)
                    }
                  }

                  if(selectedCategoryIds.length === 0){
                    errors.push(`Categories - Required`);
                  }

                  if(!selectedBrand.id ){
                    errors.push(`Brand - Required`);
                  }

                  if(errors.length>0){
                    toast.error(errors.join('\n'));
                  }

                  else {
                    methods.handleSubmit(onSubmit)()
                  }
                }}
                className="rounded-md bg-red-600 px-3 py-2 my-4 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
              >
                Create
              </button>
            </div>
          </TabPanel>
        </form>
      </Form>
    </Tabs>
  );
}

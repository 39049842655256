import React from 'react';

export default function InputTopBar({ title, subtitle, withBrandLogo }) {
  return (
    <div>
      {withBrandLogo && (
        <img className="w-auto h-32" src="/chamak.png" alt="Chamak" />
      )}
      <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
        {title}
      </h2>
      <p className="mt-2 text-sm leading-6 text-gray-500">{subtitle}</p>
    </div>
  );
}

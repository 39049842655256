import toast from 'react-hot-toast';
import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const configApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getConfig: builder.query({
      query: ({ ...query }) => ({
        url: `/api/warehouses/my-warehouse?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getConfig']
    }),
    updateConfig: builder.mutation({
      query: ({ ...config }) => ({
        url: `/api/warehouses/my-warehouse`,
        method: 'PATCH',
        body: config 
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Configuration has been updated!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getConfig'
      ]
    })
  })
});

export const {
  useGetConfigQuery,
  useUpdateConfigMutation,
} = configApi;

import React from 'react';

export default function CheckboxWithLabel({
  className = '',
  data,
  selectedIds,
  onPress,
  noLabel,
  isChecked,
  disabled
}) {
  return noLabel ? (
    <input
      type="checkbox"
      defaultChecked={isChecked}
      className={
        className +
        'cursor-pointer h-5 w-5 rounded border-gray-300 text-red-600 focus:ring-red-600 mr-4'
      }
      onClick={onPress}
      disabled={disabled}
    />
  ) : (
    <div className="px-4 py-6 sm:p-8">
      {React.Children.toArray(
        data?.map((item) => {
          return (
            <div className="my-4">
              <div className="my-2">
                <input
                  type="checkbox"
                  defaultChecked={selectedIds?.includes(item.id)}
                  className={
                    className +
                    'cursor-pointer h-6 w-6 rounded border-gray-300 text-red-600 focus:ring-red-600 mr-4'
                  }
                  onClick={() => onPress(item.id)}
                />
                <span>{item.nameEn}</span>
              </div>
              {React.Children.toArray(
                item?.children?.map((child) => {
                  return (
                    <div className="pl-8 py-2">
                      <input
                        type="checkbox"
                        defaultChecked={selectedIds?.includes(child.id)}
                        className={
                          className +
                          'cursor-pointer h-6 w-6 rounded border-gray-300 text-red-600 focus:ring-red-600 mr-4'
                        }
                        onClick={() => onPress(child.id)}
                      />
                      <span>{child.nameEn}</span>
                    </div>
                  );
                })
              )}
            </div>
          );
        })
      )}
    </div>
  );
}

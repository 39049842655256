import {
  CheckIcon,
  PlusIcon,
  RectangleStackIcon
} from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ApplyFilter from '../../../components/common/ApplyFilter';
import Table from '../../../components/common/DataTable/Table';
import TablePagination from '../../../components/common/TablePagination';
import {
  useAddInventorySingleStockRequestMutation,
  useDeleteSingleInventoryMutation,
  useGetInventoryQuery
} from '../../../store/features/inventory/inventoryApi';
import ConfirmationModal from '../../../components/common/DataTable/ConfirmationModal';
import { getQueryParams, mapFilterToUrlParam } from '../../../lib/utils';
import ModifyStock from '../../../components/common/ModifyStock';

const rootMeta = {
  page_name: 'Products',
  breadcrumbs: null
};

const searchModes = [
  {
    param_name: 'Name',
    param: 'nameEn',
    type: 'text'
  },
  {
    param_name: 'SKU',
    param: 'sku',
    type: 'text'
  },
  {
    param_name: 'Brand',
    param: 'brand',
    type: 'text'
  },
  {
    param_name: 'Supplier',
    param: 'supplier',
    type: 'text'
  },
  {
    param_name: 'Is Published',
    param: 'isPublished',
    type: 'text'
  },
  {
    param_name: 'Category Ids',
    param: 'categoryIds',
    type: 'text'
  },
  {
    param_name: 'Is Returnable',
    param: 'isReturnable',
    type: 'text'
  }
];

export default function ProductsRoot() {
  const location = useLocation();
  const queryParamsString = location.search;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isModifyStockOpen, setIsModifyStockOpen] = useState(false);
  const [productToRemove, setProductToRemove] = useState(null);
  const [selected, setSelected] = useState(null);

  const { currentData, isLoading } = useGetInventoryQuery(
    !isEmpty(selectedData)
      ? {
          page,
          ...selectedData
        }
      : {
          page
        }
  );

  const [
    callDeleteSingleInventoryMutation,
    {
      isLoading: isDeleteSingleInventoryMutationLoading,
      isSuccess: isDeleteSingleInventoryMutationSuccess
    }
  ] = useDeleteSingleInventoryMutation();

  const [
    callAddInventorySingleStockRequestMutation,
    {
      isLoading: isAddInventorySingleStockRequestMutationLoading,
      isSuccess: isAddInventorySingleStockRequestMutationSuccess
    }
  ] = useAddInventorySingleStockRequestMutation();

  const truncateText = (text, length) => {
    if (!text) return '';
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  const columnHelper = createColumnHelper();

  const columns = [
    // columnHelper.accessor(
    //   (row) => (
    //     <Fragment>
    //       <div className="flex gap-x-2">
    //         {row?.product?.photo1 ? (
    //           <img src={row?.product?.photo1} alt="" width="30" height="30" />
    //         ) : (
    //           <RectangleStackIcon className="w-10 h-10" color="gray" />
    //         )}
    //         {row?.product?.photo2 && (
    //           <img src={row?.product?.photo2} alt="" width="30" height="30" />
    //         )}
    //       </div>
    //     </Fragment>
    //   ),
    //   {
    //     cell: (info) => info.getValue(),
    //     header: 'PHOTO'
    //   }
    // ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.product?.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU ID'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div title={row?.product?.nameEn}>
            {truncateText(row?.product?.nameEn, 40)}
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'PRODUCT NAME'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row.unitPrice}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'UNIT PRICE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row.memberUnitPrice}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'MEMBER PRICE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          {row.isFreeDelivery ? (
            <CheckIcon className="w-6 h-6" color="green" />
          ) : (
            <span>N/A</span>
          )}
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'FREE DELIVERY'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row.stock}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'STOCK'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row.discountType}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'INCENTIVE TYPE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row.discountValue}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'INCENTIVE VALUE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row.commissionType}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'COMMISSION TYPE'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            <span>{row.commissionValue}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'COMMISSION VALUE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          
          <Link to={`/app/products/details?inventoryId=${row.id}`}>
            <button className="text-red-600 hover:underline mr-3">
              Details
            </button>
          </Link>

          <button
            className="text-red-600 hover:underline pr-4"
            onClick={() => {
              setSelected(row);
              setIsModifyStockOpen(true);
            }}
          >
            Edit Stock
          </button>

          <Link to={`/app/products/edit?inventoryId=${row.id}`}>
            <button className="text-red-600 hover:underline mr-3">Edit</button>
          </Link>

          <button
            className="text-red-600 hover:underline"
            onClick={() => {
              setProductToRemove(row.id);
              setIsConfirmationOpen(true);
            }}
          >
            Remove
          </button>

          <Link to={`/app/products/update?inventoryId=${row.id}`}>
            <button className="text-red-600 hover:underline ml-3">
              Stock Update History
            </button>
          </Link>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];

  const confirmRemoveProduct = () => {
    callDeleteSingleInventoryMutation(productToRemove);
    setProductToRemove(null);
  };

  const handleSelectedData = (data) => {
    navigate(`/app/products/view${mapFilterToUrlParam(data)}`);
    setSelectedData(data);
  };

  const updateStock = (data, stock) => {
    const payload = {
      id: data?.id,
      type:
        parseInt(data?.stock, 10) - parseInt(stock, 10) >= 0 ? 'INC' : 'DEC',
      value:
        parseInt(data?.stock, 10) - parseInt(stock, 10) >= 0
          ? parseInt(data?.stock, 10) - parseInt(stock, 10)
          : parseInt(stock, 10) - parseInt(data?.stock, 10)
    };
    callAddInventorySingleStockRequestMutation(payload);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedData]);

  useEffect(() => {
    setSelectedData(getQueryParams(queryParamsString));
  }, [queryParamsString]);

  useEffect(() => {
    isDeleteSingleInventoryMutationSuccess && setIsConfirmationOpen(false);
  }, [isDeleteSingleInventoryMutationSuccess]);

  useEffect(() => {
    isAddInventorySingleStockRequestMutationSuccess &&
      setIsModifyStockOpen(false);
  }, [isAddInventorySingleStockRequestMutationSuccess]);

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <ApplyFilter
              data={searchModes}
              selectedData={selectedData}
              handleSelectedData={handleSelectedData}
            />
            <Link
              to="/app/products/add"
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Add
            </Link>
          </div>
        </div>
      </div>
      <Table data={currentData?.data?.items ?? []} columns={columns} />
      <TablePagination
        paginationData={currentData?.data?.meta}
        page={page}
        setPage={setPage}
      />
      <ConfirmationModal
        open={isConfirmationOpen}
        setOpen={setIsConfirmationOpen}
        title="Confirm Removal"
        message="Are you sure you want to remove this product from the inventory?"
        confirmAction={confirmRemoveProduct}
        cleanup={() => setProductToRemove(null)}
        disabled={isDeleteSingleInventoryMutationLoading}
      />
      <ModifyStock
        open={isModifyStockOpen}
        setOpen={setIsModifyStockOpen}
        id={selected?.id}
        updateStock={updateStock}
        productName={selected?.product?.nameEn}
        sku={selected?.product?.sku}
        stock={selected?.stock}
      />
    </div>
  );
}

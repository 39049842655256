import toast from 'react-hot-toast';
import { chamakApi } from '../../app/api';

export const mapShopApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getMapShops: builder.query({
      query: (query) => ({
        url: `/api/shops?${query}`,
        method: 'GET'
      }),
      providesTags: ['getMapShops']
    }),
    createMapShop: builder.mutation({
      query: (body) => ({
        url: '/api/shops',
        method: 'POST',
        body
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Map shop created successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getMapShops', 'mapShopAutoComplete', 'getShopsById']
    }),
    getShopsById: builder.query({
      query: ({ idType, id }) => ({
        url: `/api/shops/${id}?idType=${idType}`,
        method: 'GET'
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      providesTags: ['getShopsById']
    }),
    updateShopById: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/api/shops/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['getMapShops', 'mapShopAutoComplete', 'getShopsById'],
      onQueryStarted: async (_, { queryFullfilled, dispatch }) => {
        try {
          await queryFullfilled;
          toast.success('Map shop updated successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      }
    }),
    mapShopAutoComplete: builder.query({
      query: ({ chamakId, page }) => ({
        url: `/api/shops?page=${page}&chamakId=${chamakId}`,
        method: 'GET'
      }),
      providesTags: ['mapShopAutoComplete']
    })
  })
});

export const {
  useGetMapShopsQuery,
  useCreateMapShopMutation,
  useLazyGetShopsByIdQuery,
  useGetShopsByIdQuery,
  useUpdateShopByIdMutation,
  useMapShopAutoCompleteQuery
} = mapShopApi;

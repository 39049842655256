import toast from 'react-hot-toast';
import {
  appendParamsToUrl,
  constructFormData,
  handleErrorMessage
} from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const productManagementApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getProductManagement: builder.query({
      query: ({ ...query }) => ({
        url: `/api/products?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getProductManagement']
    }),
    createProductManagement: builder.mutation({
      query: (body) => ({
        url: '/api/products',
        method: 'POST',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Product created successfully!');
        } catch (error) {
          handleErrorMessage(error);
        }
      },
      invalidatesTags: ['getProductManagement']
    }),
    getSingleProduct: builder.query({
      query: ({ productId }) => ({
        url: `/api/products/${productId}`,
        method: 'GET'
      }),
      providesTags: ['getSingleProduct']
    }),
    editSingleProduct: builder.mutation({
      query: ({ productId, ...body }) => ({
        url: `/api/products/${productId}`,
        method: 'PATCH',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Product updated successfully!');
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
      invalidatesTags: ['getProductManagement', 'getSingleProduct']
    }),
    createBulkProduct: builder.mutation({
      query: ({ productId, ...body }) => ({
        url: `/api/products/bulk-upload`,
        method: 'POST',
        headers: {
          // 'Content-Type': 'multipart/form-data'
        },
        body: constructFormData(body)
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Bulk Product Created successfully!');
        } catch (error) {
          toast.error(error?.error?.data?.message);
        }
      },
      invalidatesTags: ['getProductManagement']
    })
  })
});

export const {
  useGetProductManagementQuery,
  useCreateProductManagementMutation,
  useGetSingleProductQuery,
  useEditSingleProductMutation,
  useCreateBulkProductMutation
} = productManagementApi;

import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';

import { useGetOneBrandQuery, useUpdateBrandMutation } from '../../../store/features/brand/brandApi';
import MultipleRoutePickup from '../../common/MultipleRoutePickup';

export default function EditBrandForm() {
  const navigate = useNavigate();
  const query = useQuery();

  const brandId = query.get('brandId');

  const { currentData } = useGetOneBrandQuery({
    brandId
  });

  const [selectedRoutes,setSelectedRoutes] = useState([]);

  useEffect(() => {
    currentData?.data?.routes?.length > 0 &&
      setSelectedRoutes(currentData?.data?.routes);
  }, [currentData]);

  const handleRoutePickUpIds = (selectedRoutes) => {
    setSelectedRoutes(selectedRoutes);
  };

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    values: {
      name: currentData?.data?.name ?? '',
      id: currentData?.data?.id ?? '',
    }
  });

  const [
    updateBrand,
    { isLoading: updateBrandMutationLoading, isSuccess: updateBrandSuccess }
  ] = useUpdateBrandMutation();

  const onSubmit = (data) => {
    const payload = {
      brandId: data?.id,
      name: data?.name,
      routeIds: selectedRoutes?.map((route) => route?.id) ?? []
    };
    updateBrand(payload);
  };

  useEffect(() => {
    if (updateBrandSuccess) {
      navigate('/app/manage-brands/view');
    }
  }, [updateBrandSuccess]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
    >
      <div className="px-4 py-6 sm:p-8">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Brand Name*
            </label>
            <div className="mt-2">
              <input
                {...register('name', { required: 'Required' })}
                placeholder={'Enter Brand Name'}
                className={clsx(
                  'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                )}
              />
            </div>
            <div className="text-red-500">
              {errors.name && errors.name.message}
            </div>
          </div>
        </div>
        <div className="col-span-6 mt-10">
          <MultipleRoutePickup
            selected={selectedRoutes}
            handleRoutePickUpIds={handleRoutePickUpIds}
          />
        </div>
      </div>

      <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
        <button
          type="button"
          onClick={() => navigate('/app/manage-brands/view')}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={updateBrandMutationLoading}
          className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
        >
          Save
        </button>
      </div>
    </form>
  );
}

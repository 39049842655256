import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import useQuery from '../../../hooks/useQuery';
import EditProductForm from '../../../components/app/products/EditProductForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Edit Product'
};

export default function EditProduct() {
  const query = useQuery();

  const pages = [
    {
      name: 'Product',
      location: '/app/products/',
      current: false
    },
    {
      name: 'Edit',
      location: `/app/products/edit?${query.get('inventoryId')}`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <EditProductForm />
    </div>
  );
}

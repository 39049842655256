import { EnvelopeIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useUserLoginMutation } from '../../../store/features/auth/authApi';

export default function SignInForm() {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const [callUserLoginMutation, { data: userLoginMutationData, isLoading }] =
    useUserLoginMutation();

  const onSubmit = async (data) => {
    await callUserLoginMutation(data);
  };

  useEffect(() => {
    if (userLoginMutationData?.data?.nextStep === 'OTP_VERIFICATION') {
      navigate(
        `/auth/verify-with-otp?session=${userLoginMutationData?.data?.sessionId}`,
        { replace: true }
      );
    } else if (userLoginMutationData?.data?.nextStep === 'DONE') {
      navigate(`/app`, { replace: true });
    }
  }, [userLoginMutationData]);

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  return (
    <div className="mt-10">
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          action="#"
          method="POST"
          className="space-y-6"
        >
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2 relative">
              <input
                id="email"
                type="email"
                placeholder="Enter Email"
                autoComplete="email"
                {...register('email', { required: true, type: 'email' })}
                className="block w-full rounded-md border-0 py-1.5 pl-8 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
              />
              <span className="absolute left-2 top-1/2 -translate-y-1/2 w-6 cursor-pointer">
                <EnvelopeIcon className="text-gray-400 h-4 w-4" />
              </span>
            </div>
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Password
            </label>
            <div className="mt-2 relative">
              <input
                id="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                placeholder="••••••••"
                {...register('password', { required: true })}
                className="block w-full  rounded-md border-0 py-1.5 pl-8 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
              />
              <span className="absolute left-2 top-1/2 -translate-y-1/2 w-6 cursor-pointer">
                <LockClosedIcon className="text-gray-400 h-4 w-4" />
              </span>
              <span
                className="absolute right-2 top-1/2 -translate-y-1/2 w-6 cursor-pointer group"
                onClick={handleShowPassword}
              >
                {showPassword ? (
                  <EyeSlashIcon className="group-hover:text-red-500 text-gray-400 h-6 w-6" />
                ) : (
                  <EyeIcon className="group-hover:text-red-500 text-gray-400 h-6 w-6" />
                )}
              </span>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm leading-6">
              {/* <a
                href="/"
                className="font-semibold text-red-600 hover:text-red-500"
              >
                Forgot password?
              </a> */}
              <Link to={`/auth/forgot-password`}>
                <button
                  type="button"
                  className="text-red-600 hover:underline ml-3"
                >
                  Forget Password?
                </button>
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              value="submit"
              disabled={isLoading}
              className="flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:bg-gray-500 disabled:cursor-not-allowed"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import EditPickupLocationForm from '../../../components/app/pickup-location/PickupLocationDetail';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import useQuery from '../../../hooks/useQuery';
import { useGetOnePickupLocationQuery } from '../../../store/features/pickup-location/pickupLocationApi';
import { useGetOneWarehouseQuery } from '../../../store/features/warehouse/warehouseApi';

const pageMeta = {
  page_name: 'View Warehouse'
};

export default function ViewPickupLocation() {
  const query = useQuery();
  const warehouseId = query.get('warehouseId');

  const pages = [
    {
      name: 'Warehouse',
      location: '/app/warehouse/',
      current: false
    },
    {
      name: 'Detail',
      location: `/app/warehouse/detail?${warehouseId}`,
      current: true
    }
  ];

  const { currentData } = useGetOneWarehouseQuery({ warehouseId });

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <EditPickupLocationForm data={currentData?.data} />
    </div>
  );
}

import React from 'react';
import ViewProduct from '../../../components/app/product-management/ViewProduct';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import useQuery from '../../../hooks/useQuery';

const pageMeta = {
  page_name: 'View Product'
};

export default function ViewProductManagement() {
  const productId = useQuery().get('productId');
  const pages = [
    {
      name: 'Product Management',
      location: '/app/product-management/',
      current: false
    },
    {
      name: 'View',
      location: `/app/product-management/details?productId=${productId}`,
      current: true
    }
  ];
  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <ViewProduct />
    </div>
  );
}

import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useAddPaymentOrderSettlementMutation } from '../../store/features/order/orderApi';
import DropdownSelect from './DropdownSelect';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from './Form';
import ChamakModal from './Modal';

const paymentData = [
  {
    id: 0,
    name: 'Select Payment Method'
  },
  {
    id: 1,
    name: 'CASH'
  },
  {
    id: 2,
    name: 'ONLINE'
  }
];

const addPaymentSchema = z.object({
  amount: z.coerce.number().min(1, 'Amount is Required'),
  remarks: z.string().optional()
});

export default function AddPaymentModal({
  open,
  setOpen,
  orderId,
  cleanUp = null
}) {
  const [
    callAddPaymentSettlementMutation,
    {
      isLoading: addPaymentMutationLoading,
      isSuccess: addPaymentMutationSuccess
    }
  ] = useAddPaymentOrderSettlementMutation();

  const cancelButtonRef = useRef(null);

  const [selectedPayment, setSelectedPayment] = useState(paymentData[0]);
  const formMethods = useForm({
    resolver: zodResolver(addPaymentSchema),
    mode: 'onChange',
    defaultValues: {
      remarks: ''
    }
  });
  const addPayment = async (data) => {
    try {
      const res = await callAddPaymentSettlementMutation({
        orderId,
        ...data,
        paymentMethod: selectedPayment?.name?.toLowerCase()
      }).unwrap();
    } catch (error) {
      formMethods.setValue('amount', 0);
    }
  };

  useEffect(() => {
    addPaymentMutationSuccess && setOpen(false);
  }, [addPaymentMutationSuccess]);

  useEffect(() => {
    if (open) {
      formMethods.getValues('amount') && formMethods.setValue('amount', 0);
      formMethods.getValues('remarks') && formMethods.setValue('remarks', '');
      selectedPayment?.id && setSelectedPayment(paymentData[0]);
    }
  }, [open]);
  return (
    <ChamakModal
      open={open}
      setOpen={setOpen}
      title={'Add Payment'}
      cleanUp={cleanUp}
    >
      <div className="w-[650px] max-h-[80vh] overflow-scroll scrollbar-none">
        <Form {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(addPayment)}
            className="mt-3"
          >
            <FormField
              name="paymentMethod"
              control={formMethods.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold">
                    Payment Method*
                  </FormLabel>
                  <FormControl>
                    <DropdownSelect
                      data={paymentData}
                      selected={selectedPayment}
                      setSelected={setSelectedPayment}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="amount"
              control={formMethods.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold">Amount*</FormLabel>
                  <FormControl>
                    <input
                      {...field}
                      type="number"
                      id="amount"
                      placeholder={'Enter Collected Amount'}
                      className={clsx(
                        'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="remarks"
              control={formMethods.control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="font-semibold">Remarks</FormLabel>
                  <FormControl>
                    <textarea
                      {...field}
                      rows={3}
                      placeholder="Enter Bank Slip No, Cheques or Cash"
                      type="text"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <ActionButtonsWrapper>
              <button
                type="submit"
                disabled={
                  !(formMethods.formState.isValid && selectedPayment?.id) ||
                  addPaymentMutationLoading
                }
                className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto disabled:cursor-not-allowed disabled:opacity-50"
              >
                Add Payment
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(false);
                }}
                ref={cancelButtonRef}
              >
                Close
              </button>
            </ActionButtonsWrapper>
          </form>
        </Form>
      </div>
    </ChamakModal>
  );
}

const ActionButtonsWrapper = ({ children }) => (
  <div className="py-3 sm:flex sm:flex-row-reverse">{children}</div>
);

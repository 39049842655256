import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import CreateRouteForm from '../../../components/app/manage-routes/CreateRouteForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Create Routes'
};

const pages = [
  {
    name: 'Manage Routes',
    location: '/app/manage-routes',
    current: false
  },
  { name: 'Create', location: '/app/manage-routes/create', current: true }
];

export default function CreateRoutes() {
  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <CreateRouteForm />
    </div>
  );
}

import toast from 'react-hot-toast';
import { appendParamsToUrl, handleErrorMessage } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const usersApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: ({ ...query }) => ({
        url: `/api/users?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getUser']
    }),
    createUser: builder.mutation({
      query: ({ name, email, phone, role, warehouseId }) => ({
        url: '/api/users',
        method: 'POST',
        body: { name, email, phone, role, warehouseId }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('User has been created!');
        } catch (error) {
          handleErrorMessage(error);
        }
      },
      invalidatesTags: ['getUser']
    }),
    getOneUser: builder.query({
      query: ({ userId }) => ({
        url: `/api/users/${userId}`,
        method: 'GET'
      }),
      providesTags: ['getOneUserWithId']
    }),
    updateUser: builder.mutation({
      query: ({ userId, ...query }) => ({
        url: `/api/users/${userId}`,
        method: 'PUT',
        body: { ...query }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('User has been updated!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getUser', 'getOneUserWithId, getUnAssignedUsers']
    }),
    assignUserToWarehouse: builder.mutation({
      query: ({ userId, ...body }) => ({
        url: `/api/users/${userId}/assign-warehouse`,
        method: 'PATCH',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('User has been assigned to this warehouse!');
        } catch (error) {
          const errorMessage = error.error.data.message;
          toast.error(errorMessage);
        }
      },
      invalidatesTags: ['getUser']
    }),
    removeUserFromWarehouse: builder.mutation({
      query: (userId) => ({
        url: `/api/users/${userId}/remove-warehouse`,
        method: 'PATCH'
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('User has been removed from this warehouse!');
        } catch (error) {
          const errorMessage = error.error.data.message;
          toast.error(errorMessage);
        }
      },
      invalidatesTags: ['getUser']
    })
  })
});

export const {
  useGetUserQuery,
  useCreateUserMutation,
  useGetOneUserQuery,
  useUpdateUserMutation,
  useAssignUserToWarehouseMutation,
  useRemoveUserFromWarehouseMutation
} = usersApi;

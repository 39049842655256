import React from 'react';
import useQuery from '../../../hooks/useQuery';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import EditBrandForm from '../../../components/app/brand/EditBrandForm';

const pageMeta = {
  page_name: 'Edit Brand'
};

export default function EditBrands() {
  const routeId = useQuery().get('brandId');
  const pages = [
    {
      name: 'Manage Brands',
      location: '/app/manage-brands',
      current: false
    },
    {
      name: 'Edit',
      location: '/app/manage-brands/edit?routeId=' + routeId,
      current: true
    }
  ];
  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <EditBrandForm />
    </div>
  );
}

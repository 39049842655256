import React from 'react';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';
import { LoaderIcon } from 'react-hot-toast';
import { accountStatus } from '../../../data/accountStatus';

export default function ViewUsers({ currentData }) {
  const navigate = useNavigate();
  const query = useQuery();

  return (
    <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div className="px-4 py-6 sm:p-8">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          {/* <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
               ID
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.id ?? <LoaderIcon />}</span>
            </div>
          </div> */}

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
               Name
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.name ?? <LoaderIcon />}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Email
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.email || 'N/A'}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Mobile Number
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.phone || 'N/A'}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Role
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.role || 'N/A'}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Account Status
            </label>
            <div className="mt-2">
              <span>{accountStatus[currentData?.data?.accountStatus ?? <LoaderIcon />]}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Warehouse
            </label>
            <div className="mt-2">
              <span>
                {currentData?.data?.warehouse?.name || 'N/A'}
              </span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Routes
            </label>
            <div className="mt-2 flex gap-2">
                {currentData?.data?.routes?.map((route)=> <p className='border p-0.5 rounded-md'> {route.name} </p>) || 'N/A'}
            </div>
          </div>

        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
        <button
          type="button"
          onClick={() => navigate('/app/users/view')}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Back
        </button>
      </div>
    </form>
  );
}

import { createColumnHelper } from '@tanstack/react-table';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../components/common/DataTable/Table';
import { useGetConfigQuery } from '../../../store/features/config-warehouse/configApi';
import { startCase } from 'lodash';

const rootMeta = {
  page_name: 'Warehouse Configuration',
  breadcrumbs: null
};

export default function ConfigRoot() {
  const { currentData } = useGetConfigQuery({});

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.name}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'Config Name'
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex items-center gap-1">
            <span>{row?.value}</span>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'Value'
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <Link to={`/app/config-warehouse/edit`}>
            <button className="text-red-600 hover:underline ml-3">Edit</button>
          </Link>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center"></div>
        </div>
      </div>
      {currentData && (
        <Table
          data={[
            { name: 'Delivery Charge', value: currentData?.data?.deliveryCharge },
            { name: 'Operation Status', value: currentData?.data?.opsStatus },
          ]}
          columns={columns}
        />
      )}
    </div>
  );
}

import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import CreateCustomerForm from '../../../components/app/customers/CreateCustomerForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Add Customer'
};

const pages = [
  {
    name: 'Customer',
    location: '/app/customers',
    current: false
  },
  { name: 'Add', location: '/app/customers/add', current: true }
];

export default function CreateCustomer() {
  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <CreateCustomerForm />
    </div>
  );
}

import React from "react";

export default function SideBanner({ bannerSource }) {
	return (
		<div className="relative hidden w-0 flex-1 lg:block">
			<img
				className="absolute inset-0 h-full w-full object-cover"
				src={bannerSource}
				alt=""
			/>
		</div>
	);
}

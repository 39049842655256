import { PlusIcon } from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import { Fragment, useEffect, useState } from 'react';
import Table from '../../../components/common/DataTable/Table';
import TopNavigationBar from '../../../components/common/TopNavigationBar';
import useQuery from '../../../hooks/useQuery';
import {
  useGetCartQuery,
  useUpdateCartMutation
} from '../../../store/features/cart/cartApi';
import ModifyAmount from '../../../components/common/ModifyAmount';
import AddProductsModal from '../../../components/common/AddProductsModal/AddProductsModal';
import InvoiceSummary from '../../../components/common/InvoiceSummary';
import { useNavigate } from 'react-router-dom';
import CustomerSummary from '../../../components/common/CustomerSummary';

const pageMeta = {
  page_name: 'Cart Details',
  breadcrumbs: null
};

export default function CartDetailsRoot() {
  const navigate = useNavigate();

  const customerId = useQuery().get('customerId');
  const [isAddProductsOpen, setIsAddProductsOpen] = useState(false);
  const [isModifyOpen, setIsModifyOpen] = useState(false);
  const [cartProducts, setCartProducts] = useState([]);
  const [selected, setSelected] = useState(null);

  const { currentData, isLoading } = useGetCartQuery({
    customerId
  });

  const [
    callUpdateCartMutation,
    {
      isSuccess: updateCartMutationSuccess,
      isLoading: updateCartMutationLoading,
      currentData: updateCartMutationData
    }
  ] = useUpdateCartMutation();

  useEffect(() => {
    currentData?.data && setCartProducts(currentData?.data?.items);
  }, [currentData]);

  const pages = [
    {
      name: 'Customers',
      location: `/app/customers`,
      current: false
    },
    {
      name: 'View Cart',
      location: `/app/cart/view?customerId=${customerId}`,
      current: true
    }
  ];

  const toggleModal = () => {
    setIsAddProductsOpen((e) => !e);
  };

  const updateCartProduct = (data) => {
    const payload = {
      customerId,
      inventoryId: data?.id,
      quantity: parseFloat(data?.quantity, 10)
    };
    callUpdateCartMutation(payload);
  };

  const handleModifyAmount = (data) => {
    setSelected({
      id: data?.id,
      quantity: data?.quantity,
      productName: data?.product?.nameEn,
      sku: data?.product?.sku,
      stock: data?.stock
    });
    setIsModifyOpen(true);
  };

  useEffect(() => {
    if (updateCartMutationSuccess) {
      setIsModifyOpen(false);
      setSelected(null);
    }
  }, [updateCartMutationSuccess]);

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.product?.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.product?.nameEn}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.quantity}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'QUANTITY',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.stock}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'STOCK',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.unitPrice}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'UNIT PRICE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.subTotal}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SUBTOTAL',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <button
            className="text-red-600 hover:underline"
            onClick={() => handleModifyAmount(row)}
          >
            Modify
          </button>
          <button
            className="ml-4 text-red-600 hover:underline"
            onClick={() =>
              updateCartProduct({
                id: row.id,
                quantity: 0
              })
            }
          >
            Remove
          </button>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION'
      }
    )
  ];

  const handleReviewOrder = () => {
    navigate(`/app/cart/checkout?customerId=${customerId}`);
  };

  const getSkuIds = () => {
    return cartProducts?.map((cart) => ({
      sku: cart?.product?.sku,
      quantity: cart?.quantity
    }));
  };

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <div className="mt-4 sm:ml-0 sm:mt-0 sm:flex-none md:flex-none">
        <div className="mt-4 sm:ml-0 sm:mt-0 sm:flex-none md:flex-none">
          <CustomerSummary
            title={'Customer Summary'}
            customer={currentData?.data?.customer}
            noAssignment
          />
        </div>
      </div>
      <div className="mt-8 flex justify-between items-center bg-gray-200 rounded py-2 px-4">
        <div className="text-gray-700 text-lg font-bold">Product Summary</div>
        <div>
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
            onClick={toggleModal}
          >
            <PlusIcon className="h-5 w-5" aria-hidden="true" />
            Add Product
          </button>
        </div>
      </div>
      <Table data={cartProducts ?? []} columns={columns} />
      <InvoiceSummary
        title={'Invoice Summary'}
        invoice={currentData?.data?.invoice}
      />
      <div className="flex justify-end">
        <button
          type="button"
          className="mt-8 px-10 rounded-md bg-red-600 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
          onClick={handleReviewOrder}
        >
          Review Cart
        </button>
      </div>
      <ModifyAmount
        open={isModifyOpen}
        setOpen={setIsModifyOpen}
        id={selected?.id}
        quantity={selected?.quantity}
        updateProduct={updateCartProduct}
        productName={selected?.productName}
        sku={selected?.sku}
        stock={selected?.stock}
      />
      <AddProductsModal
        open={isAddProductsOpen}
        setOpen={toggleModal}
        title="Products"
        customerId={customerId}
        skuIds={getSkuIds()}
      />
    </div>
  );
}

import React from 'react';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';
import { LoaderIcon } from 'react-hot-toast';
import { accountStatus } from '../../../data/accountStatus';

export default function ViewCustomers({ currentData }) {
  const navigate = useNavigate();
  const query = useQuery();

  return (
    <form className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
      <div className="px-4 py-6 sm:p-8">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Name
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.name ?? <LoaderIcon />}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Mobile Number
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.phone ?? <LoaderIcon />}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Chamak Id
            </label>
            <div className="mt-2">
              <span> {currentData?.data?.chamakId ?? 'N/A'} </span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Shop Name
            </label>
            <div className="mt-2">
              <span> {currentData?.data?.shopName ?? 'N/A'} </span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              District
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.address?.district || 'N/A'}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Thana
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.address?.thana || 'N/A'}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Village name
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.address?.village || 'N/A'}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Full Address
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.address?.full || 'N/A'}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Route Name
            </label>
            <div className="mt-2">
              <div>
                {currentData?.data?.routes?.length > 0
                  ? currentData.data.routes.map((route, index) => (
                      <span
                        key={index}
                        className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${
                          index % 2 === 0
                            ? 'inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700 mr-1'
                            : 'inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800 ml-1'
                        }`}
                      >
                        {route.name}
                      </span>
                    ))
                  : 'N/A'}
              </div>

              
            </div>
          </div>
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Account Status
            </label>
            <div className="mt-2">
              <span>
                {
                  accountStatus[
                    currentData?.data?.accountStatus ?? <LoaderIcon />
                  ]
                }
              </span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Warehouse
            </label>
            <div className="mt-2">
              <span>{currentData?.data?.warehouse?.name || 'N/A'}</span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Membership Status
            </label>
            <div className="mt-2">
              <span>
                {currentData?.data?.isMember ? 'Member' : 'Non-Member'}
              </span>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Membership Point
            </label>
            <div className="mt-2">
              <span>
                {currentData?.data?.membershipPoint !== null
                  ? currentData?.data?.membershipPoint
                  : 0}
              </span>
            </div>
          </div>

        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
        <button
          type="button"
          onClick={() => navigate('/app/customers/view')}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Back
        </button>
      </div>
    </form>
  );
}

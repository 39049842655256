import { Navigate } from 'react-router-dom';
import App from '../App';

/**Common views */
import ErrorPage from '../views/common/ErrorPage';
import RequestExpired from '../views/common/RequestExpired';

/**HOCs */
import AuthRoutes from '../hoc/AuthRoutes';
import FirstPage from '../hoc/FirstPage';
import ProtectedRoutes from '../hoc/ProtectedRoutes';
import RoleProtectedRoutes from '../hoc/RoleProtectedRoutes';

/**Auth views */
import SetPasswordRoot from '../views/auth/set-password/SetPasswordRoot';
import SignInRoot from '../views/auth/sign-in/SignInRoot';
import VerifyWithOtpRoot from '../views/auth/verify-with-otp/VerifyWithOtpRoot';

/**App views */
import DashboardRoot from '../views/app/dashboard/DashboardRoot';
import CreateWarehouse from '../views/app/warehouse/CreateWarehouse';
import WarehouseRoot from '../views/app/warehouse/WarehouseRoot';
import ReportsRoot from '../views/app/reports/ReportsRoot';

/**Portal views */
import EditWarehouse from '../views/app/warehouse/EditWarehouse';
import ViewWarehouse from '../views/app/warehouse/ViewWarehouse';
import CreateShipment from '../views/app/shipment/CreateShipment';
import ShipmentDetail from '../views/app/shipment/ShipmentDetail';
import ShipmentRoot from '../views/app/shipment/ShipmentRoot';
import ChangeLogRoot from '../views/app/changelog/ChangeLogRoot';
import CategoryRoot from '../views/app/category/CategoryRoot';
import CreateCategory from '../views/app/category/CreateCategory';

import CreateProductManagement from '../views/app/product-management/CreateProductManagement';
import ProductManagementRoot from '../views/app/product-management/ProductManagementRoot';
import AddProductOffer from '../views/app/offer-products/AddProductOffer';
import ProductsOfferRoot from '../views/app/offer-products/ProductsOfferRoot';
import PendingStocksRoot from '../views/app/pending-stocks/PendingStocksRoot';
import AddStocksRoot from '../views/app/add-stocks/AddStocksRoot';
import UsersRoot from '../views/app/users/UsersRoot';
import CreateUsers from '../views/app/users/CreateUsers';
import EditUsers from '../views/app/users/EditUsers';
import ViewSingleWarehouseUsers from '../views/app/warehouse/ViewSingleWarehouseUsers';
import AddSingleWarehouseUser from '../views/app/warehouse/AddSingleWarehouseUser';
import UsersDetails from '../views/app/users/UsersDetails';
import EditProductManagement from '../views/app/product-management/EditProductManagment';
import CartDetailsRoot from '../views/app/cart/CartDetailsRoot';
import CheckoutDetails from '../views/app/cart/CheckoutDetails';
import EditCategory from '../views/app/category/EditCategory';
import ManageSalesAssociateRoot from '../views/app/manage-sales-associate/ManageSalesAssociateRoot';
import CreateSalesAssociate from '../views/app/manage-sales-associate/CreateSalesAssociate';
import EditSalesAssociate from '../views/app/manage-sales-associate/EditSalesAssociate';
import SalesAssociateDetails from '../views/app/manage-sales-associate/SalesAssociateDetails';
import SetTargetGoals from '../views/app/manage-sales-associate/SetTargetGoals';
import DeliveryAssociateRoot from '../views/app/delivery-associate/DeliveryAssociateRoot';
import CreateDeliveryAssociate from '../views/app/delivery-associate/CreateDeliveryAssociate';
import EditDeliveryAssociate from '../views/app/delivery-associate/EditDeliveryAssociate';
import DeliveryAssociateDetails from '../views/app/delivery-associate/DeliveryAssociateDetails';
import CustomerRoot from '../views/app/customers/CustomerRoot';
import CreateCustomer from '../views/app/customers/CreateCustomer';
import EditCustomer from '../views/app/customers/EditCustomer';
import CustomerDetails from '../views/app/customers/CustomerDetails';
import ManageRoutesRoot from '../views/app/manage-routes/ManageRoutesRoot';
import EditRoutes from '../views/app/manage-routes/EditRoutes';
import CreateRoutes from '../views/app/manage-routes/CreateRoutes';
import ProductsRoot from '../views/app/products/ProductsRoot';
import AddProduct from '../views/app/products/AddProduct';
import EditProduct from '../views/app/products/EditProduct';
import CashCollectionRoot from '../views/app/cash-collection/CashCollectionRoot';
import CollectCash from '../views/app/cash-collection/CollectCash';
import ReportsRootWarehouse from '../views/app/reports-warehouse/ReportsRootWarehouse';
import OrderHistoryRoot from '../views/app/order-history/OrderHistoryRoot';
import OrderDetails from '../views/app/order-history/OrderDetails';
import ProfileDetails from '../views/app/profile/ProfileDetails';
import PasswordUpdate from '../views/app/password-update/PasswordUpdate';
import MfaUpdate from '../views/app/mfa-update/MfaUpdate';
import DataUserRoot from '../views/app/data-user/DataUserRoot';
import CreateDataUser from '../views/app/data-user/CreateDataUser';
import EditDataUser from '../views/app/data-user/EditDataUser';
import DataUserDetails from '../views/app/data-user/DataUserDetails';
import SalesAssociateGoals from '../views/app/manage-sales-associate/SalesAssociateGoals';
import CustomerRegistrationRoot from '../views/app/customer-reg/CustomerRegistrationRoot';
import CreateCustomerRegistration from '../views/app/customer-reg/CreateCustomerRegistration';
import CustomerRegistrationDetails from '../views/app/customer-reg/CustomerRegistrationDetails';
import ForgetPasswordRoot from '../views/auth/forget-password/ForgetPasswordRoot';
import ConfigRoot from '../views/app/config-warehouse/ConfigRoot';
import EditConfig from '../views/app/config-warehouse/EditConfig';
import SmsRoot from '../views/app/sms/SmsRoot';
import InvoiceHistoryRoot from '../views/app/invoice-history/InvoiceHistoryRoot';
import DashboardDataUserRoot from '../views/app/dashboard-data-user/DashboardDataUserRoot';
import ReportsDataUserRoot from '../views/app/reports-data-user/ReportsDataUserRoot';
import EditCustomerRegistration from '../views/app/customer-reg/EditCustomerRegistration';
import ActivityLogRoot from '../views/app/activity-log/ActivityLogRoot';
import SalesAssociatesAchievements from '../views/app/manage-sales-associate/SalesAssociatesAchievements';
import StockUpdateHistory from '../views/app/products/StockUpdateHistory';
import AccountDeletionGuide from '../views/request-account-delete/AccountDeletionGuide';
import ViewProductManagement from '../views/app/product-management/ViewProductManagment';
import ProductDetails from '../views/app/products/ProductDetails';
import ManageBrandsRoot from '../views/app/brand/ManageBrandsRoot';
import CreateBrands from '../views/app/brand/CreateBrands';
import EditBrands from '../views/app/brand/EditBrands';
import ViewBrand from '../views/app/brand/ViewBrand';

const BrowserRouterObject = [
  {
    path: '/',
    element: (
      <ProtectedRoutes>
        <App />
      </ProtectedRoutes>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <FirstPage />
      },
      {
        path: '/app/dashboard',
        element: (
          <ProtectedRoutes>
            <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
              <DashboardRoot />
            </RoleProtectedRoutes>
          </ProtectedRoutes>
        )
      },
      {
        path: '/app/dashboard-data-user',
        element: (
          <ProtectedRoutes>
            <RoleProtectedRoutes allowedRoles={['DATA_USER']}>
              <DashboardDataUserRoot />
            </RoleProtectedRoutes>
          </ProtectedRoutes>
        )
      },
      {
        path: '/app/warehouse',
        children: [
          { path: '', element: <Navigate to="view" replace /> },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <WarehouseRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'detail',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <ViewWarehouse />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'create',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <CreateWarehouse />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <EditWarehouse />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: '/app/warehouse/users',
            children: [
              {
                path: '',
                element: (
                  <ProtectedRoutes>
                    <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                      <ViewSingleWarehouseUsers />
                    </RoleProtectedRoutes>
                  </ProtectedRoutes>
                )
              },
              {
                path: 'add',
                element: (
                  <ProtectedRoutes>
                    <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                      <AddSingleWarehouseUser />
                    </RoleProtectedRoutes>
                  </ProtectedRoutes>
                )
              }
            ]
          }
        ]
      },
      {
        path: '/app/users',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <UsersRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'create',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <CreateUsers />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <EditUsers />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'details',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <UsersDetails />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/product-management',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <ProductManagementRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'create',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <CreateProductManagement />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <EditProductManagement />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'details',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN', 'WAREHOUSE_ASSOCIATE']}>
                  <ViewProductManagement />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/category',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <CategoryRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'create',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <CreateCategory />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <EditCategory />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/reports',
        element: (
          <ProtectedRoutes>
            <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
              <ReportsRoot />
            </RoleProtectedRoutes>
          </ProtectedRoutes>
        )
      },
      {
        path: '/app/reports-data-user',
        element: (
          <ProtectedRoutes>
            <RoleProtectedRoutes allowedRoles={['DATA_USER']}>
              <ReportsDataUserRoot />
            </RoleProtectedRoutes>
          </ProtectedRoutes>
        )
      },
      {
        path: '/app/change-log',
        element: (
          <ProtectedRoutes>
            <RoleProtectedRoutes
              allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
            >
              <ChangeLogRoot />
            </RoleProtectedRoutes>
          </ProtectedRoutes>
        )
      },
      {
        path: '/app/sms',
        element: (
          <ProtectedRoutes>
            <RoleProtectedRoutes
              allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
            >
              <SmsRoot />
            </RoleProtectedRoutes>
          </ProtectedRoutes>
        )
      },
      {
        path: '/app/cart',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <CartDetailsRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'checkout',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <CheckoutDetails />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/manage-sales-associate',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <ManageSalesAssociateRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'create',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <CreateSalesAssociate />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <EditSalesAssociate />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'details',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <SalesAssociateDetails />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'goals-list',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <SalesAssociateGoals />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'goals',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <SetTargetGoals />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'achievements',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <SalesAssociatesAchievements />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/profile',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={[
                    'SUPER_ADMIN',
                    'WAREHOUSE_ADMIN',
                    'DATA_USER',
                    'WAREHOUSE_ASSOCIATE'
                  ]}
                >
                  <ProfileDetails />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      // {
      //   path: '/app/request-account-delete',
      //   children: [
      //     {
      //       path: '',
      //       element: <Navigate to="view" replace />
      //     },
      //     {
      //       path: 'view',
      //       element: (
      //         // <ProtectedRoutes>
      //         //   <RoleProtectedRoutes
      //         //     allowedRoles={[
      //         //       'SUPER_ADMIN',
      //         //       'WAREHOUSE_ADMIN',
      //         //       'DATA_USER',
      //         //       'WAREHOUSE_ASSOCIATE',
      //         //       'CUSTOMER',
      //         //       'SALES_ASSOCIATE',
      //         //       'DELIVERY_ASSOCIATE'
      //         //     ]}
      //         //   >
      //         //     <AccountDeletionGuide />
      //         //   </RoleProtectedRoutes>
      //         // </ProtectedRoutes>
      //         <AccountDeletionGuide />
      //       )
      //     }
      //   ]
      // },
      {
        path: '/app/activity-log',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={[
                    'SUPER_ADMIN',
                    'WAREHOUSE_ADMIN',
                    'DATA_USER',
                    'WAREHOUSE_ASSOCIATE'
                  ]}
                >
                  <ActivityLogRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/password-update',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={[
                    'SUPER_ADMIN',
                    'WAREHOUSE_ADMIN',
                    'DATA_USER',
                    'WAREHOUSE_ASSOCIATE'
                  ]}
                >
                  <PasswordUpdate />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/mfa-update',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={[
                    'SUPER_ADMIN',
                    'WAREHOUSE_ADMIN',
                    'DATA_USER',
                    'WAREHOUSE_ASSOCIATE'
                  ]}
                >
                  <MfaUpdate />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/delivery-associate',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <DeliveryAssociateRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'create',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <CreateDeliveryAssociate />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <EditDeliveryAssociate />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'details',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <DeliveryAssociateDetails />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/data-user',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <DataUserRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'create',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <CreateDataUser />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <EditDataUser />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'details',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <DataUserDetails />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/shipment',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  <ShipmentRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'create',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  <CreateShipment />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'detail',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  <ShipmentDetail />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  Edit
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/order-history',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['WAREHOUSE_ADMIN', 'WAREHOUSE_ASSOCIATE', 'SUPER_ADMIN']}
                >
                  <OrderHistoryRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'detail',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN', 'WAREHOUSE_ASSOCIATE', 'SUPER_ADMIN']}>
                  <OrderDetails />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/manage-routes',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <ManageRoutesRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'create',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <CreateRoutes />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <EditRoutes />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/manage-brands',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <ManageBrandsRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'create',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <CreateBrands />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <EditBrands />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'detail',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['SUPER_ADMIN']}>
                  <ViewBrand />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
        ]
      },
      {
        path: '/app/products',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  <ProductsRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'add',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  <AddProduct />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  <EditProduct />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'details',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  <ProductDetails />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'update',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  <StockUpdateHistory />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/config-warehouse',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  <ConfigRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  <EditConfig />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/offer-products',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  <ProductsOfferRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'add',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  <AddProductOffer />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/pending-stocks',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN']}>
                  <PendingStocksRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/add-stocks',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ASSOCIATE']}>
                  <AddStocksRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/invoice-history',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ASSOCIATE','WAREHOUSE_ADMIN']}>
                  <InvoiceHistoryRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/cash-collection',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN', 'WAREHOUSE_ASSOCIATE']}>
                  <CashCollectionRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'collect',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes allowedRoles={['WAREHOUSE_ADMIN', 'WAREHOUSE_ASSOCIATE']}>
                  <CollectCash />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/reports-warehouse',
        element: (
          <ProtectedRoutes>
            <RoleProtectedRoutes
              allowedRoles={['WAREHOUSE_ADMIN', 'WAREHOUSE_ASSOCIATE']}
            >
              <ReportsRootWarehouse />
            </RoleProtectedRoutes>
          </ProtectedRoutes>
        )
      },
      {
        path: '/app/customers',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <CustomerRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'add',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <CreateCustomer />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <EditCustomer />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'details',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN']}
                >
                  <CustomerDetails />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      },
      {
        path: '/app/customers-reg-requests',
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN', 'WAREHOUSE_ASSOCIATE']}
                >
                  <CustomerRegistrationRoot />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'create',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN', 'WAREHOUSE_ASSOCIATE']}
                >
                  <CreateCustomerRegistration />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'edit',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN', 'WAREHOUSE_ASSOCIATE']}
                >
                  <EditCustomerRegistration />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          },
          {
            path: 'details',
            element: (
              <ProtectedRoutes>
                <RoleProtectedRoutes
                  allowedRoles={['SUPER_ADMIN', 'WAREHOUSE_ADMIN', 'WAREHOUSE_ASSOCIATE']}
                >
                  <CustomerRegistrationDetails />
                </RoleProtectedRoutes>
              </ProtectedRoutes>
            )
          }
        ]
      }
    ]
  },

  // Static Page Route //
  {
    path: '/request-account-delete',
    element: <AccountDeletionGuide />
  },

  // Authenticaiton Routes //
  {
    path: '/auth',
    children: [
      {
        path: '',
        element: <Navigate to="sign-in" replace />
      },
      {
        path: 'sign-in',
        element: (
          <AuthRoutes>
            <SignInRoot />
          </AuthRoutes>
        )
      },
      {
        path: 'verify-with-otp',
        element: (
          <AuthRoutes>
            <VerifyWithOtpRoot />
          </AuthRoutes>
        )
      },
      {
        path: 'set-password',
        element: (
          <AuthRoutes>
            <SetPasswordRoot />
          </AuthRoutes>
        )
      },
      {
        path: 'request-expired',
        element: (
          <AuthRoutes>
            <RequestExpired />
          </AuthRoutes>
        )
      },
      {
        path: 'forgot-password',
        element: (
          <AuthRoutes>
            <ForgetPasswordRoot />
          </AuthRoutes>
        )
      }
    ]
  }
];

export default BrowserRouterObject;

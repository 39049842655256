import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import useQuery from '../../../hooks/useQuery';
import DropdownSelect from '../../common/DropdownSelect';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '../../common/Form';
import { useGetWarehouseQuery } from '../../../store/features/warehouse/warehouseApi';
import {
  useGetOneCustomerQuery,
  useUpdateCustomerMutation
} from '../../../store/features/customer/customerApi';
import RoutesPickup from '../../common/RoutesPickup';
import districts from '../../../data/districts';
import thana from '../../../data/thana';
import toast from 'react-hot-toast';

const accountStatus = [
  { id: 1, name: 'ACTIVE' },
  { id: 2, name: 'INACTIVE' }
];

const membershipStatus = [
  { id: 1, name: 'NON-MEMBER' },
  { id: 2, name: 'MEMBER' }
];

export const customerSchema = z.object({
  name: z.string().min(1, 'Name is required').max(50, 'Too Long'),
  shopName: z.string().min(1, 'Shop Name is required').max(50, 'Too Long'),
  address: z.object({
    full: z
      .string()
      .min(1, 'Full Address is required')
      .max(100, 'Too Long'),
    village: z
      .string()
      .min(1, 'Village name is required')
      .max(50, 'Too Long')
  }),
  phone: z.string().regex(/^\+?(88)?0?1[3456789][0-9]{8}\b/, 'Invalid phone'),
  previousMembershipPoint: z.string().optional(),
  membershipPoint: z.string().optional(),
  chamakId: z.string().min(1, 'ID is required').max(20, 'Too Long')
});

export default function EditCustomerForm() {
  const customerId = useQuery().get('customerId');
  const { currentData } = useGetOneCustomerQuery({
    customerId
  });

  const [selectedRoute, setSelectedRoute] = useState({});
  const [warehouseName, setWarehouseName] = useState([]);

  const [selected, setSelected] = useState(
    accountStatus[0] ?? { id: '', name: '' }
  );

  const handleRoutePickUpId = (selectedRoute) => {
    setSelectedRoute(selectedRoute);
  };

  const [selectedStatus, setSelectedStatus] = useState(
    currentData?.data?.isMember ? membershipStatus[1] : membershipStatus[0]
  );

  const [selectedDistrict, setSelectedDistrict] = useState({
    id: '',
    name: 'Select District'
  });

  const [selectedThana, setSelectedThana] = useState({
    id: '',
    name: 'Select Thana'
  });

  const [filteredThana, setFilteredThana] = useState([
    { id: '', name: 'Select district first' }
  ]);

  const [page, setPage] = useState(1);
  const [selectedWarehouse, setSelectedWarehouse] = useState({
    id: '',
    name: 'Select Warehouse'
  });

  const methods = useForm({
    resolver: zodResolver(customerSchema),
    mode: 'onChange',
    values: {
      ...currentData?.data,
      previousMembershipPoint: currentData?.data?.membershipPoint
        ? String(currentData?.data?.membershipPoint)
        : '0',
      membershipPoint: currentData?.data?.membershipPoint
        ? String(currentData?.data?.membershipPoint)
        : '0'
    }
  });

  const [
    callUpdateCustomerMutation,
    {
      isSuccess: isUpdateCustomerMutationSuccess,
      isLoading: updateCustomerMutationLoading
    }
  ] = useUpdateCustomerMutation();

  const { data: warehouseData, isLoading: getWarehouseQueryLoading } =
    useGetWarehouseQuery({});

  useEffect(() => {
    currentData?.data?.routes?.length > 0 &&
      setSelectedRoute(currentData?.data?.routes?.[0]);
  }, [currentData]);

  useEffect(() => {
    if (warehouseData?.data?.warehouses) {
      setWarehouseName(
        warehouseData?.data?.warehouses?.map((warehouse) => ({
          id: warehouse?.id,
          name: warehouse?.name
        }))
      );
    }
  }, [warehouseData]);

  useEffect(() => {
    setSelected({
      id:
        accountStatus.find(
          (status) => status?.name === currentData?.data?.accountStatus
        )?.id ?? '',
      name: currentData?.data?.accountStatus ?? ''
    });
  }, [currentData]);

  useEffect(() => {
    setSelectedStatus(
      currentData?.data?.isMember ? membershipStatus[1] : membershipStatus[0]
    );
  }, [currentData]);

  useEffect(() => {
    setSelectedWarehouse({
      id: currentData?.data?.warehouseId,
      name: warehouseName.find(
        (status) => status?.id === currentData?.data?.warehouseId
      )?.name
    });
  }, [currentData, warehouseName]);

  useEffect(() => {
    const remainingThana = thana.filter(
      (eachThana) => eachThana.district_id === selectedDistrict.id
    );
    setFilteredThana(remainingThana);
    !remainingThana?.find((thana) => thana.id === selectedThana.id) &&
      setSelectedThana({
        id: '',
        name: 'Select Thana'
      });
  }, [selectedDistrict]);

  useEffect(() => {
    setSelectedDistrict({
      id: districts?.find(
        (each) =>
          each?.name === currentData?.data?.address?.district
      )?.id,
      // id: currentData?.data?.address?.district?.name,
      name: currentData?.data?.address?.district
    });
    setSelectedThana({
      id: thana?.find(
        (each) =>
          each?.name === currentData?.data?.address?.thana
      )?.id,      
      name: currentData?.data?.address?.thana
    });
  }, [currentData?.data?.address]);

  

  const navigate = useNavigate();

  const onSubmit = (data) => {
    if (selectedThana.id !== '') {
    const incrementPointBy =
      parseInt(data.membershipPoint, 10) -
      parseInt(data?.previousMembershipPoint);

    const { phone, membershipPoint, previousMembershipPoint, ...body } = data;

    callUpdateCustomerMutation({
      ...body,
      address: {
        ...data?.address,
        thana: selectedThana?.name,
        district: selectedDistrict?.name
      },
      incrementPointBy,
      accountStatus: selected?.name,
      warehouseId: selectedWarehouse?.id,
      isMember: selectedStatus?.id === 2,
      routeId: selectedRoute?.id,
      customerId
    });
  }
  else {
    toast.error('Please Select your Thana!');
  }
};

  useEffect(() => {
    if (isUpdateCustomerMutationSuccess) {
      navigate('/app/customers/view');
    }
  }, [isUpdateCustomerMutationSuccess]);

  return (
    <Form {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
      >
        <div className="px-4 py-6 sm:p-8">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <FormField
                name="name"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="user-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Customer Name*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="user-name"
                          placeholder={'Enter Customer name'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="phone"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone Number*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="tel"
                          {...field}
                          id="phone"
                          disabled
                          readOnly
                          placeholder={'Enter Phone Number'}
                          className={clsx(
                            'disabled:bg-gray-200 disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="chamakId"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="chamakId"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Chamak ID*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="chamakId"
                          placeholder={'Enter Chamak ID'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="shopName"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="shopName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      SHOP NAME*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="shopName"
                          placeholder={'Enter Shop Name'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="district"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Select District*
              </label>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <DropdownSelect
                    data={
                      districts?.sort((a, b) => (a.name > b.name ? 1 : -1))
                    }
                    selected={selectedDistrict}
                    setSelected={setSelectedDistrict}
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="thana"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Select Thana*
              </label>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <DropdownSelect
                    data={
                      filteredThana?.sort((a, b) =>
                        a.name > b.name ? 1 : -1
                      ) ?? []
                    }
                    selected={selectedThana}
                    setSelected={setSelectedThana}
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="address.village"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="village"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Village Name*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="village"
                          placeholder={'Enter Village name'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="address.full"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="full"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Full Address*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="full"
                          placeholder={'Enter Full Address'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="warehouse"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Select Warehouse*
              </label>
              <div className="mt-2">
                <DropdownSelect
                  data={warehouseName}
                  isLoading={getWarehouseQueryLoading}
                  selected={selectedWarehouse}
                  setSelected={setSelectedWarehouse}
                />
              </div>
            </div>
            <div className="sm:col-span-3"></div>

            <div className="sm:col-span-3">
              <label
                htmlFor="accountStatus"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Account Status*
              </label>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <DropdownSelect
                    data={accountStatus}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </div>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="membershipStatus"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Membership Status*
              </label>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <DropdownSelect
                    data={membershipStatus}
                    selected={selectedStatus}
                    setSelected={setSelectedStatus}
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-3">
              <FormField
                name="previousMembershipPoint"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="previousMembershipPoint"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Previous Membership Point
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="number"
                          {...field}
                          id="previousMembershipPoint"
                          disabled
                          readOnly
                          placeholder="Previous Membership Point"
                          className={clsx(
                            'disabled:bg-gray-200 disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <FormField
                name="membershipPoint"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="membershipPoint"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      New Membership Point
                    </label>
                    <FormControl>
                      <div className="mt-2 flex">
                        <button
                          type="button"
                          onClick={() =>
                            field.onChange(
                              String(parseInt(field.value || 0) - 1)
                            )
                          }
                          className="rounded-md bg-red-600 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500 mr-1"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          {...field}
                          id="membershipPoint"
                          placeholder="Enter Membership Point"
                          className={clsx(
                            'block flex-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />

                        <button
                          type="button"
                          onClick={() =>
                            field.onChange(
                              String(parseInt(field.value || 0) + 1)
                            )
                          }
                          className="rounded-md bg-red-600 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500 ml-1"
                        >
                          +
                        </button>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="col-span-6 mt-10">
              <RoutesPickup
                selected={selectedRoute}
                setSelected={handleRoutePickUpId}
              />
              {/* <MultipleRoutePickup handleRoutePickUpIds={handleRoutePickUpId} /> */}
            </div>
          </div>
        </div>

        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button
            type="button"
            onClick={() => navigate('/app/customers/view')}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={updateCustomerMutationLoading || !methods.formState.isValid }
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
          >
            Update
          </button>
        </div>
      </form>
    </Form>
  );
}

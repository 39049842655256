import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import useQuery from '../../../hooks/useQuery';
import EditDataUserForm from '../../../components/app/data-user/EditDataUserForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Edit Data User'
};

export default function EditDataUser() {
  const query = useQuery();

  const pages = [
    {
      name: 'Data User',
      location: '/app/data-user/',
      current: false
    },
    {
      name: 'Edit',
      location: `/app/data-user/edit?${query.get('dataUserId')}`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <EditDataUserForm />
    </div>
  );
}

import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import React from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../../components/common/BreadCrumbs";
import ShipmentForm from "../../../components/app/shipment/ShipmentForm";

const pageMeta = {
  page_name: "Create Shipment",
};

const pages = [
  {
    name: "Shipment",
    location: "/app/shipment",
    current: false,
  },
  { name: "Create", location: "/app/shipment/create", current: true },
];

export default function CreateShipment() {
  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-start">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            <Link to="/app/shipment/view">
              <span className="flex items-center gap-4 hover:text-gray-500 transition-all">
                <ArrowLeftIcon className="h-7 w-7" />
                {pageMeta.page_name}
              </span>
            </Link>
          </h1>
          <div className="my-6">
            <BreadCrumbs pages={pages} />
          </div>
        </div>
      </div>
      <ShipmentForm />
    </div>
  );
}

// // // // import { FunnelIcon } from '@heroicons/react/24/outline';
// // // // import clsx from 'clsx';
// // // // import React, { useState } from 'react';
// // // // import { cn } from '../../../lib/utils';
// // // // import { ChamakDatePickerRangeReports } from '../../common/ChamakDatePickerRangeReports';
// // // // import SlideOver from '../../common/SlideOver';

// // // // export default function FilterTray({
// // // //   currentFilter,
// // // //   setCurrentFilter,
// // // //   setDate,
// // // //   date,
// // // //   selectedIdType,
// // // //   setSelectedIdType,
// // // //   selectedId,
// // // //   setSelectedId,
// // // //   setApplyFilter
// // // // }) {
// // // //   const [open, setOpen] = useState(false);
// // // //   return (
// // // //     <div className="mt-4 sm:ml-16 sm:mt-6 sm:flex-none overflow-x-scroll scrollbar-none">
// // // //       <div className="flex gap-4 sm:flex-row flex-col">
// // // //         {/* <div className="sm:flex rounded-md shadow-sm gap-2 text-sm">
// // // //           <button
// // // //             onClick={() => {
// // // //               setApplyFilter(false);
// // // //               setSelectedId(null);
// // // //               setSelectedIdType(null);
// // // //             }}
// // // //             className="text-red-600 hover:underline"
// // // //           >
// // // //             Clear filter
// // // //           </button>
// // // //           <button
// // // //             type="button"
// // // //             className="relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
// // // //             onClick={() => setOpen(true)}
// // // //           >
// // // //             <FunnelIcon
// // // //               className="-ml-0.5 h-5 w-5 text-gray-400"
// // // //               aria-hidden="true"
// // // //             />
// // // //             Filter
// // // //           </button>
// // // //         </div> */}

// // // //         <div className="sm:flex rounded-md shadow-sm space-y-2 sm:space-y-0">
// // // //           <CommonButton
// // // //             className={clsx(
// // // //               'sm:rounded-l-md sm:-ml-px',
// // // //               currentFilter === 'TODAY' &&
// // // //                 'bg-red-100 border-red-600 text-red-600'
// // // //             )}
// // // //             onClick={() => setCurrentFilter('TODAY')}
// // // //           >
// // // //             Today
// // // //           </CommonButton>

// // // //           <CommonButton
// // // //             className={clsx(
// // // //               currentFilter === 'THIS_WEEK' &&
// // // //                 'bg-red-100 border-red-600 text-red-600'
// // // //             )}
// // // //             onClick={() => setCurrentFilter('THIS_WEEK')}
// // // //           >
// // // //             Last 7 Days
// // // //           </CommonButton>

// // // //           <CommonButton
// // // //             className={
// // // //               currentFilter === 'THIS_MONTH' &&
// // // //               'bg-red-100 border-red-600 text-red-600'
// // // //             }
// // // //             onClick={() => setCurrentFilter('THIS_MONTH')}
// // // //           >
// // // //             This Month
// // // //           </CommonButton>

// // // //           <ChamakDatePickerRangeReports
// // // //             date={date}
// // // //             setDate={setDate}
// // // //             title="Custom Date"
// // // //             className={clsx(
// // // //               'relative whitespace-nowrap inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset text-center ring-gray-300 hover:bg-gray-50 focus:z-10 rounded-r-md',
// // // //               currentFilter === 'CUSTOM' &&
// // // //                 'bg-red-100 border-red-600 text-red-600'
// // // //             )}
// // // //             onClick={() => setCurrentFilter('CUSTOM')}
// // // //           />
// // // //         </div>
// // // //       </div>
// // // //       <SlideOver
// // // //         open={open}
// // // //         setOpen={setOpen}
// // // //         selectedId={selectedId}
// // // //         setSelectedId={setSelectedId}
// // // //         selectedIdType={selectedIdType}
// // // //         setSelectedIdType={setSelectedIdType}
// // // //         setApplyFilter={setApplyFilter}
// // // //       />
// // // //     </div>
// // // //   );
// // // // }

// // // // const CommonButton = ({ children, className, ...props }) => (
// // // //   <button
// // // //     type="button"
// // // //     className={cn(
// // // //       'relative whitespace-nowrap w-full sm:inline-flex sm:items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-red-100 focus:z-10',
// // // //       className
// // // //     )}
// // // //     {...props}
// // // //   >
// // // //     {children}
// // // //   </button>
// // // // );

// // // import { FunnelIcon } from '@heroicons/react/24/outline';
// // // import clsx from 'clsx';
// // // import React, { useState, useEffect } from 'react';
// // // import { cn } from '../../../lib/utils';
// // // import { ChamakDatePickerRangeReports } from '../../common/ChamakDatePickerRangeReports';
// // // import SlideOver from '../../common/SlideOver';

// // // export default function FilterTray({
// // //   currentFilter,
// // //   setCurrentFilter,
// // //   setDate,
// // //   date,
// // //   selectedIdType,
// // //   setSelectedIdType,
// // //   selectedId,
// // //   setSelectedId,
// // //   setApplyFilter
// // // }) {
// // //   const [open, setOpen] = useState(false);

// // //   // Update fromDate and toDate based on the selected date range
// // //   useEffect(() => {
// // //     if (date && date.length === 2) {
// // //       const [fromDate, toDate] = date;
// // //       setCurrentFilter((prev) => ({ ...prev, fromDate, toDate }));
// // //     }
// // //   }, [date, setCurrentFilter]);

// // //   return (
// // //     <div className="mt-4 sm:ml-16 sm:mt-6 sm:flex-none overflow-x-scroll scrollbar-none">
// // //       <div className="flex gap-4 sm:flex-row flex-col">
// // //         <div className="sm:flex rounded-md shadow-sm space-y-2 sm:space-y-0">
// // //           <CommonButton
// // //             className={clsx(
// // //               'sm:rounded-l-md sm:-ml-px',
// // //               currentFilter === 'TODAY' &&
// // //                 'bg-red-100 border-red-600 text-red-600'
// // //             )}
// // //             onClick={() => setCurrentFilter('TODAY')}
// // //           >
// // //             Today
// // //           </CommonButton>

// // //           <CommonButton
// // //             className={clsx(
// // //               currentFilter === 'THIS_WEEK' &&
// // //                 'bg-red-100 border-red-600 text-red-600'
// // //             )}
// // //             onClick={() => setCurrentFilter('THIS_WEEK')}
// // //           >
// // //             Last 7 Days
// // //           </CommonButton>

// // //           <CommonButton
// // //             className={clsx(
// // //               currentFilter === 'THIS_MONTH' &&
// // //                 'bg-red-100 border-red-600 text-red-600'
// // //             )}
// // //             onClick={() => setCurrentFilter('THIS_MONTH')}
// // //           >
// // //             This Month
// // //           </CommonButton>

// // //           <ChamakDatePickerRangeReports
// // //             date={date}
// // //             setDate={setDate}
// // //             title="Custom Date"
// // //             className={clsx(
// // //               'relative whitespace-nowrap inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset text-center ring-gray-300 hover:bg-gray-50 focus:z-10 rounded-r-md',
// // //               currentFilter === 'CUSTOM' &&
// // //                 'bg-red-100 border-red-600 text-red-600'
// // //             )}
// // //             onClick={() => setCurrentFilter('CUSTOM')}
// // //           />
// // //         </div>
// // //       </div>
// // //       <SlideOver
// // //         open={open}
// // //         setOpen={setOpen}
// // //         selectedId={selectedId}
// // //         setSelectedId={setSelectedId}
// // //         selectedIdType={selectedIdType}
// // //         setSelectedIdType={setSelectedIdType}
// // //         setApplyFilter={setApplyFilter}
// // //       />
// // //     </div>
// // //   );
// // // }

// // // const CommonButton = ({ children, className, ...props }) => (
// // //   <button
// // //     type="button"
// // //     className={cn(
// // //       'relative whitespace-nowrap w-full sm:inline-flex sm:items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-red-100 focus:z-10',
// // //       className
// // //     )}
// // //     {...props}
// // //   >
// // //     {children}
// // //   </button>
// // // );


// // import { FunnelIcon } from '@heroicons/react/24/outline';
// // import clsx from 'clsx';
// // import React, { useState } from 'react';
// // import { cn } from '../../../lib/utils';
// // import { ChamakDatePickerRangeReports } from '../../common/ChamakDatePickerRangeReports';
// // import SlideOver from '../../common/SlideOver';

// // export default function FilterTray({
// //   currentFilter,
// //   setCurrentFilter,
// //   setDate,
// //   date,
// //   selectedIdType,
// //   setSelectedIdType,
// //   selectedId,
// //   setSelectedId,
// //   setApplyFilter
// // }) {
// //   const [open, setOpen] = useState(false);

// //   const handleDateChange = (newDate) => {
// //     setDate({
// //       fromDate: newDate[0],
// //       toDate: newDate[1]
// //     });
// //     setCurrentFilter('CUSTOM');
// //     setApplyFilter(true); // Trigger filter application
// //   };

// //   return (
// //     <div className="mt-4 sm:ml-16 sm:mt-6 sm:flex-none overflow-x-scroll scrollbar-none">
// //       <div className="flex gap-4 sm:flex-row flex-col">
// //         <div className="sm:flex rounded-md shadow-sm space-y-2 sm:space-y-0">
// //           <CommonButton
// //             className={clsx(
// //               'sm:rounded-l-md sm:-ml-px',
// //               currentFilter === 'TODAY' &&
// //                 'bg-red-100 border-red-600 text-red-600'
// //             )}
// //             onClick={() => {
// //               setCurrentFilter('TODAY');
// //               setApplyFilter(true); // Trigger filter application
// //             }}
// //           >
// //             Today
// //           </CommonButton>

// //           <CommonButton
// //             className={clsx(
// //               currentFilter === 'THIS_WEEK' &&
// //                 'bg-red-100 border-red-600 text-red-600'
// //             )}
// //             onClick={() => {
// //               setCurrentFilter('THIS_WEEK');
// //               setApplyFilter(true); // Trigger filter application
// //             }}
// //           >
// //             Last 7 Days
// //           </CommonButton>

// //           <CommonButton
// //             className={clsx(
// //               currentFilter === 'THIS_MONTH' &&
// //                 'bg-red-100 border-red-600 text-red-600'
// //             )}
// //             onClick={() => {
// //               setCurrentFilter('THIS_MONTH');
// //               setApplyFilter(true); // Trigger filter application
// //             }}
// //           >
// //             This Month
// //           </CommonButton>

// //           <ChamakDatePickerRangeReports
// //             date={date}
// //             setDate={handleDateChange}
// //             title="Custom Date"
// //             className={clsx(
// //               'relative whitespace-nowrap inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset text-center ring-gray-300 hover:bg-gray-50 focus:z-10 rounded-r-md',
// //               currentFilter === 'CUSTOM' &&
// //                 'bg-red-100 border-red-600 text-red-600'
// //             )}
// //             onClick={() => setCurrentFilter('CUSTOM')}
// //           />
// //         </div>
// //       </div>
// //       <SlideOver
// //         open={open}
// //         setOpen={setOpen}
// //         selectedId={selectedId}
// //         setSelectedId={setSelectedId}
// //         selectedIdType={selectedIdType}
// //         setSelectedIdType={setSelectedIdType}
// //         setApplyFilter={setApplyFilter}
// //       />
// //     </div>
// //   );
// // }

// // const CommonButton = ({ children, className, ...props }) => (
// //   <button
// //     type="button"
// //     className={cn(
// //       'relative whitespace-nowrap w-full sm:inline-flex sm:items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-red-100 focus:z-10',
// //       className
// //     )}
// //     {...props}
// //   >
// //     {children}
// //   </button>
// // );

import { FunnelIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { useState } from 'react';
import { cn } from '../../../lib/utils';
import { ChamakDatePickerRangeReports } from '../../common/ChamakDatePickerRangeReports';
import SlideOver from '../../common/SlideOver';

export default function FilterTray({
  currentFilter,
  setCurrentFilter,
  setDate,
  date,
  selectedIdType,
  setSelectedIdType,
  selectedId,
  setSelectedId,
  setApplyFilter
}) {
  const [open, setOpen] = useState(false);

  const handleDateChange = (newDate) => {
    setDate({
      fromDate: newDate[0],
      toDate: newDate[1]
    });
    setCurrentFilter('CUSTOM');
    setApplyFilter(true); // Trigger filter application
  };

  return (
    <div className="mt-4 sm:ml-16 sm:mt-6 sm:flex-none overflow-x-scroll scrollbar-none">
      <div className="flex gap-4 sm:flex-row flex-col">
        {/* <div className="sm:flex rounded-md shadow-sm space-y-2 sm:space-y-0">
          <CommonButton
            className={clsx(
              'sm:rounded-l-md sm:-ml-px',
              currentFilter === 'TODAY' &&
                'bg-red-100 border-red-600 text-red-600'
            )}
            onClick={() => {
              setCurrentFilter('TODAY');
              setApplyFilter(true); // Trigger filter application
            }}
          >
            Today
          </CommonButton>

          <CommonButton
            className={clsx(
              currentFilter === 'THIS_WEEK' &&
                'bg-red-100 border-red-600 text-red-600'
            )}
            onClick={() => {
              setCurrentFilter('THIS_WEEK');
              setApplyFilter(true); // Trigger filter application
            }}
          >
            Last 7 Days
          </CommonButton>

          <CommonButton
            className={clsx(
              currentFilter === 'THIS_MONTH' &&
                'bg-red-100 border-red-600 text-red-600'
            )}
            onClick={() => {
              setCurrentFilter('THIS_MONTH');
              setApplyFilter(true); // Trigger filter application
            }}
          >
            This Month
          </CommonButton>

          <ChamakDatePickerRangeReports
            date={date}
            setDate={handleDateChange}
            title="Custom Date"
            className={clsx(
              'relative whitespace-nowrap inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset text-center ring-gray-300 hover:bg-gray-50 focus:z-10 rounded-r-md',
              currentFilter === 'CUSTOM' &&
                'bg-red-100 border-red-600 text-red-600'
            )}
            onClick={() => setCurrentFilter('CUSTOM')}
          />
        </div> */}
      </div>
      <SlideOver
        open={open}
        setOpen={setOpen}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        selectedIdType={selectedIdType}
        setSelectedIdType={setSelectedIdType}
        setApplyFilter={setApplyFilter}
      />
    </div>
  );
}

const CommonButton = ({ children, className, ...props }) => (
  <button
    type="button"
    className={cn(
      'relative whitespace-nowrap w-full sm:inline-flex sm:items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-red-100 focus:z-10',
      className
    )}
    {...props}
  >
    {children}
  </button>
);

import toast from 'react-hot-toast';
import { chamakApi } from '../../app/api';
import { setCredentials } from './authSlice';
import { clearOtpSession, setOTPSession } from './otpSlice';
import longDurationSuccessToast from '../../../components/common/CustomToast';

export const authApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: ({ email, password }) => ({
        url: '/api/auth/login/user',
        method: 'POST',
        body: { email, password }
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            if (data.data.nextStep === 'OTP_VERIFICATION') {
              toast.success('An otp has been sent to your email/phone');
              dispatch(
                setOTPSession({
                  email: _.email,
                  sessionId: data.data.sessionId
                })
              );
            } else {
              toast.success(`Welcome ${data?.data?.name}`);
              dispatch(
                setCredentials({
                  email: data.data.email,
                  name: data.data.name,
                  role: data.data.role,
                  warehouseName: data.data.warehouseName,
                  accessToken: data.data.token
                })
              );
              dispatch(clearOtpSession());
            }
          }
        } catch (error) {
          toast.error(error.error.data.message);
        }
      }
    }),

    verifyOtp: builder.mutation({
      query: ({ email, otp, sessionId }) => ({
        url: '/api/auth/verify-otp/',
        method: 'POST',
        body: { email, otp, sessionId }
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          toast.success(`Welcome ${data?.data?.name}`);

          dispatch(
            setCredentials({
              email: data.data.email,
              name: data.data.name,
              role: data.data.role,
              warehouseName: data.data.warehouseName,
              accessToken: data.data.token
            })
          );
          dispatch(clearOtpSession());
        } catch (error) {
          toast.error(error.error.data.message);
        }
      }
    }),

    resendOtp: builder.mutation({
      query: ({ email, sessionId }) => ({
        url: '/api/auth/resend-otp/',
        method: 'POST',
        body: { email, sessionId }
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;

          toast.success(`A new OTP has been sent to your email/phone`);
        } catch (error) {
          toast.error(error.error.data.message);
        }
      }
    }),
    forgotPassword: builder.mutation({
      query: ({ email }) => ({
        url: '/api/auth/forgot-password/',
        method: 'POST',
        body: { email }
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;
          longDurationSuccessToast('A link has been sent to your email', 20000);
        } catch (error) {
          toast.error(error.error.data.message);
        }
      }
    }),

    deliveryManagerSetPassword: builder.mutation({
      query: (body) => ({
        url: `/api/auth/reset-forgot-password`,
        method: 'POST',
        body
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          await queryFulfilled;

          toast.success('Your password has been re-sent');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      }
    })
  })
});

export const {
  useUserLoginMutation,
  useVerifyOtpMutation,
  useResendOtpMutation,
  useForgotPasswordMutation,
  useDeliveryManagerSetPasswordMutation
} = authApi;

import toast from 'react-hot-toast';
import { chamakApi } from '../../app/api';
import { appendParamsToUrl } from '../../../lib/utils';

export const smsApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query({
      query: ({ ...query }) => ({
        url: `/api/users?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getUser']
    }),
    createSms: builder.mutation({
      query: ({ ...query }) => ({
        url: '/api/send-sms/bulk',
        method: 'POST',
        body: { ...query }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('SMS has been sent!');
        } catch (error) {
          toast.error('Failed to send SMS');
        }
      },
      invalidatesTags: [
      ]
    }),
    
  })
});

export const {
  useCreateSmsMutation,
  useGetUserQuery
} = smsApi;

import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import useQuery from '../../../hooks/useQuery';
import {
  useGetOneUserQuery,
  useUpdateUserMutation
} from '../../../store/features/users/usersApi';
import DropdownSelect from '../../common/DropdownSelect';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage
} from '../../common/Form';
import { UserRole } from '../../../data/UserRole';
import { useGetWarehouseQuery } from '../../../store/features/warehouse/warehouseApi';
import toast from 'react-hot-toast';

const accountStatus = [
  { id: 1, name: 'ACTIVE' },
  { id: 2, name: 'INACTIVE' }
];

const emailRegex = /^$|^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const userSchema = z.object({
  name: z.string().min(1, 'Name is required').max(50, 'Too Long'),
  phone: z.string().regex(/^\+?(88)?0?1[3456789][0-9]{8}\b/, 'Invalid phone'),
  email: z
    .string()
    .max(50)
    .refine((value) => value === '' || emailRegex.test(value), {
      message: 'Invalid email'
    })
    .optional()
});

export default function EditUsersForm() {
  const userId = useQuery().get('userId');
  const { currentData } = useGetOneUserQuery({
    userId
  });

  const [selected, setSelected] = useState(
    accountStatus[0] ?? { id: '', name: '' }
  );

  const [page, setPage] = useState(1);
  const [selectedWarehouse, setSelectedWarehouse] = useState({
    id: '',
    name: 'Select Warehouse'
  });

  const [warehouseName, setWarehouseName] = useState([]);
  const [selectedRole, setSelectedRole] = useState({
    id: 0,
    name: 'Select Role'
  });

  const methods = useForm({
    resolver: zodResolver(userSchema),
    mode: 'onChange',
    values: {
      ...currentData?.data,
      email: currentData?.data?.email || ''
    }
  });

  useEffect(() => {
    setSelectedRole({
      id:
        UserRole.find((status) => status?.name === currentData?.data?.UserRole)
          ?.id ?? '',
      name: currentData?.data?.role ?? ''
    });
  }, [currentData]);

  const [
    callUpdateUserMutation,
    {
      isSuccess: isUpdateUserMutationSuccess,
      isLoading: updateUserMutationLoading
    }
  ] = useUpdateUserMutation();

  const { data: warehouseData, isLoading: getWarehouseQueryLoading } =
    useGetWarehouseQuery({});

  useEffect(() => {
    if (warehouseData?.data?.warehouses) {
      setWarehouseName(
        warehouseData?.data?.warehouses?.map((warehouse) => ({
          id: warehouse?.id,
          name: warehouse?.name
        }))
      );
    }
  }, [warehouseData]);

  useEffect(() => {
    setSelected({
      id:
        accountStatus.find(
          (status) => status?.name === currentData?.data?.accountStatus
        )?.id ?? '',
      name: currentData?.data?.accountStatus ?? ''
    });
  }, [currentData]);

  useEffect(() => {
    setSelectedWarehouse({
      id: currentData?.data?.warehouseId,
      name: warehouseName.find(
        (status) => status?.id === currentData?.data?.warehouseId
      )?.name
    });
  }, [currentData, warehouseName]);

  const navigate = useNavigate();

  const onSubmit = (data) => {
    if (
      selectedRole?.name === 'SUPER_ADMIN' ||
      selectedRole?.name === 'DATA_USER'
    ) {
      const { email, ...body } = data;
      callUpdateUserMutation({
        ...body,
        role: selectedRole?.name,
        accountStatus: selected?.name,
        userId
      });
    } else {
      if (!selectedWarehouse?.id) {
        // Display an error toast message
        toast.error('Please select a warehouse before saving.');
        return; // Exit the function to prevent updating the user
      }
      const { email, ...body } = data;
      callUpdateUserMutation({
        ...body,
        role: selectedRole?.name,
        accountStatus: selected?.name,
        warehouseId: selectedWarehouse?.id,
        userId
      });
    }
  };

  useEffect(() => {
    if (isUpdateUserMutationSuccess) {
      navigate('/app/users/view');
    }
  }, [isUpdateUserMutationSuccess]);

  return (
    <Form {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
      >
        <div className="px-4 py-6 sm:p-8">
          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <FormField
                name="name"
                control={methods.control}
                render={({ field }) => (
                  <FormItem>
                    <label
                      htmlFor="user-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      User Name*
                    </label>
                    <FormControl>
                      <div className="mt-2">
                        <input
                          type="text"
                          {...field}
                          id="user-name"
                          placeholder={'Enter User name'}
                          className={clsx(
                            'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                          )}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="role"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Select Role*
              </label>
              <div className="mt-2">
                <DropdownSelect
                  data={UserRole}
                  selected={selectedRole}
                  setSelected={setSelectedRole}
                />
              </div>
            </div>

            {currentData?.data?.role !== 'SALES_ASSOCIATE' &&
            currentData?.data?.role !== 'CUSTOMER' &&
            currentData?.data?.role !== 'DELIVERY_ASSOCIATE' ? (
              <div className="sm:col-span-3">
                <FormField
                  name="email"
                  control={methods.control}
                  render={({ field }) => (
                    <FormItem>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email Address*
                      </label>
                      <FormControl>
                        <div className="mt-2">
                          <input
                            type="email"
                            {...field}
                            id="email"
                            disabled
                            readOnly
                            placeholder={'Enter Email'}
                            className={clsx(
                              'disabled:bg-gray-200 disabled:cursor-not-allowed block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                            )}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            ) : (
              <div className="sm:col-span-3">
                <FormField
                  name="email"
                  control={methods.control}
                  render={({ field }) => (
                    <FormItem>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email Address*
                      </label>
                      <FormControl>
                        <div className="mt-2">
                          <input
                            type="email"
                            {...field}
                            id="email"
                            placeholder={'Enter Email'}
                            className={clsx(
                              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                            )}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}

            <div className="sm:col-span-3">
              <label
                htmlFor="accountStatus"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Account Status*
              </label>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <DropdownSelect
                    data={accountStatus}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </div>
              </div>
            </div>

            {!['Select Role', 'SUPER_ADMIN', 'DATA_USER'].includes(
              selectedRole?.name
            ) && (
              <div className="sm:col-span-3">
                <label
                  htmlFor="warehouse"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Select Warehouse*
                </label>
                <div className="mt-2">
                  <DropdownSelect
                    data={warehouseName}
                    isLoading={getWarehouseQueryLoading}
                    selected={selectedWarehouse}
                    setSelected={setSelectedWarehouse}
                    placeholder="Select Warehouse"
                  />
                  {/* {methods.formState.isSubmitted &&
                    !methods.getValues('warehouseId') && (
                      <p className="text-red-600">Please select a warehouse.</p>
                    )} */}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
          <button
            type="button"
            onClick={() => navigate('/app/users/view')}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={updateUserMutationLoading}
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
          >
            Save
          </button>
        </div>
      </form>
    </Form>
  );
}

import React from 'react';

export default function ChangeLogRoot() {
  return (
    <div className="flex flex-col gap-10">
      <div>
        <h1 className="text-3xl font-bold mb-2">v0.95</h1>
        <ul className="space-y-2 text-gray-500">
          <li>
            1. Fix district name sorting not working on Warehouse create/edit
          </li>
        </ul>
      </div>
      <div>
        <h1 className="text-3xl font-bold mb-2">v0.94</h1>
        <ul className="space-y-2 text-gray-500">
          <li>
            1. Default date is set to next day automatically when creating
            shipments
          </li>
          <li>
            2. Assigning delivery manager is no longer required when creating a
            Warehouse
          </li>
          <li>3. Added line break before phone number in PDF export</li>
          <li>4. Delivery instruction is now set to optional</li>
          <li>5. District and Upazila names are now sorted and easy to find</li>
        </ul>
      </div>
    </div>
  );
}

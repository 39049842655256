import { PlusCircleIcon, UserCircleIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useEffect, useState } from 'react';
import Divider from './Divider';
import AssignMultipleModals from './AssignModal/AssignMultipleModals';
import { useGetProductManagementQuery } from '../../store/features/product-management/productManagementApi';

export default function MultipleProductPickup({ selected, handleRoutePickUpIds }) {
  const [selectedItems, setSelectedItems] = useState([]);

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');

  const { currentData } = useGetProductManagementQuery({
    page: 1,
    nameEn: query || undefined
  });

  
  const handleSetSelected = (newSelected) => {
    setSelectedItems(newSelected);
    handleRoutePickUpIds(newSelected);
    setOpen(false);
  };
  
  const handleRemoveSelected = (id) => {
    setSelectedItems(selectedItems?.filter((item) => item.id !== id));
    handleRoutePickUpIds(selectedItems?.filter((item) => item.id !== id));
  };
  
  useEffect(() => {
    selected?.length > 0 && setSelectedItems(selected)
  }, [selected])

  return (
    <div>
      <h4 className="font-bold mb-2 block leading-6 text-gray-700">
        Assign Product
      </h4>
      <Divider className="my-2" />

      {/* Render unique selected items */}
      {selectedItems?.map((item, index) => {
        return (
          <Fragment key={index}>
            <div className="flex items-center justify-between">
              <div className="my-1 flex items-center">
                <UserCircleIcon className="h-12 w-12 text-gray-400" />
                <span className="font-medium ml-2 text-gray-600">
                  {item.nameEn}
                </span>
              </div>
              <div>
                <span
                  className="font-medium text-red-600 hover:text-red-500 cursor-pointer"
                  onClick={() => handleRemoveSelected(item.id)}
                >
                  Remove
                </span>
              </div>
            </div>
            <Divider />
          </Fragment>
        );
      })}

      <div className="flex items-center">
        <div
          onClick={() => setOpen(true)}
          className="mt-2 flex items-center cursor-pointer py-2 pr-4 hover:bg-gray-100 rounded-xl transition-all hover:shadow-sm"
        >
          <PlusCircleIcon className="h-12 w-12 text-gray-400" />
          <span className="font-medium ml-2 text-red-600">Assign</span>
        </div>
      </div>

      <AssignMultipleModals
        open={open}
        setOpen={setOpen}
        query={query}
        setQuery={setQuery}
        title="Product(s)"
        setSelected={handleSetSelected}
        selected={selectedItems}
        dataList={currentData?.data?.items ?? []}
      />
    </div>
  );
}

import toast from 'react-hot-toast';
import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const deliveryPersonApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getDeliveryPersons: builder.query({
      query: ({ ...query }) => ({
        url: `/api/delivery-persons?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getDeliveryPersons']
    }),
    getDeliveryPersonsForCashCollection: builder.query({
      query: ({ ...query }) => ({
        url:
          `/api/delivery-persons/for-cash-collection?` +
          appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getDeliveryPersonsForCashCollection']
    }),
    getOneDeliveryPerson: builder.query({
      query: ({ deliveryPersonId }) => ({
        url: `/api/delivery-persons/${deliveryPersonId}/details`,
        method: 'GET'
      }),
      providesTags: ['getOneDeliveryPerson']
    }),
    createDeliveryPerson: builder.mutation({
      query: ({
        name,
        email,
        phone,
        NID,
        full,
        village,
        policeStation,
        district
      }) => ({
        url: '/api/delivery-persons',
        method: 'POST',
        body: {
          name,
          email,
          phone,
          NID,
          address: {
            full,
            village,
            policeStation,
            district
          }
        }
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getDeliveryPersons', 'getOneDeliveryPerson']
    }),
    getCashCollectableList: builder.query({
      query: ({ page, status, deliveryPersonId }) => ({
        url: `/api/delivery-persons/${deliveryPersonId}/shipments-for-cash-collection?paymentCollectionStatus=${status}&page=${page}`,
        method: 'GET'
      }),
      providesTags: ['getCashCollectableList']
    }),
    collectCash: builder.mutation({
      query: ({ deliveryPersonId, shipmentIds, collectedAmount, remarks }) => ({
        url: `/api/delivery-persons/${deliveryPersonId}/collect-cash`,
        method: 'POST',
        body: { shipmentIds, collectedAmount, remarks }
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          const { data } = await queryFulfilled;
          toast.success(data.data.message ?? 'Cash Collected');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getCashCollectableList',
        'getDeliveryPersons',
        'getDeliveryPersonsForCashCollection'
      ]
    }),
    getDeliveryPersonDetails: builder.query({
      query: ({ id }) => ({
        url: `/api/delivery-persons/${id}/details`,
        method: 'GET'
      })
    }),
    updateDeliveryPerson: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/api/delivery-persons/${id}/details`,
        method: 'PUT',
        body
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getDeliveryPersons', 'getOneDeliveryPerson']
    })
  })
});

export const {
  useCreateDeliveryPersonMutation,
  useGetCashCollectableListQuery,
  useCollectCashMutation,
  useGetOneDeliveryPersonQuery,
  useGetDeliveryPersonsForCashCollectionQuery,
  useGetDeliveryPersonDetailsQuery,
  useUpdateDeliveryPersonMutation,
  useGetDeliveryPersonsQuery
} = deliveryPersonApi;

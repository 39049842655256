import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import useQuery from '../../../hooks/useQuery';
import { useGetOneDataUserQuery } from '../../../store/features/data-user/dataUserApi';
import DetailsDataUser from '../../../components/app/data-user/DetailsDataUser';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Details'
};

export default function DataUserDetails() {
  const query = useQuery();

  const { currentData } = useGetOneDataUserQuery({
    dataUserId: query.get('dataUserId')
  });

  const pages = [
    {
      name: 'Data User',
      location: '/app/data-user',
      current: false
    },
    {
      name: 'Details',
      location: `/app/data-user/details?=${query.get('dataUserId')}`,
      current: true
    }
  ];

  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <DetailsDataUser currentData={currentData} />
    </div>
  );
}

import { z } from 'zod';

const pickupLocationSchema = z.object({
  name: z.string().min(1, 'Name is required').max(25, 'Invalid name'),
  contactPhone: z
    .string()
    .regex(/^\+?(88)?0?1[3456789][0-9]{8}\b/, 'Invalid phone'),
  contactEmail: z
    .string()
    .regex(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email')
    .max(50, 'Invalid email'),
  address: z.object({
    village: z.string().min(1, 'Village is required'),
    full: z.string().min(1, 'Full address is required')
  }),
  mapLink: z
    .string()
    .min(1, 'Map link is required')
    .regex(/@(-?\d+\.\d+),(-?\d+\.\d+)/, 'Invalid map link')
});

export default pickupLocationSchema;

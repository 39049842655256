import { PhoneIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../components/common/DataTable/Table';
import DebouncedSearchTab from '../../../components/common/DebouncedSearchTab';
import TablePagination from '../../../components/common/TablePagination';
import { useGetDeliveryAssociateQuery } from '../../../store/features/delivery-associate/deliveryAssociateApi';

const rootMeta = {
  page_name: 'Delivery Associates',
  breadcrumbs: null
};

const columnHelper = createColumnHelper();

const columns = [
  columnHelper.accessor(
    (row) => (
      <Fragment>
        <div>{row.name}</div>
      </Fragment>
    ),
    {
      cell: (info) => info.getValue(),
      header: 'NAME',
    }
  ),

  columnHelper.accessor(
    (row) => (
      <Fragment>
        <div className="flex items-center gap-1">
          <span>{row.phone}</span>
          <a className="flex items-center gap-1" href={`tel:${row.phone}`}>
            <span className="text-red-600">
              <PhoneIcon width={16} />
            </span>
            <span className="text-red-600 font-medium">Call</span>
          </a>
        </div>
      </Fragment>
    ),
    {
      cell: (info) => info.getValue(),
      header: 'MAKE A CALL'
    }
  ),
  columnHelper.accessor(
    (row) => (
      <Fragment>
        <div>{row.warehouse?.name}</div>
      </Fragment>
    ),
    {
      cell: (info) => info.getValue(),
      header: 'WAREHOUSE NAME',
    }
  ),

  columnHelper.accessor(
    (row) => (
      <Fragment>
        <Link
          to={`/app/cash-collection/collect?deliveryAssociateId=${row.id}&deliveryAssociateName=${row.name}`}
        >
          <button className="text-red-600 hover:underline">Details</button>
        </Link>
      </Fragment>
    ),
    {
      cell: (info) => info.getValue(),
      header: 'ACTION',
    }
  )
];

const searchModes = [
  {
    param_name: 'Name',
    param: 'name'
  },
  {
    param_name: 'Phone',
    param: 'phone'
  }
];

export default function CashCollectionRoot() {
  const [page, setPage] = useState(1);
  const [searchMode, setSearchMode] = useState('name');
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const { currentData, isLoading } = useGetDeliveryAssociateQuery(
    searchValue
      ? {
          page,
          [searchMode]: searchValue
        }
      : {
          page
        }
  );

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <DebouncedSearchTab
              modes={searchModes}
              handleSearch={handleSearch}
              setValue={setSearchValue}
              setMode={setSearchMode}
              value={searchValue}
              isLoading={isLoading}
              data={currentData}
            />
            {/* <Link
							to="/portal/manage-delivery-person/create"
							type="button"
							className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
						>
							<PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
							Registration
						</Link> */}
          </div>
        </div>
      </div>
      <Table data={currentData?.data?.users ?? []} columns={columns} />
      <TablePagination
        paginationData={currentData?.data?.meta ?? undefined}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

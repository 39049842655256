import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const activityLogApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getActivityLog: builder.query({
      query: ({ ...query }) => ({
        url: `/api/audit-logs?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getActivityLog']
    }),
    getOneStockUpdateHistory: builder.query({
      query: ({ inventoryId }) => ({
        url: `/api/audit-logs/inventories/${inventoryId}`,
        method: 'GET'
      }),
      providesTags: ['getOneActivityLog']
    }),
})
});

export const {
  useGetActivityLogQuery,
  useGetOneStockUpdateHistoryQuery,
} = activityLogApi;
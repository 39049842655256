import clsx from 'clsx';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useMapShopAutoCompleteQuery } from '../../../store/features/map-shop/mapShopApi';
import { useCreateShipmentMutation } from '../../../store/features/shipment/shipmentApi';
import AutoCompleteChamakIds from '../../common/AutoCompleteChamakIds';
import { ShipmentDatePicker } from './date-picker/ShipmentDatePicker';

export default function ShipmentForm({ dirtyData }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm({
    mode: 'onChange'
  });

  const navigate = useNavigate();

  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  const [selectedUnit, setSelectedUnit] = useState('KG');
  const [date, setDate] = useState(currentDate);
  const [createShipment] = useCreateShipmentMutation();
  const [chamakId, setChamakId] = useState('');
  const [chamakIdSelected, setChamakIdSelected] = useState(false);
  const { currentData: chamakIdData } = useMapShopAutoCompleteQuery(
    {
      page: 1,
      chamakId
    },
    {
      skip: !chamakId
    }
  );

  const onSubmit = async (data) => {
    if (date !== '') {
      const body = {
        chamakId: chamakId,
        orderId: data.orderId,
        totalPayable: +data.totalPayable,
        totalPackages: +data.totalPackages,
        weight: {
          total: +data.grossWeight,
          unit: selectedUnit
        },
        dateOfDelivery: dayjs(date).toISOString(),
        deliveryInstruction: data.deliveryInstruction
      };
      try {
        const res = await createShipment({ body }).unwrap();
        navigate(`/app/shipment/detail?shipmentId=${res.data.shipmentId}`);
      } catch (error) { }
    } else {
      toast.error('Please select a date');
    }
  };

  // useEffect(() => {
  // 	isSuccess &&

  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSuccess]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
    >
      <div className="px-4 py-6 sm:p-8">
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Shipped to*
            </label>
            <div className="mt-2 relative">
              <input
                {...register('chamakId', { required: 'Required' })}
                placeholder={'Search Chamak ID or Phone'}
                onChange={(e) => {
                  setChamakId(e.target.value);
                  setChamakIdSelected(false);
                  if (e.target.value === '') {
                    setError('chamakId', {
                      type: 'manual',
                      message: 'Required'
                    });
                  } else {
                    setError('chamakId', {
                      type: 'manual',
                      message: ''
                    });
                  }
                }}
                value={chamakId}
                className={clsx(
                  'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                )}
              />
              {chamakIdData?.data?.shops.length > 0 && !chamakIdSelected && (
                <AutoCompleteChamakIds
                  data={chamakIdData?.data?.shops ?? []}
                  setChamakId={setChamakId}
                  isSelected={chamakIdSelected}
                  setIsSelected={setChamakIdSelected}
                />
              )}
              {/* {chamakIdData?.data?.shops && !chamakIdSelected && (
                <div className="absolute inset-x-0 mt-3 rounded-lg p-3 z-[99999999999] bg-white border shadow-sm">
                  <ul className="divide-y space-y-2 divide-gray-200 max-h-48 overflow-y-scroll scroll- overflow-hidden scrollbar-none">
                    {chamakIdData?.data?.shops?.map((item) => (
                      <li
                        key={item?.id}
                        className="px-2 py-1 hover:bg-slate-200 rounded-sm cursor-pointer"
                        onClick={() => {
                          setChamakId(item?.chamakId);
                          setChamakIdSelected(true);
                        }}
                      >
                        <p>
                          {item?.name}{" "}
                          <span className="text-gray-500">
                            #{item?.chamakId}
                          </span>
                        </p>
                        <p className="text-xs text-gray-500">
                          {item?.ownerName}, {item?.phone}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              )} */}
            </div>
            <div className="text-red-500">
              {errors.chamakId && errors.chamakId.message}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Shipped for*
            </label>
            <div className="mt-2">
              <input
                {...register('orderId', { required: 'Required' })}
                placeholder={'Order ID'}
                className={clsx(
                  'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                )}
              />
            </div>
            <div className="text-red-500">
              {errors.orderId && errors.orderId.message}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Total Payable*
            </label>
            <div className="mt-2">
              <input
                {...register('totalPayable', { required: 'Required' })}
                placeholder={'Enter total amount payable'}
                type="number"
                className={clsx(
                  'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                )}
              />
            </div>
            <div className="text-red-500">
              {errors.totalPayable && errors.totalPayable.message}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              {`Total No of Package (Optional)`}
            </label>
            <div className="mt-2">
              <input
                {...register('totalPackages')}
                placeholder={'Enter total number of packages'}
                type="number"
                className={clsx(
                  'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                )}
              />
            </div>
            <div className="text-red-500">
              {errors.totalPackages && errors.totalPackages.message}
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              {`Gross Weight (Optional)`}
            </label>
            <div className="mt-2 relative">
              <input
                {...register('grossWeight')}
                type="number"
                placeholder={'Enter gross weight of the product'}
                className={clsx(
                  'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                )}
              />
              <div className="absolute inset-y-0 right-0 flex items-center bg-slate-200 rounded-r-md">
                <label htmlFor="Mode" className="sr-only">
                  Unit
                </label>
                <select
                  id="Mode"
                  name="params"
                  className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-800 focus:ring-0 sm:text-sm"
                  onChange={(e) => {
                    setSelectedUnit(e.target.value);
                  }}
                >
                  {Array.from(['KG', 'GM', 'LT']).map((unit, index) => (
                    <option value={unit} key={index}>
                      {unit}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              {`Date of Delivery*`}
            </label>
            <div className="mt-2">
              <ShipmentDatePicker date={date} setDate={setDate} />
            </div>
          </div>

          <div className="sm:col-span-6">
            <label className="block text-sm font-medium leading-6 text-gray-900">
              Delivery Instruction (Optional)
            </label>
            <div className="mt-2">
              <textarea
                {...register('deliveryInstruction')}
                placeholder={'Add your delivery instruction'}
                className={clsx(
                  'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                )}
              />
            </div>
            <div className="text-red-500">
              {errors.deliveryInstruction && errors.deliveryInstruction.message}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
        <button
          type="button"
          onClick={() => navigate('/app/shipment/view')}
          className="text-sm font-semibold leading-6 text-gray-900"
        >
          Cancel
        </button>

        {dirtyData ? (
          <button
            type="submit"
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
          >
            Save
          </button>
        ) : (
          <button
            type="submit"
            className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:hover:cursor-not-allowed disabled:bg-gray-500"
          >
            Create Shipment
          </button>
        )}
      </div>
    </form>
  );
}

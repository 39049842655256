import { useEffect, useRef } from "react";

export default function TableCheckbox({
	indeterminate,
	className = "",
	...rest
}) {
	const ref = useRef(null);

	useEffect(() => {
		if (typeof indeterminate === "boolean") {
			ref.current.indeterminate = !rest.checked && indeterminate;
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref, indeterminate]);

	return (
		<input
			type="checkbox"
			ref={ref}
			className={
				className +
				" cursor-pointer h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
			}
			{...rest}
		/>
	);
}

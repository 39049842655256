import { z } from 'zod';

export const productManagementSchema = z.object({
  nameBn: z.string().min(1, 'Name is required'),
  nameEn: z.string().min(1, 'Name is required'),
  sku: z.string().min(1, 'SKU Number is required'),
  shortNameBn: z
    .string()
    .min(1, 'Short Name is required'),
  shortNameEn: z
    .string()
    .min(1, 'Short Name is required'),
  returnInDays: z.string().min(1, 'Refund days is required'),
  photo1: z.string(),
  photo2: z.string().optional(),
  shortDescriptionBn: z.string().optional(),
  shortDescriptionEn: z.string().optional(),
  supplier: z.string().min(1, 'Supplier is required'),
  unitQuantity: z.coerce.number(),
  unitType: z.string(),
  unitWeight: z.coerce.number()
});

export const productSchemaLabel = {
  nameBn: 'Name (In Bangla)',
  nameEn: 'Name (In English)',
  sku: 'SKU',
  shortNameBn:'Short Name*(In Bangla)',
  shortNameEn: 'Short Name*(In English)',
  returnInDays: 'Refund days',
  photo1: 'Image 1',
  photo2: 'Image 2',
  shortDescriptionBn: 'Short Description (In Bangla)',
  shortDescriptionEn: 'Short Description (In English)',
  brand: 'Brand',
  supplier: 'Vendor/Supplier',
  unitQuantity: 'Unit Quantity',
  unitType: 'Unit Type',
  unitWeight: 'Weight (KG)'
};

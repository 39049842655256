import { createColumnHelper } from '@tanstack/react-table';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Table from '../../../components/common/DataTable/Table';
import TablePagination from '../../../components/common/TablePagination';
import { getQueryParams } from '../../../lib/utils';
import { useGetOneStockUpdateHistoryQuery } from '../../../store/features/activity-log/activityLogApi';
import dayjs from 'dayjs';

export default function DetailsStockUpdateHistory() {
  const location = useLocation();
  const queryParamsString = location.search;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});

  const { currentData } = useGetOneStockUpdateHistoryQuery(
    !isEmpty(selectedData)
      ? {
          page,
          ...selectedData
        }
      : {
          page
        }
  );

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.user?.name}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'USER NAME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row?.user?.role}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'USER ROLE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.title}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'DETAILS',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>
            {row.changes.map((change) => (
              <div key={change.key}>
                {`${change.key}: ${change.from} -> ${change.to}`}
              </div>
            ))}
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'Changes',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),

    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex items-center gap-1">
            <div>{dayjs(row.timestamp).format('hh:mm A')}</div>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'TIME',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex items-center gap-1">
            <div>{dayjs(row.timestamp).format('DD MMM YYYY')}</div>
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'DATE',
        meta: { align: 'center', style: { textAlign: 'center' } }
      }
    )
  ];

  useEffect(() => {
    setPage(1);
  }, [selectedData]);

  useEffect(() => {
    setSelectedData(getQueryParams(queryParamsString));
  }, [queryParamsString]);

  return (
    <div className="sm:px-4">
      <Table data={currentData?.data?.items ?? []} columns={columns} />
      <TablePagination
        paginationData={currentData?.data?.meta ?? undefined}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

/// Redux Persist

import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage"; // Choose your storage solution

import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistReducer,
	persistStore,
} from "redux-persist";

import { chamakApi } from "./app/api";
import authReducer from "./features/auth/authSlice";
import otpReducer from "./features/auth/otpSlice";

const rootReducer = combineReducers({
	[chamakApi.reducerPath]: chamakApi.reducer,
	auth: authReducer,
	otp: otpReducer,
});

const persistConfig = {
	key: "root",
	storage,
	blacklist: [chamakApi.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddlewares) =>
		getDefaultMiddlewares({
			serializableCheck: {
				ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REHYDRATE, REGISTER],
			},
		}).concat(chamakApi.middleware),
	devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

import dayjs from 'dayjs';
import React, { useEffect, useState, useRef } from 'react';
import FilterTray from '../../../components/app/reports/FilterTray';
import ReportDownloader from '../../../components/app/reports/ReportDownloader';
import StatsMarker from '../../../components/app/reports/StatsMarker';
import { useGetReportsSuperAdminQuery } from '../../../store/features/reports/reportsApi';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const rootMeta = {
  page_name: 'Warehouse Dashboard'
};

export default function ReportsRootWarehouse() {
  const [customDate, setCustomDate] = useState({
    fromDate: dayjs().format('YYYY-MM-DD'),
    toDate: dayjs().format('YYYY-MM-DD')
  });

  const [applyFilter, setApplyFilter] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('TODAY');
  const [selectedIdType, setSelectedIdType] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [isFromCalendarOpen, setIsFromCalendarOpen] = useState(false);
  const [isToCalendarOpen, setIsToCalendarOpen] = useState(false);

  const fromCalendarRef = useRef(null);
  const toCalendarRef = useRef(null);

  const { currentData } = useGetReportsSuperAdminQuery({
    fromDate: customDate.fromDate,
    toDate: customDate.toDate,
    ...(applyFilter && {
      idType: selectedIdType?.key,
      id: selectedId?.id
    })
  });

  useEffect(() => {
    if (currentFilter !== 'CUSTOM') {
      setCustomDate({
        fromDate: dayjs().format('YYYY-MM-DD'),
        toDate: dayjs().format('YYYY-MM-DD')
      });
    }
  }, [currentFilter]);

  useEffect(() => {
    if (applyFilter) {
      setApplyFilter(false);
    }
  }, [applyFilter]);

  const handleFromDateChange = (date) => {
    if (date) {
      const newFromDate = dayjs(date).format('YYYY-MM-DD');
      setCustomDate((prev) => ({
        ...prev,
        fromDate: newFromDate,
        toDate: dayjs(newFromDate).isAfter(prev.toDate)
          ? newFromDate
          : prev.toDate
      }));
      setIsFromCalendarOpen(false);
    }
  };

  const handleToDateChange = (date) => {
    if (date) {
      const newToDate = dayjs(date).format('YYYY-MM-DD');
      setCustomDate((prev) => ({
        ...prev,
        toDate: newToDate
      }));
      setIsToCalendarOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (
      fromCalendarRef.current &&
      !fromCalendarRef.current.contains(event.target)
    ) {
      setIsFromCalendarOpen(false);
    }
    if (
      toCalendarRef.current &&
      !toCalendarRef.current.contains(event.target)
    ) {
      setIsToCalendarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="sm:px-4">
      <div className="sm:flex justify-between items-center">
        <h1 className="text-3xl font-semibold leading-6 text-gray-900">
          {rootMeta.page_name}
        </h1>
        <div className="flex space-x-4">
          <div className="relative" ref={fromCalendarRef}>
            <button
              onClick={() => setIsFromCalendarOpen(!isFromCalendarOpen)}
              className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
            >
              From Date
            </button>
            {isFromCalendarOpen && (
              <div className="absolute right-0 mt-2 bg-white border border-gray-300 rounded shadow-lg z-10">
                <DayPicker
                  mode="single"
                  selected={dayjs(customDate.fromDate).toDate()}
                  onSelect={handleFromDateChange}
                  disabled={{
                    after: dayjs(customDate.toDate).toDate()
                  }}
                />
              </div>
            )}
          </div>
          <div className="relative" ref={toCalendarRef}>
            <button
              onClick={() => setIsToCalendarOpen(!isToCalendarOpen)}
              className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
            >
              To Date
            </button>
            {isToCalendarOpen && (
              <div className="absolute right-0 mt-2 bg-white border border-gray-300 rounded shadow-lg z-10">
                <DayPicker
                  mode="single"
                  selected={dayjs(customDate.toDate).toDate()}
                  onSelect={handleToDateChange}
                  disabled={{
                    before: dayjs(customDate.fromDate).toDate()
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mt-2 flex justify-end">
        <div className="p-2 border border-gray-300 rounded shadow-sm bg-white inline-block">
          <p className="text-gray-900 font-semibold whitespace-nowrap">
            {`${dayjs(customDate.fromDate).format('MMM DD, YYYY')} - ${dayjs(
              customDate.toDate
            ).format('MMM DD, YYYY')}`}
          </p>
        </div>
      </div>
      <div className="flex items-end justify-end">
        <FilterTray
          enableSidebarFilter={true}
          currentFilter={currentFilter}
          setCurrentFilter={setCurrentFilter}
          setDate={setCustomDate}
          date={customDate}
          selectedIdType={selectedIdType}
          setSelectedIdType={setSelectedIdType}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setApplyFilter={setApplyFilter}
        />
      </div>
      {/* <ReportDownloader rawData={currentData?.data} /> */}
      <StatsMarker stats={currentData?.data ?? {}} />
    </div>
  );
}

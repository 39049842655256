import toast from 'react-hot-toast';
import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const dataUserApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getDataUser: builder.query({
      query: ({ ...query }) => ({
        url: `/api/users?` + appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getDataUser']
    }),
    createDataUser: builder.mutation({
      query: ({ ...query }) => ({
        url: '/api/users/data-users',
        method: 'POST',
        body: { ...query}
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;

          toast.success('Data User has been created!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getDataUser','getUser'
        // 'getOneUser, getUnAssignedUsers'
      ]
    }),
    getOneDataUser: builder.query({
      query: ({ dataUserId }) => ({
        url: `/api/users/data-users/${dataUserId}`,
        method: 'GET'
      }),
      providesTags: ['getOneDataUserWithId']
    }),
    updateDataUser: builder.mutation({
      query: ({ dataUserId, ...query }) => ({
        url: `/api/users/data-users/${dataUserId}`,
        method: 'PUT',
        body: { ...query}
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Data User has been updated!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getUser', 'getDataUser',
        'getOneDataUserWithId'
      ]
    })
  })
});

export const {
  useGetDataUserQuery,
  useCreateDataUserMutation,
  useGetOneDataUserQuery,
  useUpdateDataUserMutation,
} = dataUserApi;

import { Switch } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/20/solid';
import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ApplyFilter from '../../../components/common/ApplyFilter';
import Table from '../../../components/common/DataTable/Table';
import TablePagination from '../../../components/common/TablePagination';
import {
  useGetWarehouseQuery,
  useWarehouseChangeOpsStatusMutation
} from '../../../store/features/warehouse/warehouseApi';
import { getQueryParams, mapFilterToUrlParam } from '../../../lib/utils';

const rootMeta = {
  page_name: 'Warehouse',
  breadcrumbs: null
};

const columnHelper = createColumnHelper();

const searchModes = [
  {
    param_name: 'Name',
    param: 'name',
    type: 'text'
  },
  {
    param_name: 'Email',
    param: 'contactEmail',
    type: 'text'
  },
  {
    param_name: 'Phone',
    param: 'contactPhone',
    type: 'text'
  }
];

function PickupLocationRoot() {
  const location = useLocation();
  const queryParamsString = location.search;
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [selectedData, setSelectedData] = useState({});

  const { currentData, isLoading } = useGetWarehouseQuery(
    !isEmpty(selectedData)
      ? {
          page,
          ...selectedData
        }
      : {
          page
        }
  );
  const [changeOpsStatus] = useWarehouseChangeOpsStatusMutation();

  const handleOpsStatus = ({ id, status }) => {
    changeOpsStatus({ warehouseId: id, opsStatus: status });
  };

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="text-red-600 font-medium">{row.name}</div>
          <div>{row._id}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'LOCATION NAME'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="text-gray-700 font-medium">{row.contactEmail}</div>
          <div>{row.contactPhone}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'CONTACT'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="text-gray-700 font-medium">{row?.address?.full || 'N/A'}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ADDRESS'
      }
    ),
    // // columnHelper.accessor(
    // //   (row) => (
    // //     <Switch
    // //       checked={row.opsStatus === 'ACTIVE'}
    // //       onChange={() => {
    // //         const newStatus =
    // //           row.opsStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
    // //         handleOpsStatus({ id: row.id, status: newStatus });
    // //       }}
    // //       className={clsx(
    // //         row.opsStatus === 'ACTIVE' ? 'bg-red-600' : 'bg-gray-200',
    // //         'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2'
    // //       )}
    // //     >
    // //       <span className="sr-only">Use setting</span>
    // //       <span
    // //         className={clsx(
    // //           row.opsStatus === 'ACTIVE' ? 'translate-x-5' : 'translate-x-0',
    // //           'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
    // //         )}
    // //       >
    // //         <span
    // //           className={clsx(
    // //             row.opsStatus === 'ACTIVE'
    // //               ? 'opacity-0 duration-100 ease-out'
    // //               : 'opacity-100 duration-200 ease-in',
    // //             'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
    // //           )}
    // //           aria-hidden="true"
    // //         >
    // //           <svg
    // //             className="h-3 w-3 text-gray-400"
    // //             fill="none"
    // //             viewBox="0 0 12 12"
    // //           >
    // //             <path
    // //               d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
    // //               stroke="currentColor"
    // //               strokeWidth={2}
    // //               strokeLinecap="round"
    // //               strokeLinejoin="round"
    // //             />
    // //           </svg>
    // //         </span>
    // //         <span
    // //           className={clsx(
    // //             row.opsStatus === 'ACTIVE'
    // //               ? 'opacity-100 duration-200 ease-in'
    // //               : 'opacity-0 duration-100 ease-out',
    // //             'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
    // //           )}
    // //           aria-hidden="true"
    // //         >
    // //           <svg
    // //             className="h-3 w-3 text-red-600"
    // //             fill="currentColor"
    // //             viewBox="0 0 12 12"
    // //           >
    // //             <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
    // //           </svg>
    // //         </span>
    // //       </span>
    // //     </Switch>
    // //   ),
    //   {
    //     cell: (info) => info.getValue(),
    //     header: 'OPS STATUS',
    //     meta: { align: 'center', style: { textAlign: 'center' } }
    //   }
    // ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <Link to={`/app/warehouse/detail?warehouseId=${row.id}`}>
            <button className="text-red-600 mr-3 hover:underline">View</button>
          </Link>
          <Link to={`/app/warehouse/edit?warehouseId=${row.id}`}>
            <button className="text-red-600 ml-3 hover:underline">Edit</button>
          </Link>
          <Link to={`/app/warehouse/users?warehouseId=${row.id}`}>
            <button className="text-red-600 ml-6 hover:underline">Users</button>
          </Link>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION'
      }
    )
  ];

  const handleSelectedData = (data) => {
    navigate(`/app/warehouse/view${mapFilterToUrlParam(data)}`);
    setSelectedData(data);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedData]);

  useEffect(() => {
    setSelectedData(getQueryParams(queryParamsString));
  }, [queryParamsString]);

  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <ApplyFilter
              data={searchModes}
              selectedData={selectedData}
              handleSelectedData={handleSelectedData}
            />
            <Link
              to="/app/warehouse/create"
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Create
            </Link>
          </div>
        </div>
      </div>

      <Table data={currentData?.data?.warehouses ?? []} columns={columns} />
      <TablePagination
        paginationData={currentData?.data?.meta ?? undefined}
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

export default PickupLocationRoot;

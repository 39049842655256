import React, { Fragment } from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  Image
} from '@react-pdf/renderer';
import useQrDataUrl from '../../../hooks/useQrDataUrl';
import { Dialog, Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import clsx from 'clsx';

// Register Bangla font using a local path
Font.register({
  family: 'SolaimanLipi',
  src: '/SolaimanLipi.ttf'
});

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
    fontFamily: 'SolaimanLipi'
  },
  rowSection: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  section: {
    marginBottom: 10
  },
  boldText: {
    fontSize: 14
  },
  sectionCenter: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 10,
    marginBottom: 10,
    borderBottom: 1
  },
  sectionCenterBold: {
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 18,
    marginBottom: 10
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: 'center'
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  }
});
const height = window.screen.height * 0.7;

const DocumentItem = ({
  item,
  totalQuantity,
  totalPrice,
  totalDiscount,
  totalPayable,
  chamakHotline
}) => {
  const { QRbase64 } = useQrDataUrl({ text: item.id });
  return (
    <Page style={styles.page}>
      <View style={styles.rowSection}>
        <View style={styles.section}>
          <Text style={styles.boldText}>নাম: {item.customer?.name}</Text>
          <Text>ফোন: {item?.customer?.phone}</Text>
          <Text>প্রতি: {item?.customer?.shopName}</Text>
          <Text>রুট: {item?.route?.name}</Text>
          <Text>ঠিকানা: {item?.address?.full}</Text>
          <Text>নোট: {item?.deliveryInstruction}</Text>
        </View>
        <View style={styles.section}>
          <Text>অ: নং {item?.id}</Text>
          <Text>অ: তাং {dayjs(item?.createdAt).format('DD MMM YYYY')}</Text>
          <Text>ডে: তাং {dayjs(item?.deliveryDate).format('DD MMM YYYY')}</Text>
          <Text>রেফ: {item?.salesAssociate?.name}</Text>
          <Text>( {item?.salesAssociate?.phone})</Text>
          <Text>ওহা: {item?.warehouse?.name}</Text>
        </View>
        <Image
          source={{ uri: QRbase64 }}
          style={{
            height: '80px',
            maxWidth: '80px',
            width: '80px'
          }}
        />
      </View>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Item</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Des.</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Qty</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Price</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Dis.</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Total</Text>
            </View>
          </View>
          {item?.items?.map((child, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{child.product?.sku}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text
                  style={styles.tableCell}
                >{`${child.product?.nameBn} ${child.product?.weight}kg\nফেরতযোগ্য - ${child.product?.returnInDays} দিন`}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{child.quantity}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{child.price}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{child.discount}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {child.price - child.discount}
                </Text>
              </View>
            </View>
          ))}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}></Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Total</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{totalQuantity}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{totalPrice}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{totalDiscount}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{totalPayable}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.sectionCenter}>
        <Text>চমক হটলাইনঃ {chamakHotline}</Text>
      </View>
      <View style={styles.sectionCenterBold}>
        <Text>পেমেন্ট স্লিপ</Text>
      </View>
      <View style={styles.rowSection}>
        <View>
          <Text>নাম: {item?.customer?.name}</Text>
          <Text>ফোন: {item?.customer?.phone}</Text>
          <Text>প্রতি: {item?.customer?.shopName}</Text>
          <Text>অ.নং {item?.id}</Text>
          <Text>অ: তাং {dayjs(item?.createdAt).format('DD MMM YYYY')}</Text>
        </View>
        <View>
          <Text>ডে: তাং {dayjs(item?.deliveryDate).format('DD MMM YYYY')}</Text>
          <Text>মোট টাকা: {totalPayable}/-</Text>
          <Text>গ্রহণকৃত টাকা: __________</Text>
          <Text>বেকয়া: ____________</Text>
        </View>
      </View>
    </Page>
  );
};

const MyDocument = ({ data }) => {
  return (
    <Document>
      {React.Children.toArray(
        data?.map((item) => (
          <DocumentItem
            item={item}
            totalQuantity={item?.invoice?.totalQuantity}
            totalPrice={item?.invoice?.totalPrice}
            totalDiscount={item?.invoice?.totalDiscount}
            totalPayable={item?.invoice?.totalPayable}
            chamakHotline={item?.chamakHotline}
          />
        ))
      )}
    </Document>
  );
};

const PrintModal = ({
  data,
  open,
  setOpen,
  isIFrameEnabled,
  handleFunc,
  disabled
}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return open ? (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-[99999]" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-scroll">
          <div className="flex relative justify-center text-center sm:items-center sm:p-0">
            <div className="mt-3 w-[60vw] text-center sm:mt-0 sm:text-left">
              <div className="mt-2 relative">
                <div className="absolute inset-x-0 rounded-lg my-10 z-[99999999999] bg-white justify-center border shadow-sm">
                  <div className="sm:block justify-between mb-2">
                    <div className="sm:mt-0 sm:flex-none">
                      {isIFrameEnabled ? (
                        <iframe
                          id="dlRetpdfPreview"
                          width="100%"
                          height={height}
                          src={
                            data ? `data:application/pdf;base64,${data}` : ''
                          }
                        />
                      ) : (
                        <PDFViewer width="100%" height={height}>
                          <MyDocument data={data} />
                        </PDFViewer>
                      )}

                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse print:hidden">
                        <button
                          type="button"
                          className={clsx(
                            `inline-flex w-full justify-center ${
                              isIFrameEnabled
                                ? 'bg-red-600 text-white'
                                : 'text-gray-900'
                            } rounded-md px-8 mr-8 mb-2 py-2 text-sm font-semibold sm:ml-3 sm:w-auto print:hidden`
                          )}
                          onClick={handleClose}
                        >
                          Close
                        </button>
                        {!isIFrameEnabled && (
                          <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-8 mr-4 mb-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto print:hidden disabled:bg-gray-500"
                            onClick={handleFunc}
                            disabled={disabled}
                          >
                            Set Invoices as Printed
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  ) : null;
};

export default PrintModal;

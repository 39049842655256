import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

export default function InputFieldWithPlusMinus({
  id,
  type,
  value,
  onChange,
  isOnChange = false,
  leftDisabled = false,
  rightDisabled = false,
  inputDisabled = false
}) {
  const [qty, setQty] = useState('');
  const handleDecrement = () => {
    onChange(id, String(parseFloat(qty, 10) - 1));
  };
  const handleIncrement = () => {
    onChange(id, String(parseFloat(qty, 10) + 1));
  };
  const handleOnChange = (val) => {
    onChange(id, String(parseFloat(val, 10)));
  };
  useEffect(() => {
    setQty(value);
  }, [value]);
  return (
    <div className="mt-2 flex gap-3">
      <button
        type="button"
        className="rounded-md w-14 py-2 border-red-600 border-2 text-sm font-semibold text-red-600 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-white disabled:border-2 disabled:border-gray-500 disabled:text-gray-500"
        onClick={handleDecrement}
        disabled={leftDisabled}
      >
        -
      </button>
      <input
        type={type}
        className={clsx(
          'block w-20 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6 disabled:border-gray-500'
        )}
        {...(!isOnChange && { defaultValue: qty })}
        {...(!isOnChange && {
          onBlur: (event) => handleOnChange(event.target.value)
        })}
        {...(isOnChange && { value: qty })}
        {...(isOnChange && {
          onChange: (event) => handleOnChange(event.target.value)
        })}
        disabled={inputDisabled}
        readOnly={inputDisabled}
      />

      <button
        type="button"
        className="rounded-md w-14 border-2 border-green-600 py-2 text-sm font-semibold text-green-600 shadow-sm transition-all disabled:bg-white disabled:border-2 disabled:border-gray-500 disabled:text-gray-500"
        onClick={handleIncrement}
        disabled={rightDisabled}
      >
        +
      </button>
    </div>
  );
}

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import React from "react";

export default function Input({ query, setQuery, title = "" }) {
  return (
    <div>
      <label
        htmlFor="search"
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        Search {title}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type="text"
          id="search"
          className="block w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
          placeholder="Search Typing..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { Link, useNavigate } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import toast from 'react-hot-toast';
import { useUpdatePasswordMutation } from '../../../store/features/profile/passwordApi';

export default function PasswordUpdate() {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm();
  const newPassword = watch('newPassword', '');
  const navigate = useNavigate();

  const [updatePassword, { isLoading, isSuccess, isError, error }] =
    useUpdatePasswordMutation();

  const onSubmit = async (data) => {
    if (data.newPassword.length < 8) {
      toast.error('New Password must be at least 8 characters long.');
    } else if (data.newPassword === data.confirmPassword) {
      await updatePassword(data);
    } else {
      toast.error('New Password and Confirm Password do not match.');
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate('/app/users/view');
    }

    if (isError) {
      toast.error(
        error?.data?.message ||
          'Failed to update password. Please try again later.'
      );
    }
  }, [isSuccess, isError, error, navigate]);

  const pageMeta = {
    page_name: 'Update Password'
  };

  const pages = [
    {
      name: 'Password',
      location: '/app/password-update',
      current: true
    }
  ];

  return (
    <>
      <div className="sm:px-4">
        <div className="sm:flex sm:items-start">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold leading-6 text-gray-900">
              <Link to="/app/password-update/view">
                <span className="flex items-center gap-4 hover:text-gray-500 transition-all">
                  {pageMeta.page_name}
                </span>
              </Link>
            </h1>
            <div className="my-6">
              <BreadCrumbs pages={pages} />
            </div>
          </div>
        </div>
      </div>
      <Form>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        >
          <div className="px-4 py-6 sm:p-8">
            <div className="grid grid-cols-1 gap-y-8">
              {/* Old Password */}
              <div>
                <Controller
                  name="oldPassword"
                  control={control}
                  render={({ field }) => (
                    <FormItem>
                      <label
                        htmlFor="old-password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Old Password*
                      </label>
                      <FormControl>
                        <div className="mt-2">
                          <input
                            type="password"
                            {...field}
                            id="old-password"
                            placeholder="Enter Old Password"
                            className={clsx(
                              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                            )}
                          />
                        </div>
                      </FormControl>
                      {errors.oldPassword && <FormMessage />}
                    </FormItem>
                  )}
                />
              </div>

              {/* New Password */}
              <div>
                <Controller
                  name="newPassword"
                  control={control}
                  render={({ field }) => (
                    <FormItem>
                      <label
                        htmlFor="new-password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        New Password*
                      </label>
                      <FormControl>
                        <div className="mt-2">
                          <input
                            type="password"
                            {...field}
                            id="new-password"
                            placeholder="Enter New Password"
                            className={clsx(
                              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                            )}
                          />
                        </div>
                      </FormControl>
                      {errors.newPassword && <FormMessage />}
                    </FormItem>
                  )}
                />
              </div>

              {/* Confirm Password */}
              <div>
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <FormItem>
                      <label
                        htmlFor="confirm-password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Confirm New Password*
                      </label>
                      <FormControl>
                        <div className="mt-2">
                          <input
                            type="password"
                            {...field}
                            id="confirm-password"
                            placeholder="Confirm New Password"
                            className={clsx(
                              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6'
                            )}
                          />
                        </div>
                      </FormControl>
                      {errors.confirmPassword && <FormMessage />}
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            <button
              type="button"
              onClick={() => navigate('/app/users/view')}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>

            <button
              type="submit"
              disabled={isLoading}
              className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all disabled:bg-gray-500"
            >
              Done
            </button>
          </div>
        </form>
      </Form>
    </>
  );
}

const Form = ({ children }) => <div>{children}</div>;

const FormItem = ({ children }) => <div>{children}</div>;

const FormControl = ({ children }) => <div>{children}</div>;

const FormMessage = () => <p className="text-red-600">Error message</p>;

import React from 'react';
import CreateBrandForm from '../../../components/app/brand/CreateBrandForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Create Brands'
};

const pages = [
  {
    name: 'Manage Brands',
    location: '/app/manage-brands',
    current: false
  },
  { name: 'Create', location: '/app/manage-brands/create', current: true }
];

export default function CreateBrands() {
  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <CreateBrandForm />
    </div>
  );
}

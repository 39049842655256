import { PlusIcon, TagIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Divider from '../../../components/common/Divider';
import { isValidURL } from '../../../lib/utils';
import { useGetCategoryQuery } from '../../../store/features/category/categoryApi';

const rootMeta = {
  page_name: 'Category / SubCategory',
  breadcrumbs: null
};

export default function CategoryRoot() {
  const [page, setPage] = useState(1);
  const [searchMode, setSearchMode] = useState('name');
  const [searchValue, setSearchValue] = useState('');

  const { currentData, isLoading } = useGetCategoryQuery();

  useEffect(() => {
    setPage(1);
  }, [searchValue, searchMode]);
  return (
    <div className="sm:px-4">
      <div className="sm:flex sm:items-center mb-10">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            {rootMeta.page_name}
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="flex items-center">
            <Link
              to="/app/category/create"
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 transition-all"
            >
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Add New
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-white py-4 px-8 rounded-lg">
        {React.Children.toArray(
          currentData?.data?.map((item) => (
            <Fragment>
              <div className="flex items-center justify-between">
                <div className="flex my-2">
                  {isValidURL(item.photo) ? (
                    <img src={item.photo} alt="" width="30" height="30" />
                  ) : (
                    <TagIcon className="w-6 h-6" />
                  )}
                  <span className="ml-2 font-bold">{item.nameEn}</span>
                </div>
                <div>
                  <Link to={`/app/category/edit?categoryId=${item.id}`}>
                    <span className="ml-6 text-red-600 hover:underline">
                      Edit
                    </span>
                  </Link>
                </div>
              </div>
              <Divider className="my-2" />
              {React.Children.toArray(
                item?.children?.map((child) => (
                  <Fragment>
                    <div className="flex items-center justify-between">
                      <div className="flex my-2 ml-6 items-center">
                        {isValidURL(child.photo) ? (
                          <img
                            src={child.photo}
                            alt=""
                            width="30"
                            height="30"
                          />
                        ) : (
                          <TagIcon className="w-6 h-6" color="#BBB" />
                        )}
                        <span className="ml-4">{child.nameEn}</span>
                      </div>
                      <Link to={`/app/category/edit?categoryId=${child.id}`}>
                        <span className="ml-6 text-red-600 hover:underline">
                          Edit
                        </span>
                      </Link>
                    </div>
                    <Divider className="my-2" />
                  </Fragment>
                ))
              )}
            </Fragment>
          ))
        )}
      </div>
    </div>
  );
}

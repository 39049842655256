import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import CreateDeliveryAssociateForm from '../../../components/app/delivery-associate/CreateDeliveryAssociateForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Create Delivery Associate'
};

const pages = [
  {
    name: 'Delivery Associate',
    location: '/app/delivery-associate',
    current: false
  },
  {
    name: 'Create',
    location: '/app/delivery-associate/create',
    current: true
  }
];

export default function CreateDeliveryAssociate() {
  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <CreateDeliveryAssociateForm />
    </div>
  );
}

import { createColumnHelper } from '@tanstack/react-table';
import React, { Fragment } from 'react';
import Table from '../../common/DataTable/Table';
import { RectangleStackIcon } from '@heroicons/react/20/solid';

export default function CustomizeLinkProductsOffer({
  draftProductsOffer,
  removeProduct,
  handleDraftProducts
}) {
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div className="flex gap-x-2">
            {row?.photo1 ? (
              <img src={row?.photo1} alt="" width="30" height="30" />
            ) : (
              <RectangleStackIcon className="w-10 h-10" color="gray" />
            )}
            {row?.photo2 && (
              <img src={row?.photo2} alt="" width="30" height="30" />
            )}
          </div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'PHOTO'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.nameEn}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'NAME'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <div>{row.sku}</div>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'SKU NO.'
      }
    ),
    columnHelper.accessor(
      (row) => (
        <Fragment>
          <button
            className="text-red-600 hover:underline"
            onClick={() => removeProduct(row)}
          >
            Remove
          </button>
        </Fragment>
      ),
      {
        cell: (info) => info.getValue(),
        header: 'ACTION'
      }
    )
  ];
  return (
    <div className="sm:px-4 mt-5">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-3xl font-semibold leading-6 text-gray-900">
            Draft Products Offers
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
      </div>
      <Table data={draftProductsOffer} columns={columns} />
    </div>
  );
}

import toast from 'react-hot-toast';
import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';
export const offerProductApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    getOfferProduct: builder.query({
      query: ({ ...query }) => ({
        url:
          `/api/featured-products/offer-product?` +
          appendParamsToUrl({ ...query }),
        method: 'GET'
      }),
      providesTags: ['getOfferProduct']
    }),
    createOfferProduct: builder.mutation({
      query: (body) => ({
        url: '/api/featured-products/offer-product',
        method: 'POST',
        body
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Offer Product created successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getOfferProduct']
    }),
    deleteSingleOfferProduct: builder.mutation({
      query: ({ inventoryId }) => ({
        url: `/api/featured-products/offer-product`,
        method: 'DELETE',
        body: { inventoryId }
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Offer Products updated successfully');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: ['getOfferProduct']
    })
  })
});
export const {
  useGetOfferProductQuery,
  useCreateOfferProductMutation,
  useDeleteSingleOfferProductMutation
} = offerProductApi;

import clsx from 'clsx';
import React, { useState } from 'react';
import { cn } from '../../../lib/utils';
import SlideOver from '../../common/SlideOver';

export default function FilterTrayDataUser({
  currentFilter,
  setCurrentFilter,
  setDate,
  date,
  selectedIdType,
  setSelectedIdType,
  selectedId,
  setSelectedId,
  setApplyFilter
}) {
  const [open, setOpen] = useState(false);
  return (
    <div className="mt-4 sm:ml-16 sm:mt-6 sm:flex-none overflow-x-scroll scrollbar-none">
      <div className="flex gap-4 sm:flex-row flex-col">
        <div className="sm:flex rounded-md shadow-sm gap-2 text-sm">
          <button
            onClick={() => {
              setApplyFilter(false);
              setSelectedId(null);
              setSelectedIdType(null);
            }}
            className="text-red-600 hover:underline"
          >
            Clear filter
          </button>
          
        </div>

        <div className="sm:flex rounded-md shadow-sm space-y-2 sm:space-y-0">
          <CommonButton
            className={clsx(
              'sm:rounded-l-md sm:-ml-px',
              currentFilter === 'TODAY' &&
                'bg-red-100 border-red-600 text-red-600'
            )}
            onClick={() => setCurrentFilter('TODAY')}
          >
            Today
          </CommonButton>

          <CommonButton
            className={clsx(
              currentFilter === 'THIS_WEEK' &&
                'bg-red-100 border-red-600 text-red-600'
            )}
            onClick={() => setCurrentFilter('THIS_WEEK')}
          >
            This Week
          </CommonButton>

          <CommonButton
            className={
              currentFilter === 'THIS_MONTH' &&
              'bg-red-100 border-red-600 text-red-600'
            }
            onClick={() => setCurrentFilter('THIS_MONTH')}
          >
            This Month
          </CommonButton>

          
        </div>
      </div>
      <SlideOver
        open={open}
        setOpen={setOpen}
        selectedId={selectedId}
        setSelectedId={setSelectedId}
        selectedIdType={selectedIdType}
        setSelectedIdType={setSelectedIdType}
        setApplyFilter={setApplyFilter}
      />
    </div>
  );
}

const CommonButton = ({ children, className, ...props }) => (
  <button
    type="button"
    className={cn(
      'relative whitespace-nowrap w-full sm:inline-flex sm:items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-red-100 focus:z-10',
      className
    )}
    {...props}
  >
    {children}
  </button>
);

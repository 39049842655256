import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadCrumbs from '../../../components/common/BreadCrumbs';
import CreateDataUserForm from '../../../components/app/data-user/CreateDataUserForm';
import TopNavigationBar from '../../../components/common/TopNavigationBar';

const pageMeta = {
  page_name: 'Create Data User'
};

const pages = [
  {
    name: 'Data User',
    location: '/app/data-user',
    current: false
  },
  {
    name: 'Create',
    location: '/app/data-user/create',
    current: true
  }
];

export default function CreateDataUser() {
  return (
    <div className="sm:px-4">
      <TopNavigationBar pageName={pageMeta.page_name} pages={pages} />
      <CreateDataUserForm />
    </div>
  );
}

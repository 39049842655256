import toast from 'react-hot-toast';
import { appendParamsToUrl } from '../../../lib/utils';
import { chamakApi } from '../../app/api';

export const passwordApi = chamakApi.injectEndpoints({
  endpoints: (builder) => ({
    // getProfile: builder.query({
    //   query: ({ ...query }) => ({
    //     url: `/api/profile?` + appendParamsToUrl({ ...query }),
    //     method: 'GET'
    //   }),
    //   providesTags: ['getProfile']
    // }),
    // createSalesAssociate: builder.mutation({
    //   query: ({ name, phone, warehouseId, routeIds }) => ({
    //     url: '/api/users/sales-associates',
    //     method: 'POST',
    //     body: { name, phone, warehouseId, routeIds}
    //   }),
    //   onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
    //     try {
    //       await queryFulfilled;

    //       toast.success('Sales Associate has been created!');
    //     } catch (error) {
    //       toast.error(error.error.data.message);
    //     }
    //   },
    //   invalidatesTags: [
    //     'getSalesAssociate','getUser'
    //     // 'getOneUser, getUnAssignedUsers'
    //   ]
    // }),
    // getOneSalesAssociate: builder.query({
    //   query: ({ salesAssociateId }) => ({
    //     url: `/api/users/sales-associates/${salesAssociateId}`,
    //     method: 'GET'
    //   }),
    //   providesTags: ['getOneSalesAssociateWithId']
    // }),
    updatePassword: builder.mutation({
      query: ({ confirmPassword, ...query }) => ({
        url: `/api/profile/password`,
        method: 'PATCH',
        body: { ...query}
      }),
      onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          toast.success('Password has been updated successfully!');
        } catch (error) {
          toast.error(error.error.data.message);
        }
      },
      invalidatesTags: [
        'getPassword',
      ]
    })
  })
});

export const {
  useGetProfileQuery,
  useCreateSalesAssociateMutation,
  useGetOneSalesAssociateQuery,
  useUpdatePasswordMutation,
} = passwordApi;
